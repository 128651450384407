import React, { useEffect, useState, useContext } from 'react'

import { Grid } from '@material-ui/core/'
import { makeStyles, withStyles } from '@material-ui/core/styles'

/*
 **-------------------- My Components ---------------------------
 */

import FFTextField from '../../../libft/FFTextField'
import FFSelect from '../../../libft/FFSelect'
import FFLoader from '../../../libft/FFLoader'
import FFLoaderComponent from '../../../libft/FFLoaderComponent'
import FFSchedule from './FFSchedule'

import Context from '../../../context'
import moment from 'moment'
import 'moment/locale/ru'
const uuidv4 = require('uuid/v4')

export default function FF_BaseLineSchedule ({
  isLogin,
  handleClickVariant,
  deviceScenario,
  parameters,
  ...props
}) {
  const [valueParameter, setValueParameter] = useState('')
  const [valueParameterPower, setValueParameterPower] = useState('')
  const [idParameter, setIdParameter] = useState(null)
  const [schedule, setSchedule] = useState(null)
  const { toGoSaveAlgorithm } = useContext(Context)

  useEffect(() => {
    if (parameters) {
      setIdParameter(deviceScenario?.actual_value)
      let value = parameters.filter(
        obj => obj.label.indexOf('Базовое потребление мощности') > -1
      )
      if (value.length) {
        setValueParameterPower(value[0].values.value)
      }
    }
  }, [parameters])

  useEffect(() => {
    if (schedule) {
      deviceScenario['schedule_value'] = schedule
      toGoSaveAlgorithm(deviceScenario)
    }
  }, [schedule])

  useEffect(() => {
    let temp = deviceScenario['schedule_value']
    let tempDate = {}
    if (temp && Object.keys(temp).length) {
      if (Object.keys(temp)[0].indexOf('-') > -1) tempDate = temp
      else {
        Object.keys(temp).map(y => {
          Object.keys(temp[y]).map(m => {
            Object.keys(temp[y][m]).map(d => {
              tempDate[
                moment(`${d}-${m}-${y}`, 'E-WW-YYYY').format('YYYY-MM-DD')
              ] = temp[y][m][d]
            })
          })
        })
      }
    }
    setSchedule(tempDate)
  }, [])

  const getSchedule = obj => {
    setSchedule(obj)
  }

  return (
    <Context.Provider value={{}}>
      <FFLoaderComponent
        key={'FFLoaderComponent'}
        loading={parameters}
        isLoader={<FFLoader />}
        component={
          <Grid item xs={12} container>
            <Grid item xs={12} container direction={'column'}>
              <FFTextField
                value={deviceScenario.label}
                id={'deviceScenario.device'}
                name={'label'}
                label={'Наименование'}
                is_disabled={true}
              />
              <FFTextField
                value={'Фактическое потребление'}
                id={'deviceScenario.algorithm'}
                name={'algorithm'}
                label={'Алгоритм'}
                is_disabled={true}
              />
              <FFTextField
                key={valueParameterPower}
                value={valueParameterPower}
                id={'valueParameterPower'}
                name={'valueParameterPower'}
                label={'Базовое потребление мощности'}
                is_disabled={true}
              />
            </Grid>
            {schedule ? (
              <FFSchedule getSchedule={getSchedule} schedule={schedule} />
            ) : null}
          </Grid>
        }
      />
    </Context.Provider>
  )
}
