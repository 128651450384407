import cookie from "react-cookies";
import { notification } from "antd";
// export const urlAuth = 'api-ie-auth'
// // export const urlAuth = 'https://test-ie.foresight-fund.ru/api-ie-auth'

// export const urlBaseLine = 'https://api-base-line.foresight-fund.ru'

// export const urlManage = 'ie.foresight-fund.ru'

// export const urlConfiguration = 'base-api';
// // export const urlConfiguration = 'https://test-ie.foresight-fund.ru/base-api'
// // export const urlConfiguration = 'https://ie.foresight-fund.ru/base-api'
// // export const urlConfiguration = 'http://0.0.0.0:5023'
// console.log(process.env)
// export const urlPortal = 'http://213.59.222.209:8000'
// // export const urlReader = 'https://development.foresight-fund.ru/portal_reader'
// export const urlReader = 'https://api-configurator.foresight-fund.ru/portal-reader-prod'
const openNotification = (url) => {
  notification.error({
    message: "Error",
    description: url,
  });
};

let urlAuth = "https://kc.fund-f.ru/realms/master/protocol/openid-connect/token";
let urlConfiguration = "base-api";
let urlRest = "api-ie-rest";

const urlBaseLine = "https://api-base-line.foresight-fund.ru";
const urlManage = "ie.foresight-fund.ru";
const urlPortal = "http://213.59.222.209:8000";
const urlReader =
  "https://ie.foresight-fund.ru/portal-reader";
//git describe --tags --long
const appVersion = "v0.1.14.10-0-g3cfe1ae";
if (process.env.NODE_ENV === "development" || window.location.host.includes('test-')) {
  urlAuth = "https://test-kc.fund-f.ru/realms/dev/protocol/openid-connect/token";
  urlConfiguration = "https://test-ie.foresight-fund.ru/base-api";
  urlRest = "https://test-ie.foresight-fund.ru/api-ie-rest";
}

export {
  urlAuth,
  urlConfiguration,
  urlRest,
  urlBaseLine,
  urlManage,
  urlPortal,
  urlReader,
  appVersion,
};

export function ApiConnect(props) {
  const strAuthorization = "Bearer " + cookie.load("access_token");

  const getData = (url, setterEssence, setterLoading) => {
    fetch(url, {
      method: "get",
      headers: new Headers({
        Authorization: strAuthorization,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          props.isLogin(false);
        }
        return response.json();
      })
      .then((temp) => {
        if (temp["msg"] && props.clickHandle) {
          if (temp?.code === 204) {
            props.clickHandle("info", temp["msg"]);
          } else {
            props.clickHandle(
              temp["msg"].indexOf("спешно") !== -1 ? "success" : "error",
              temp["msg"]
            );
          }
          return;
        }
        setterEssence(temp);
        if (setterLoading) {
          setterLoading(true);
        }
      })
      .catch((error) => openNotification(url));
  };
  const getFile = (url, objToGet, setterEssence, setterLoader) => {
    fetch(url, {
      method: "post",
      body: JSON.stringify(objToGet),
      headers: new Headers({
        Authorization: strAuthorization,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => {
        if (response.status === 401) props.isLogin(false);
        else if (response.status === 204 && props.clickHandle)
          props.clickHandle("info", "Контент отсутсвует");
        else if (response.status === 200) return response.blob();
      })
      .then((blob) => {
        if (setterEssence) setterEssence(false);
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Выгрузка от ${new Date().toLocaleString()}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const getDataPost = (url, objToGet, setterEssence, setterLoader) => {
    fetch(url, {
      method: "post",
      body: JSON.stringify(objToGet),
      headers: new Headers({
        Authorization: strAuthorization,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          props.isLogin(false);
        }
        return response.json();
      })
      .then((temp) => {
        console.log(temp, url);
        if (setterLoader) {
          setterLoader(true);
        }
        if (setterEssence) {
          setterEssence(temp);
        }
      })
      .catch((error) => openNotification(url));
  };

  const createData = (url, objToCreate) => {
    fetch(url, {
      method: "post",
      body: JSON.stringify(objToCreate),
      headers: new Headers({
        Authorization: strAuthorization,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          props.isLogin(false);
        }
        return response.json();
      })
      .then((temp) => {
        if (props.clickHandle)
          props.clickHandle(
            temp["msg"].indexOf("спешно") !== -1 ? "success" : "error",
            temp["msg"]
          );
      })
      .catch((error) => openNotification(url));
  };

  const deleteDate = (url, objToDelete) => {
    fetch(url, {
      method: "delete",
      body: JSON.stringify(objToDelete),
      headers: new Headers({
        Authorization: strAuthorization,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          props.isLogin(false);
        }
        return response.json();
      })
      .then((temp) => {
        if (props.setterEssence) props.setterEssence(temp);
        if (props.clickHandle)
          props.clickHandle(
            temp["msg"].indexOf("спешно") !== -1 ? "success" : "error",
            temp["msg"]
          );
      })
      .catch((error) => openNotification(url));
  };

  const updateDate = (url, objToCreate, setterEssence) => {
    fetch(url, {
      method: "put",
      body: JSON.stringify(objToCreate),
      headers: new Headers({
        Authorization: strAuthorization,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          props.isLogin(false);
        }
        // else if (response.status !== 200) {
        //   if (props.clickHandle) {
        //     props.clickHandle('error', 'Что-то с бэкендом')
        //   } else {
        //     props.isLogin(false)
        //   }
        // }
        return response.json();
      })
      .then((temp) => {
        if (temp?.msg && props.clickHandle) {
          if (setterEssence) {
            setterEssence(temp);
          }
          props.clickHandle(
            temp["msg"].indexOf("спешно") !== -1 ? "success" : "error",
            temp["msg"]
          );
        } else {
          props.clickHandle("info", temp);
        }
      })
      .catch((error) => openNotification(url));
  };

  if (props.name === "getData") {
    return getData(props.url, props.setterEssence, props.setterLoading);
  } else if (props.name === "getFile") {
    return getFile(
      props.url,
      props.objToGet,
      props.setterEssence,
      props.setterLoading
    );
  } else if (props.name === "getDataPost") {
    return getDataPost(
      props.url,
      props.objToGet,
      props.setterEssence,
      props.setterLoading
    );
  } else if (props.name === "createData") {
    return createData(props.url, props.objToCreate);
  } else if (props.name === "deleteDate") {
    return deleteDate(props.url, props.objToDelete);
  } else if (props.name === "updateDate") {
    return updateDate(props.url, props.objToUpdate, props.setterEssence);
  }
  return "";
}
