import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import moment from "moment";
import { isNil } from "ramda";

export const SaveAlert = ({ saveResult }) => {
    console.log(saveResult);
    if (saveResult.code === 200) {
        return (
            <Alert severity="success">
                Успешно сохранено
            </Alert>
        );
    } else {
        return (
            <Alert severity="error">
                {`Не сохранено. Ошибка ${saveResult.code} ${saveResult.error.message}`}
            </Alert>
        );
    }
    
}