import React, { useState, useEffect, useContext } from "react";
import { Tree } from "antd";
import Context from "../../context";
import { Grid } from "@material-ui/core";
import { ApiConnect, urlConfiguration } from "../../ApiConnectNew";
import FFLoaderComponent from "../../libft/FFLoaderComponent";
import FFLoader from "../../libft/FFLoader";
import "antd/dist/antd.css";
import { listToTree } from "../../utils/list-to-tree";

const initTreeData = [
  {
    title: "Expand to load",
    id: "0",
  },
  {
    title: "Expand to load",
    id: "1",
  },
  {
    title: "Tree Node",
    id: "2",
    isLeaf: true,
  },
]; // It's just a simple demo. You can use tree map to optimize update perf.
const fieldNames = { title: "label", key: "id", children: "children" };

function updateTreeData(list, id, children) {
  return list.map((node) => {
    if (node.id === id) {
      return { ...node, children };
    }

    if (node?.children && node?.children?.length) {
      return {
        ...node,
        children: updateTreeData(node.children, id, children),
      };
    }

    return node;
  });
}

export const AsyncTree = ({
  setEssence,
  setEssenceLoading,
  essenceLoading,
}) => {
  const {
    doClickActiveEssence,
    doClickActiveEssenceDialog,
    doGoPositionScroll,
    doClickMetaSelect,
  } = useContext(Context);
  //   const [treeData, setTreeData] = useState([]);
  const [treeData, setTreeData] = useState([]);

  const [objects, setObjects] = useState([]);

  useEffect(() => {
    ApiConnect({
      name: "getData",
      url:
        urlConfiguration +
        "/api/support/info/?entity=essence&action=select&info=tree",
      setterEssence: setTreeData,
      setterLoading: setEssenceLoading,
      isLogin: true,
    });

    ApiConnect({
      name: "getData",
      url: `${urlConfiguration}/api/support/info/all_essences`,
      setterEssence: setObjects,
      isLogin: true,
    });

    // https://test-ie.foresight-fund.ru/base-api/api/support/info/all_essences
  }, []);

  useEffect(() => {
    console.log(objects);
    const tree = listToTree(objects, { id: "id", parentId: "parent_id" });
  }, [objects]);

  useEffect(() => {
    setEssence(treeData);
  }, [treeData]);

  function updateTreeData(list, id, children) {
    return list.map((node) => {
      if (node.id === id) {
        return { ...node, children };
      }

      if (node?.children && node?.children?.length) {
        return {
          ...node,
          children: updateTreeData(node.children, id, children),
        };
      }

      return node;
    });
  }

  const onLoadData = ({ id, children }) =>
    new Promise((resolve) => {
      if (children && children?.length) {
        resolve();
        return;
      }
      setTimeout(async () => {
        await ApiConnect({
          name: "getData",
          url: `${urlConfiguration}/api/support/info/?entity=essence&action=select&info=tree&id=${id}`,
          setterEssence: (result) => {
            if (result && result?.length === 1) {
              setTreeData((origin) =>
                updateTreeData(origin, id, result[0]?.children || [])
              );
            }
          },
          setterLoading: () => resolve(),
          isLogin: true,
        });
      });
    });

  return (
    <div style={{ flexGrow: 1, height: "100%" }}>
      <FFLoaderComponent
        key={essenceLoading}
        loading={essenceLoading}
        isLoader={<FFLoader />}
        component={
          <Tree
            loadData={onLoadData}
            treeData={treeData}
            fieldNames={fieldNames}
            onSelect={(_, info) => {
              console.log(info?.selectedNodes[0]?.id);
              doClickActiveEssence(
                info?.selectedNodes[0]?.id,
                info?.selectedNodes[0]
              );
            }}
          />
        }
      />
    </div>
  );
};
