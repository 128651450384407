/* eslint-disable consistent-return */
import cookie from "react-cookies";
import Context from "../depricated/context";
// import { store } from "../store/store";
import { setIsLogin } from "../features/auth-login/slice";
import { METHOD, API_BASE_PATH, apiPrefix } from "./api.consts";

export function queryStringify(data) {
  if (!data) return "";

  const queryArr = Object.entries(data).map(
    ([key, value]) => `${key}=${value}`
  );

  return `?${queryArr.join("&")}`;
}

export class HTTP {
  static contextType = Context;
  _path = API_BASE_PATH;

  constructor(path = "") {
    this._path += path;
  }

  get(url, options = {}) {
    return this.request(url, { ...options, method: METHOD.GET });
  }

  post(url, options = {}) {
    return this.request(url, { ...options, method: METHOD.POST });
  }

  put(url, options = {}) {
    return this.request(url, { ...options, method: METHOD.PUT });
  }

  delete(url, options = {}) {
    return this.request(url, { ...options, method: METHOD.DELETE });
  }

  request(url, options = { method: METHOD.GET }) {
    function serializeBody(method, data) {
      if (method === METHOD.GET || method === METHOD.DELETE) {
        return;
      }
      if (data instanceof FormData) {
        return data;
      }
      return JSON.stringify(data);
    }

    function serializeHeader(method, data) {
      const strAuthorization = cookie.load("access_token");
      const header = { authorization: `Bearer ${strAuthorization}` };

      if (method === METHOD.GET || method === METHOD.DELETE) {
        return header;
      }
      if (data instanceof FormData) {
        return header;
      }
      return { ...header, "Content-Type": "application/json" };
    }

    const { method, data, responseFormat = "json" } = options;

    const defaultReject = (response) => {
      if (response instanceof TypeError) {
        response.message = `${response.message} URL: ${url}`;
        return Promise.reject(response);
      } else if (response.status === 401) {
        // TODO:
        // store.dispatch(setLogin(false));
      } else {
        return Promise.reject(response);
      }
    };

    const basePath = `${this._path}${url}`;
    const path =
      method === METHOD.GET || method === METHOD.DELETE
        ? `${basePath}${queryStringify(data)}`
        : basePath;
    return fetch(path, {
      method,
      body: serializeBody(method, data),
      headers: serializeHeader(method, data),
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }

        return response[responseFormat]();
      })
      .then((resData) => resData)
      .catch(defaultReject);
  }
}

export const client = new HTTP(`https://${apiPrefix}ie.foresight-fund.ru`);
