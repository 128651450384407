import React, { useEffect, useState, useContext } from 'react'

import Context from '../../../../context'

import {
  Grid,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@material-ui/core'

import FFTextFields from '../../../../components/FFTextFields'

import FF_Classifier_step3_formatData_valueRange from './FF_Classifier_step3_formatData_valueRange'

export default function FF_Essence_parameterRouterValueRange (props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // ---------------------------- ---------------------------------------------------
  const [meta, setMeta] = useState(props.meta)
  const [value, setValue] = useState(props.value)
  const source = props.source

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  const pFormatDataValueList = (name, tempMeta) => {
    setMeta(tempMeta)
  }

  const handleChangeFFTextFields = (value2, id, name, element) => {
    if (element) {
      if (source.name === 'p-source-data-user') {
        if (isNaN(Number(value2))) {
          return 'Укажите положительное число'
        } else if (Number(value2) < Number(meta.left.value)) {
          return 'Укажите число не меньше нижней границы'
        } else if (Number(value2) > Number(meta.right.value)) {
          return 'Укажите число не больше верней границы'
        } else {
          setValue(Number(value2))
          return ''
        }
      } else {
        setValue(value2)
      }
    }
    return ''
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    props.getBack({
      name: 'p-format-data-value-range',
      value: value,
      meta: meta
    })
  }, [value, meta])
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={2}>
        <Typography variant={"body1"}>{props.label}</Typography>
        <Typography variant={"body2"} color={"primary"}>{`(${props.name})`}</Typography>
      </Grid>
      <Grid item xs={5} style={{ textAlign: 'center' }}>
        <Typography>{'Источник данных - ' + source.label}</Typography>
        {source.name !== 'p-source-data-user' ? (
          <Context.Provider value={{ handleChangeFFTextFields }}>
            <FFTextFields
              style={{ width: '100%' }}
              key={'FFTextFields' + 'value'}
              value={value}
              is_filter={false}
              name={'value'}
              label={'Значение'}
              parameter={{ id: 'valueList' }}
              dis={false}
            />
          </Context.Provider>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Context.Provider value={{ handleChangeFFTextFields }}>
                <FFTextFields
                  style={{ width: '100%' }}
                  key={'FFTextFieldsValueRange' + value}
                  value={value}
                  is_filter={false}
                  name={'length'}
                  label={'Значение'}
                  parameter={{ id: 'value' }}
                  dis={false}
                />
              </Context.Provider>
            </Grid>
            <Grid item>{meta.left.value}</Grid>
            <Grid item xs={8} style={{ alignSelf: 'center' }} spacing={1}>
              <Slider
                aria-labelledby='discrete-slider'
                value={value}
                valueLabelDisplay='auto'
                step={Number(meta.step)}
                marks
                min={
                  Number(meta.left.value) <= Number(meta.right.value)
                    ? Number(meta.left.value)
                    : Number(meta.right.value)
                }
                onChange={handleChange}
                max={Number(meta.right.value)}
              />
            </Grid>
            <Grid item>{meta.right.value}</Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={5} style={{ textAlign: 'center' }}>
        <Typography>{'Формат данных - (Значение из диапазона)'}</Typography>
        <Context.Provider value={{ pFormatDataValueList }}>
          <FF_Classifier_step3_formatData_valueRange
            id={props.id}
            key={'FF_Classifier_step3_formatData_valueRange' + props.id}
            value={JSON.parse(JSON.stringify({ value: meta }))}
            props={'meta'}
          />
        </Context.Provider>
      </Grid>
    </Grid>
  )
}
