import { client } from "./api";

export const api = {
  essence: {
    get: (props) =>
      client.get("/base-api/api/support/info/all_essences", { data: props }),
  },
  client: {
    get: () => client.get("/base-api/api/support/info/fast_tree"),
  },
  mapping: {
    consumers: () => client.get("/reconf-api/consumers"),
    essences: (consumer_id) =>
      client.get("/reconf-api/v2/essences", { data: { consumer_id } }),
    pairsEssenceToEssence: {
      get: (consumer_id) =>
      client.get("/reconf-api/v1/pair/essenceToEssence", { data: { consumer_id } }),
      save: (id, data) => client.post(`/reconf-api/v1/pair/essenceToEssence?consumer_id=${id}`, { data }),
    },
    pairsEssenceToDc: {
      get: (consumer_id) =>
      client.get("/reconf-api/v1/pair/essenceToDc", { data: { consumer_id } }),
      save: (id, data) => client.post(`/reconf-api/v1/pair/essenceToDc?consumer_id=${id}`, { data }),
    },
    sync: {
      post: () => client.post("/reconf-api/v1/sync"),
    },
    syncCheck: {
      get: () =>
      client.get("/reconf-api/v1/sync/check"),
    }
  },
};
