import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Context from '../context'

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
}))

export default function FFSelect ({
  id,
  name,
  value,
  label,
  style,
  options,
  ...props
}) {
  const classes = useStyles()

  const { changeValue } = useContext(Context)

  const [state, setState] = useState(value)

  const handleChange = newValue => {
    setState(newValue)
    changeValue(newValue, id, name, 'select')
  }

  return (
    <Autocomplete
      className={classes.margin}
      multiple
      limitTags={2}
      options={options}
      style={style ? style : null}
      getOptionLabel={option => option.label}
      value={state}
      onChange={(e, val, t) => handleChange(val)}
      renderInput={params => (
        <TextField {...params} variant='outlined' label={label} />
      )}
    />
  )
}
