import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Switch } from '@material-ui/core'

import Context from '../context'

const useStyles = makeStyles(theme => ({
  textField: {
    margin: theme.spacing(1)
  }
}))

export default function FFSwitch ({ value, id, name, ...props }) {
  const { changeValue } = useContext(Context)

  const [checked, setChecked] = useState(value)

  const classes = useStyles()

  const toggleChecked = () => {
    setChecked(prev => !prev)
    changeValue(!checked, id, name, 'FFSwitch')
  }
  return (
    <Switch
      className={classes.margin}
      checked={checked}
      onChange={toggleChecked}
      color='primary'
    />
  )
}
