import React, { useState, useContext } from 'react'
import Context from '../../../../context'
import { Grid, Button, IconButton } from '@material-ui/core'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

/*
 **-------------------- My Components ---------------------------
 */

import FFSelectTree from '../../../../components/FFSelectTree'
import FFSwitch_N from '../../../../libft/FFSwitchN_new'
import FFTextField from '../../../../libft/FFTextField'
import FFSelect from '../../../../libft/FFSelect'
import FFToggleSimple from '../../../../components/FFToggleSimple2'
import { ButtonError, ButtonSuccess } from '../../../../libft/FFDialogDelete'
import FFActionButton from '../../../../libft/FFActionButton'
/*
 **------------------- My Page Components -------------------------
 */

/** */
/**Диалоговое окно для ввода или удаления фильтров применяемых к дереву сущностей
 * @param  {Boolean} open Флаг открытия диалогово окна
 * @param  {ReactHook} setOpen Хук для изменения состояния диалога
 * @param  {String} keyFilter Ключ, какой фильтр мы хотим применить
 * @param  {Object} objFilter Сами элементы фильтра
 * @param  {Function} updateTree Функция для отправки запроса на бэк, для обновления дерева
 */
const FFDialogManipulationTree = ({
  open,
  setOpen,
  keyFilter,
  objFilter,
  updateTree
}) => {
  const [updateItems, setUpdateItems] = useState(1)

  const doChangeSelect = (name, value, obj) => {
    const temp = objFilter
    const index = name.split('#').length === 2 ? Number(name.split('#')[1]) : -1

    if (Array.isArray(temp.value)) {
      if (index >= 0 && index < temp.value.length) temp.value[index] = value
      else temp.value = [...temp.value, value]
    } else temp.value = value
    setUpdateItems(updateItems + 1)
  }

  const changeValue = (value, id, name, type) => {
    const temp = objFilter
    const index = name.split('#').length === 2 ? Number(name.split('#')[1]) : -1
    if (type === 'FFSwitch_N') {
      temp.is_and = value
      return
    }
    if (keyFilter !== 'id_parameter') {
      if (Array.isArray(temp.value)) {
        if (index >= 0 && index < temp.value.length) temp.value[index] = value
        else temp.value = [...temp.value, value]
      } else temp.value = value
      setUpdateItems(updateItems + 1)
    } else if (keyFilter === 'id_parameter' && type === 'select') {
      if (index >= 0 && index < temp.value.length)
        temp.value[index] = {
          id_parameter: value,
          value: null,
          is_active: false
        }
      else
        temp.value = [
          ...temp.value,
          { id_parameter: value, value: null, is_active: false }
        ]
      setUpdateItems(updateItems + 1)
    } else if (keyFilter === 'id_parameter') {
      temp.value[index].value = value
    }
  }

  const handleChangeFFTextFields = (value, id, name, type) => {
    const temp = objFilter
    const index = name.split('#').length === 2 ? Number(name.split('#')[1]) : -1
    if (index >= 0 && index <= temp.value.length)
      temp.value[index].is_active = !temp.value[index].is_active
    temp.value[index].value = null
    setUpdateItems(updateItems + 1)
  }

  const deleteItems = index => {
    objFilter.value.splice(index, 1)
    setUpdateItems(updateItems + 1)
  }

  const buttonDelete = index => {
    if (index > -1)
      return (
        <Grid item xs={1} container alignItems='center'>
          <FFActionButton
            type={'delete'}
            onClick={() => {
              deleteItems(index)
            }}
            color='error'
            tooltip={'Удалить фильтр'}
          />
        </Grid>
      )
    return null
  }
  const routerComponent = (value = undefined, index = undefined) => {
    switch (objFilter.type) {
      case 'FFSelect':
        return (
          <Grid container item xs={12}>
            <Grid container item xs={index > -1 ? 11 : 12}>
              <FFSelect
                value={
                  Array.isArray(objFilter.value)
                    ? value || ''
                    : objFilter.value || ''
                }
                label={objFilter.title}
                name={`Select_${keyFilter}#${index}`}
                options={objFilter.options || []}
                style={{ flexGrow: 1, margin: 8 }}
              />
            </Grid>
            {buttonDelete(index)}
          </Grid>
        )
      case 'FFSelectTree':
        return (
          <Grid container item xs={12}>
            <Grid container item xs={index > -1 ? 11 : 12}>
              <FFSelectTree
                key={'FFSelectTree'}
                stateMeta={{ open: false, active: -1, label: '' }}
                idCls={`Select_${keyFilter}#${index}`}
                selectValue={
                  Array.isArray(objFilter.value)
                    ? value || ''
                    : objFilter.value || ''
                }
                selectMetaLabel={objFilter.title}
                selectNodes={objFilter?.options || []}
                style={{ flexGrow: 1, margin: 8 }}
              />
            </Grid>
            {buttonDelete(index)}
          </Grid>
        )
      case 'FFSelectAndText':
        return (
          <Grid container xs={12}>
            <Grid container xs={index > -1 ? 5 : 6}>
              <FFSelect
                value={value?.id_parameter || ''}
                label={'Параметр'}
                name={`Select_${keyFilter}#${index}`}
                options={objFilter.options || []}
                style={{ flexGrow: 1, margin: 8 }}
              />
            </Grid>
            <Grid container xs={1} style={{ alignItems: 'center' }}>
              <FFToggleSimple
                value={value?.is_active || false}
                name={`Toogle_${keyFilter}#${index}`}
              />
            </Grid>
            <Grid container xs={5}>
              <FFTextField
                is_disabled={!value?.is_active && true}
                value={value?.value || ''}
                label={'Значение параметра'}
                name={`TextField_${keyFilter}#${index}`}
                style={{ flexGrow: 1, margin: 8 }}
              />
            </Grid>
            {buttonDelete(index)}
          </Grid>
        )
      default:
        return (
          <FFTextField
            is_disabled={!objFilter.is_active}
            value={
              Array.isArray(objFilter.value)
                ? value || ''
                : objFilter.value || ''
            }
            label={objFilter.title}
            name={keyFilter}
            style={{ flexGrow: 1, margin: 8 }}
          />
        )
    }
  }

  return (
    <Context.Provider
      value={{ changeValue, doChangeSelect, handleChangeFFTextFields }}
    >
      <Dialog
        open={open}
        maxWidth={'sm'}
        fullWidth={true}
        onClose={e => setOpen(false)}
      >
        <DialogTitle>{objFilter.title}</DialogTitle>
        <DialogContent>
          <Grid
            container
            xs={12}
            style={{ flexDirection: 'column' }}
            key={`items_${updateItems}`}
          >
            {Array.isArray(objFilter.value)
              ? objFilter.value.map((temp, index) =>
                  routerComponent(temp, index)
                )
              : routerComponent()}
            {Array.isArray(objFilter.value) && routerComponent('', -1)}

            {'is_and' in objFilter && objFilter['is_and'] > -1 && (
              <Grid container xs={12} style={{ justifyContent: 'center' }}>
                <FFSwitch_N
                  id={`FFSwitch${keyFilter}`}
                  meta={{
                    label: ['Логическое ИЛИ', 'Логическое И'],
                    value: objFilter.is_and
                  }}
                  name={'is_and'}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonError
            onClick={e => {
              objFilter.value = Array.isArray(objFilter.value) ? [] : null
              setUpdateItems(updateItems + 1)
            }}
          >
            {'Удалить фильтр'}
          </ButtonError>
          <Button
            onClick={e => {
              setOpen(false)
              console.log(objFilter)
            }}
            variant='contained'
            color='primary'
          >
            {'Сохранить'}
          </Button>
          <ButtonSuccess
            onClick={e => {
              updateTree()
              setOpen(false)
            }}
            variant='contained'
            color='primary'
          >
            {'Обновить дерево'}
          </ButtonSuccess>
        </DialogActions>
      </Dialog>
    </Context.Provider>
  )
}

export default FFDialogManipulationTree
