import React, { useRef } from 'react'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx'
import { fade, makeStyles } from '@material-ui/core/styles'
import TreeItem from '@material-ui/lab/TreeItem'

import { Grid, Tooltip } from '@material-ui/core'

const useTreeItemStyles = makeStyles(theme => ({
  group: {
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderLeft: `1px solid ${fade(theme.palette.text.primary, 0.4)}`,
    '& $content': {}
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    margin: theme.spacing(0.2)
  }
}))

export default function FFTreeItem (props) {
  const classes = useTreeItemStyles()
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    badge,
    obj,
    active,
    ...other
  } = props

  return (
    <TreeItem
      label={
        <Grid className={clsx(classes.labelRoot)}>
          <Grid item xs={9}>
            <Tooltip title={labelText}>
              <Typography
                component={'p'}
                variant='body2'
                className={classes.labelText}
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
              >
                {labelText}
              </Typography>
            </Tooltip>
          </Grid>

        </Grid>
      }
      classes={{
        group: classes.group
      }}
      {...other}
    />
  )
}


// <Grid>
// {badge !== 0 ? (
//   <Chip color='primary' size='small' label={badge} />
// ) : null}
// {obj && 'is_default_value' in obj ? (
//   <IconBrightnessAuto
//     color='action'
//     visibility={obj.is_default_value ? 'visible' : 'hidden'}
//   />
// ) : null}

// {obj && 'is_system' in obj && obj.is_system ? (
//   <IconHttps
//     color='action'
//     visibility={obj.is_system ? 'visible' : 'hidden'}
//   />
// ) : null}
// </Grid>
