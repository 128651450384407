import React, { useEffect, useState, useContext } from 'react'

import Context from '../../../../context'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import FFTextFields from '../../../../components/FFTextFields'

import FF_Classifier_step3_formatData_valueList from './FF_Classifier_step3_formatData_valueList'

const uuidv4 = require('uuid/v4')

export default function FF_Essence_parameterRouterValueList (props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // ---------------------------- ---------------------------------------------------
  const [meta, setMeta] = useState(props.meta)
  const [value, setValue] = useState(props.value)
  const source = props.source

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  const pFormatDataValueList = (name, tempMeta) => {
    setMeta(tempMeta)
    props.getBack({
      name: 'p-format-data-value-list',
      value: value,
      meta: tempMeta
    })
  }

  const handleChangeFFTextFields = (value2, id, name, element) => {
    console.log(value2, id, name, element)
    if(element){
      setValue(value2)
      props.getBack({
        name: 'p-format-data-value-list',
        value: value2,
        meta: meta
      })
    }
  }

  const doChangeSelect = e => {
    setValue(e.target.value)
    props.getBack({
      name: 'p-format-data-value-list',
      value: e.target.value,
      meta: meta
    })
  }
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={2}>
        <Typography variant={"body1"}>{props.label}</Typography>
        <Typography variant={"body2"} color={"primary"}>{`(${props.name})`}</Typography>
      </Grid>
      <Grid item xs={5} style={{ textAlign: 'center' }}>
        <Typography>{'Источник данных - ' + source.label}</Typography>
        {source.name !== 'p-source-data-user' ? (
          <Context.Provider value={{ handleChangeFFTextFields }}>
            <FFTextFields
              style={{ width: '100%' }}
              key={'FFTextFields' + 'value'}
              value={value}
              is_filter={false}
              name={'value'}
              label={'Значение'}
              parameter={{ id: 'valueList' }}
              dis={false}
            />
          </Context.Provider>
        ) : (
          <FormControl
            variant='filled'
            style={{ margin: 4, width: '100%' }}
            key={'FormControl' + props.id + meta.length}
          >
            <InputLabel>Значение</InputLabel>
            <Select
              key={'Select' + props.id}
              value={value}
              onChange={doChangeSelect}
            >
              {meta.map(objChild => {
                return (
                  <MenuItem
                    value={objChild.id}
                    id={objChild.id}
                    key={'SelectMenuItem_' + objChild.id}
                  >
                    {objChild.value}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}
      </Grid>
      <Grid item xs={5}>
        <Context.Provider value={{ pFormatDataValueList }}>
        <Typography>{'Формат данных - (Значение из списка)'}</Typography>
          <FF_Classifier_step3_formatData_valueList
            key={'FF_Classifier_step3_formatData_valueList' + props.id}
            value={JSON.parse(JSON.stringify({ value: meta }))}
            props={'meta'}
          />
        </Context.Provider>
      </Grid>
    </Grid>
  )
}
