import React, { useState } from 'react'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid
} from '@material-ui/core/'
import { withStyles } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'
import FFEditTemplateAccardion from './FFEditTemplateAccardion'
import FFSelect from '../../../../libft/FFSelect'
import Context from '../../../../context'
import FFLoaderComponent from '../../../../libft/FFLoaderComponent'
import FFLoader from '../../../../libft/FFLoader'
import { ApiConnect, urlConfiguration } from '../../../../ApiConnectNew'

const ButtonSuccess = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  }
}))(Button)

const FFDialogEditTemplate = ({ open, setOpen, templates, activeEssence, ...props }) => {
  const [tempTemplate, setTempTemplate] = useState('')
  const [listElement, setListElement] = useState(null)

  const changeValue = (val, id, name, type) => {
    setTempTemplate(val)
    setListElement(null)
    ApiConnect({
      name: 'getDataPost',
      url: urlConfiguration + '/api/essence/compare_template/',
      objToGet: {'essence_id': activeEssence.id, 'new_template_id': val },
      setterEssence: setListElement,
      clickHandle: props.handleClickVariant,
      isLogin: props.isLogin
    })
  }

  const isAgree = () => {
    ApiConnect({
      name: 'updateDate',
      url: urlConfiguration + '/api/essence/change_template/',
      objToUpdate: {'essence_id': activeEssence.id, 'new_template_id': tempTemplate },
      clickHandle: props.handleClickVariant,
      isLogin: props.isLogin
    })
  }
  return (
    <Context.Provider value={{ changeValue }}>
      <Dialog open={open} maxWidth={'sm'} fullWidth={true}>
        <DialogTitle>{'Изменить шаблон?'}</DialogTitle>
        <DialogContent>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <FFSelect
                style={{ flexGrow: 1, margin: 0, marginBottom: 8, display: 'flex' }}
                value={tempTemplate}
                options={templates}
                label={'Выберите шаблон'}
                name={'template'}
              />
            </Grid>
            <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <FFLoaderComponent
                loading={tempTemplate.length}
                isLoader={null}
                component={
                  <FFLoaderComponent
                  loading={listElement}
                  isLoader={<FFLoader />}
                  component={<FFEditTemplateAccardion listElement={listElement}/>}
                  />
                }
              />

            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonSuccess
            onClick={e => {
              isAgree()
              setOpen(false)
            }}
          >
            {'Подтвердить'}
          </ButtonSuccess>
          <Button
            onClick={e => setOpen(false)}
            variant='contained'
            color='primary'
          >
            {'Отмена'}
          </Button>
        </DialogActions>
      </Dialog>
    </Context.Provider>
  )
}

export default FFDialogEditTemplate
