import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Context from '../context'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    position: 'sticky',
    bottom: '100%'
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    fontWeight: 2
  },
  list: {
    width: '100%',

    height: '1000px',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  right: {
    position: 'sticky',
    bottom: '100%'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  end: {
    flexGrow: 3
  },
  tree: {
    padding: 8,
    margin: 8
  },
  flexEnd: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexFlow: 3
  },
  scroller: {
    // maxHeight: '-webkit-fill-available',
    overflow: 'overlay'
  }
}))

function not (a, b) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection (a, b) {
  return a.filter(value => b.indexOf(value) !== -1)
}

function union (a, b) {
  return [...a, ...not(b, a)]
}

export default function FFTransportList (props) {
  // console.log(props)

  const { handleTransporterList } = useContext(Context)

  const classes = useStyles()
  const [checked, setChecked] = React.useState([])
  const [left, setLeft] = React.useState([0])
  const [right, setRight] = React.useState([4, 5, 6, 7])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  useEffect(() => {
    const cls_obj = props.cls
    const left_cls = []
    const right_cls = []
    props.cls_main.map((clsMain, indexMain) => {
      let flag = false
      props.cls.map((cls, index) => {
        if (cls === clsMain.id) {
          right_cls.push(indexMain)
          flag = true
          return
        }
      })
      if (flag === false) {
        left_cls.push(indexMain)
      }
    })
    setRight(right_cls)
    setLeft(left_cls)
    // console.log(props.cls_main[0]);
  }, [])

  // useEffect(() => {
  //   handleTransporterList(right, props.id, props.type);
  // }, [right])

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = items => intersection(checked, items).length

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedRight = () => {
    handleTransporterList(right.concat(leftChecked), props.id, props.type)
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
    // handleTransporterList(not(left, leftChecked), props.id, props.type);
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    handleTransporterList(not(right, rightChecked), props.id, props.type)
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
    // handleTransporterList(not(right, rightChecked), props.id, props.type);
  }

  const customList = (title, items, s) => (
    <div className={s}>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{ 'aria-label': 'all items selected' }}
              color='primary'
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} Отмечено`}
        />
        <Divider />
        <List className={classes.list} dense component='div' role='list'>
          {items.map(value => {
            const labelId = `transfer-list-all-item-${value}-label`

            return (
              <ListItem
                key={value}
                role='listitem'
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    color='primary'
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={props.cls_main[value].label}
                />
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      </Card>
    </div>
  )

  return (
    <Grid
      container
      spacing={2}
      justify='center'
      alignItems='center'
      className={classes.root}
    >
      {/* // className={classes.root}> */}
      <Grid item xs={5}>
        {customList(props.label[0], left, classes.rigth)}
      </Grid>
      {/* styles={{position: 'sticky', bottom: '100%'}} */}
      <Grid xs={1} container direction='column' className={classes.root}>
        <Button
          variant='outlined'
          size='small'
          className={classes.button}
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          aria-label='move selected right'
        >
          &gt;
        </Button>
        <Button
          variant='outlined'
          size='small'
          className={classes.button}
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
          aria-label='move selected left'
        >
          &lt;
        </Button>
      </Grid>
      <Grid item xs={5}>
        {customList(props.label[1], right, classes.rigth)}
      </Grid>
      {/* <div style={{position: 'sticky', bottom: '100%'}}>{customList(props.label[1], right, null)} </div> */}
    </Grid>
  )
}
