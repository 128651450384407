import React, { useState, useEffect } from 'react'
import Context from '../../../../context'

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@material-ui/core'

import FFTextFields from '../../../../components/FFTextFields'
import FFToogleStyle from '../../../../components/FFToogleStyle'

import FF_Classifier_step3_typeData_bool from './FF_Classifier_step3_typeData_bool'

import { validation_data } from '../../../../supportFunction'

export default function FF_Essence_parameterRouterConstant (props) {
  // console.log(props)
  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // ---------------------------- ---------------------------------------------------

  const [value, setValue] = useState('')
  const [valueTemp, setValueTemp] = useState({})
  const [te, setTe] = useState(0)
  // console.log(props.meta, 'meta')
  const source = props.source
  const [meta, setMeta] = useState('meta' in props && props.meta && 'bool-value' in props.meta ? props.meta['bool-value'] : null)
  const type = props.type
  // console.log(props.label, props.meta)

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------
  useEffect(() => {
    // console.log(props, 'start')
    // if ('meta' in props && props.meta) {
    //   // console.log(props.meta, null, props)
    //   if ('bool-value' in props.meta) {
    //     setMeta(props.meta['bool-value'])
    //   }
    // }
    // if (source.name === 'p-source-data-user') {
    //   if (
    //     [
    //       'p-type-data-bool',
    //       'p-type-data-float',
    //       'p-type-data-int',
    //       'p-type-data-timestamp',
    //       'p-type-data-ushort'
    //     ].indexOf(type) > -1
    //   ) {
    //     setValue(props.value)
    //   } else {
    //     setValue(props.value ? props.value : '')
    //   }
    // } else {
    //   setValue(props.value ? props.value : '')
    // }
    setValue(props.value)
    setTe(te + 1)
  }, [])
  const handleChangeFFTextFields = (value, id, name, element) => {
    if (element) {
      if (source.name === 'p-source-data-user') {
        // console.log(value, id, name, element, validation_data(type, value))
        if (validation_data(type, value, props) === '') {
          if (
            [
              'p-type-data-bool',
              'p-type-data-float',
              'p-type-data-int',
              'p-type-data-timestamp',
              'p-type-data-ushort'
            ].indexOf(type) > -1
          ) {
            value = Number(value)
          }
          setValue(value)
          props.getBack({
            name: 'bool-value',
            value: value,
            meta: meta
          })
        } else {
          props.getBack({
            name: 'bool-value',
            value: 'ValidationError',
            meta: meta
          })
          return validation_data(type, value)
        }
      } else {
        setValue(value)
        props.getBack({
          // name: 'p-format-data-value-constant',
          name: 'bool-value',
          value: value,
          meta: meta
        })
      }
    }
    return ''
  }

  const pFormatDataValueList = (nameAttr, data, flagLength) => {
    // console.log(nameAttr, data, value, meta)
    setMeta(data)
    props.getBack({
      name: 'bool-value',
      value: value,
      meta: data
    })
    // if (flagLength === 'length') {
    //   setValue(Array.from({ length: data.length }, (v, i) => ''))
    // }
    // props.getBack({ name: 'p-format-data-list', value: value, meta: meta })
  }
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={2}>
        <Typography variant={"body1"}>{props.label}</Typography>
        <Typography variant={"body2"} color={"primary"}>{`(${props.name})`}</Typography>
      </Grid>
      <Grid item xs={5} style={{ textAlign: 'center' }}>
        <Typography>{'Источник данных - ' + source.label}</Typography>
        <Context.Provider value={{ handleChangeFFTextFields }}>
          {type !== 'p-type-data-bool' ||
          source.name !== 'p-source-data-user' ? (
            <FFTextFields
              style={{ width: '100%' }}
              key={'FFTextFieldsConstant' + te}
              value={value}
              is_filter={false}
              name={'value'}
              label={'Значение'}
              parameter={{ id: 'value' }}
              dis={false}
            />
          ) : (
            <FFToogleStyle
              value={value}
              key={'FFToogleStyle' + te}
              off={
                meta && meta['label-false'] ? meta['label-false'] : 'Выключено'
              }
              on={meta && meta['label-true'] ? meta['label-true'] : 'Включено'}
              name={'value'}
              id={props.id}
            />
          )}
        </Context.Provider>
      </Grid>
      <Grid item xs={5} style={{ textAlign: 'center' }}>
        <Typography>{'Формат данных - Значение (константа)'}</Typography>
        {type === 'p-type-data-bool' ? (
          <Context.Provider value={{ pFormatDataValueList }}>
            <FF_Classifier_step3_typeData_bool
              value={{ value: meta ? meta : null }}
            />
          </Context.Provider>
        ) : null}
      </Grid>
    </Grid>
  )
}
