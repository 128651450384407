import React, {useRef} from 'react'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx'
import { fade, makeStyles } from '@material-ui/core/styles'
import TreeItem from '@material-ui/lab/TreeItem'
import IconHttps from '@material-ui/icons/Https'
import IconBrightnessAuto from '@material-ui/icons/BrightnessAuto'

import { Grid, Tooltip } from '@material-ui/core'

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    '&:focus > $content': {
      color: theme.palette.primary.main,
      // border: 'dashed',
      // boxShadow: '0 0 2px 1px yellow',
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[300]})`
      // `var(--tree-view-bg-color, ${theme.palette.grey[400]})`
    }
  },
  active: {
    color: theme.palette.primary.main,
    // color: 'blue',
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[300]})`,
    paddingRight: 2,
    borderTopRightRadius: 2,
    borderTopRightRadius: 2
    // backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`
  },

  content: {
    // borderTopRightRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),
    // paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: 12,
    paddingLeft: 12,
    borderLeft: `1px solid ${fade(theme.palette.text.primary, 0.4)}`,
    '& $content': {}
  },
  // expanded: {},
  // label: {
  //   fontWeight: 'inherit',
  //   color: 'inherit'
  // },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0)
  }
  // labelIcon: {},
  // labelText: {
  //   fontWeight: 0.1
  // }
}))

export default function FFTreeItem (props) {
  const classes = useTreeItemStyles()
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    badge,
    obj,
    active,
    ...other
  } = props

  // console.log(props)
  return (
    <TreeItem
      label={
        <Grid
          className={clsx(classes.labelRoot, active ? classes.active : null)}
        >
          <Grid item xs={9}>
            <Tooltip title={labelText}>
              <Typography
                component={'p'}
                variant='body2'
                className={classes.labelText}
                style={{
                  width: props.widthText ? props.widthText : 250,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
              >
                {labelText}
              </Typography>
            </Tooltip>
          </Grid>

          <Grid>
            {badge !== 0 ? (
              <Chip color='primary' size='small' label={badge} />
            ) : null}
            {obj && 'is_default_value' in obj ? (
              <IconBrightnessAuto
                color='action'
                visibility={obj.is_default_value ? 'visible' : 'hidden'}
              />
            ) : null}

            {obj && 'is_system' in obj && obj.is_system ? (
              <IconHttps
                color='action'
                visibility={obj.is_system ? 'visible' : 'hidden'}
              />
            ) : null}
          </Grid>
        </Grid>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label
      }}
      {...other}
    />
  )
}
