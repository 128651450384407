import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Context from '../context'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import IconButton from '@material-ui/core/IconButton'
import IconSettings from '@material-ui/icons/Settings'

import FFTextFields from './FFTextFields'

const FF_ST = props => {
  const [open, setOpen] = useState(false)

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        background: 'rgba(0, 0, 0, 0.09)',
        margin: 4,
        borderRadius: '4px 4px 0 0',
        paddingLeft: 10,
        borderBottom: '0.7px solid #9e9e9e'
      }}
    >
      {console.log(props.obj)}

      {open ? (
        <FFDialogJsonTable
          key={open + 'FFDialogJsonTable'}
          open={open}
          obj={
            props.obj && props.obj[props.name_attr]
              ? props.obj[props.name_attr]
              : null
          }
          close={e => setOpen(false)}
        />
      ) : null}
      <Grid item xs={10} style={{ alignSelf: 'center' }}>
        <Typography>{props.name_attr}</Typography>
      </Grid>
      <Grid item>
        <IconButton aria-label='filter' onClick={e => setOpen(true)}>
          <IconSettings color='primary' fontSize='large' />
        </IconButton>
      </Grid>
    </Grid>
  )
}

const FFDialogJsonTable = props => {
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      {/* {open ? <FFDialogJsonTable open={open} close={() => setOpen(false)}/> : null} */}
      <Dialog
        maxWidth={'md'}
        onClose={e => props.close()}
        aria-labelledby='simple-dialog-title'
        open={props.open}
      >
        <DialogTitle id='simple-dialog-title'>
          {(Array.isArray(props.obj) ? 'Array' : 'Object') +
            JSON.stringify(props.obj)}
        </DialogTitle>
        <DialogContent>
          {Array.isArray(props.obj) ? null : typeof props.obj === 'object' ? (
            <Grid item xs={12}>
              {console.log('aaaaa', props.obj)}
              {Object.keys(props.obj).map(key => {
                if (typeof props.obj[key] === 'object') {
                  return (
                    <Grid item>
                      <FF_ST obj={props.obj} name_attr={key} open={false} />
                    </Grid>
                  )
                } else if (typeof props.obj[key] === 'string') {
                  return <Grid item>{key}</Grid>
                }
              })}
            </Grid>
          ) : null}

          <IconButton aria-label='filter' onClick={e => setOpen(true)}>
            <IconSettings color='primary' fontSize='large' />
          </IconButton>
        </DialogContent>
        <DialogActions>
          <Button onClick={e => props.close()} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default function FFJsonTable ({ obj, ...props }) {
  const recur = obj => {
    let temp = {}
    if (obj) {
      if (Array.isArray(obj)) {
        if (obj.length > 0 && typeof obj[0] == 'string') {
          return { value: 'string' }
        } else if (obj.length > 0 && typeof obj[0] === 'object') {
          return recur(obj[0])
        }
      } else if (typeof obj === 'object') {
        Object.keys(obj).map(key => {
          if (Array.isArray(obj[key])) {
            temp[key] = { type: 'array', meta: recur(obj[key]) }
          } else if (typeof obj[key] === 'object') {
            temp[key] = recur(obj[key])
          } else {
            temp[key] = 'string'
            // temp[key] = 'string'
          }
        })
      }
      return temp
    }
  }
  console.log(obj)
  console.log(recur(obj))
  const [open, setOpen] = useState(false)

  return <FF_ST obj={obj} name_attr={props.name_attr} open={false} />
}
