import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Context from '../context'

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
}))

export default function FFSelect ({
  id,
  name,
  value,
  label,
  style,
  options,
  is_disabled,
  ...props
}) {
  const classes = useStyles()

  const { changeValue } = useContext(Context)

  const [tempValue, setTempValue] = useState(value)

  const handleChange = val => {
    setTempValue(val)
    changeValue(val, id, name, 'select')
  }

  return (
    <FormControl
      className={classes.margin}
      style={style ? style : {}}
      variant='filled'
      disabled={is_disabled || false}
    >
      <InputLabel>{label}</InputLabel>
      <Select value={tempValue} onChange={e => handleChange(e.target.value)}>
        {options
          ? options.map(obj => {
              return <MenuItem value={obj.id}>{obj.label}</MenuItem>
            })
          : null}
      </Select>
    </FormControl>
  )
}
