import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Paper, Grid } from '@material-ui/core'

import { useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'stretch'
    // flexShrink: 0
  },
  paper: {
    display: 'flex',
    // height: '100%',
    width: '100%',
    alignItems: 'stretch',
    flexDirection: 'column'
    // flexShrink: 0
  },
  isRow: {
    flexDirection: 'row'
  },
  padding: {
    display: 'flex',
    // maxWidth: '100%',
    padding: theme.spacing(1),
    alignItems: 'stretch'
    // flexShrink: 0
  }
}))

export default function FFContainerColumn (props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------
  const classes = useStyles()

  return (
    <div
      className={classes.root}
      style={{
        flexDirection: props.is_row ? 'row' : 'column',
        overflow: 'auto'
      }}
      key={'FFContainer_' + props.id}
    >
      {props.components.map((metaComponent, indexComp) => {
        if (metaComponent?.width) {
          return (
            <Grid
              item
              xs={metaComponent.width}
              key={'FFContainerGrid_' + indexComp}
              className={classes.padding}
              style={{flexShrink: metaComponent?.shrink ? metaComponent?.shrink : 1}}
            >
              <Paper
                elevation={
                  metaComponent?.elevation ? metaComponent?.elevation : 0
                }
                variant={
                  metaComponent?.variant ? metaComponent?.variant : 'elevation'
                }
                key={'FFContainerPaper_' + indexComp}
                className={clsx(classes.paper, (metaComponent?.isRow ? classes.isRow : null))}
                style={metaComponent?.style}
              >
                {metaComponent.component}
              </Paper>
            </Grid>
          )
        }
      })}
    </div>
  )
}
