import React, { useState, useContext } from 'react'
import Context from '../context'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import FFTreeItemChecked from './FFTreeItemChecked'
import TreeView from '@material-ui/lab/TreeView'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import TreeNodeUtils from 'tree-node-utils'

import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import IconLibraryAddCheck from '@material-ui/icons/LibraryAddCheck'
import IconSortByAlpha from '@material-ui/icons/SortByAlpha'

import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import FilledInput from '@material-ui/core/FilledInput'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Tooltip from '@material-ui/core/Tooltip'

import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import IconMoreVert from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 20
  },
  margin: {
    marginTop: '10px',
    marginLeft: '10px',
    marginBottom: '5px'
  },
  padding: {
    // paddingLeft: 20,
    paddingRight: 10
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: '100%'
  },
  formControl: {
    minWidth: 250
  },
  formControl2: {
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  end: {
    flexGrow: 3
  },
  tree: {
    padding: 8,
    margin: 8
  },
  flexEnd: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexFlow: 3
  },
  scroller: {
    // maxHeight: '-webkit-fill-available',
    overflow: 'overlay'
  }
})

const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius
    }
  }
}))(ToggleButtonGroup)

export default function FFTreeEssenceChecked (props) {
  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // -------------------------------------------------------------------------------

  const { doClickCheckedEssence, doClickActiveEssenceDialog } = useContext(
    Context
  )
  const [active, setActive] = useState('')
  const [search, setSearch] = useState('')
  const config = {
    childrenField: 'children',
    keyField: 'id'
  }
  const treeUtils = new TreeNodeUtils(config)

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  const doChangeSearch = e => {
    setSearch(e.target.value)
  }

  const toGoRecur = (node, list, checked) => {
    if (checked) {
      list.value = [...list.value, node.id]
    } else {
      list.value = list.value.filter(obj => obj !== node.id)
    }
    node.children.map(obj => toGoRecur(obj, list, checked))
  }

  const onCheckedFFItem = (e, checked, id) => {
    let temp = { value: props.listChecked }
    if (formats.indexOf('mult') > -1) {
      toGoRecur(treeUtils.getNodeByKey(props.essence, id), temp, checked)
    } else {
      if (checked) {
        temp.value = [...temp.value, id]
      } else {
        temp.value = temp.value.filter(obj => obj !== id)
      }
    }
    // console.log(e, checked, id, treeUtils.getNodeByKey(props.essence, id), temp)
    if (checked && props.listChecked.indexOf(id) === -1) {
      doClickCheckedEssence(
        temp.value,
        formats.indexOf('mult') > -1 ? 'mult' : 'not_mult'
      )
    } else if (!checked && props.listChecked.indexOf(id) > -1) {
      doClickCheckedEssence(
        temp.value,
        formats.indexOf('mult') > -1 ? 'mult' : 'not_mult'
      )
    }
  }
  // -------------------------------------------------------------------------------
  //
  //                            Рекурсивное построение дерева
  //
  // -------------------------------------------------------------------------------

  const recurTreeView = (obj, depth, index) => {
    let temp_sort_label =
      'children' in obj && obj.children.length > 0
        ? obj.children.map(obj => obj.label)
        : []
    temp_sort_label.sort()

    return (
      <FFTreeItemChecked
        style={{ margin: 4 }}
        key={obj.id + obj.indeterminate + obj.checked}
        active={obj.id === active}
        nodeId={obj.id}
        value={obj.id}
        // checked={false}
        checked={
          obj.checked
          // props.listChecked && props.listChecked.length
          //   ? props.listChecked.indexOf(obj.id) > -1
          //   : false
        }
        indeterminate={obj.indeterminate}
        checkedFFItem={(e, checked) => onCheckedFFItem(e, checked, obj.id)}
        // onClick={e => doClickTreeItem(obj)}
        labelText={obj[formats.indexOf('name') > -1 ? 'name' : 'label']}
        id={'FFTreeEssence_FFTreeItem_tree_' + obj.id}
        badge={'badge' in obj ? obj.badge : 0}
        obj={obj}
      >
        {temp_sort_label.map(name_label => {
          let obj_child = obj.children.filter(obj => obj.label === name_label)
          if (obj_child.length > 0) {
            return recurTreeView(obj_child[0], depth + 1, index)
          }
        })}
      </FFTreeItemChecked>
    )
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [formats, setFormats] = useState([])
  const handleClick = event => {
    // console.log(event.target)
    setAnchorEl(event.currentTarget)
  }

  const ITEM_HEIGHT = 48
  const [select, setSelect] = useState('label')
  const options = [
    { key: 'name', label: 'Системное наименование' },
    { key: 'label', label: 'Наименование' }
  ]
  const handleClose = e => {
    // console.log(e.target.value)
    setAnchorEl(null)
  }

  const onClickMenu = e => {
    let temp = e.target.textContent
    setSelect(options.filter(obj => obj.label === temp)[0].key)
    setAnchorEl(null)
  }
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------
  return (
    <>
      <Grid
        container
        xs={12}
        spacing={1}
        style={{ flexBasis: 90, marginBottom: 0 }}
      >
            <Grid item xs={8}>
              <FormControl
                className={classes.root}
                key={'FFTreeEssence_FormControl_search'}
              >
                <InputLabel
                  htmlFor='FFTreeEssence_InputLabel_search'
                  style={{ marginLeft: 10 }}
                  key={'FFTreeEssence_InputLabel_search'}
                >
                  Поиск
                </InputLabel>

                <FilledInput
                  label={'Поиск'}
                  id='FFTreeEssence_filledInput_search'
                  value={search}
                  onChange={doChangeSearch}
                  endAdornment={
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <StyledToggleButtonGroup
                size='medium'
                // style={{ margin: 10 }}
                value={formats}
                onChange={(e, newFormat) => setFormats(newFormat)}
                aria-label='text formatting'
              >
                <ToggleButton
                  value='name'
                  aria-label='name'
                  // disabled={switchChange ? false : true}
                >
                  <Tooltip title={'Системное наименование'}>
                    <IconSortByAlpha />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  value='mult'
                  aria-label='mult'
                  // disabled={switchChange ? false : true}
                >
                  <Tooltip title={'Мульти выбор'}>
                    <IconLibraryAddCheck />
                  </Tooltip>
                </ToggleButton>
              </StyledToggleButtonGroup>
              {/* <IconButton
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            style={{ marginTop: 5 }}
            onClick={handleClick}
          >
            <IconMoreVert />
          </IconButton>
          <Menu
            id='long-menu'
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5
                // width: ''
              }
            }}
          >
            {options.map(option => (
              <MenuItem
                key={option.key}
                selected={option.key === select}
                onClick={e => onClickMenu(e)}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu> */}
              {/* <FFSwitch_N
            id={'TreeSwitch_2'}
            meta={{ value: 1, label: ['Name', 'Label'], width: 100 }}
          /> */}
            </Grid>
          </Grid>
      <Grid className={[classes.scroller]} style={{ margin: 0 }} item xs={12}>
        <TreeView
          // defaultExpanded={props.listChecked}
          key={
            'FFTreeEssence_TreeView_treeEssence' +
            (props.dialog ? '_Dialog' : '')
          }
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={null}
        >
          {treeUtils
            .filterNodes(
              props.essence,
              obj =>
                obj.label.toUpperCase().indexOf(search.toUpperCase()) !== -1
            )
            .map((obj, index) => {
              return recurTreeView(obj, 0, index)
            })}
        </TreeView>
      </Grid>
    </>
  )
}
