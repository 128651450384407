import React, { useEffect, useState, useContext, useLayoutEffect } from 'react'
// import ReactDOM from 'react-dom'
// import { Redirect } from 'react-router-dom'
import clsx from 'clsx'

// import { makeStyles } from "@material-ui/core/styles";
// import React from 'react';
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
// import Loader from './Loader'
// import Context from './context'

import Tooltip from '@material-ui/core/Tooltip'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Select from '@material-ui/core/Select'
import { Switch, Checkbox, Backdrop, Button } from '@material-ui/core'

// import Input from '@material-ui/core/Input'

// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
import Badge from '@material-ui/core/Badge'
// import Avatar from '@material-ui/core/Avatar'
// import Chip from '@material-ui/core/Chip'
// import Divider from '@material-ui/core/Divider'
// import TablePagination from '@material-ui/core/TablePagination'
// import FFPaginationAction from './components/FFPaginationAction'
import { SnackbarProvider, useSnackbar } from 'notistack'
// import FFListSwitch from './components/FFListSwitch';
// import FFSelect from './components/FFSelect'
// import FFTextFields from './components/FFTextFields'
// import FFSelectMulti from './components/FFSelectMulti'
// import FFRouterComponents from './components/FFRouterComponents'
// import FFTextFields from './components/FFTextFields'
// import FFSelect from './components/FFSelect'

// import FFCheckList from './components/FFCheckList'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import FFPanelMenu from './components/FFPanelMenu'
// import FFPersistentDrawerRight from './components/FFPersistentDrawerRight'
import Grid from '@material-ui/core/Grid'
// import TextField from '@material-ui/core/TextField'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
// import Drawer from '@material-ui/core/Drawer'
// import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
// import FFTester from './FFTester'
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'

import IconSettings from '@material-ui/icons/Settings'

import IconDelete from '@material-ui/icons/Delete'
// import IconFilter from '@material-ui/icons/FilterList'
// import IconSearch from '@material-ui/icons/Search'
import IconLibraryAdd from '@material-ui/icons/LibraryAdd'
import IconAdd from '@material-ui/icons/Add'
import IconSave from '@material-ui/icons/Save'
import IconRefresh from '@material-ui/icons/Refresh'
import IconSystemUpdateAlt from '@material-ui/icons/SystemUpdateAlt'
// import IconBuild from '@material-ui/icons/Build'
// import IconReplay from '@material-ui/icons/Replay'
// import IconCancel from '@material-ui/icons/Cancel'
// import IconUpdate from '@material-ui/icons/Update'

// import FFFilterLabel from './components/FFFilterLabel'
// import FFToggle from './components/FFToggle'

// import CardHeader from '@material-ui/core/CardHeader'
// import TreeItem from '@material-ui/lab/TreeItem'
// import TreeView from '@material-ui/lab/TreeView'
// import Essence from './essence_action_info.json'
// import CancelIcon from '@material-ui/icons/Cancel';
// import Context from './context'
// import FileCls from './cls.json'
// import FileParameter from './parameter.json'
// import { Button, GridListTile, ListSubheader } from '@material-ui/core'
// import SwipeableViews from 'react-swipeable-views';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
// import ArrowRightIcon from '@material-ui/icons/ArrowRight'
// import Remove from '@material-ui/icons/Remove'
// import FileBaseLine from './fileSrc/scenarios_model.json'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

// import { withStyles } from '@material-ui/core/styles'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import DoneOutline from '@material-ui/icons/DoneOutline'
// import FFPanelMenu from "./components/FFPanelMenu";

// import SortableTree from 'react-sortable-tree/dist/index.esm.js'
// import 'react-sortable-tree/style.css'
// import '../node_modules/react-grid-layout/css/styles.css'
// import '../node_modules/react-resizable/css/styles.css'

import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Avatar from '@material-ui/core/Avatar'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import MoreVertIcon from '@material-ui/icons/MoreVert'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
// import FFTextFields from './components/FFTextFields'
// import { validation_data } from './supportFunction'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from 'highcharts/highcharts-more.js'
import ExportingModule from 'highcharts/modules/exporting'
// import FFSelect from './components/FFSelectSimple'
// import treeNodeUtils from 'tree-node-utils'

import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined'
import IconLoop from '@material-ui/icons/Loop'

import SaveIcon from '@material-ui/icons/Save'
import PrintIcon from '@material-ui/icons/Print'
import IconLinearScale from '@material-ui/icons/LinearScale'
import IconRemoveRedEye from '@material-ui/icons/RemoveRedEye'
import IconBorderLeft from '@material-ui/icons/BorderLeft'

import IconShowChart from '@material-ui/icons/ShowChart'
import IconBarChart from '@material-ui/icons/BarChart'
import IconPieChart from '@material-ui/icons/Landscape'
import IconPalette from '@material-ui/icons/Palette'
import IconViewStreamTwoTone from '@material-ui/icons/ViewStreamTwoTone'

import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft'
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter'
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight'
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify'
import FormatBoldIcon from '@material-ui/icons/FormatBold'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined'
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Divider from '@material-ui/core/Divider'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import IconPermDataSetting from '@material-ui/icons/PermDataSetting'
import ViewListIcon from '@material-ui/icons/ViewList'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'

import Popover from '@material-ui/core/Popover'

import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey
} from '@material-ui/core/colors'

import SvgIcon from '@material-ui/core/SvgIcon'

// import ShareIcon from '@material-ui/icons/Share';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// let colormap = require('colormap')

highchartsMore(Highcharts)
ExportingModule(Highcharts)

const useStylesTheme = makeStyles(theme => ({
  speedDialType: {
    position: 'absolute',
    // '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    //   bottom: theme.spacing(1),
    //   right: theme.spacing(1)
    // },
    // '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    //   top: theme.spacing(1),
    //   left: theme.spacing(1)
    // },
    transform: 'scale(0.6)',
    top: -28,
    right: 50,
    zIndex: 100
  },
  speedDialStack: {
    position: 'absolute',
    transform: 'scale(0.6)',
    top: -5,
    right: 100,
    zIndex: 100
  },
  speedDialColor: {
    position: 'absolute',
    transform: 'scale(0.6)',
    top: 6,
    right: 150,
    zIndex: 100
  },
  paper: {
    display: 'flex',
    // margin: '10px 0px 5px 5px',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  paperPoper: {
    display: 'flex',
    // margin: '10px 0px 5px 5px',
    width: 50,
    // border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  divider: {
    height: 1,
    margin: '0px 5px 0',
    width: '-webkit-fill-available'
    // margin: theme.spacing(1, 0.5)
  }
}))

const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius
    }
  }
}))(ToggleButtonGroup)

const useStyles = makeStyles(theme => ({
  root: {
    '& > svg': {
      margin: theme.spacing(2)
    }
  }
}))

// function HomeIcon(props) {
//   return (
//     <SvgIcon {...props}>
//       <path d="M20 20L121.15 20L121.15 85.29L20 85.29L20 20Z" />
//     </SvgIcon>
//   );
// }

// const CustomizedDividers = () => {
//   const [alignment, setAlignment] = React.useState('left')
//   const [formats, setFormats] = React.useState(() => ['italic'])

//   const handleFormat = (event, newFormats) => {
//     setFormats(newFormats)
//   }

//   const handleAlignment = (event, newAlignment) => {
//     setAlignment(newAlignment)
//   }

//   const classes = useStyles()

//   // const menuIconsType = {
//   //   '': {
//   //     icon: <IconShowChart onClick={e => changeType('')} />,
//   //     name: 'Линейный'
//   //   },
//   //   column: {
//   //     icon: <IconBarChart onClick={e => changeType('column')} />,
//   //     name: 'Колонки'
//   //   },
//   //   bar: {
//   //     icon: (
//   //       <IconBarChart
//   //         onClick={e => changeType('bar')}
//   //         style={{ transform: 'rotate(90deg)' }}
//   //       />
//   //     ),
//   //     name: 'Бар'
//   //   },
//   //   pie: {
//   //     icon: <IconPieChart onClick={e => changeType('pie')} />,
//   //     name: 'Пироги'
//   //   }
//   // }

//   return (
//     <div>
//       <Paper elevation={0} className={classes.paper}>
//         <StyledToggleButtonGroup
//           size='small'
//           value={alignment}
//           exclusive
//           onChange={handleAlignment}
//           aria-label='text alignment'
//         >
//           <ToggleButton value='left' aria-label='chart-line'>
//             <IconShowChart onClick={e => changeType('')} />
//           </ToggleButton>
//           <ToggleButton value='center' aria-label='chart-column'>
//             <IconBarChart onClick={e => changeType('column')} />
//           </ToggleButton>
//           <ToggleButton value='right' aria-label='chart-bar'>
//             <IconBarChart
//               onClick={e => changeType('bar')}
//               style={{ transform: 'rotate(90deg)' }}
//             />
//           </ToggleButton>
//           <ToggleButton value='justify' aria-label='pie-chart'>
//             <IconPieChart onClick={e => changeType('pie')} />
//           </ToggleButton>
//         </StyledToggleButtonGroup>
//         <Divider flexItem orientation='vertical' className={classes.divider} />
//         <StyledToggleButtonGroup
//           size='small'
//           value={formats}
//           onChange={handleFormat}
//           aria-label='text formatting'
//         >
//           <ToggleButton value='bold' aria-label='bold'>
//             <FormatBoldIcon />
//           </ToggleButton>
//           <ToggleButton value='italic' aria-label='italic'>
//             <FormatItalicIcon />
//           </ToggleButton>
//           <ToggleButton value='underlined' aria-label='underlined'>
//             <FormatUnderlinedIcon />
//           </ToggleButton>
//           <ToggleButton value='color' aria-label='color'>
//             <FormatColorFillIcon />
//             <ArrowDropDownIcon />
//           </ToggleButton>
//         </StyledToggleButtonGroup>
//       </Paper>
//     </div>
//   )
// }

export default function FF_Chart (props) {
  // console.log(props)
  const [alignment, setAlignment] = React.useState('left')
  const [formats, setFormats] = React.useState(() => ['italic'])

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats)
  }

  // const classes_helper = useStyles()
  const classes = useStylesTheme()

  let contr = 500
  let colors = [
    red[contr],
    pink[contr],
    purple[contr],
    deepPurple[contr],
    indigo[contr],
    blue[contr],
    lightBlue[contr],
    cyan[contr],
    teal[contr],
    green[contr],
    lightGreen[contr],
    lime[contr],
    yellow[contr],
    amber[contr],
    orange[contr],
    deepOrange[contr],
    brown[contr],
    grey[contr],
    blueGrey[contr]
  ]

  const [open, setOpen] = useState(false)
  const [openStack, setOpenStack] = useState(false)
  const [openColor, setOpenColor] = useState(false)

  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const [atomMeta, setAtomMeta] = useState(props.atomMeta.value)
  const [update, setUpdate] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [indexSerie, setIndexSerie] = useState(0)
  const [anchorMain, setAnchorMain] = useState(null)

  const [currentSeries, setCurrentSeries] = useState(null)
  const [stemp, setStemp] = useState(0)

  const menuIconsType = {
    line: {
      icon: (
        <Tooltip title={'Линейный'}>
          <IconShowChart />
        </Tooltip>
      ),
      name: 'Линейный'
    },
    column: {
      icon: (
        <Tooltip title={'Колонки'}>
          <IconBarChart />
        </Tooltip>
      ),
      name: 'Колонки'
    },
    // bar: {
    //   icon: <IconBarChart style={{ transform: 'rotate(90deg)' }} />,
    //   name: 'Бар'
    // },
    area: {
      icon: <IconPieChart />,
      name: 'Площадь'
    }
  }

  const menuIconsStack = {
    normal: {
      icon: (
        <Tooltip title={'Стэк'}>
          <IconViewStreamTwoTone />
        </Tooltip>
      ),
      name: 'Стэк'
    },
    '': {
      icon: (
        <Tooltip title={'Не стэк'}>
          <IconViewStreamTwoTone style={{ transform: 'rotate(90deg)' }} />
        </Tooltip>
      ),
      name: 'Не стэк)'
    }
  }

  const clickLegend = event => {
    if (event.browserEvent.altKey === true) {
      // console.log(event.target.userOptions.name)
      let temp = atomMeta.series.filter(
        obj => obj.name === event.target.userOptions.name
      )[0]
      setCurrentSeries(temp)
      setIndexSerie(atomMeta.series.indexOf(temp))
      // console.log(event,event.browserEvent.path[0])
      // console.log(atomMeta.series.filter(obj => obj.name === event.target.userOptions.name)[0])
      setAnchorEl(event.browserEvent.path[0])
      setStemp(stemp + 1)
      return false
    }
  }

  const clickMain = event => {
    // if (event.browserEvent.altKey === true) {
    // console.log(event.target.userOptions.name)
    // setCurrentSeries(
    //   atomMeta.series.filter(
    //     obj => obj.name === event.target.userOptions.name
    //   )[0]
    // )
    // console.log(atomMeta.series.filter(obj => obj.name === event.target.userOptions.name)[0])
    if (event.altKey === true) {
      setX(event.chartX)
      setY(event.chartY)
      setAnchorMain(event.target)
    }
    // return false
    // }
  }

  useEffect(() => {
    let temp = props.atomMeta.value
    temp['chart'] = {
      ...temp['chart'],
      // events: {
      //   click: clickMain
      // },
      zoomType: 'x'
    }

    // temp['chart'] = {
    //     height: 440 // 16:9 ratio
    // }

    if (!('timer' in temp && ['1h', '1d', 'now'].indexOf(temp['timer']) > -1)) {
      temp['timer'] = '1h'
    }
    let is_date = false
    if (
      !(
        'yAxis' in temp &&
        temp['yAxis'] &&
        temp['yAxis'].length - 1 === temp['series'].length
      )
    ) {
      temp['yAxis'] = [
        {
          title: {
            text: props?.defaultAxis ? props.defaultAxis : 'Базовая ось'
          }
        }
      ]
      temp.series.map((serie, index) => {
        if ('source' in serie && serie['source'] === 'p-source-data-stream') {
          is_date = true
        }
        // console.log(serie, is_date)
        if ('measure_unit' in serie) {
          let newYAxis = {
            title: { text: serie['measure_unit'][1].short_name },
            visible: true,
            opposite: false
          }
          serie['yAxis'] = index + 1
          temp['yAxis'] = [...temp['yAxis'], newYAxis]
        }
      })
    }
    temp['yAxis'].map((axis, index) => {
      let newIndex = temp['yAxis'].filter(
        (tempAxis, indexTemp) =>
          indexTemp < index && tempAxis.title.text === axis.title.text
      )
      // console.log(newIndex, temp, axis, index)
      if (newIndex.length) {
        temp['yAxis'][index].visible = false
        temp['series'][index - 1]['yAxis'] = newIndex[0].index
      }
      // if (newIndex !== -1) {
      //   temp['yAxis'][newIndex].visible = false
      //   temp['series'][newIndex]['yAxis'] = index
      // }
    })
    // console.log(temp)

    if (is_date && isNaN(temp['xAxis'].length)) {
      temp['xAxis'] = { ...temp['xAxis'], type: 'datetime' }
    }
    // temp['xAxis'] = [
    //   {
    //     type: 'datetime',
    //     plotBands: []
    //   },
    //   {
    //     linkedTo: 0,
    //     type: 'datetime',
    //     opposite: true,
    //     labels: {
    //       formatter: function () {
    //         return String(this.value)
    //       }
    //     }
    //   }
    // ]
    temp['plotOptions'] = {
      column: {
        stacking: '',
        events: { legendItemClick: clickLegend },
        showInLegend: true
      },
      bar: {
        stacking: '',
        events: { legendItemClick: clickLegend },
        showInLegend: true
      },
      line: {
        stacking: '',
        events: { legendItemClick: clickLegend },
        showInLegend: true
      },
      area: {
        stacking: '',
        events: { legendItemClick: clickLegend },
        showInLegend: true,
        fillOpacity: 0.5
      },
      series: {
        connectNulls: true,
        stacking: ''
      }
    }
    // temp['xAxis'][1] = {
    //   linkedTo: 0,
    //   type: 'datetime',
    //   opposite: true,
    //   qq: temp['xAxis'][1].qq,
    //   labels: {
    //     formatter: function () {
    //       // startDate.valueOf() - startDateChange.valueOf()
    //       // String(this.value - (new Date().valueOf()))
    //       return new Date(this.value - temp['xAxis'][1].qq ).toLocaleString()
    //     }
    //   }
    // }
    // console.log(temp, props)
    setAtomMeta(temp)
    setTimeout(() => {
      changeColor('', 'start2')
    }, 50)
  }, [])

  const changeConnect = (event, type) => {
    let meta = atomMeta
    meta['plotOptions']['series']['connectNulls'] = !meta['plotOptions'][
      'series'
    ]['connectNulls']
    setAtomMeta(meta)
    setAnchorEl(null)
    setUpdate(update + 1)
  }

  const changeColor = (event, type) => {
    let meta = atomMeta
    if (type === 'start') meta['colors'] = colors
    else {
      meta['colors'] = colors.sort(() => {
        return Math.random() - 0.5
      })
    }

    meta['series'].map((serie, index) => {
      if (serie.name.indexOf(' (Сравнение)') === -1) {
        serie['color'] = meta['colors'][index]
        let temp = meta['series'].filter(
          obj => obj.name.indexOf(serie.name + ' (Сравнение)') > -1
        )
        if (temp.length) {
          temp[0]['color'] = meta['colors'][index]
        }
      }
    })
    // console.log(meta)
    setAtomMeta(meta)
    setAnchorEl(null)
    setAnchorMain(null)
    setUpdate(update + 1)
  }

  const changeType = (event, type) => {
    let meta = atomMeta
    currentSeries['type'] = type
    // meta['chart']['type'] = type
    // setAtomMeta(meta)
    setUpdate(update + 1)
    setAnchorMain(null)
    setAnchorEl(null)
  }

  const changeTypeMain = (event, type) => {
    let meta = atomMeta
    atomMeta['series'].map(obj => (obj.type = type))
    // meta['chart']['type'] = type
    // setAtomMeta(meta)
    setUpdate(update + 1)
    setAnchorMain(null)
    setAnchorEl(null)
  }

  const changeTimeMain = (event, type) => {
    let meta = atomMeta
    if (type !== 'normal') {
      atomMeta['timer'] = type
    }
    // meta['chart']['type'] = type
    // setAtomMeta(meta)
    if ('updateTimePlot' in props && props.updateTimePlot) {
      props.updateTimePlot(true)
    }
    setUpdate(update + 1)
    setAnchorMain(null)
    setAnchorEl(null)
    // console.log(type)
  }

  const changeStack = (event, type) => {
    let meta = atomMeta
    Object.keys(meta['plotOptions']).map(
      key =>
        (meta['plotOptions'][key]['stacking'] =
          meta['plotOptions'][key]['stacking'] === 'normal' ? '' : 'normal')
    )
    // ['stacking'] =
    // type === 'normal' ? 'normal' : ''
    setAtomMeta(meta)
    setAnchorEl(null)
    setAnchorMain(null)
    setUpdate(update + 1)
  }

  const changeVisibleYAxis = (event, type) => {
    let meta = atomMeta
    meta['yAxis'][indexSerie + 1]['visible'] = !meta['yAxis'][indexSerie + 1][
      'visible'
    ]
    if (meta['yAxis'][indexSerie + 1]['visible'] === false) {
      meta['series'][indexSerie]['yAxis'] = 0
    } else {
      meta['series'][indexSerie]['yAxis'] = indexSerie + 1
    }

    setAtomMeta(meta)
    setAnchorEl(null)
    setAnchorMain(null)
    setUpdate(update + 1)
  }

  const changeOppositeYAxis = (event, type) => {
    let meta = atomMeta
    meta['yAxis'][indexSerie + 1]['opposite'] = !meta['yAxis'][indexSerie + 1][
      'opposite'
    ]
    setAtomMeta(meta)
    setAnchorEl(null)
    setAnchorMain(null)
    setUpdate(update + 1)
  }
  // console.log(atomMeta)
  return atomMeta ? (
    <Grid
      item
      container
      xs={12}
      style={
        props?.noPanel
          ? {}
          : { margin: 0, background: 'white', transform: 'translateX(-10px)' }
      }
      onDoubleClick={e => setUpdate(update + 1)}
    >
      {/* {console.log(anchorEl)} */}

      {Boolean(anchorEl) ? (
        <Popover
          id={'small-popover' + props.atomMeta.id}
          key={stemp + 'small-popover' + props.atomMeta.id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={e => {
            setAnchorEl(null)
            // console.log('ckil')
            setStemp(stemp + 1)
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {/* {console.log(anchorEl, 'PP')} */}

          <Paper style={{ width: 190 }}>
            <div>
              <Paper elevation={0} className={classes.paper}>
                <StyledToggleButtonGroup
                  orientation='vertical'
                  size='small'
                  value={currentSeries ? currentSeries['type'] : 'line'}
                  // value={'bar'}
                  exclusive
                  onChange={changeType}
                  aria-label='ChartType'
                >
                  {Object.keys(menuIconsType).map(key => {
                    return (
                      <ToggleButton value={key} aria-label={key + '--type'}>
                        {menuIconsType[key].icon}
                      </ToggleButton>
                    )
                  })}
                </StyledToggleButtonGroup>
                {/* <Divider
                  flexItem
                  orientation='vertical'
                  className={classes.divider}
                /> */}
                {/* <StyledToggleButtonGroup
                  size='small'
                  value={
                    atomMeta['plotOptions'][currentSeries['type']]['stacking']
                  }
                  onChange={changeStack}
                  aria-label='ChartStack'
                >
                  <ToggleButton value='normal' aria-label='normal--stack'>
                    <IconViewStreamTwoTone />
                  </ToggleButton>
                </StyledToggleButtonGroup>
                <Divider
                  flexItem
                  orientation='vertical'
                  className={classes.divider}
                /> */}
                {/* <StyledToggleButtonGroup
                  size='small'
                  value={''}
                  onChange={e => changeColor(true)}
                  aria-label='ChartColor'
                >
                  <ToggleButton value='color' aria-label='color--color'>
                    <IconPalette />
                  </ToggleButton>
                </StyledToggleButtonGroup>
                <Divider
                  flexItem
                  orientation='vertical'
                  className={classes.divider}
                /> */}
                <StyledToggleButtonGroup
                  orientation='vertical'
                  size='small'
                  value={
                    atomMeta?.plotOptions?.series?.connectNulls
                      ? 'connectNulls'
                      : ''
                  }
                  onChange={changeConnect}
                  aria-label='ChartConnectNulls'
                >
                  <ToggleButton
                    value='connectNulls'
                    aria-label='connectNulls--color'
                  >
                    <Tooltip title={'Соединять пропущенные значения'}>
                      <IconLinearScale />
                    </Tooltip>
                  </ToggleButton>
                </StyledToggleButtonGroup>
                {/* <StyledToggleButtonGroup
                  orientation='vertical'
                  size='small'
                  value={
                    atomMeta?.yAxis[indexSerie + 1] &&
                    atomMeta?.yAxis[indexSerie + 1]['visible']
                      ? atomMeta.yAxis[indexSerie + 1]
                      : false
                  }
                  // onChange={(ev, t) => console.log(ev, t, 'd')}
                  // changeVisibleYAxis}
                  aria-label='ChartVisibleYAxis'
                >
                  <ToggleButton value={true} aria-label='connectNulls--color'>
                    <IconRemoveRedEye onClick={changeVisibleYAxis} />
                  </ToggleButton>
                </StyledToggleButtonGroup> */}

                <StyledToggleButtonGroup
                  orientation='vertical'
                  size='small'
                  value={
                    atomMeta?.yAxis[indexSerie + 1] &&
                    atomMeta?.yAxis[indexSerie + 1]['opposite']
                      ? atomMeta.yAxis[indexSerie + 1]
                      : false
                  }
                  // onChange={(ev, t) => console.log(ev, t, 'd')}
                  // changeVisibleYAxis}
                  aria-label='ChartOppositeYAxis'
                >
                  <ToggleButton value={false} aria-label='connectNulls--color'>
                    <Tooltip title={'Отобразить ось справа'}>
                      <IconBorderLeft onClick={changeOppositeYAxis} />
                    </Tooltip>
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </Paper>
            </div>
          </Paper>
        </Popover>
      ) : null}

      <Grid item xs={1}>
        <StyledToggleButtonGroup
          size='small'
          value={''}
          onChange={e => setAnchorMain(e.currentTarget)}
          orientation='vertical'
          aria-label='mainPover'
        >
          <ToggleButton value='normal' aria-label='mainPover'>
            <Tooltip title={'Конфигурация графика'}>
              <IconPermDataSetting color='primary' />
            </Tooltip>
          </ToggleButton>
        </StyledToggleButtonGroup>
        <Popover
          id={
            Boolean(anchorMain) ? 'main-popover' + props.atomMeta.id : undefined
          }
          key={stemp}
          open={Boolean(anchorMain)}
          anchorEl={anchorMain}
          // placement="bottom-start"
          onClose={e => setAnchorMain(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <Paper elevation={0} className={classes.paperPoper}>
            <StyledToggleButtonGroup
              size='small'
              orientation='vertical'
              // value={currentSeries['type']}
              value={atomMeta['timer']}
              // value={'bar'}
              exclusive
              onChange={changeTimeMain}
              aria-label='ChartTime'
            >
              <ToggleButton value='normal' aria-label='normal--stack'>
                <Tooltip title={'Обновить'}>
                  <IconLoop />
                </Tooltip>
              </ToggleButton>
            </StyledToggleButtonGroup>
            <Divider
              flexItem
              // orientation='vertical'
              className={classes.divider}
            />
            <StyledToggleButtonGroup
              size='small'
              // value={currentSeries['type']}
              value={''}
              orientation='vertical'
              exclusive
              onChange={changeTypeMain}
              aria-label='ChartType'
            >
              {Object.keys(menuIconsType).map(key => {
                return (
                  <ToggleButton value={key} aria-label={key + '--type'}>
                    {menuIconsType[key].icon}
                  </ToggleButton>
                )
              })}
            </StyledToggleButtonGroup>
            <Divider
              flexItem
              // orientation='vertical'
              className={classes.divider}
            />
            <StyledToggleButtonGroup
              size='small'
              orientation='vertical'
              // value={currentSeries['type']}
              value={atomMeta['timer']}
              // value={'bar'}
              exclusive
              onChange={changeTimeMain}
              aria-label='ChartTime'
            >
              <Tooltip title={'Сведения за последний час'}>
                <ToggleButton value={'1h'} aria-label={'1h' + '--time'}>
                  <Typography variant='body2' style={{ width: 24 }}>
                    -1h
                  </Typography>
                </ToggleButton>
              </Tooltip>
              <Tooltip title={'Сведения за последние сутки'}>
                <ToggleButton value={'1d'} aria-label={'1d' + '--time'}>
                  <Typography variant='body2' style={{ width: 24 }}>
                    -1d
                  </Typography>
                </ToggleButton>
              </Tooltip>
              <Tooltip title={'Сведения за текущие сутки'}>
                <ToggleButton
                  value={'now'}
                  aria-label={'now' + '--time'}
                  style={{ paddingLeft: 0 }}
                >
                  <Typography variant='body2' style={{ width: 24 }}>
                    Now
                  </Typography>
                </ToggleButton>
              </Tooltip>
            </StyledToggleButtonGroup>
            <Divider
              flexItem
              // orientation='vertical'
              className={classes.divider}
            />
            <StyledToggleButtonGroup
              size='small'
              value={''}
              onChange={changeStack}
              orientation='vertical'
              aria-label='ChartStack'
            >
              <ToggleButton value='normal' aria-label='normal--stack'>
                <Tooltip title={'Стэк'}>
                  <IconViewStreamTwoTone />
                </Tooltip>
              </ToggleButton>
            </StyledToggleButtonGroup>
            <Divider
              flexItem
              // orientation='vertical'
              className={classes.divider}
            />
            <StyledToggleButtonGroup
              size='small'
              orientation='vertical'
              value={''}
              onChange={e => changeColor(true)}
              aria-label='ChartColor'
            >
              <ToggleButton value='color' aria-label='color--color'>
                <Tooltip title={'Изменить цвет'}>
                  <IconPalette />
                </Tooltip>
              </ToggleButton>
            </StyledToggleButtonGroup>
            <Divider
              flexItem
              // orientation='vertical'
              className={classes.divider}
            />
            <StyledToggleButtonGroup
              size='small'
              orientation='vertical'
              value={''}
              onChange={changeConnect}
              aria-label='ChartConnectNulls'
            >
              <ToggleButton
                value='connectNulls'
                aria-label='connectNulls--color'
              >
                <Tooltip title={'Соединить пропущенные значения'}>
                  <IconLinearScale />
                </Tooltip>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Paper>
        </Popover>
      </Grid>

      <Grid item xs={11}>
        <HighchartsReact
          options={atomMeta}
          key={update + props.atomMeta.id}
          // id={chart2 + atomMeta.id}
        />
      </Grid>
    </Grid>
  ) : null
}

// {Boolean(anchorMain) ? (
// <Popover
//   id={
//     Boolean(anchorMain) ? 'main-popover' + props.atomMeta.id : undefined
//   }
//   key={stemp}
//   open={Boolean(anchorMain)}
//   anchorEl={anchorMain}
//   onClose={e => setAnchorMain(null)}
//   anchorOrigin={{
//     vertical: y,
//     horizontal: x
//   }}
//   transformOrigin={{
//     vertical: 'top',
//     horizontal: 'left'
//   }}
// >
//     <Paper style={{ width: 190 }}>
//       <div>
//         <Paper elevation={0} className={classes.paper}>
//           <StyledToggleButtonGroup
//             size='small'
//             // value={currentSeries['type']}
//             value={''}
//             // value={'bar'}
//             exclusive
//             onChange={changeTypeMain}
//             aria-label='ChartType'
//           >
//             {Object.keys(menuIconsType).map(key => {
//               return (
//                 <ToggleButton value={key} aria-label={key + '--type'}>
//                   {menuIconsType[key].icon}
//                 </ToggleButton>
//               )
//             })}
//           </StyledToggleButtonGroup>

//           <StyledToggleButtonGroup
//             size='small'
//             // value={currentSeries['type']}
//             value={atomMeta['timer']}
//             // value={'bar'}
//             exclusive
//             onChange={changeTimeMain}
//             aria-label='ChartTime'
//           >
//             <ToggleButton value={'1h'} aria-label={'1h' + '--time'}>
//               <Typography style={{ width: 24 }}>-1ч</Typography>
//             </ToggleButton>
//             <ToggleButton value={'1d'} aria-label={'1d' + '--time'}>
//               <Typography style={{ width: 24 }}>-1д</Typography>
//             </ToggleButton>
//             <ToggleButton value={'now'} aria-label={'now' + '--time'}>
//               <Typography style={{ width: 75 }}>Сегодня</Typography>
//             </ToggleButton>
//           </StyledToggleButtonGroup>
//           {/* <Divider
//             flexItem
//             orientation='vertical'
//             className={classes.divider}
//           /> */}
//           <StyledToggleButtonGroup
//             size='small'
//             value={''}
//             onChange={changeStack}
//             aria-label='ChartStack'
//           >
//             <ToggleButton value='normal' aria-label='normal--stack'>
//               <IconViewStreamTwoTone />
//             </ToggleButton>
//           </StyledToggleButtonGroup>
//           <Divider
//             flexItem
//             orientation='vertical'
//             className={classes.divider}
//           />
//           <StyledToggleButtonGroup
//             size='small'
//             value={''}
//             onChange={e => changeColor(true)}
//             aria-label='ChartColor'
//           >
//             <ToggleButton value='color' aria-label='color--color'>
//               <IconPalette />
//             </ToggleButton>
//           </StyledToggleButtonGroup>
//           <Divider
//             flexItem
//             orientation='vertical'
//             className={classes.divider}
//           />
//           <StyledToggleButtonGroup
//             size='small'
//             value={''}
//             onChange={changeConnect}
//             aria-label='ChartConnectNulls'
//           >
//             <ToggleButton
//               value='connectNulls'
//               aria-label='connectNulls--color'
//             >
//               <IconLinearScale />
//             </ToggleButton>
//           </StyledToggleButtonGroup>
//         </Paper>
//       </div>
//     </Paper>
//   </Popover>
// ) : null}

// <SpeedDial
//         ariaLabel='SpeedDial Type'
//         className={classes.speedDialType}
//         hidden={false}
//         // fontSize='small'
//         icon={menuIconsType[atomMeta['chart']['type']]['icon']}
//         onClose={e => setOpen(false)}
//         onOpen={e => setOpen(true)}
//         open={open}
//         direction={'down'}
//       >
//         {Object.keys(menuIconsType).map(id => {
//           if (atomMeta['chart']['type'] !== id) {
//             return (
//               <SpeedDialAction
//                 onClick={e => changeType(id)}
//                 key={menuIconsType[id].name}
//                 icon={menuIconsType[id].icon}
//                 tooltipTitle={menuIconsType[id].name}
//                 onClick={e => setOpen(false)}
//               />
//             )
//           }
//         })}
//       </SpeedDial>
//       <SpeedDial
//         ariaLabel='SpeedDial Stack'
//         className={classes.speedDialStack}
//         hidden={false}
//         // fontSize='small'
//         icon={
//           menuIconsStack[atomMeta['plotOptions']['column']['stacking']]['icon']
//         }
//         onClose={e => setOpenStack(false)}
//         onOpen={e => setOpenStack(true)}
//         open={openStack}
//         direction={'down'}
//       >
//         {Object.keys(menuIconsStack).map(id => {
//           if (atomMeta['plotOptions']['column']['stacking'] !== id) {
//             return (
//               <SpeedDialAction
//                 onClick={e => changeType(id)}
//                 key={menuIconsStack[id].name}
//                 icon={menuIconsStack[id].icon}
//                 tooltipTitle={menuIconsStack[id].name}
//                 onClick={e => setOpenStack(false)}
//               />
//             )
//           }
//         })}
//       </SpeedDial>
//       <SpeedDial
//         ariaLabel='SpeedDial Color'
//         className={classes.speedDialColor}
//         hidden={false}
//         icon={<IconPalette />}
//         onClose={e => setOpenColor(false)}
//         onOpen={e => setOpenColor(true)}
//         onClick={e => changeColor(true)}
//         open={openColor}
//         direction={'down'}
//       ></SpeedDial>

// import React, { useEffect, useState, useContext, useLayoutEffect } from 'react'
// // import ReactDOM from 'react-dom'
// // import { Redirect } from 'react-router-dom'
// import clsx from 'clsx'

// // import { makeStyles } from "@material-ui/core/styles";
// // import React from 'react';
// import PropTypes from 'prop-types'
// import Paper from '@material-ui/core/Paper'
// import { makeStyles } from '@material-ui/core/styles'
// import { withStyles } from '@material-ui/core/styles'
// import Tabs from '@material-ui/core/Tabs'
// import Tab from '@material-ui/core/Tab'
// import Typography from '@material-ui/core/Typography'
// import Box from '@material-ui/core/Box'
// // import Loader from './Loader'
// // import Context from './context'

// import Tooltip from '@material-ui/core/Tooltip'
// import AppBar from '@material-ui/core/AppBar'
// import Toolbar from '@material-ui/core/Toolbar'
// import InputLabel from '@material-ui/core/InputLabel'
// import MenuItem from '@material-ui/core/MenuItem'
// import FormControl from '@material-ui/core/FormControl'
// // import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// import Select from '@material-ui/core/Select'
// import { Switch, Checkbox, Backdrop, Button } from '@material-ui/core'

// // import Input from '@material-ui/core/Input'

// // import Table from '@material-ui/core/Table'
// // import TableBody from '@material-ui/core/TableBody'
// // import TableCell from '@material-ui/core/TableCell'
// import TableContainer from '@material-ui/core/TableContainer'
// // import TableHead from '@material-ui/core/TableHead'
// // import TableRow from '@material-ui/core/TableRow'
// import Badge from '@material-ui/core/Badge'
// // import Avatar from '@material-ui/core/Avatar'
// // import Chip from '@material-ui/core/Chip'
// // import Divider from '@material-ui/core/Divider'
// // import TablePagination from '@material-ui/core/TablePagination'
// // import FFPaginationAction from './components/FFPaginationAction'
// import { SnackbarProvider, useSnackbar } from 'notistack'
// // import FFListSwitch from './components/FFListSwitch';
// // import FFSelect from './components/FFSelect'
// // import FFTextFields from './components/FFTextFields'
// // import FFSelectMulti from './components/FFSelectMulti'
// // import FFRouterComponents from './components/FFRouterComponents'
// // import FFTextFields from './components/FFTextFields'
// // import FFSelect from './components/FFSelect'

// // import FFCheckList from './components/FFCheckList'
// // import InputAdornment from '@material-ui/core/InputAdornment'
// // import FFPanelMenu from './components/FFPanelMenu'
// // import FFPersistentDrawerRight from './components/FFPersistentDrawerRight'
// import Grid from '@material-ui/core/Grid'
// // import TextField from '@material-ui/core/TextField'
// // import MoreVertIcon from '@material-ui/icons/MoreVert'
// // import Drawer from '@material-ui/core/Drawer'
// // import Button from '@material-ui/core/Button';
// // import List from '@material-ui/core/List'
// import Card from '@material-ui/core/Card'
// // import FFTester from './FFTester'
// // import Divider from '@material-ui/core/Divider';
// // import ListItem from '@material-ui/core/ListItem';
// // import ListItemIcon from '@material-ui/core/ListItemIcon';
// // import ListItemText from '@material-ui/core/ListItemText';
// // import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MenuIcon from '@material-ui/icons/Menu'
// import IconButton from '@material-ui/core/IconButton'

// import IconSettings from '@material-ui/icons/Settings'

// import IconDelete from '@material-ui/icons/Delete'
// // import IconFilter from '@material-ui/icons/FilterList'
// // import IconSearch from '@material-ui/icons/Search'
// import IconLibraryAdd from '@material-ui/icons/LibraryAdd'
// import IconAdd from '@material-ui/icons/Add'
// import IconSave from '@material-ui/icons/Save'
// import IconRefresh from '@material-ui/icons/Refresh'
// import IconSystemUpdateAlt from '@material-ui/icons/SystemUpdateAlt'
// // import IconBuild from '@material-ui/icons/Build'
// // import IconReplay from '@material-ui/icons/Replay'
// // import IconCancel from '@material-ui/icons/Cancel'
// // import IconUpdate from '@material-ui/icons/Update'

// // import FFFilterLabel from './components/FFFilterLabel'
// // import FFToggle from './components/FFToggle'

// // import CardHeader from '@material-ui/core/CardHeader'
// // import TreeItem from '@material-ui/lab/TreeItem'
// // import TreeView from '@material-ui/lab/TreeView'
// // import Essence from './essence_action_info.json'
// // import CancelIcon from '@material-ui/icons/Cancel';
// // import Context from './context'
// // import FileCls from './cls.json'
// // import FileParameter from './parameter.json'
// // import { Button, GridListTile, ListSubheader } from '@material-ui/core'
// // import SwipeableViews from 'react-swipeable-views';
// // import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
// // import ArrowRightIcon from '@material-ui/icons/ArrowRight'
// // import Remove from '@material-ui/icons/Remove'
// // import FileBaseLine from './fileSrc/scenarios_model.json'

// import Dialog from '@material-ui/core/Dialog'
// import DialogActions from '@material-ui/core/DialogActions'
// import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
// import DialogTitle from '@material-ui/core/DialogTitle'
// import Slide from '@material-ui/core/Slide'

// // import { withStyles } from '@material-ui/core/styles'

// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import CheckCircle from '@material-ui/icons/CheckCircle'
// import DoneOutline from '@material-ui/icons/DoneOutline'
// // import FFPanelMenu from "./components/FFPanelMenu";

// // import SortableTree from 'react-sortable-tree/dist/index.esm.js'
// // import 'react-sortable-tree/style.css'
// // import '../node_modules/react-grid-layout/css/styles.css'
// // import '../node_modules/react-resizable/css/styles.css'

// import CardHeader from '@material-ui/core/CardHeader'
// import CardMedia from '@material-ui/core/CardMedia'
// import CardContent from '@material-ui/core/CardContent'
// import CardActions from '@material-ui/core/CardActions'
// import Collapse from '@material-ui/core/Collapse'
// import Avatar from '@material-ui/core/Avatar'
// import FavoriteIcon from '@material-ui/icons/Favorite'
// import ShareIcon from '@material-ui/icons/Share'
// import ExpandLessIcon from '@material-ui/icons/ExpandLess'

// import MoreVertIcon from '@material-ui/icons/MoreVert'

// import ExpansionPanel from '@material-ui/core/ExpansionPanel'
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
// // import FFTextFields from './components/FFTextFields'
// // import { validation_data } from './supportFunction'

// import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official'
// import highchartsMore from 'highcharts/highcharts-more.js'
// import ExportingModule from 'highcharts/modules/exporting'
// // import FFSelect from './components/FFSelectSimple'
// // import treeNodeUtils from 'tree-node-utils'

// import SpeedDial from '@material-ui/lab/SpeedDial'
// import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
// import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
// import FileCopyIcon from '@material-ui/icons/FileCopyOutlined'
// import SaveIcon from '@material-ui/icons/Save'
// import PrintIcon from '@material-ui/icons/Print'
// import IconLinearScale from '@material-ui/icons/LinearScale'
// import IconRemoveRedEye from '@material-ui/icons/RemoveRedEye'
// import IconBorderLeft from '@material-ui/icons/BorderLeft'

// import IconShowChart from '@material-ui/icons/ShowChart'
// import IconBarChart from '@material-ui/icons/BarChart'
// import IconPieChart from '@material-ui/icons/Landscape'
// import IconPalette from '@material-ui/icons/Palette'
// import IconViewStreamTwoTone from '@material-ui/icons/ViewStreamTwoTone'

// import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft'
// import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter'
// import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight'
// import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify'
// import FormatBoldIcon from '@material-ui/icons/FormatBold'
// import FormatItalicIcon from '@material-ui/icons/FormatItalic'
// import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined'
// import FormatColorFillIcon from '@material-ui/icons/FormatColorFill'
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
// import Divider from '@material-ui/core/Divider'
// import ToggleButton from '@material-ui/lab/ToggleButton'
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

// import ViewListIcon from '@material-ui/icons/ViewList';
// import ViewModuleIcon from '@material-ui/icons/ViewModule';
// import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';

// import Popover from '@material-ui/core/Popover'

// import {
//   red,
//   pink,
//   purple,
//   deepPurple,
//   indigo,
//   blue,
//   lightBlue,
//   cyan,
//   teal,
//   green,
//   lightGreen,
//   lime,
//   yellow,
//   amber,
//   orange,
//   deepOrange,
//   brown,
//   grey,
//   blueGrey
// } from '@material-ui/core/colors'
// // import ShareIcon from '@material-ui/icons/Share';
// // import FavoriteIcon from '@material-ui/icons/Favorite';
// // let colormap = require('colormap')

// highchartsMore(Highcharts)
// ExportingModule(Highcharts)

// const useStylesTheme = makeStyles(theme => ({
//   speedDialType: {
//     position: 'absolute',
//     // '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
//     //   bottom: theme.spacing(1),
//     //   right: theme.spacing(1)
//     // },
//     // '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
//     //   top: theme.spacing(1),
//     //   left: theme.spacing(1)
//     // },
//     transform: 'scale(0.6)',
//     top: -28,
//     right: 50,
//     zIndex: 100
//   },
//   speedDialStack: {
//     position: 'absolute',
//     transform: 'scale(0.6)',
//     top: -5,
//     right: 100,
//     zIndex: 100
//   },
//   speedDialColor: {
//     position: 'absolute',
//     transform: 'scale(0.6)',
//     top: 6,
//     right: 150,
//     zIndex: 100
//   },
//   paper: {
//     display: 'flex',
//     border: `1px solid ${theme.palette.divider}`,
//     flexWrap: 'wrap',
//     justifyContent: 'center'
//   },
//   divider: {
//     margin: theme.spacing(1, 0.5)
//   }
// }))

// const StyledToggleButtonGroup = withStyles(theme => ({
//   grouped: {
//     margin: theme.spacing(0.5),
//     border: 'none',
//     '&:not(:first-child)': {
//       borderRadius: theme.shape.borderRadius
//     },
//     '&:first-child': {
//       borderRadius: theme.shape.borderRadius
//     }
//   }
// }))(ToggleButtonGroup)

// // const CustomizedDividers = () => {
// //   const [alignment, setAlignment] = React.useState('left')
// //   const [formats, setFormats] = React.useState(() => ['italic'])

// //   const handleFormat = (event, newFormats) => {
// //     setFormats(newFormats)
// //   }

// //   const handleAlignment = (event, newAlignment) => {
// //     setAlignment(newAlignment)
// //   }

// //   const classes = useStyles()

// //   // const menuIconsType = {
// //   //   '': {
// //   //     icon: <IconShowChart onClick={e => changeType('')} />,
// //   //     name: 'Линейный'
// //   //   },
// //   //   column: {
// //   //     icon: <IconBarChart onClick={e => changeType('column')} />,
// //   //     name: 'Колонки'
// //   //   },
// //   //   bar: {
// //   //     icon: (
// //   //       <IconBarChart
// //   //         onClick={e => changeType('bar')}
// //   //         style={{ transform: 'rotate(90deg)' }}
// //   //       />
// //   //     ),
// //   //     name: 'Бар'
// //   //   },
// //   //   pie: {
// //   //     icon: <IconPieChart onClick={e => changeType('pie')} />,
// //   //     name: 'Пироги'
// //   //   }
// //   // }

// //   return (
// //     <div>
// //       <Paper elevation={0} className={classes.paper}>
// //         <StyledToggleButtonGroup
// //           size='small'
// //           value={alignment}
// //           exclusive
// //           onChange={handleAlignment}
// //           aria-label='text alignment'
// //         >
// //           <ToggleButton value='left' aria-label='chart-line'>
// //             <IconShowChart onClick={e => changeType('')} />
// //           </ToggleButton>
// //           <ToggleButton value='center' aria-label='chart-column'>
// //             <IconBarChart onClick={e => changeType('column')} />
// //           </ToggleButton>
// //           <ToggleButton value='right' aria-label='chart-bar'>
// //             <IconBarChart
// //               onClick={e => changeType('bar')}
// //               style={{ transform: 'rotate(90deg)' }}
// //             />
// //           </ToggleButton>
// //           <ToggleButton value='justify' aria-label='pie-chart'>
// //             <IconPieChart onClick={e => changeType('pie')} />
// //           </ToggleButton>
// //         </StyledToggleButtonGroup>
// //         <Divider flexItem orientation='vertical' className={classes.divider} />
// //         <StyledToggleButtonGroup
// //           size='small'
// //           value={formats}
// //           onChange={handleFormat}
// //           aria-label='text formatting'
// //         >
// //           <ToggleButton value='bold' aria-label='bold'>
// //             <FormatBoldIcon />
// //           </ToggleButton>
// //           <ToggleButton value='italic' aria-label='italic'>
// //             <FormatItalicIcon />
// //           </ToggleButton>
// //           <ToggleButton value='underlined' aria-label='underlined'>
// //             <FormatUnderlinedIcon />
// //           </ToggleButton>
// //           <ToggleButton value='color' aria-label='color'>
// //             <FormatColorFillIcon />
// //             <ArrowDropDownIcon />
// //           </ToggleButton>
// //         </StyledToggleButtonGroup>
// //       </Paper>
// //     </div>
// //   )
// // }

// export default function FF_Chart (props) {
//   // console.log(props)
//   const [alignment, setAlignment] = React.useState('left')
//   const [formats, setFormats] = React.useState(() => ['italic'])

//   const handleFormat = (event, newFormats) => {
//     setFormats(newFormats)
//   }

//   // const classes_helper = useStyles()
//   const classes = useStylesTheme()

//   let contr = 500
//   let colors = [
//     red[contr],
//     pink[contr],
//     purple[contr],
//     deepPurple[contr],
//     indigo[contr],
//     blue[contr],
//     lightBlue[contr],
//     cyan[contr],
//     teal[contr],
//     green[contr],
//     lightGreen[contr],
//     lime[contr],
//     yellow[contr],
//     amber[contr],
//     orange[contr],
//     deepOrange[contr],
//     brown[contr],
//     grey[contr],
//     blueGrey[contr]
//   ]

//   const [open, setOpen] = useState(false)
//   const [openStack, setOpenStack] = useState(false)
//   const [openColor, setOpenColor] = useState(false)

//   const [x, setX] = useState(0)
//   const [y, setY] = useState(0)
//   const [atomMeta, setAtomMeta] = useState(props.atomMeta.value)
//   const [update, setUpdate] = useState(0)
//   const [anchorEl, setAnchorEl] = useState(null)
//   const [indexSerie, setIndexSerie] = useState(0)
//   const [anchorMain, setAnchorMain] = useState(null)

//   const [currentSeries, setCurrentSeries] = useState(null)
//   const [stemp, setStemp] = useState(0)

//   const menuIconsType = {
//     line: {
//       icon: <IconShowChart />,
//       name: 'Линейный'
//     },
//     column: {
//       icon: <IconBarChart />,
//       name: 'Колонки'
//     },
//     bar: {
//       icon: <IconBarChart style={{ transform: 'rotate(90deg)' }} />,
//       name: 'Бар'
//     },
//     area: {
//       icon: <IconPieChart />,
//       name: 'Площадь'
//     }
//   }

//   const menuIconsStack = {
//     normal: {
//       icon: <IconViewStreamTwoTone />,
//       name: 'Стэк'
//     },
//     '': {
//       icon: <IconViewStreamTwoTone style={{ transform: 'rotate(90deg)' }} />,
//       name: 'Не стэк)'
//     }
//   }

//   const clickLegend = event => {
//     if (event.browserEvent.altKey === true) {
//       // console.log(event.target.userOptions.name)
//       let temp = atomMeta.series.filter(
//         obj => obj.name === event.target.userOptions.name
//       )[0]
//       setCurrentSeries(temp)
//       setIndexSerie(atomMeta.series.indexOf(temp))
//       // console.log(event,event.browserEvent.path[0])
//       // console.log(atomMeta.series.filter(obj => obj.name === event.target.userOptions.name)[0])
//       setAnchorEl(event.browserEvent.path[0])
//       setStemp(stemp + 1)
//       return false
//     }
//   }

//   const clickMain = event => {
//     // if (event.browserEvent.altKey === true) {
//     // console.log(event.target.userOptions.name)
//     // setCurrentSeries(
//     //   atomMeta.series.filter(
//     //     obj => obj.name === event.target.userOptions.name
//     //   )[0]
//     // )
//     // console.log(atomMeta.series.filter(obj => obj.name === event.target.userOptions.name)[0])
//     if (event.altKey === true) {
//       setX(event.chartX)
//       setY(event.chartY)
//       setAnchorMain(event.target)
//     }
//     // return false
//     // }
//   }

//   useEffect(() => {
//     let temp = props.atomMeta.value
//     temp['chart'] = {
//       events: {
//         click: clickMain
//       },
//       zoomType: 'x'
//     }
//     if (!('timer' in temp && ['1h', '1d', 'now'].indexOf(temp['timer']) > -1)) {
//       temp['timer'] = 'now'
//     }
//     let is_date = false
//     if (
//       !(
//         'yAxis' in temp &&
//         temp['yAxis'] &&
//         temp['yAxis'].length - 1 === temp['series'].length
//       )
//     ) {
//       temp['yAxis'] = [
//         {
//           title: {
//             text: 'Безразмерная базовая ось'
//           }
//         }
//       ]
//       temp.series.map((serie, index) => {
//         if ('source' in serie && serie['source'] === 'p-source-data-stream') {
//           is_date = true
//         }
//         if ('measure_unit' in serie) {
//           serie['yAxis'] = index + 1
//           temp['yAxis'] = [
//             ...temp['yAxis'],
//             {
//               title: { text: serie['measure_unit'][1].short_name },
//               visible: true,
//               opposite: false
//             }
//           ]
//         }
//       })
//     }
//     if (is_date) {
//       temp['xAxis'] = { ...temp['xAxis'], type: 'datetime' }
//     }
//     temp['plotOptions'] = {
//       column: {
//         stacking: 'normal',
//         events: { legendItemClick: clickLegend },
//         showInLegend: true
//       },
//       bar: {
//         stacking: 'normal',
//         events: { legendItemClick: clickLegend },
//         showInLegend: true
//       },
//       line: {
//         stacking: 'normal',
//         events: { legendItemClick: clickLegend },
//         showInLegend: true
//       },
//       area: {
//         stacking: 'normal',
//         events: { legendItemClick: clickLegend },
//         showInLegend: true,
//         fillOpacity: 0.5
//       },
//       series: {
//         connectNulls: true,
//         stacking: 'normal'
//       }
//     }
//     setAtomMeta(temp)
//   }, [])

//   const changeConnect = (event, type) => {
//     let meta = atomMeta
//     meta['plotOptions']['series']['connectNulls'] = !meta['plotOptions'][
//       'series'
//     ]['connectNulls']
//     setAtomMeta(meta)
//     setAnchorEl(null)
//     setUpdate(update + 1)
//   }

//   const changeColor = (event, type) => {
//     let meta = atomMeta
//     meta['colors'] = colors.sort(() => {
//       return Math.random() - 0.5
//     })
//     setAtomMeta(meta)
//     setAnchorEl(null)
//     setAnchorMain(null)
//     setUpdate(update + 1)
//   }

//   const changeType = (event, type) => {
//     let meta = atomMeta
//     currentSeries['type'] = type
//     // meta['chart']['type'] = type
//     // setAtomMeta(meta)
//     setUpdate(update + 1)
//     setAnchorMain(null)
//     setAnchorEl(null)
//   }

//   const changeTypeMain = (event, type) => {
//     let meta = atomMeta
//     atomMeta['series'].map(obj => (obj.type = type))
//     // meta['chart']['type'] = type
//     // setAtomMeta(meta)
//     setUpdate(update + 1)
//     setAnchorMain(null)
//     setAnchorEl(null)
//   }

//   const changeTimeMain = (event, type) => {
//     let meta = atomMeta
//     atomMeta['timer'] = type
//     // meta['chart']['type'] = type
//     // setAtomMeta(meta)
//     if ('updateTime' in props && props.updateTime) {
//       props.updateTime(true)
//     }
//     setUpdate(update + 1)
//     setAnchorMain(null)
//     setAnchorEl(null)
//     // console.log(type)
//   }

//   const changeStack = (event, type) => {
//     let meta = atomMeta
//     Object.keys(meta['plotOptions']).map(
//       key =>
//         (meta['plotOptions'][key]['stacking'] =
//           meta['plotOptions'][key]['stacking'] === 'normal' ? '' : 'normal')
//     )
//     // ['stacking'] =
//     // type === 'normal' ? 'normal' : ''
//     setAtomMeta(meta)
//     setAnchorEl(null)
//     setAnchorMain(null)
//     setUpdate(update + 1)
//   }

//   const changeVisibleYAxis = (event, type) => {
//     let meta = atomMeta
//     meta['yAxis'][indexSerie + 1]['visible'] = !meta['yAxis'][indexSerie + 1][
//       'visible'
//     ]
//     if (meta['yAxis'][indexSerie + 1]['visible'] === false) {
//       meta['series'][indexSerie]['yAxis'] = 0
//     } else {
//       meta['series'][indexSerie]['yAxis'] = indexSerie + 1
//     }

//     setAtomMeta(meta)
//     setAnchorEl(null)
//     setAnchorMain(null)
//     setUpdate(update + 1)
//   }

//   const changeOppositeYAxis = (event, type) => {
//     let meta = atomMeta
//     meta['yAxis'][indexSerie + 1]['opposite'] = !meta['yAxis'][indexSerie + 1][
//       'opposite'
//     ]
//     setAtomMeta(meta)
//     setAnchorEl(null)
//     setAnchorMain(null)
//     setUpdate(update + 1)
//   }

//   return atomMeta ? (
//     <Grid
//       item
//       xs={12}
//       style={{ margin: 4 }}
//       onDoubleClick={e => setUpdate(update + 1)}
//     >
//       {Boolean(anchorEl) ? (
//         <Popover
//           id={
//             Boolean(anchorEl) ? 'small-popover' + props.atomMeta.id : undefined
//           }
//           key={stemp}
//           open={Boolean(anchorEl)}
//           anchorEl={anchorEl}
//           onClose={e => setAnchorEl(null)}
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left'
//           }}
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'left'
//           }}
//         >
//           {/* {console.log(anchorEl, 'PP')} */}

//           <Paper style={{ width: 190 }}>
//             <div>
//               <Paper elevation={0} className={classes.paper}>
//                 <StyledToggleButtonGroup
//                   orientation='vertical'
//                   size='small'
//                   value={currentSeries['type']}
//                   // value={'bar'}
//                   exclusive
//                   onChange={changeType}
//                   aria-label='ChartType'
//                 >
//                   {Object.keys(menuIconsType).map(key => {
//                     return (
//                       <ToggleButton value={key} aria-label={key + '--type'}>
//                         {menuIconsType[key].icon}
//                       </ToggleButton>
//                     )
//                   })}
//                 </StyledToggleButtonGroup>
//                 {/* <Divider
//                   flexItem
//                   orientation='vertical'
//                   className={classes.divider}
//                 /> */}
//                 {/* <StyledToggleButtonGroup
//                   size='small'
//                   value={
//                     atomMeta['plotOptions'][currentSeries['type']]['stacking']
//                   }
//                   onChange={changeStack}
//                   aria-label='ChartStack'
//                 >
//                   <ToggleButton value='normal' aria-label='normal--stack'>
//                     <IconViewStreamTwoTone />
//                   </ToggleButton>
//                 </StyledToggleButtonGroup>
//                 <Divider
//                   flexItem
//                   orientation='vertical'
//                   className={classes.divider}
//                 /> */}
//                 {/* <StyledToggleButtonGroup
//                   size='small'
//                   value={''}
//                   onChange={e => changeColor(true)}
//                   aria-label='ChartColor'
//                 >
//                   <ToggleButton value='color' aria-label='color--color'>
//                     <IconPalette />
//                   </ToggleButton>
//                 </StyledToggleButtonGroup>
//                 <Divider
//                   flexItem
//                   orientation='vertical'
//                   className={classes.divider}
//                 /> */}
//                 <StyledToggleButtonGroup
//                   orientation='vertical'
//                   size='small'
//                   value={
//                     atomMeta['plotOptions']['series']['connectNulls']
//                       ? 'connectNulls'
//                       : ''
//                   }
//                   onChange={changeConnect}
//                   aria-label='ChartConnectNulls'
//                 >
//                   <ToggleButton
//                     value='connectNulls'
//                     aria-label='connectNulls--color'
//                   >
//                     <IconLinearScale />
//                   </ToggleButton>
//                 </StyledToggleButtonGroup>
//                 <StyledToggleButtonGroup
//                   orientation='vertical'
//                   size='small'
//                   value={atomMeta['yAxis'][indexSerie + 1]['visible']}
//                   // onChange={(ev, t) => console.log(ev, t, 'd')}
//                   // changeVisibleYAxis}
//                   aria-label='ChartVisibleYAxis'
//                 >
//                   <ToggleButton value={true} aria-label='connectNulls--color'>
//                     <IconRemoveRedEye onClick={changeVisibleYAxis} />
//                   </ToggleButton>
//                 </StyledToggleButtonGroup>

//                 <StyledToggleButtonGroup
//                   orientation='vertical'
//                   size='small'
//                   value={atomMeta['yAxis'][indexSerie + 1]['opposite']}
//                   // onChange={(ev, t) => console.log(ev, t, 'd')}
//                   // changeVisibleYAxis}
//                   aria-label='ChartOppositeYAxis'
//                 >
//                   <ToggleButton value={false} aria-label='connectNulls--color'>
//                     <IconBorderLeft onClick={changeOppositeYAxis} />
//                   </ToggleButton>
//                 </StyledToggleButtonGroup>
//               </Paper>
//             </div>
//           </Paper>
//         </Popover>
//       ) : null}

//       {Boolean(anchorMain) ? (
//         <Popover
//           id={
//             Boolean(anchorMain) ? 'main-popover' + props.atomMeta.id : undefined
//           }
//           key={stemp}
//           open={Boolean(anchorMain)}
//           anchorEl={anchorMain}
//           onClose={e => setAnchorMain(null)}
//           anchorOrigin={{
//             vertical: y,
//             horizontal: x
//           }}
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'left'
//           }}
//         >
//           <Paper style={{ width: 190 }}>
//             <div>
//               <Paper elevation={0} className={classes.paper}>
//                 <StyledToggleButtonGroup
//                   size='small'
//                   // value={currentSeries['type']}
//                   value={''}
//                   // value={'bar'}
//                   exclusive
//                   onChange={changeTypeMain}
//                   aria-label='ChartType'
//                 >
//                   {Object.keys(menuIconsType).map(key => {
//                     return (
//                       <ToggleButton value={key} aria-label={key + '--type'}>
//                         {menuIconsType[key].icon}
//                       </ToggleButton>
//                     )
//                   })}
//                 </StyledToggleButtonGroup>

//                 <StyledToggleButtonGroup
//                   size='small'
//                   // value={currentSeries['type']}
//                   value={atomMeta['timer']}
//                   // value={'bar'}
//                   exclusive
//                   onChange={changeTimeMain}
//                   aria-label='ChartTime'
//                 >
//                   <ToggleButton value={'1h'} aria-label={'1h' + '--time'}>
//                     <Typography style={{ width: 24 }}>-1ч</Typography>
//                   </ToggleButton>
//                   <ToggleButton value={'1d'} aria-label={'1d' + '--time'}>
//                     <Typography style={{ width: 24 }}>-1д</Typography>
//                   </ToggleButton>
//                   <ToggleButton value={'now'} aria-label={'now' + '--time'}>
//                     <Typography style={{ width: 75 }}>Сегодня</Typography>
//                   </ToggleButton>
//                 </StyledToggleButtonGroup>
//                 {/* <Divider
//                   flexItem
//                   orientation='vertical'
//                   className={classes.divider}
//                 /> */}
//                 <StyledToggleButtonGroup
//                   size='small'
//                   value={''}
//                   onChange={changeStack}
//                   aria-label='ChartStack'
//                 >
//                   <ToggleButton value='normal' aria-label='normal--stack'>
//                     <IconViewStreamTwoTone />
//                   </ToggleButton>
//                 </StyledToggleButtonGroup>
//                 <Divider
//                   flexItem
//                   orientation='vertical'
//                   className={classes.divider}
//                 />
//                 <StyledToggleButtonGroup
//                   size='small'
//                   value={''}
//                   onChange={e => changeColor(true)}
//                   aria-label='ChartColor'
//                 >
//                   <ToggleButton value='color' aria-label='color--color'>
//                     <IconPalette />
//                   </ToggleButton>
//                 </StyledToggleButtonGroup>
//                 <Divider
//                   flexItem
//                   orientation='vertical'
//                   className={classes.divider}
//                 />
//                 <StyledToggleButtonGroup
//                   size='small'
//                   value={''}
//                   onChange={changeConnect}
//                   aria-label='ChartConnectNulls'
//                 >
//                   <ToggleButton
//                     value='connectNulls'
//                     aria-label='connectNulls--color'
//                   >
//                     <IconLinearScale />
//                   </ToggleButton>
//                 </StyledToggleButtonGroup>
//               </Paper>
//             </div>
//           </Paper>
//         </Popover>
//       ) : null}

//       <HighchartsReact
//         options={atomMeta}
//         key={update + props.atomMeta.id}
//         // id={chart2 + atomMeta.id}
//       />
//     </Grid>
//   ) : null
// }

// // <SpeedDial
// //         ariaLabel='SpeedDial Type'
// //         className={classes.speedDialType}
// //         hidden={false}
// //         // fontSize='small'
// //         icon={menuIconsType[atomMeta['chart']['type']]['icon']}
// //         onClose={e => setOpen(false)}
// //         onOpen={e => setOpen(true)}
// //         open={open}
// //         direction={'down'}
// //       >
// //         {Object.keys(menuIconsType).map(id => {
// //           if (atomMeta['chart']['type'] !== id) {
// //             return (
// //               <SpeedDialAction
// //                 onClick={e => changeType(id)}
// //                 key={menuIconsType[id].name}
// //                 icon={menuIconsType[id].icon}
// //                 tooltipTitle={menuIconsType[id].name}
// //                 onClick={e => setOpen(false)}
// //               />
// //             )
// //           }
// //         })}
// //       </SpeedDial>
// //       <SpeedDial
// //         ariaLabel='SpeedDial Stack'
// //         className={classes.speedDialStack}
// //         hidden={false}
// //         // fontSize='small'
// //         icon={
// //           menuIconsStack[atomMeta['plotOptions']['column']['stacking']]['icon']
// //         }
// //         onClose={e => setOpenStack(false)}
// //         onOpen={e => setOpenStack(true)}
// //         open={openStack}
// //         direction={'down'}
// //       >
// //         {Object.keys(menuIconsStack).map(id => {
// //           if (atomMeta['plotOptions']['column']['stacking'] !== id) {
// //             return (
// //               <SpeedDialAction
// //                 onClick={e => changeType(id)}
// //                 key={menuIconsStack[id].name}
// //                 icon={menuIconsStack[id].icon}
// //                 tooltipTitle={menuIconsStack[id].name}
// //                 onClick={e => setOpenStack(false)}
// //               />
// //             )
// //           }
// //         })}
// //       </SpeedDial>
// //       <SpeedDial
// //         ariaLabel='SpeedDial Color'
// //         className={classes.speedDialColor}
// //         hidden={false}
// //         icon={<IconPalette />}
// //         onClose={e => setOpenColor(false)}
// //         onOpen={e => setOpenColor(true)}
// //         onClick={e => changeColor(true)}
// //         open={openColor}
// //         direction={'down'}
// //       ></SpeedDial>
