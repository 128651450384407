/* eslint-disable react/jsx-pascal-case */
import React, {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
// import ReactDOM from 'react-dom'
// import { Redirect } from 'react-router-dom'
import clsx from "clsx";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
// import { makeStyles } from "@material-ui/core/styles";
// import React from 'react';
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// import Loader from './Loader'
import Context from "../context";
// import FFTreeEssence from './App2'
import FFSelectTree from "./FFSelectTree";
import TreeNodeUtils from "tree-node-utils";
import FFLoaderComponent from "./FFLoaderComponent";

import FFTransportList from "./FFTransportList";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Select from "@material-ui/core/Select";
import { Switch, Checkbox, Slider, Chip, Backdrop } from "@material-ui/core";
import FFTable from "./FFTable";
// import Input from '@material-ui/core/Input'

// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
import Badge from "@material-ui/core/Badge";
// import Avatar from '@material-ui/core/Avatar'
// import Chip from '@material-ui/core/Chip'
// import Divider from '@material-ui/core/Divider'
// import TablePagination from '@material-ui/core/TablePagination'
// import FFPaginationAction from './components/FFPaginationAction'
import { SnackbarProvider, useSnackbar } from "notistack";
// import FFListSwitch from './components/FFListSwitch';
// import FFSelect from './components/FFSelect'
// import FFTextFields from './components/FFTextFields'
// import FFSelectMulti from './components/FFSelectMulti'
import FFRouterComponents from "./FFRouterComponents";
// import FFTextFields from './components/FFTextFields'
// import FFSelect from './components/FFSelect'

// import FFCheckList from './components/FFCheckList'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import FFPanelMenu from './components/FFPanelMenu'
// import FFPersistentDrawerRight from './components/FFPersistentDrawerRight'
import Grid from "@material-ui/core/Grid";
// import TextField from '@material-ui/core/TextField'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
import Drawer from "@material-ui/core/Drawer";
import FFTreeEssence from "./FFTreeEssence";
// import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List'
import Card from "@material-ui/core/Card";
// import FFTester from './FFTester'
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";

import IconSettings from "@material-ui/icons/Settings";

import IconDelete from "@material-ui/icons/Delete";
// import IconFilter from '@material-ui/icons/FilterList'
// import IconSearch from '@material-ui/icons/Search'
import IconLibraryAdd from "@material-ui/icons/LibraryAdd";
import IconAdd from "@material-ui/icons/Add";
import IconSave from "@material-ui/icons/Save";
import IconRefresh from "@material-ui/icons/Refresh";
import IconHelp from "@material-ui/icons/Help";
import IconError from "@material-ui/icons/Error";

import CircularProgress from "@material-ui/core/CircularProgress";

import IconSystemUpdateAlt from "@material-ui/icons/SystemUpdateAlt";
// import IconBuild from '@material-ui/icons/Build'
// import IconReplay from '@material-ui/icons/Replay'
// import IconCancel from '@material-ui/icons/Cancel'
// import IconUpdate from '@material-ui/icons/Update'

// import FFFilterLabel from './components/FFFilterLabel'
// import FFToggle from './components/FFToggle'

// import CardHeader from '@material-ui/core/CardHeader'
// import TreeItem from '@material-ui/lab/TreeItem'
// import TreeView from '@material-ui/lab/TreeView'
// import Essence from './essence_action_info.json'
// import CancelIcon from '@material-ui/icons/Cancel';
// import Context from './context'
// import FileCls from './cls.json'
// import FileParameter from './parameter.json'
import { Button, GridListTile, ListSubheader } from "@material-ui/core";
// import SwipeableViews from 'react-swipeable-views';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
// import ArrowRightIcon from '@material-ui/icons/ArrowRight'
// import Remove from '@material-ui/icons/Remove'
// import FileBaseLine from './fileSrc/scenarios_model.json'

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { withStyles } from "@material-ui/core/styles";

import { green } from "@material-ui/core/colors";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DoneOutline from "@material-ui/icons/DoneOutline";
// import FFPanelMenu from "./components/FFPanelMenu";

// import SortableTree from 'react-sortable-tree/dist/index.esm.js'
// import 'react-sortable-tree/style.css'
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";

import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import { blue, red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import cookie from "react-cookies";
import Loader from "../Loader";
// import ApiConnect from '../ApiConnect'
import { ApiConnect, urlConfiguration, urlRest } from "../ApiConnectNew";

import FFSwitch_N from "./FFSwitch_N";
import FFToggle from "./FFToggleSimple";
import FFSlider from "./FFSliderWButton";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Alert from "@material-ui/lab/Alert";

import FFTextFields from "./FFTextFields";
import FFSelect from "./FFSelectSimple";

import { useTheme } from "@material-ui/core/styles";

import { validation_data } from "../supportFunction";
import grey from "@material-ui/core/colors/grey";
import FF_Chart from "./FF_Charts";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import ExportingModule from "highcharts/modules/exporting";

import FFDateRange from "./FFDateRange";

highchartsMore(Highcharts);
ExportingModule(Highcharts);

const uuidv4 = require("uuid/v4");

var ReactGridLayout = require("react-grid-layout");

const useTreeItemStyles = makeStyles((theme) => ({
  panel: {
    width: "-webkit-fill-available",
    overflow: "overlay",
    height: "-webkit-fill-available",
    borderRadius: 15,
    background: theme.palette.grey[50],
    // borderLeft: '2px solid ' + theme.palette.grey[500]
  },
  activePanel: {
    borderLeftColor: theme.palette.primary.main,
  },
  panelHeader: {
    borderBottom: "2px solid " + theme.palette.grey[500],
    background: theme.palette.primary.light,
    height: 40,
  },
  panelHeaderRoot: {
    borderBottom: "2px solid " + theme.palette.grey[500],
    background: theme.palette.grey[400],
    height: 40,
  },
  panelNull: {
    height: 0,
  },
  grid: {
    width: "-webkit-fill-available",
    overflow: "overlay",
    height: "-webkit-fill-available",
    borderRadius: 15,
    background: theme.palette.grey[50],
    // border: '1px dashed ' + theme.palette.grey[500]
  },
  ffTypography: {
    borderBottom: "1px solid" + theme.palette.grey[600],
    color: theme.palette.grey[700],
    margin: 4,
    width: "-webkit-fill-available",
    // color: theme.palette.primary.main,
    background: theme.palette.grey[300],
    borderRadius: "4px 4px 0px 0px",
    padding: 4,
  },
  flexEnd: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flexFlow: 3,
  },
  actionButton: {
    width: "-webkit-fill-available",
    margin: 4,
    // color: defTheme.palette.success.main,
    marginTop: 6,
  },
  FAILURE: {
    color: theme.palette.grey[50],
    backgroundColor: theme.palette.error.main,
    margin: 4,
  },
  loader: {
    width: "-webkit-fill-available",
    height: "-webkit-fill-available",
    color: theme.palette.grey[50],
    borderRadius: 15,
    textAlign: "center",
    zIndex: 101,
  },
  INFO: {
    color: theme.palette.grey[50],
    backgroundColor: theme.palette.warning.main,
    margin: 4,
  },
}));

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const FF_Exp = (props) => {
  const theme = useTreeItemStyles();

  const defTheme = useTheme();
  // const classes = useStyles()

  const { changeValue, updateTimePlot } = useContext(Context);

  const [active, setActive] = useState(props.active);
  //   const [cu]

  const [meta, setMeta] = useState(props.meta);

  const width = props.width;
  const [te, setTe] = useState(1);
  const [helpMeta, setHelpMeta] = useState({});
  const [chart2, setChart2] = useState(0);

  const config = {
    childrenField: "children",
    keyField: "id",
  };

  const treeUtils = new TreeNodeUtils(config);

  useEffect(() => {
    if (
      helpMeta.length > 1 &&
      props.parent === "root" &&
      props.isOpen === false
    ) {
      helpMeta.map((currentHelpMeta, index) => {
        skipPanel(currentHelpMeta, index);
      });
    }
  }, [helpMeta]);

  useEffect(() => {
    let temp = [];
    meta.map((currentHelpMeta, index) => {
      let tempPos = currentHelpMeta.position;
      tempPos["i"] = currentHelpMeta.id;
      tempPos["h"] = true ? JSON.parse(JSON.stringify(tempPos["h"])) : 2;
      tempPos["minH"] = 2;
      tempPos["isDraggable"] = false;
      tempPos["isResizable"] = false;
      // tempPos['static'] = true
      //   props.parent.indexOf('root') > 0 ? true : false
      tempPos["x"] =
        props.parent.indexOf("root") === 0 ? (index % 2) * 5 : tempPos["x"];

      if (meta.length === 1) {
        tempPos["x"] = 5;
      }

      if (currentHelpMeta.id === "root") {
        tempPos["static"] = true;
      }
      temp = [...temp, JSON.parse(JSON.stringify(tempPos))];
    });
    setHelpMeta(temp);
  }, []);

  const refresher = (state) => {
    let temp = meta;
    state.map((obj) => {
      temp.map((objTemp) => {
        if (objTemp.id === obj.i) {
          objTemp.position = JSON.parse(JSON.stringify(obj));
        }
      });
    });
    // props.getBack(temp, props.parent)
  };

  const handleChangeFFTextFields = (value, id, name, element) => {
    // ла.log(meta, value, id, name)
    if (element) {
      let tempValue = value;
      let node = treeUtils.getNodeByKey(meta, id);
      // console.log(name, value)
      if (name && typeof name === "string" && name.indexOf("_false") > -1) {
        // console.log(value)
        tempValue = Number(!tempValue);
        // console.log(tempValue)
      }
      changeValue(node.essence, node.parameter, tempValue, id, element);
      treeUtils.getNodeByKey(meta, id)["value"] = value;
      return validation_data(name, value);
    }
    return "";
    // return ''
  };

  const getTextValue = (atomMeta) => {
    if (atomMeta?.options) {
      // console.log('!!!', atomMeta, atomMeta.value)
      //Select value options
      if (Array.isArray(atomMeta.options)) {
        let valueSelect = atomMeta.options.filter(
          (obj) => String(obj.id) === String(atomMeta.value)
        );
        if (valueSelect.length) {
          return valueSelect[0].label;
        } else {
          return JSON.stringify(atomMeta.value);
        }
      }
      //Bool value options
      else if (atomMeta?.options && atomMeta?.options["value-true"]) {
        if (
          !(atomMeta?.options["label-true"] && atomMeta?.options["label-false"])
        )
          return JSON.stringify(atomMeta.value);
        if (String(atomMeta.value) === String(atomMeta.options["value-true"])) {
          return atomMeta.options["label-true"];
        } else {
          return atomMeta.options["label-false"];
        }
      }
      return JSON.stringify(atomMeta.options);
    } else {
      return JSON.stringify(atomMeta.value);
    }
  };

  const routerAtomChildren = (atomMeta, currentWidth, currentHeight) => {
    if (atomMeta.type === "ffTextFields") {
      return (
        <FFTextFields
          key={"FFTextFields_" + atomMeta.id}
          value={atomMeta.value}
          is_filter={false}
          measure_unit={atomMeta?.measure_unit}
          name={atomMeta.typeData}
          label={atomMeta.label}
          parameter={atomMeta}
          dis={false}
        />
      );
    } else if (atomMeta.type === "ffTypography") {
      console.log(atomMeta);
      return (
        <Grid
          container
          item
          xs={12}
          key={atomMeta.id}
          // style={{ margin: 4 }}
          className={theme.ffTypography}
        >
          <Typography>{atomMeta.label + ":"}</Typography>
          {atomMeta.source === "p-source-data-stream" &&
          atomMeta.value === null ? (
            <Loader size={"small"} />
          ) : (
            <Typography style={{ marginLeft: 2, color: "black" }}>
              {getTextValue(atomMeta)}
            </Typography>
          )}
          {atomMeta?.measure_unit && atomMeta?.measure_unit !== "none" ? (
            <Typography>{` (${atomMeta.measure_unit})`}</Typography>
          ) : null}
        </Grid>
      );
    } else if (atomMeta.type === "ffSwitch") {
      return (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography style={{ textAlign: "center" }}>
              {atomMeta.label}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FFSwitch_N
              id={atomMeta.id}
              meta={{
                // label: atomMeta.labelValue,
                label: ["False", "True"],
                // width: parseInt((currentWidth - 4) / atomMeta.label.length),
                width: parseInt((currentWidth - 4) / 2),
                value: atomMeta.value,
              }}
            />
          </Grid>
        </Grid>
      );
    } else if (atomMeta.type === "ffToggle") {
      let inver = false;
      if (
        "options" in atomMeta &&
        "value-false" in atomMeta["options"] &&
        atomMeta["options"]["value-false"] &&
        !isNaN(Number(atomMeta["options"]["value-false"]))
      ) {
        inver = Boolean(Number(atomMeta["options"]["value-false"]));
      }
      // console.log(atomMeta, inver)
      return (
        <FFToggle
          id={atomMeta.id}
          name={"toggle_" + !inver}
          value={
            inver
              ? !Boolean(Number(atomMeta.value))
              : Boolean(Number(atomMeta.value))
          }
          label={atomMeta.label}
        />
      );
    } else if (atomMeta.type === "ffSelect") {
      return (
        <FFSelect
          id={atomMeta.id}
          value={String(atomMeta.value)}
          options={atomMeta.options}
          name={"ffSelect"}
          label={atomMeta.label}
        />
      );
    } else if (atomMeta.type === "ffRangeOne") {
      return (
        <FFSlider
          id={atomMeta.id}
          value={atomMeta.value}
          options={atomMeta.options}
          label={
            atomMeta.label +
            (atomMeta?.measure_unit ? ` (${atomMeta.measure_unit}) ` : "")
          }
          // name={'rangeOne'}
          type={"One"}
        />
      );
    } else if (atomMeta.type === "ffRangeTwo") {
      return (
        <FFSlider
          id={atomMeta.id}
          value={atomMeta.value}
          options={atomMeta.options}
          label={
            atomMeta.label +
            (atomMeta?.measure_unit ? ` (${atomMeta.measure_unit}) ` : "")
          }
          // name={'rangeTwo'}
          type={"Two"}
        />
      );
    } else if (atomMeta.type === "ffChart") {
      // const type = { 0: '', 1: 'bar', 2: 'column', 3: 'pie' }
      // let state = {
      //   chart: {
      //     type: type[atomMeta.typeChart],
      //     zoomType: 'x'
      //   },
      //   title: {
      //     text: atomMeta.title,
      //     style: {
      //       color: 'blue',
      //       fontWeight: 'bold',
      //       fontSize: 14
      //     }
      //   },
      //   xAxis: {
      //     // categories: ,
      //     crosshair: true
      //   },
      //   yAxis: {
      //     min: 0,
      //     title: {
      //       text: 'Ось Y'
      //     }
      //   },
      //   legend: {
      //     reversed: false
      //   },
      //   tooltip: {
      //     headerFormat:
      //       '<span style="font-size:10px">{point.key}</span><table>',
      //     pointFormat:
      //       '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      //       '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      //     footerFormat: '</table>',
      //     shared: true,
      //     useHTML: true
      //   },
      //   series: [
      //     {
      //       type: 'line',
      //       name: atomMeta.label,
      //       data: atomMeta.value
      //     }
      //   ]
      // }
      // let a = atomMeta['parameterSeries'].map(obj => {
      //   return {
      //     type: 'line',
      //     data: obj.value,
      //     name: obj.label,
      //     id: obj.id,
      //     source: obj.source
      //   }
      // })
      // console.log(atomMeta)
      atomMeta["value"]["series"] = atomMeta["childrenSeries"];
      atomMeta["value"]["chart"] = {
        ...atomMeta["value"]["chart"],
        height: currentHeight,
      };
      return (
        // <Grid
        //   item
        //   xs={12}
        //   style={{ margin: 10 }}
        //   onDoubleClick={e => setChart2(chart2 + 12)}
        // >
        <>
          {/* {console.log(atomMeta, 'FFCHArt')} */}
          <FF_Chart
            atomMeta={atomMeta}
            key={"FF_Chart_" + atomMeta.id + chart2}
            updateTimePlot={() => updateTimePlot(atomMeta.id, atomMeta.essence)}
          />
          {atomMeta?.log ? (
            <Grid>
              <Grid container item xs={12}>
                <Chip
                  className={theme.FAILURE}
                  icon={
                    <IconError style={{ color: defTheme.palette.grey[50] }} />
                  }
                  label={
                    "Высокий приоритет: " +
                    atomMeta.log.filter((obj) => obj.priority === "HIGH").length
                  }
                />
                <Chip
                  className={theme.INFO}
                  icon={
                    <IconHelp style={{ color: defTheme.palette.grey[50] }} />
                  }
                  label={
                    "Низкий приоритет: " +
                    atomMeta.log.filter((obj) => obj.priority === "LOW").length
                  }
                />
                <Chip
                  style={{ margin: 4 }}
                  label={
                    "Норма: " +
                    atomMeta.log.filter(
                      (obj) => obj.priority !== "HIGH" && obj.priority !== "LOW"
                    ).length
                  }
                />
              </Grid>
              <Grid>
                {atomMeta.log.length ? (
                  <FFTable
                    kye={"FFTableLog" + atomMeta.log.length}
                    width={currentWidth}
                    data={atomMeta.log}
                    id={atomMeta.id}
                    listColumn={[
                      {
                        timestamp: "Data&Time",
                        source: "Source",
                        user_name: "User Name",
                        msg_code: "Code",
                        msg_parameter: "Parameter",
                        msg_value: "Value",
                      },
                      { timestamp: "Data&Time", message: "Сообщение" },
                    ]}
                  />
                ) : null}
              </Grid>
            </Grid>
          ) : null}
        </>
        //   {/* <HighchartsReact
        //     options={state}
        //     key={chart2 + atomMeta.id}
        //     // id={chart2 + atomMeta.id}
        //   /> */}
        //   {/* <span>{JSON.stringify(atomMeta)}</span> */}
        // // </Grid>
      );
    } else {
      return null;
    }
  };

  const routerPanel = (currentHelpMeta, index) => {
    if (meta[index].type === "panel") {
      return (
        <>
          {/* <Backdrop
            className={theme.loader}
            open={
              props?.actial === false &&
              currentHelpMeta.h !== 2 &&
              currentHelpMeta.w === 6
                ? true
                : false
            }
          >
            <Grid className={theme.loader}>
              <Grid>
                <Loader />
              </Grid>

              <Grid>
                <Typography variant='h6'>
                  {'Получаем актуальные сведения'}
                </Typography>
              </Grid>
            </Grid>
          </Backdrop> */}
          <FF_Exp
            id={meta[index].id + "_routerPanel"}
            // dragStop={idParent =>
            //   idParent === currentHelpMeta.i && idParent !== active
            //     ? setActive(idParent)
            //     : null
            // }
            // dragStart={idParent =>
            //   idParent === currentHelpMeta.i && active !== ''
            //     ? setActive('')
            //     : null
            // }
            parent={
              props.parent === "root"
                ? currentHelpMeta.i + "_root"
                : currentHelpMeta.i
            }
            isOpen={props.isOpen}
            meta={meta[index].children}
            index={index}
            // getBack={(state, parent) => }
            width={(props.width * currentHelpMeta.w) / 10 - 30}
          />
        </>
      );
    } else if (meta[index].type === "grid") {
      return (
        <Grid
          id={meta[index].id + "_routerGrid"}
          container
          item
          xs={12}
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          {meta[index].children.map((atomMeta) => {
            return (
              <Context.Provider value={{ handleChangeFFTextFields }}>
                <Grid
                  key={"GridAtom" + atomMeta["updateCom"]}
                  item
                  xs={12}
                  style={{
                    borderLeft:
                      atomMeta["updateCom"].indexOf("back") > -1
                        ? "4px solid " + red[500]
                        : "",
                  }}
                >
                  {/* <Backdrop
                    className={theme.loader}
                    open={
                      // true
                      atomMeta['updateCom'].indexOf('Loader') > -1 &&
                      atomMeta['updateCom'].indexOf('LoaderRoot') === -1
                        ? true
                        : false
                    }
                  >
                    <Grid className={theme.loader}>
                      <Grid>
                        <Loader
                          size={
                            atomMeta['type'] !== 'ffChart' ? 'small' : 'large'
                          }
                        />
                      </Grid>
                      <Grid>
                        <Typography variant='h6'>
                          {'Обновляем график'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Backdrop> */}
                  <Tooltip
                    title={
                      "tooltip" in atomMeta && atomMeta.tooltip
                        ? atomMeta.tooltip
                        : ""
                    }
                  >
                    {routerAtomChildren(
                      atomMeta,
                      (((props.width * currentHelpMeta.w) / 10) * 10) / 12 - 20,
                      currentHelpMeta.h * (20 + 7) - 20
                    )}
                  </Tooltip>
                </Grid>
              </Context.Provider>
            );
          })}
          {/* <Grid item xs={1}>
            <IconButton
              style={{ padding: 4, float: 'right', margin: 4 }}
              onClick={e => addComponent(meta[index].id, 'QQ')}
            >
              <IconSettings fontSize='small' />
            </IconButton>
          </Grid> */}
        </Grid>
      );
    } else {
      return null;
    }
  };

  const skipPanel = (currentHelpMeta, index, tempHelpMeta) => {
    if (helpMeta.length > 0) {
      if (currentHelpMeta.h !== 2) {
        let temp = helpMeta;
        temp.map((obj) => {
          if (obj.i === currentHelpMeta.i) {
            obj.h = 2;
          }
        });
        setTe(te + 1);
        setHelpMeta(temp);
      } else {
        let temp = helpMeta;
        temp.map((obj) => {
          if (obj.i === currentHelpMeta.i) {
            obj.h = meta[index].position.h;
          }
        });
        setTe(te + 1);
        setHelpMeta(temp);
      }
    }
  };
  return helpMeta.length ? (
    <ReactGridLayout
      className="layout"
      cols={10}
      rowHeight={20}
      margin={props.parent === "root" ? [20, 10] : [10, 10]}
      // style={{ overflow: 'scroll' }}
      width={width}
      key={props.parent + "reactGridLayout" + te}
      layout={helpMeta}
      isDraggable={
        helpMeta.filter((currentHelpMeta) => currentHelpMeta.i === active)
          .length
          ? false
          : true
      }
      // onDrag={e => {
      //   setHelpMeta(e)
      //   refresher(e)
      // }}
      // onResize={e => {
      //   setHelpMeta(e)
      //   refresher(e)
      // }}
    >
      {helpMeta.length
        ? helpMeta.map((currentHelpMeta, index) => {
            return (
              <div
                key={currentHelpMeta.i}
                id={currentHelpMeta.i + "_panel"}
                // onMouseOver={e =>
                //   props.parent !== 'root' ? props.dragStop(props.parent) : null
                // }
                // onMouseOut={e =>
                //   props.parent !== 'root' ? props.dragStart(props.parent) : null
                // }
              >
                <Paper
                  elevation={meta[index].type === "panel" ? 2 : 0}
                  id={currentHelpMeta.i + "_panel_paper"}
                  className={clsx(
                    theme[meta[index].type],
                    meta[index].type === "panel" && active === currentHelpMeta.i
                      ? theme.activePanel
                      : null
                  )}
                  style={{
                    overflow: currentHelpMeta.h === 2 ? "hidden" : "overlay",
                  }}
                >
                  <Grid container item xs={12} id={currentHelpMeta.i + "_body"}>
                    <Grid
                      container
                      item
                      id={currentHelpMeta.i + "_header"}
                      xs={12}
                      className={
                        meta[index].type === "panel"
                          ? props.parent === "root"
                            ? theme.panelHeaderRoot
                            : theme.panelHeader
                          : theme.panelNull
                      }
                    >
                      <Grid item xs={10} id={currentHelpMeta.i + "_hLabel"}>
                        <Typography
                          style={{
                            textAlign: "center",
                            margin: 7,
                            fontWeight: props.parent === "root" ? "bold" : "",
                          }}
                        >
                          {meta[index].label}
                        </Typography>
                      </Grid>
                      {meta[index].type === "panel" ? (
                        <Grid
                          item
                          xs={2}
                          style={{ textAlign: "center" }}
                          id={currentHelpMeta.i + "_hAction"}
                        >
                          {currentHelpMeta.i !== "root" ? (
                            <IconButton
                              style={{ padding: 8, float: "right" }}
                              onClick={(e) => skipPanel(currentHelpMeta, index)}
                            >
                              <ExpandMoreIcon
                                fontSize="small"
                                style={{
                                  transform: `rotate(${
                                    currentHelpMeta.h === 2 ? 180 : 0
                                  }deg)`,
                                }}
                              />
                            </IconButton>
                          ) : null}
                          {/* <IconButton
                            style={{ padding: 8, float: 'right' }}
                            onClick={e => addComponent(currentHelpMeta.i, 'QQ')}
                          >
                            <IconSettings fontSize='small' />
                          </IconButton> */}
                        </Grid>
                      ) : null}
                    </Grid>

                    <Grid key={currentHelpMeta.i + "grid"} item xs={12}>
                      {routerPanel(currentHelpMeta, index)}
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            );
          })
        : null}
    </ReactGridLayout>
  ) : null;
};

export default function FF_Main(props) {
  const config = {
    childrenField: "children",
    keyField: "id",
  };

  const treeUtils = new TreeNodeUtils(config);

  // -------------------------------------------------------------------------------
  //
  //                                 Снэк Бар
  //
  // -------------------------------------------------------------------------------

  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (variant, msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: "top", horizontal: "right" },
      }
    );
  };

  // const classes = useStyles()
  const theme = useTreeItemStyles();
  const strAuthorization = "Bearer " + cookie.load("access_token");
  const isLogin = props.isLogin;
  const [width, height] = useWindowSize();
  const [update1, setUpdate] = useState({});
  const [te, setTe] = useState(0);
  const [meta, setMeta] = useState(props.meta);
  const [open, setOpen] = useState(false);
  const [toManage, setToManage] = useState(null);
  const [backLog, setBackLog] = useState([]);
  const [actial, setActial] = useState(false);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  // function sortByLabel(arr) {
  //   arr.sort((a, b) => a.label > b.label ? 1 : -1);
  // }

  // useEffect(() => {
  //   console.log(props.meta, sortByLabel(props.meta))
  //   setMeta(props.meta)
  // }, [])

  useEffect(() => {
    if ("obj" in update1) {
      meta.map((tempMeta) => {
        let idEssence = tempMeta.essence;
        let updateAllParam = update1.obj.filter(
          (obj) => obj.essence === idEssence
        );
        if (
          updateAllParam &&
          updateAllParam.length &&
          idEssence in tempMeta["full_parameters"]
        ) {
          let updateCol = ["streamParam", "simpleParam"];

          updateAllParam.forEach((updateParams) => {
            updateCol.forEach((nameCol) => {
              let updateParam = updateParams[nameCol];
              Object.keys(updateParams["parameters_meta"]).map((key) => {
                if (key in tempMeta["full_parameters"][idEssence]) {
                  if (updateParams["parameters_meta"][key]) {
                    tempMeta["full_parameters"][idEssence][key].options =
                      updateParams["parameters_meta"][key];
                  }
                  // console.log(Object.values(updateAllParam[0]['parameters_meta'][key])[0])
                  // tempMeta['full_parameters'][idEssence][
                  //   key
                  // ].options = updateAllParam[0]?.parameters_meta[key]
                  //   ? Object.values(updateAllParam[0]?.parameters_meta[key])[0]
                  //   : null
                }
              });

              Object.keys(updateParam).map((key) => {
                if (key in tempMeta["full_parameters"][idEssence]) {
                  console.log(updateParam, key, updateParam[key]);
                  if (nameCol === "streamParam") {
                    tempMeta["full_parameters"][idEssence][key].value = String(
                      updateParam[key].value
                    );
                    tempMeta["full_parameters"][idEssence][key].tooltip =
                      "last_update" in updateParam[key]
                        ? new Date(
                            parseInt(updateParam[key].last_update + "000")
                          ).toLocaleString()
                        : null;
                  } else {
                    tempMeta["full_parameters"][idEssence][key].value =
                      updateParam[key];
                  }
                }
              });
            });
          });

          // updateCol.map(nameCol => {
          //   // let updateParam = updateAllParam[0][nameCol]
          //   // console.log(updateAllParam)
          //   // Object.keys(updateAllParam[0]['parameters_meta']).map(key => {
          //   //   if (key in tempMeta['full_parameters'][idEssence]) {
          //   //     if (updateAllParam[0]['parameters_meta'][key]) {
          //   //       tempMeta['full_parameters'][idEssence][key].options =
          //   //         updateAllParam[0]['parameters_meta'][key]
          //   //     }
          //   //     // console.log(Object.values(updateAllParam[0]['parameters_meta'][key])[0])
          //   //     // tempMeta['full_parameters'][idEssence][
          //   //     //   key
          //   //     // ].options = updateAllParam[0]?.parameters_meta[key]
          //   //     //   ? Object.values(updateAllParam[0]?.parameters_meta[key])[0]
          //   //     //   : null
          //   //   }
          //   // })
          //   // Object.keys(updateParam).map(key => {
          //   //   if (key in tempMeta['full_parameters'][idEssence]) {
          //   //     console.log(updateParam, key, updateParam[key])
          //   //     if (nameCol === 'streamParam') {
          //   //       tempMeta['full_parameters'][idEssence][key].value = String(
          //   //         updateParam[key].value
          //   //       )
          //   //       tempMeta['full_parameters'][idEssence][key].tooltip =
          //   //         'last_update' in updateParam[key]
          //   //           ? new Date(
          //   //               parseInt(updateParam[key].last_update + '000')
          //   //             ).toLocaleString()
          //   //           : null
          //   //     } else {
          //   //       tempMeta['full_parameters'][idEssence][key].value =
          //   //         updateParam[key]
          //   //     }
          //   //   }
          //   // })
          // })
        }
      });

      update1.obj.map((updateAllParam) => {
        let timeStream = updateAllParam["timeStreamParam"];
        timeStream.map((plot) => {
          let tempMeta = meta.filter(
            (obj) => obj.essence === updateAllParam.essence
          );
          if (tempMeta.length) {
            let nodeChart = treeUtils.getNodeByKey(tempMeta, plot.plot);
            if (nodeChart) {
              if (plot?.log) {
                nodeChart.log = plot.log;
              } else {
                nodeChart.log = null;
              }
              nodeChart.childrenSeries.map((paramChart) => {
                paramChart.data = plot.parameters[paramChart.parameter];
                if (paramChart.data) {
                  paramChart.data.map((values) => {
                    if (Array.isArray(values)) {
                      if ((values[0] + "").length < 13) {
                        values[0] = Number(values[0] + "000");
                      }
                      values[1] = Number(values[1]);
                    }
                  });
                }
              });
              // nodeChart['updateCom'] = uuidv4()
            }
          }
        });
      });
      meta.map((node) => refreshConnection(node, true));
      setActial(true);
    }
  }, [update1]);

  // Обновление не графических сведений каждые 10 сек
  useInterval(() => {
    let hotUpdate = [];
    meta.map((tempMeta) => {
      if ("full_parameters" in tempMeta && "connections" in tempMeta) {
        hotUpdate = [
          ...hotUpdate,
          {
            essence: tempMeta.essence,
            simpleParam:
              tempMeta["connections"][tempMeta.essence]["simpleParam"],
            timeStreamParam: [],
            streamParam:
              tempMeta["connections"][tempMeta.essence]["streamParam"],
          },
        ];
      }
    });

    ApiConnect({
      name: "getDataPost",
      url: urlConfiguration + "/api/control/hot_values/",
      setterEssence: setUpdate,
      objToGet: hotUpdate,
      isLogin: isLogin,
      setterLoading: setActial,
    });
  }, 10000);

  useEffect(() => {
    updateTimePlot("root", "root");
  }, []);

  // Обновление данных для графиков каждые 60 сек
  useInterval(() => {
    // updateTimePlot()
    // let hotUpdate = []
    // meta.map(tempMeta => {
    //   if ('full_parameters' in tempMeta && 'connections' in tempMeta) {
    //     let timeStream =
    //       tempMeta['connections'][tempMeta.essence]['timeStreamParam']
    //     timeStream.map(plot => {
    //       let metaPlot = treeUtils.getNodeByKey(meta, plot.plot)
    //       metaPlot.updateCom = uuidv4() + '_Loader'
    //       let nowDate = new Date(new Date().toDateString())
    //       if (
    //         metaPlot &&
    //         'value' in metaPlot &&
    //         'timer' in metaPlot['value'] &&
    //         metaPlot['value']['timer']
    //       ) {
    //         if (metaPlot['value']['timer'] === '1h') {
    //           plot['time_start'] = Number(
    //             String(new Date().setHours(new Date().getHours() - 1)).slice(
    //               0,
    //               10
    //             )
    //           )
    //           plot['time_stop'] = Number(
    //             String(new Date().valueOf()).slice(0, 10)
    //           )
    //         } else if (metaPlot['value']['timer'] === '1d') {
    //           plot['time_start'] = Number(
    //             String(new Date().setDate(new Date().getDate() - 1)).slice(0, 10)
    //           )
    //           plot['time_stop'] = Number(
    //             String(new Date().valueOf()).slice(
    //               0,
    //               10
    //             )
    //           )
    //         } else if (metaPlot['value']['timer'] === 'now') {
    //           plot['time_start'] = Number(
    //             String(new Date(new Date().toDateString()).valueOf()).slice(
    //               0,
    //               10
    //             )
    //           )
    //           plot['time_stop'] = Number(
    //             String(new Date().valueOf()).slice(0, 10)
    //           )
    //         }
    //       } else {
    //         plot['time_start'] = Number(
    //           String(new Date(new Date().toDateString()).valueOf()).slice(0, 10)
    //         )
    //         plot['time_stop'] = Number(
    //           String(new Date().valueOf()).slice(0, 10)
    //         )
    //       }
    //     })
    //     hotUpdate = [
    //       ...hotUpdate,
    //       {
    //         essence: tempMeta.essence,
    //         simpleParam:
    //           tempMeta['connections'][tempMeta.essence]['simpleParam'],
    //         timeStreamParam: timeStream,
    //         streamParam:
    //           tempMeta['connections'][tempMeta.essence]['streamParam']
    //       }
    //     ]
    //   }
    // })
    // ApiConnect({
    //   name: 'getDataPost',
    //   url: urlConfiguration + '/api/control/hot_values/',
    //   setterEssence: setUpdate,
    //   objToGet: hotUpdate,
    //   isLogin: isLogin
    // })
  }, 60000);

  const getNodeParentId = (brach, id) => {
    if ("children" in brach && brach["children"].length) {
      if (brach["children"][0].id === id) return brach.id;
      else return getNodeParentId(brach["children"][0], id);
    } else {
      return null;
    }
  };

  const refreshConnection = (branch, is_start, flag) => {
    if ("children" in branch) {
      branch.children.map((obj) => {
        refreshConnection(obj, is_start, flag);
      });
    } else if (branch.type !== "ffChart") {
      let temp = meta.filter((obj) => obj.essence === branch.essence);
      if (
        temp &&
        temp.length &&
        "full_parameters" in temp[0] &&
        branch.parameter in temp[0].full_parameters[branch.essence]
      ) {
        let param = temp[0].full_parameters[branch.essence][branch.parameter];
        let backManage = backLog.filter((obj) => obj.id === branch.id);
        if (
          backManage &&
          backManage.length &&
          String(backManage[0].value) !== String(param.value)
        ) {
          branch["updateCom"] = uuidv4() + "_back";
          branch["value"] = backManage[0].value;
        } else if (
          backManage &&
          backManage.length &&
          String(backManage[0].value) === String(param.value)
        ) {
          branch["updateCom"] = uuidv4();
          branch["value"] = param.value;
          setBackLog(backLog.filter((obj) => obj.id !== branch.id));
        }
        // let backManage = backLog.filter(
        //   obj =>
        //     obj.essence === branch.essence && obj.parameter === branch.parameter
        // )

        // if (backManage && backManage.length) {
        //   backManage = backManage[0]
        //   // console.log(backLog, backManage, param, String(param.value), String(backManage.value))

        //   if (String(param.value) !== String(backManage.value)) {
        //     branch['updateCom'] = uuidv4() + '_back'
        //     branch['value'] = backManage.value
        //   } else {
        //     branch['value'] = param.value
        //     branch['updateCom'] = uuidv4()
        //     setBackLog(
        //       backLog.filter(
        //         obj =>
        //           !(
        //             obj.essence === branch.essence &&
        //             obj.parameter === branch.parameter
        //           )
        //       )
        //     )
        //   }
        // } else if (flag) {
        //   if (
        //     flag.essence === branch.essence &&
        //     flag.parameter === branch.parameter &&
        //     String(branch.value) !== String(backManage.value)
        //   ) {
        //     branch['updateCom'] = uuidv4() + '_back'
        //     branch['value'] = backManage.value
        //   }
        // }
        else {
          branch["updateCom"] = uuidv4();
          branch["value"] = param.value;
        }
        branch["tooltip"] = "tooltip" in param ? param.tooltip : null;
        branch["label"] = param.label;
        branch["typeData"] = param.type;
        branch["source"] = param.source;
        if (param?.options) {
          if (
            branch.type === "ffSelect" &&
            param?.options["p-format-data-value-list"]
          ) {
            // console.log(param.options, branch.options)
            branch["options"] = param.options["p-format-data-value-list"];
          } else if (
            (branch.type === "ffRangeOne" || branch.type === "ffRangeTwo") &&
            param?.options["p-format-data-value-range"]
          ) {
            branch["options"] = param.options["p-format-data-value-range"];
          } else if (
            (branch.type !== "ffToogle" || branch.type !== "ffSwitch") &&
            param?.options["bool-value"]
          ) {
            branch["options"] = param.options["bool-value"];
          }
          // console.log(param)
        }
        // console.log(branch)
      }
    }
  };

  const changeValue = (idEssence, idParam, value, id, element) => {
    // console.log(node)
    // node.updateCom = uuidv4() + '_back'
    let temp = {
      essence: idEssence,
      id: id,
      parameter: idParam,
      value: value,
    };
    // console.log(idEssence, idParam, value, id, element)
    setToManage(temp);
    if (
      element &&
      typeof element === "string" &&
      element.indexOf("range") > -1
    ) {
      toGo(temp);
    } else {
      setOpen(true);
    }
  };

  const toGo = (tempToManage) => {
    setOpen(false);
    // console.log(toManage)
    let temp = toManage;
    if (tempToManage) {
      temp = tempToManage;
    }
    // console.log(temp)
    if (temp) {
      let result = backLog.filter(
        (obj) =>
          !(obj.essence === temp.essence && obj.parameter === temp.parameter)
      );
      let node = treeUtils.getNodeByKey(meta, temp.id);
      // console.log([...result, temp], node, temp)

      node.updateCom = uuidv4() + "_back";
      setBackLog([...result, temp]);
      // setBackLog([
      //   ...backLog,
      //   {
      //     essence: toManage[0].id,
      //     parameter: toManage[0].parameters[0].id,
      //     value: toManage[0].parameters[0].value
      //   }
      // ])

      ApiConnect({
        name: "updateDate",
        url: `${urlRest}/management/manual/panels_v2/`,
        objToUpdate: [
          {
            id: temp.essence,
            parameters: [{ id: temp.parameter, value: temp.value }],
          },
        ],
        isLogin: isLogin,
        clickHandle: handleClickVariant,
      });
      // setToManage(null)
    }
  };

  const updateTimePlot = (id, idEssence) => {
    let hotUpdate = [];
    // console.log(id, idEssence, meta)
    meta.map((tempMeta) => {
      if (
        "full_parameters" in tempMeta &&
        "connections" in tempMeta &&
        (idEssence === "root" ||
          (idEssence in tempMeta["connections"] && idEssence !== "root"))
      ) {
        let timeStream =
          idEssence === "root"
            ? tempMeta["connections"][tempMeta.essence]["timeStreamParam"]
            : tempMeta["connections"][idEssence]["timeStreamParam"];
        // tempMeta['connections'][tempMeta.essence]['timeStreamParam']

        if (id !== "root") {
          timeStream = timeStream.filter((obj) => obj.plot === id);
        }
        // timeStream.map(plot => {
        //   let metaPlot = treeUtils.getNodeByKey(meta, plot.plot)
        //   console.log(metaPlot)
        //   metaPlot.updateCom = uuidv4() + '_Loader'
        //   let nowDate = new Date(new Date().toDateString())
        //   if (
        //     metaPlot &&
        //     'value' in metaPlot &&
        //     'timer' in metaPlot['value'] &&
        //     metaPlot['value']['timer']
        //   ) {
        //     if (metaPlot['value']['timer'] === '1h') {
        //       plot['time_start'] = Number(
        //         String(new Date().setHours(new Date().getHours() - 1)).slice(
        //           0,
        //           10
        //         )
        //       )
        //       plot['time_stop'] = Number(
        //         String(new Date().valueOf()).slice(0, 10)
        //       )
        //     } else if (metaPlot['value']['timer'] === '1d') {
        //       plot['time_start'] = Number(
        //         String(nowDate.setDate(nowDate.getDate() - 1)).slice(0, 10)
        //       )
        //       plot['time_stop'] = Number(
        //         String(nowDate.setDate(nowDate.getDate() + 1)).slice(0, 10)
        //       )
        //     } else if (metaPlot['value']['timer'] === 'now') {
        //       plot['time_start'] = Number(
        //         String(nowDate.valueOf()).slice(0, 10)
        //       )
        //       plot['time_stop'] = Number(
        //         String(new Date().valueOf()).slice(0, 10)
        //       )
        //     }
        //   } else {
        //     plot['time_start'] = Number(String(nowDate.valueOf()).slice(0, 10))
        //     plot['time_stop'] = Number(
        //       String(new Date().valueOf()).slice(0, 10)
        //     )
        //   }
        // })

        timeStream.map((plot) => {
          let metaPlot = treeUtils.getNodeByKey([tempMeta], plot.plot);
          // console.log(metaPlot, plot, tempMeta)
          console.log(tempMeta, timeStream, plot.plot, metaPlot);
          if (metaPlot) {
            if (id === "root") {
              metaPlot.updateCom = uuidv4() + "_LoaderRoot";
            } else {
              metaPlot.updateCom = uuidv4() + "_Loader";
            }
            let nowDate = new Date(new Date().toDateString());
            if (
              metaPlot &&
              "value" in metaPlot &&
              "timer" in metaPlot["value"] &&
              metaPlot["value"]["timer"]
            ) {
              // console.log(metaPlot.value, metaPlot.value.timer)
              if (metaPlot["value"]["timer"] === "1h") {
                plot["time_start"] = Number(
                  String(new Date().setHours(new Date().getHours() - 1)).slice(
                    0,
                    10
                  )
                );
                plot["time_stop"] = Number(
                  String(new Date().valueOf()).slice(0, 10)
                );
                // console.log(plot, String(new Date().valueOf()))
              } else if (metaPlot["value"]["timer"] === "1d") {
                plot["time_start"] = Number(
                  String(new Date().setDate(new Date().getDate() - 1)).slice(
                    0,
                    10
                  )
                );
                plot["time_stop"] = Number(
                  String(new Date().valueOf()).slice(0, 10)
                );
              } else if (metaPlot["value"]["timer"] === "now") {
                plot["time_start"] = Number(
                  String(new Date(new Date().toDateString()).valueOf()).slice(
                    0,
                    10
                  )
                );
                plot["time_stop"] = Number(
                  String(new Date().valueOf()).slice(0, 10)
                );
              }
            } else {
              plot["time_start"] = Number(
                String(new Date().setHours(new Date().getHours() - 1)).slice(
                  0,
                  10
                )
              );
              plot["time_stop"] = Number(
                String(new Date().valueOf()).slice(0, 10)
              );
            }
          }
        });

        // console.log(timeStream)
        hotUpdate = [
          ...hotUpdate,
          {
            essence: tempMeta.essence,
            simpleParam:
              tempMeta["connections"][tempMeta.essence]["simpleParam"],
            timeStreamParam: timeStream,
            streamParam:
              tempMeta["connections"][tempMeta.essence]["streamParam"],
          },
        ];
      }
    });
    console.log(hotUpdate);
    ApiConnect({
      name: "getDataPost",
      url: urlConfiguration + "/api/control/hot_values/",
      setterEssence: setUpdate,
      objToGet: hotUpdate,
      isLogin: isLogin,
      setterLoading: null,
      // () => {
      //   setTimeout(() => {
      //     setActial(true)
      //   }, 0)
      // }
    });
  };

  return (
    // <TableContainer className={theme.flexEnd}>
    //   <Grid item xs={12} style={{ padding: 10 }}>
    // <Paper elevation={3} className={theme.flexEnd}>
    //   <Context.Provider value={{  }}>

    <React.Fragment>
      <Backdrop className={theme.loader} open={!actial}>
        <Grid>
          <Grid style={{ textAlign: "center" }}>
            <Loader />
          </Grid>

          <Grid>
            <Typography variant="h4">
              {"Получаем актуальные сведения"}
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
      <Dialog
        open={open}
        onClose={(e) => {
          setOpen(false);
          setToManage(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Подтвердите действие"}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id='alert-dialog-description'>
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setOpen(false);
              setToManage(null);
            }}
            color="error"
          >
            Отмена
          </Button>
          <Button onClick={(e) => toGo(null)} color="primary" autoFocus>
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
      <Context.Provider value={{ changeValue, updateTimePlot }}>
        <FF_Exp
          key={te + props.isOpen}
          // onDoubleClick={e => {
          //   console.log(e)
          // }}
          actial={actial}
          meta={meta}
          width={(width * (props?.openWidth ? props?.openWidth : 12)) / 12 - 20}
          parent={"root"}
          // dragStop={idParent => null}
          // dragStart={idParent => null}
          active="root"
          isOpen={props.isOpen}
          style={{ padding: 20 }}
          backLog={backLog}
        />
      </Context.Provider>
    </React.Fragment>
    //   </Context.Provider>
    // </Paper>
    //   </Grid>
    // </TableContainer>
  );
}
