import React, { useState, useEffect } from 'react'

import Context from '../../../context'

import { Typography, Grid, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'

/*
 **-------------------- My Components ---------------------------
 */

import FF_EssenceParameterRouter from './FFEssenceCardParameterRouter'
import FFSelect from '../../../libft/FFSelect'
import FFContainer from '../../../libft/FFContainer'
import FFLoader from '../../../libft/FFLoader'
import FFLoaderComponent from '../../../libft/FFLoaderComponent'

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto',
    height: '100%',
    margin: 0
  },
  defaultText: {
    color: theme.palette.grey[200]
  },
  margin: {
    margin: theme.spacing(1)
  },

}))
/*
 **---------------------------Описание-----------------------------

	Указанный компонент предназначен для массового ввода значений
	одного параметра для разных (множественных) сущностей
	Используется основа FF_EssenceParameterRouter для подкидывания
	соотвествующих компонентов
 */

export default function FF_MassiveParameters (props) {
  const classes = useStyles()
  const defTheme = useTheme()

  console.log(props)
  const [state, setState] = useState(props.activeParameter)
  const [essence, setEssence] = useState([])

  useEffect(() => {
    setEssence(props.essenceParameter)
  }, [props.essenceParameter
  ])

  const getBack = (data, id) => {
    let temp = essence
    let a = temp.filter(obj => obj.id === id)
    if (a.length > 0) {
      a[0].parameter = data
    }
    props.getBackParameter(essence)
    setEssence(essence)
  }

  const changeValue = (value, id, name, elem) => {
    if (name === 'state') {
      setState(value)
      props.doActiveParameter(value)
    }
  }
  return (
    <Context.Provider value={{ changeValue }}>
      <FFContainer
        id={'all'}
        components={[
          {
            width: 12,
            elevation: 0,
            shrink: 12,
            component: (
              <React.Fragment>
                <FFSelect
                  label={'Параметр'}
                  value={state}
                  name={'state'}
                  options={props.parameter}
                />
              </React.Fragment>
            )
          },
          {
            width: 12,
            elevation: 2,
            style:
              state && props.listChecked.length && props.essenceMassive
                ? { justifyContent: 'stretch', alignItems: 'stretch', padding: defTheme.spacing(1) }
                : { justifyContent: 'center', alignItems: 'center' },
            component: (
              <FFLoaderComponent
                key={'FFLoaderComponent'}
                loading={state && props.listChecked.length}
                isLoader={
                  <Typography
                    variant='h1'
                    component='h2'
                    className={classes.defaultText}
                  >
                    {'Выберите сущность и параметр'}
                  </Typography>
                }
                component={
                  <FFLoaderComponent
                    key={'FFLoaderComponent'}
                    loading={props.essenceMassive}
                    isLoader={<FFLoader />}
                    component={
                      essence.map(obj => {
                      return (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <Divider
                              style={{ width: '99%', height: 2, margin: 10 }}
                            />
                            <Typography
                              variant='h6'
                              style={{ marginBottom: 20 }}
                            >
                              {obj.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {obj.parameter ? (
                              <FF_EssenceParameterRouter
                                parameter={obj.parameter}
                                parameterId={obj.id}
                                getBack={e => getBack(e, obj.id)}
                              />
                            ) : (
                              <Typography>
                                {'Этого параметра в выбранной сущности нет'}
                              </Typography>
                            )}
                          </Grid>
                        </React.Fragment>
                      )
                    })
                  }
                  />
                }
              />
            )
          }
        ]}
      />

      {/*
      <Grid container item xs={12} direction={'column'} style={{flexShrink: 12}}>
        <FFSelect
          label={'Параметр'}
          value={state}
          name={'state'}
          options={props.parameter}
        />
      </Grid>
      {props.essenceMassive ? <Grid item container xs={12} style={{overflow: 'auto', padding: 8}}>
        {essence.map(obj => {
          return (
            <React.Fragment>
              <Grid item xs={12}>
                <Divider style={{ width: '99%', height: 2, margin: 10 }} />
                <Typography variant='h6' style={{ marginBottom: 20 }}>
                  {obj.label}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {obj.parameter ? (
                  <FF_EssenceParameterRouter
                    parameter={obj.parameter}
                    parameterId={obj.id}
                    getBack={e => getBack(e, obj.id)}
                  />
                ) : (
                  <Typography>
                    {'Этого параметра в выбранной сущности нет'}
                  </Typography>
                )}
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid> : <FFLoader/>} */}
    </Context.Provider>
  )
}
