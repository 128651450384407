import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import moment from "moment";
import { isNil } from "ramda";

export const StatusAlert = ({ syncStatus }) => {
    let running = "Выполняется синхронизация"
    let notRunning = "Синхронизация сейчас не выполняется"

    return (
        !isNil(syncStatus.last_success) && !isNil(syncStatus.last_error) ?
            <Alert severity="success" sx={{ whiteSpace: 'pre-line' }}>
                <AlertTitle>Статус</AlertTitle>
                <div>{`Последняя синхронизация: ${moment(syncStatus.last_success).format('YYYY-MM-DD HH:mm')}`}<br />
                {`Последняя неуспешная попытка синхронизации: ${moment(syncStatus.last_error).format('YYYY-MM-DD HH:mm')}`}<br />
                {`${syncStatus.is_running ? running : notRunning }`}
                </div>
            </Alert>
        : !isNil(syncStatus.last_success) ?
            <Alert severity="success" sx={{ whiteSpace: 'pre-line' }}>
                <AlertTitle>Статус</AlertTitle>
                <div>{`Последняя синхронизация: ${moment(syncStatus.last_success).format('YYYY-MM-DD HH:mm')}`}<br />
                {`${syncStatus.is_running ? running : notRunning }`}
                </div>
            </Alert> 
        : !isNil(syncStatus.last_error) ?
            <Alert severity="success" sx={{ whiteSpace: 'pre-line' }}>
                <AlertTitle>Статус</AlertTitle>
                <div>{`Последняя неуспешная попытка синхронизации: ${moment(syncStatus.last_error).format('YYYY-MM-DD HH:mm')}`}<br />
                {`${syncStatus.is_running ? running : notRunning }`}
                </div>
            </Alert> 
        :   <Alert severity="success">
                <AlertTitle>Статус</AlertTitle>
                {`${syncStatus.is_running ? running : notRunning }`}
            </Alert> 
    );
}