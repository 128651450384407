import React from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { setConsumer, getScada } from "../slice";

export const SelectConsumers = () => {
  const { consumer, consumers, statusConsumers } = useSelector(getScada);
  const dispatch = useDispatch();

  const handleChange = (_, newValue) => dispatch(setConsumer(newValue));

  return (
    <Autocomplete
      id="consumer"
      loading={statusConsumers === "pending"}
      value={consumer}
      onChange={handleChange}
      options={consumers}
      getOptionLabel={(option) => option.label}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Потребитель"
          variant="outlined"
        />
      )}
    />
  );
};
