import React from 'react'
// import ReactDOM from 'react-dom';
// import { makeStyles } from "@material-ui/core/styles";

import Grid from '@material-ui/core/Grid'


import FFListToggle from './FFListToggle'
import FFSelect from './FFSelect'
import FFTextFields from './FFTextFields'
import FFJsonTable from './FFJsonTable'
// import Graph from 'react-json-graph'
// import { SchemaTypes } from 'object-editor-react'
// import { ArrayEditor, ObjectEditor } from 'object-editor-react'

export default function FFRouterComponents (props) {
  // const schema = {
  //   name: SchemaTypes.any()

  //   // bar: {
  //   //     baz: SchemaTypes.arrayOf({
  //   //         nested: SchemaTypes.string(),
  //   //     }),
  //   // },
  // }

  // const getSchema = (obj) => {
  //   let temp = {}

  // }

  if (props.meta_cell.type === 'ffTextFields') {
    return (
      <FFTextFields
        key={'FFTextFields_' + props.meta_cell.name_attr + props.parameter.id}
        value={props.parameter[props.meta_cell.name_attr]}
        is_filter={props.is_filter}
        name={props.meta_cell.name_attr}
        label={props.meta_cell.label_attr}
        parameter={props.parameter}
        dis={props.meta_cell.dis}
      />
    )
  } else if (props.meta_cell.type === 'ffListTextFields') {
    return props.meta_cell.name_attr.map((name_one_attr, index) => {
      return (
        <React.Fragment
          key={'FFTextFields_' + name_one_attr + props.parameter.id}
        >
          <FFTextFields
            value={props.parameter[name_one_attr]}
            name={name_one_attr}
            is_filter={props.is_filter}
            label={
              props.meta_cell.label_attr
                ? props.meta_cell.label_attr[index]
                : null
            }
            parameter={props.parameter}
            dis={props.meta_cell.dis}
          />
          <br />
        </React.Fragment>
      )
    })
  } else if (props.meta_cell.type === 'ffListSelect') {
    return props.parameter[props.meta_cell.name_attr].map((classifier, index) =>
      props.basic_classifier.length ? (
        <FFSelect
          is_filter={props.is_filter}
          cls={props.basic_classifier}
          parent_cls={classifier}
          name_attr={props.meta_cell.name_attr}
          key={'FFSelect_' + props.parameter.id + '_' + index}
          parameter={props.parameter}
          value_cls={classifier.values}
        />
      ) : (
        <span>No </span>
      )
    )
  } else if (props.meta_cell.type === 'ffSelect') {
    const classifier =
      props.parameter[props.meta_cell.name_attr][props.meta_cell.classifier]
    return props.basic_classifier.length ? (
      <FFSelect
        is_filter={props.is_filter}
        cls={props.basic_classifier}
        parent_cls={props.meta_cell.classifier}
        name_attr={props.meta_cell.name_attr}
        key={props.parameter.id + '_' + classifier.id + '_ffselect'}
        parameter={props.parameter}
        value_cls={classifier.values}
      />
    ) : (
      <span>No </span>
    )
  }
  // else if (props.meta_cell.type === 'ffDict') {
  //   return <JSONViewer json={props.parameter[props.meta_cell.name_attr]} />
  // }
  else if (props.meta_cell.type === 'ffListDict') {
    // console.log(props.parameter)
    return (
      <Grid container item xs={12}>
        {props.meta_cell.name_attr.map(name => {
          return <FFJsonTable obj={props.parameter} name_attr={name} />
        })}
      </Grid>
    )
  }
  // Component ListSwitch - Switch - CheckBox
  else if (props.meta_cell.type === 'ffListToggle') {
    return (
      <FFListToggle
        is_line={'is_line' in props.meta_cell ? props.meta_cell.is_line : null}
        is_filter={props.is_filter ? props.is_filter : 'false'}
        key={'FFListToggle_' + props.parameter.id}
        parameter={props.parameter}
        type_children={props.meta_cell.type_children}
        name_attr={props.meta_cell.name_attr}
        label_attr={props.meta_cell.label_attr}
      />
    )
  }
  return <span>FFRouterComponents not parse component</span>
}
