import React, { useEffect, useState, useContext } from 'react'

import {
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Chip
} from '@material-ui/core/'
import { makeStyles, withStyles } from '@material-ui/core/styles'

/*
 **-------------------- My Components ---------------------------
 */

import FFCardScenario from './FFCardScenario'
import FFTextField from '../../../libft/FFTextField'
import FFContainer from '../../../libft/FFContainer'
import FFTreeEssence from '../../../libft/FFTreeEssence'
import FFActionButton from '../../../libft/FFActionButton'
import FFIcons from '../../../libft/FFIcons'
import FFSelect from '../../../libft/FFSelect'
import FFLoader from '../../../libft/FFLoader'
import FFSwitch from '../../../libft/FFSwitch'
import FFLoaderComponent from '../../../libft/FFLoaderComponent'
import FFEditAlgorithmValue from './FFEditAlgorithmValue'
import FFEditAlgorithmFormula from './FFEditAlgorithmFormula'
import FFEditAlgorithmSchedule from './FFEditAlgorithmSchedule'

import Context from '../../../context'
import clsx from 'clsx'

const uuidv4 = require('uuid/v4')

const useStyles = makeStyles(theme => ({
  marginChip: {
    margin: theme.spacing(0.5)
  }
}))

export default function FF_EditBaseObject ({
  devices,
  devicesScenario,
  setActivePage,
  activePage,
  devicesScenarioAll,
  parameters,
  isTypeModel,
  activeEssence,
  ...props
}) {
  const { toGoSave } = useContext(Context)

  const [scenario, setScenario] = useState(props.scenario)
  const [activeDevice, setActiveDevice] = useState(null)
  console.log(props, isTypeModel)
  // Функция для изменения значений расписания устроства
  const changeValue = (value, id, name, element) => {
    devicesScenarioAll.forEach(obj => {
      if (obj.device === id) {
        obj[name] = value
        toGoSave(obj)
      }
    })
  }

  // Функция для прокидывания события что мы нажали на редактирование алгоритма
  const goEditAlgorithm = obj => {
    setActiveDevice(obj)
    setActivePage(`edit.${obj.algorithm}.${obj.id}`)
  }

  const routerEdirAlgorithm = () => {
    if (activePage === 'edit') {
      return (
        <FFLoaderComponent
          key={'FFLoaderComponent'}
          loading={devicesScenarioAll}
          isLoader={<FFLoader />}
          component={
            <Context.Provider value={{ changeValue, goEditAlgorithm }}>
              <FFDevicesTable
                devicesScenario={devicesScenarioAll}
                isTypeModel={isTypeModel}
                activeEssence={activeEssence}
              />
            </Context.Provider>
          }
        />
      )
    } else if (activePage.indexOf('edit.value') > -1) {
      return (
        <FFEditAlgorithmValue
          deviceScenario={{ ...activeDevice }}
          parameters={
            parameters.filter(obj => obj.id === activeDevice.device)[0]
              .parameter_values
          }
        />
      )
    } else if (activePage.indexOf('edit.formula') > -1) {
      return (
        <FFEditAlgorithmFormula
          deviceScenario={{ ...activeDevice }}
          devicesScenario={devicesScenarioAll}
          parameters={parameters}
        />
      )
    } else if (activePage.indexOf('edit.schedule') > -1) {
      return (
        <FFEditAlgorithmSchedule
          deviceScenario={{ ...activeDevice }}
          devicesScenario={devicesScenarioAll}
          parameters={parameters}
        />
      )
    }
  }

  return (
    <FFContainer
      is_row={false}
      id={'all'}
      components={[
        {
          width: 12,
          elevation: 2,
          shrink: 12,
          component: (
            <FFCardScenario
              activePage={activePage}
              scenario={scenario}
              setScenario={setScenario}
            />
          )
        },
        {
          width: 12,
          elevation: 2,
          isRow: true,
          component: routerEdirAlgorithm()
        }
      ]}
    />
  )
}

const FFDevicesTable = ({
  devicesScenario,
  isTypeModel,
  activeEssence,
  ...props
}) => {
  return (
    <Table stickyHeader size='small' aria-label='simple table'>
      <TableBody>
        {devicesScenario.map(deviceScenario => {
          if (isTypeModel === 1 && deviceScenario.device === activeEssence.id)
            return <FFDeviceRow deviceScenario={deviceScenario} />
          if (isTypeModel === 0 && deviceScenario.device !== activeEssence.id)
            return <FFDeviceRow deviceScenario={deviceScenario} />
        })}
      </TableBody>
    </Table>
  )
}

const FFDeviceRow = ({ deviceScenario, ...props }) => {
  const classes = useStyles()

  const { goEditAlgorithm } = useContext(Context)

  return (
    <TableRow>
      <TableCell align='center'>
        <FFSwitch
          value={deviceScenario.is_device_active}
          id={deviceScenario.device}
          name={'is_device_active'}
        />
      </TableCell>
      <TableCell align='center'>
        <FFTextField
          style={{ width: 200 }}
          value={deviceScenario.label}
          id={deviceScenario.device}
          name={'label'}
          label={'Устройство'}
          is_disabled={true}
        />
      </TableCell>
      <TableCell align='center'>
        <Chip
          label='Константа'
          size='small'
          className={classes.marginChip}
          color={deviceScenario.actual_value ? 'primary' : ''}
        />
        <Chip
          label='Формула'
          size='small'
          className={classes.marginChip}
          color={deviceScenario.formula ? 'primary' : ''}
        />
        <Chip
          label='Расписание'
          size='small'
          className={classes.marginChip}
          color={
            deviceScenario.schedule_value &&
            Object.keys(deviceScenario.schedule_value).length > 0
              ? 'primary'
              : ''
          }
        />
      </TableCell>
      <TableCell align='center'>
        <FFSelect
          style={{ width: 200 }}
          value={deviceScenario.algorithm}
          id={deviceScenario.device}
          name={'algorithm'}
          label={'Алгоритм расчета БЛ'}
          options={[
            { id: 'value', label: 'Константа' },
            { id: 'formula', label: 'Формула' },
            { id: 'schedule', label: 'Расписание' }
          ]}
        />
      </TableCell>
      <TableCell align='center'>
        <Button
          onClick={e => goEditAlgorithm(deviceScenario)}
          color='default'
          startIcon={<FFIcons type={'edit'} color={'default'} />}
        >
          {'Редактирование алгоритма'}
        </Button>
      </TableCell>
    </TableRow>
  )
}
