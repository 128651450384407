import React, { useState, useContext } from 'react'
import Button from '@material-ui/core/Button'
import { emphasize, withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import IconSettingsPower from '@material-ui/icons/SettingsPower'
import MetaMenu from '../menu.json'
import cookie from 'react-cookies'
import { useTheme } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Context from '../context'

import '../styles/menu.css'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'
import TreeNodeUtils from 'tree-node-utils'
import { appVersion } from '../ApiConnectNew.js'
import { IconButton, Popover, Avatar } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  toolbar: {
    minHeight: 48,
    alignItems: 'flex-start',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end'
  }
}))

const ButtonError = withStyles(theme => ({
  root: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.dark,
      borderColor: theme.palette.error.dark
    }
  }
}))(Button)

export default function ProminentAppBar () {
  const { isLogin } = useContext(Context)

  const doGoLogout = () => {
    // cookie.remove('userId', 'Ha', { path: '/' })
    cookie.remove('access_token', { path: '/' })
    isLogin(false)
  }

  const config = {
    childrenField: 'children',
    keyField: 'id'
  }
  const treeUtils = new TreeNodeUtils(config)

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar position='static' color='default'>
        <Toolbar className={classes.toolbar}>
          <Grid container item xs={12}>
            {/* <Grid item xs={4}>
              {console.log(
                treeUtils.filterNodes(MetaMenu, obj => obj.id === document.location.pathname)
              )}
              <Typography variant='h6' color='primary'>
                {
                  treeUtils.getNodeByKey(MetaMenu, document.location.pathname)
                    .label
                }
              </Typography>
            </Grid> */}
            <Grid container item xs={10} alignItems='center'>
              <Typography
                variant='h6'
                color='primary'
                style={{ marginRight: 40 }}
              >
                {treeUtils.getNodeByKey(MetaMenu, document.location.pathname)
                  ?.label
                  ? treeUtils.getNodeByKey(MetaMenu, document.location.pathname)
                      ?.label
                  : 'Сущности'}
              </Typography>
              <FF_ME />
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <ButtonError
                variant='outlined'
                style={{marginRight: 8 }}
                onClick={e => doGoLogout()}
              >
                {'Выход'}
              </ButtonError>
              <Info_appVersion />
            </Grid>

          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

const StyledBreadcrumb = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100]
  }
}))(Chip)

const UseTheme1 = makeStyles(theme => ({
  menu: {
    '--color-primary': theme.palette.primary.main
  },
  active: {
    color: theme.palette.primary.main
  }
}))

const Info_appVersion = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'info_appVersion' : undefined

  return (
    <React.Fragment>
      {/* <IconButton
        aria-describedby={id}
        color='primary'
        onClick={handleClick}
        aria-label='upload picture'
        component='span'
      >
        <InfoIcon />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Typography style={{ padding: 16 }} color={'primary'}>
          {`Version: ${appVersion}`}
        </Typography>
      </Popover> */}
      <Chip
        variant='outlined'
        // size=""
        avatar={<Avatar>V</Avatar>}
        label={`${appVersion}`}
        // clickable
        color='primary'
        deleteIcon={<InfoIcon />}
      />
    </React.Fragment>
  )
}

const FF_ME = () => {
  const theme = UseTheme1()
  const re = useTheme()
  const childrenMenu = node => {
    if (
      node.label !== 'Имба' ||
      (cookie.load('login') === 'a.mustaev' && node.label === 'Имба')
    ) {
      if (node.children.length) {
        return (
          <li className={node.children.length ? 'parent' : ''}>
            <a
              href={node.id.indexOf('none_') > -1 ? '' : node.id}
              className={
                node.id === document.location.pathname ? theme.active : {}
              }
            >
              {node.label}
            </a>

            <ul>
              {node.children.map(obj => {
                return childrenMenu(obj)
              })}
            </ul>
          </li>
        )
      } else {
        return (
          <li>
            <a
              href={node.id.indexOf('none_') > -1 ? '' : node.id}
              style={{
                color:
                  node.id === document.location.pathname
                    ? re.palette.primary.main
                    : ''
              }}
            >
              {node.label}
            </a>
          </li>
        )
      }
    }
  }

  return (
    <div id='mainmenu' className={theme.menu}>
      <ul>
        {MetaMenu.map(obj => {
          return (
            <li className={obj.children.length ? 'parent' : ''}>
              <StyledBreadcrumb
                component='a'
                href={obj.id.indexOf('none_') > -1 ? '' : obj.id}
                label={obj.label}
                style={{ height: 39, borderRadius: 0 }}
                icon={<IconSettingsPower fontSize='small' />}
              />
              <ul>
                {obj.children.map(child => {
                  return childrenMenu(child)
                })}
              </ul>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
