import React, { useState, useContext, useRef } from 'react'

import Context from '../context'

import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'

import FFTreeItem from './FFTreeItem'
import TreeView from '@material-ui/lab/TreeView'
import { makeStyles, withStyles, formatMs } from '@material-ui/core/styles'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import IconMoreVert from '@material-ui/icons/MoreVert'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import TreeNodeUtils from 'tree-node-utils'
import FFTextField from '../libft/FFTextField'

const useStyles = makeStyles(theme => ({
  grid: {
    maxHeight: 200
  },
  gridTree: {
    overflow: 'auto',
    overflowX: 'hidden',
    margin: theme.spacing(1)
  },
  divSearch: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flexGrow: 1
  },
  divTools: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch'
  }
}))

export default function FFSelectTree (props) {
  const classes = useStyles()

  // console.log(props, "selectTrue")
  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // ---------------------------- ---------------------------------------------------

  const { doChangeSelect } = useContext(Context)
  const [stateMeta, setStateMeta] = useState(props.stateMeta)
  const [te, setTe] = useState(0)
  const idCls = props.idCls
  const [value, setValue] = useState(props.selectValue)
  const myRef = useRef(null)
  const [search, setSearch] = useState('')

  const config = {
    childrenField: 'children',
    keyField: 'id'
  }

  const treeUtils = new TreeNodeUtils(config)

  const changeValue = (value, id, name, type) => {
    setSearch(value)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const [select, setSelect] = useState(
    props?.metaSelect ? props.metaSelect : 'label'
  )
  const options = [
    { key: 'name', label: 'Системное наименование' },
    { key: 'label', label: 'Наименование' }
  ]

  const onClickMenu = e => {
    let temp = e.target.textContent
    setSelect(options.filter(obj => obj.label === temp)[0].key)
    setAnchorEl(null)
  }

  // const selectValue = props.selectValue

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  const doCLickSelect = (e, id) => {
    let tempStateMeta = stateMeta
    if (e._targetInst.stateNode.id.indexOf('treeSelect') > -1) {
      tempStateMeta.open = true
      setTe(te + 1)
      setStateMeta(tempStateMeta)
    } else if ('active' in tempStateMeta && tempStateMeta['active'] !== -1) {
      tempStateMeta.open = false
      tempStateMeta.active = -1
      setTe(te + 1)
      setStateMeta(tempStateMeta)
    } else if (!('value' in e.target)) {
      tempStateMeta.open = false
      tempStateMeta.active = -1
      setTe(te + 1)
      setStateMeta(tempStateMeta)
    }
  }

  const doActiveItemTreeSelect = (parentId, obj) => {
    if (obj.children.length === 0) {
      stateMeta.active = obj.id
      stateMeta.label = obj.label
      setValue(obj.id)
      doChangeSelect(parentId, obj.id, obj)
    }
  }
  const handleClose = e => {
    setAnchorEl(null)
  }

  const doActiveDoubleItemTreeSelect = (parentId, obj) => {
    // if (obj.children.length === 0) {
    // stateMeta.active = obj.id
    // stateMeta.label = obj.label
    if (stateMeta.open) {
      let tempStateMeta = stateMeta
      tempStateMeta.open = false
      //   stateMeta.active = obj.id
      setStateMeta(tempStateMeta)
      setValue(obj.id)
      doChangeSelect(parentId, obj.id, obj)
      //   console.log(parentId, obj, tempStateMeta)
    }
    // }
  }
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  const recurTreeView = (obj, depth, index, idParent, value) => {
    let temp_sort_label =
      'children' in obj && obj.children.length > 0
        ? obj.children.map(obj => obj.label)
        : []
    temp_sort_label.sort()

    if (value === obj.id) {
      stateMeta.label = obj.label
    }

    return (
      <FFTreeItem
        style={{ margin: 4, overflow: 'overlay' }}
        nodeId={obj.id}
        onDoubleClick={e => doActiveDoubleItemTreeSelect(idParent, obj)}
        onClick={e => {
          doActiveItemTreeSelect(idParent, obj)
        }}
        labelText={obj.label}
        widthText={300}
        key={'treeSelect_treeItem' + obj.id + (props.dialog ? '_Dialog' : '')}
        id={'treeSelect_treeItem' + obj.id}
        badge={'badge' in obj ? obj.badge : 0}
        obj={obj}
      >
        {temp_sort_label.map(name_label => {
          let obj_child = obj.children.filter(obj => obj.label === name_label)
          if (obj_child.length > 0) {
            return recurTreeView(
              obj_child[0],
              depth + 1,
              index,
              idParent,
              value
            )
          }
        })}
      </FFTreeItem>
    )
  }

  return (
    <FormControl
      disabled={props?.is_disabled ? props.is_disabled : false}
      ref={myRef}
      variant='filled'
      style={props?.style || { margin: 4, width: '-webkit-fill-available' }}
      key={'treeSelect_formControl' + idCls + te}
    >
      <InputLabel>{props.selectMetaLabel}</InputLabel>
      <Select
        key={'treeSelect_' + idCls + te}
        id={'treeSelect_' + idCls}
        value={value}
        onClick={e => doCLickSelect(e, idCls)}
        open={stateMeta.open}
      >
        <div className={classes.grid}>
          <div className={classes.divTools}>
            <div className={classes.divSearch}>
              <Context.Provider value={{ changeValue }}>
                <FFTextField
                  id={'searchTree'}
                  name={'search'}
                  value={search}
                  label={'Полнотекстовый поиск'}
                />
              </Context.Provider>
            </div>
          </div>
          <Grid
            className={classes.gridTree}
            item
            xs={12}
            id={'Grid_tree_essence'}
          >
            <TreeView
            defaultExpanded={[]}
            key={'treeSelect_treeView' + (true ? '_Dialog' : '')}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={null}
            >
               {treeUtils
                .filterNodes(
                  props.selectNodes,
                  obj =>
                    (obj.label &&
                      obj.label.toUpperCase().indexOf(search.toUpperCase()) !==
                        -1) ||
                    (obj.name &&
                      obj.name.toUpperCase().indexOf(search.toUpperCase()) !==
                        -1)
                )
                .map((obj, index) => {
                  return recurTreeView(obj, 0, index, idCls, value)
                })}
            </TreeView>
          </Grid>
        </div>
        {/* <div>
        <Grid id={'gfvcdvb'} className={classes.grid}>
          <TreeView
            defaultExpanded={[]}
            key={'treeSelect_treeView' + (true ? '_Dialog' : '')}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={null}
            style={{ height: 300 }}
          >
            {props.selectNodes.map((obj, index) => {
              return recurTreeView(obj, 0, index, idCls, value)
            })}
          </TreeView>
        </Grid>
        </div> */}

        <span
          value={value}
          style={{ display: 'table-column' }}
          key={'FF_Classifier_step2_menuItem_'}
        >
          {'label' in stateMeta ? stateMeta.label : null}
        </span>
      </Select>
    </FormControl>
  )
}
