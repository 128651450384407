import React, { useEffect, useState, useContext } from 'react'
import Context from '../../../../context'

import FFLoaderComponent from '../../../../libft/FFLoaderComponent'
import FFRouterComponents from '../../../../components/FFRouterComponents'

import FFLoader from '../../../../libft/FFLoader'
import Grid from '@material-ui/core/Grid'

const uuidv4 = require('uuid/v4')

export default function FF_Classifier_step3_typeData_bool (props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  //
  //                                 Мета описание
  //
  // ---------------------------- ---------------------------------------------------

  let metaTable_step1 = [
    // {
    //   widthColumn: 6,
    //   data: [
    //     {
    //       type: 'ffListTextFields',
    //       style: {},
    //       name_attr: ['label-true', 'label-false'],
    //       // dis: true,
    //       label_attr: [
    //         'Значение True 1',
    //         'Значание False 0',
    //       ]
    //     }
    //   ]
    // },
    {
      widthColumn: 6,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['label-true', 'value-true'],
          // dis: false,
          label_attr: ['Наименование True', 'Значение True']
        }
      ]
    },
    {
      widthColumn: 6,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['label-false', 'value-false'],
          // dis: false,
          label_attr: ['Наименование False', 'Значение False']
        }
      ]
    }
  ]

  // -------------------------------------------------------------------------------
  //
  //                                 Состояние
  //
  // ---------------------------- ---------------------------------------------------

  const { pFormatDataValueList } = useContext(Context)
  const [valueBoolData, setValueBoolData] = useState({})
  const [loading, setLoading] = useState(false)

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------
  useEffect(() => {
    let temp = {
      id: uuidv4(),
      'value-true': '',
      'label-true': '',
      'value-false': '',
      'label-false': ''
    }
    // console.log(props, 'wwww')
    if (props.value && props.value.value) {
      temp['label-true'] = props.value.value['label-true']
      temp['label-false'] = props.value.value['label-false']
      temp['value-true'] = props.value.value['value-true']
      temp['value-false'] = props.value.value['value-false']
    }
    // console.log('------>>', temp)
    setLoading(true)
    setValueBoolData(temp)
  }, [])

  const handleChangeFFTextFields = (value, parameter_id, name, element) => {
    if (element) {
      let temp = valueBoolData
      temp[name] = value
      pFormatDataValueList('bool-value', {
        'value-true': temp['value-true'],
        'value-false': temp['value-false'],
        'label-true': temp['label-true'],
        'label-false': temp['label-false']
      })
      setValueBoolData(temp)
    }
    return ''
  }

  return (
    <Context.Provider value={{ handleChangeFFTextFields }}>
      <Grid container item xs={12} spacing={1}>
        <FFLoaderComponent
          loading={loading}
          isLoader={<FFLoader />}
          component={
            <Grid container item xs={12} spacing={1}>
              {metaTable_step1.map(metaColumn => {
                // console.log(props.essence)
                return (
                  <Grid item xs={metaColumn.widthColumn}>
                    {metaColumn.data.map(metaCell => {
                      return (
                        <FFRouterComponents
                          style={{ margin: 10 }}
                          parameter={valueBoolData}
                          meta_cell={metaCell}
                        />
                      )
                    })}
                  </Grid>
                )
              })}
            </Grid>
          }
        />
      </Grid>
    </Context.Provider>
  )
}
