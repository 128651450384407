import React, { useState, useLayoutEffect } from 'react'
import { makeStyles, withStyles, formatMs } from '@material-ui/core/styles'

import { Paper, TableContainer, Grid } from '@material-ui/core'

import { useTheme } from '@material-ui/core/styles'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  flexColumn: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  }
}))

export default function FFContainerColumn (props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------
  const classes = useStyles()

  const config = {
    childrenField: 'children',
    keyField: 'id'
  }

  const defTheme = useTheme()

  return (
    <Grid
      style={{
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        height: '100%'
      }}
    >
      {props.components.map((metaComponent, indexComp) => {
        if (metaComponent?.width) {
          return (
            <Grid
              item
              xs={metaComponent.width}
              id={'bodyTree_grid' + indexComp}
              className={classes.flexColumn}
            >
              <Paper
                elevation={3}
                id={'bodyTree_paper' + indexComp}
                className={classes.flexColumn}
              >
                {metaComponent.component}
              </Paper>
            </Grid>
          )
        }
      })}
    </Grid>
  )
}
