import highcharts from 'highcharts'
import highstock from 'highcharts/highstock'
import exporting from 'highcharts/modules/exporting'
import export_data from 'highcharts/modules/export-data'
import annotations from 'highcharts/modules/annotations'
import patternFill from 'highcharts/modules/pattern-fill'
import { useTheme } from '@material-ui/core/styles'

exporting(highcharts)
export_data(highcharts)
annotations(highcharts)
patternFill(highcharts)

exporting(highstock)
export_data(highstock)
annotations(highstock)
patternFill(highstock)
;(function (H) {
  if (window.zipcelx && H.getOptions().exporting) {
    H.Chart.prototype.downloadXLSX = function () {
      var div = document.createElement('div'),
        name,
        xlsxRows = [],
        rows
      div.style.display = 'none'
      document.body.appendChild(div)
      rows = this.getDataRows(true)
      xlsxRows = rows.slice(1).map(function (row) {
        return row.map(function (column) {
          return {
            type: typeof column === 'number' ? 'number' : 'string',
            value: column
          }
        })
      })

      // Get the filename, copied from the Chart.fileDownload function
      if (this.options.exporting.filename) {
        name = this.options.exporting.filename
      } else if (this.title && this.title.textStr) {
        name = this.title.textStr.replace(/ /g, '-').toLowerCase()
      } else {
        name = 'chart'
      }

      window.zipcelx({
        filename: name,
        sheet: {
          data: xlsxRows
        }
      })
    }

    // Default lang string, overridable in i18n options
    H.getOptions().lang.downloadXLSX = 'Download XLSX'

    // Add the menu item handler
    H.getOptions().exporting.menuItemDefinitions.downloadXLSX = {
      textKey: 'downloadXLSX',
      onclick: function () {
        this.downloadXLSX()
      }
    }

    // Replace the menu item
    var menuItems = H.getOptions().exporting.buttons.contextButton.menuItems
    menuItems[menuItems.indexOf('downloadXLS')] = 'downloadXLSX'
  }
})(highcharts)
;(function () {

  const Options = {
    lang: {
      loading: 'Загрузка...',
      months: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
      ],
      weekdays: [
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота'
      ],
      shortMonths: [
        'Янв',
        'Фев',
        'Март',
        'Апр',
        'Май',
        'Июнь',
        'Июль',
        'Авг',
        'Сент',
        'Окт',
        'Нояб',
        'Дек'
      ],
      exportButtonTitle: 'Экспорт',
      printButtonTitle: 'Печать',
      rangeSelectorFrom: 'С',
      rangeSelectorTo: 'По',
      rangeSelectorZoom: 'Период',
      downloadPNG: 'Экспортировать в PNG',
      downloadJPEG: 'Экспортировать в JPEG',
      downloadPDF: 'Экспортировать в PDF',
      downloadSVG: 'Экспортировать в SVG',
      downloadCSV: 'Экспортировать в CSV',
      downloadXLS: 'Экспортировать в XLS (Обычный формат)',
      printChart: 'Напечатать график',
      viewFullscreen: 'Просмотр в полноэкранном режиме',
      resetZoom: 'Уменьшить масштаб',
      resetZoomTitle: 'Уменьшить масштаб'
    },
    global: {
      useUTC: false
    },
    credits: false
  }

  highcharts.setOptions(Options)
  highstock.setOptions(Options)
})()
