import React, { useContext, useState } from 'react'
import Context from '../context'
import Tooltip from '@material-ui/core/Tooltip'

import { Switch, Checkbox } from '@material-ui/core'

// import FFToggle from './FFToggle'

export default function FFToggle (props) {
  const { handleChangeFFTextFields } = useContext(Context)
  const [checked, setChecked] = useState(props.value)
  const toggleChecked = () => {
    setChecked(prev => !prev)
    handleChangeFFTextFields(!checked, props.parameter.id, props.name)
  }
  if (props.type_children === 'switch') {
    return <Switch checked={checked} onChange={toggleChecked} color='primary' />
  }
  return (
    <>
      <Tooltip title={props.labelToolTip ? props.labelToolTip : ''} interactive>
        <Checkbox checked={checked} onChange={toggleChecked} color='primary' />
      </Tooltip>
      {props.label}
    </>
  )
}
