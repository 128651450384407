import { createSlice } from "@reduxjs/toolkit";

export const NAMESPACE = "authLogin";

export const initialState = {
  isLogin: true,
  isFull: false,
};

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setIsLogin: (state, { payload }) => {
      state.isLogin = payload;
    },
    setIsFull: (state, { payload }) => {
      state.isFull = payload;
    },
  },
});

export default slice.reducer;
export const { setIsLogin, setIsFull } = slice.actions;
export const getIsLogin = (store) => store[NAMESPACE].isLogin;
export const getIsFull = (store) => store[NAMESPACE].isFull;
