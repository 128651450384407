import React from "react";
import { Slider } from "@material-ui/core";

export const SliderComponent = ({ step, left, right, value, index, handleChange }) => {
  return (
    <div style={{ display: "flex", gap: 5, alignItems: "end" }}>
      <span>{left}</span>
      <Slider
        key={index}
        valueLabelDisplay="auto"
        step={step}
        value={value}
        defaultValue={[left, right]}
        min={left}
        max={right}
        marks
        onChange={handleChange}
      />
      <span>{right}</span>
    </div>
  );
};
