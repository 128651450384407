import { ApiConnect, urlConfiguration } from "../ApiConnectNew";

const client = (url, { callback, callbackLoading, isLogin, body }, method) =>
  ApiConnect({
    name: method,
    url: `${urlConfiguration}${url}`,
    objToGet: body,
    setterEssence: callback,
    setterLoading: callbackLoading,
    isLogin,
  });

export const api = {
  essence: {
    get_all: (props) =>
      client("/api/support/info/all_essences", props, "getData"),
    // get_tree: (props) => essenceTree(props),
    get_tree: (props) =>
      client("/api/support/info/fast_tree", props, "getData"),
    filter: (props) => client("/api/support/info/filter_tree/", props, "getDataPost"),
  },
  client: {
    get_all: (props) =>
      client("/api/support/info/all_clients", props, "getData"),
  },
};
