import React from 'react'

import IconDynamicFeed from '@material-ui/icons/DynamicFeed'
import IconDelete from '@material-ui/icons/Delete'
import IconLibraryAdd from '@material-ui/icons/LibraryAdd'
import IconAdd from '@material-ui/icons/Add'
import IconSave from '@material-ui/icons/Save'
import IconRefresh from '@material-ui/icons/Refresh'
import IconSettings from '@material-ui/icons/Settings'
import IconUpdate from '@material-ui/icons/Update'
import IconEdit from '@material-ui/icons/Edit'
import IconCancel from '@material-ui/icons/Cancel'
import IconIso from '@material-ui/icons/Iso'
import IconNext from '@material-ui/icons/SkipNext'
import IconPrevious from '@material-ui/icons/SkipPrevious'
import IconTimeline from '@material-ui/icons/Timeline'
import IconPhotoLibrary from '@material-ui/icons/PhotoLibrary'
import IconPhoto from '@material-ui/icons/Photo'
import IconGetApp from '@material-ui/icons/GetApp'
import IconCallSplit from '@material-ui/icons/CallSplit'
import IconAccountTree from '@material-ui/icons/AccountTree'
import IconClass from '@material-ui/icons/Class'
import IconChromeReaderMode from '@material-ui/icons/ChromeReaderMode'
import IconLocalLibrary from '@material-ui/icons/LocalLibrary'
import IconDeveloperBoard from '@material-ui/icons/DeveloperBoard'
import IconDashboard from '@material-ui/icons/Dashboard'
import IconAllInbox from '@material-ui/icons/AllInbox'
import IconAssignment from '@material-ui/icons/Assignment'
export default function MyIconButton ({ type, size, color }) {
  if (type === 'add')
    return (
      <IconAdd
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'delete')
    return (
      <IconDelete
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'edit')
    return (
      <IconEdit
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'cancel')
    return (
      <IconCancel
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'calc')
    return (
      <IconIso
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'list')
    return (
      <IconDynamicFeed
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'copy')
    return (
      <IconLibraryAdd
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'save')
    return (
      <IconSave
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'refresh')
    return (
      <IconRefresh
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'settings')
    return (
      <IconSettings
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'history')
    return (
      <IconUpdate
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'next')
    return (
      <IconNext
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'prev')
    return (
      <IconPrevious
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'chart')
    return (
      <IconTimeline
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'photoLib')
    return (
      <IconPhotoLibrary
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'photo')
    return (
      <IconPhoto
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'download')
    return (
      <IconGetApp
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'split')
    return (
      <IconCallSplit
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'tree')
    return (
      <IconAccountTree
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'template')
    return (
      <IconClass
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'panel')
    return (
      <IconDashboard
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'classifier')
    return (
      <IconLocalLibrary
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'parameter')
    return (
      <IconAllInbox
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
  else if (type === 'value')
    return (
      <IconAssignment
        color={color ? color : 'primary'}
        fontSize={size ? size : 'large'}
      />
    )
}
