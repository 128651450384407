import React from 'react'

import { Button, Dialog, DialogTitle, DialogActions } from '@material-ui/core/'
import { withStyles } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

const ButtonError = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}))(Button)


const ButtonSuccess = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  }
}))(Button)

const FFDialogDelete = ({ open, setOpen, isAgree }) => {
  return (
    <Dialog open={open} maxWidth={'sm'} fullWidth={true}>
      <DialogTitle>{'Вы действительно хотите удалить?'}</DialogTitle>
      <DialogActions>
        <ButtonError
          onClick={e => {
            isAgree()
            setOpen(false)
          }}
        >
          {'Да, я хочу удалить!'}
        </ButtonError>
        <Button
          onClick={e => setOpen(false)}
          variant='contained'
          color='primary'
        >
          {'Я передумал'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FFDialogDelete

export {ButtonError, ButtonSuccess}
