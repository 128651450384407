import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FFEditTemplateList from './FFEditTemplateList'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}))

const AccordionSummaryMy = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(AccordionSummary)

export default function SimpleAccordion ({ listElement }) {
  const classes = useStyles()


  const clsList = listElement.filter(obj => obj.type !== 'parameter').sort((a, b) => (!a.is_deleted ? a : b)) || []
  const paramList = listElement.filter(obj => obj.type === 'parameter').sort((a, b) => (!a.is_deleted ? a : b)) || []

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummaryMy
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography className={classes.heading}>
            {'Классификаторы'}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {`Количество: ${clsList.length}`}
          </Typography>
        </AccordionSummaryMy>
        <AccordionDetails>
          <FFEditTemplateList list={clsList}/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummaryMy
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography className={classes.heading}>{'Параметры'}</Typography>
          <Typography className={classes.secondaryHeading}>
          {`Количество: ${paramList.length}`}
          </Typography>
        </AccordionSummaryMy>
        <AccordionDetails>
          <FFEditTemplateList list={paramList}/>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
