import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Radio,
  Typography,
  TextField,
} from "@material-ui/core";
import Context from "../../../../../context";
import { transformSliderConfig, getIntervals } from "./utils";
import { SliderComponent } from "./slider";
import { IntervalItem } from "./interval-item";

export const SliderIntevalRange = ({ options, value, onChangeValue }) => {
  const { length, step, left, right } = transformSliderConfig(options);
  const intervals = getIntervals(value, length, left, right);

  const [currentIndex, setCurrentIndex] = useState(0);


  const handleChange = (event, newInterval) => {
    handleChangeInterval(newInterval);
  };

  const handleChangeInterval = (newInterval) => {
    const list = [...intervals];
    list[currentIndex] = newInterval;
    onChangeValue(list);
  };

  return (
    <div>
      <SliderComponent
        index={currentIndex}
        left={left}
        right={right}
        step={step}
        value={intervals[currentIndex]}
        handleChange={handleChange}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 15,
          marginTop: 25,
        }}
      >
        {intervals.map((interval, index) => {
          return (
            <IntervalItem
            value={interval}
              index={index}
              checked={currentIndex === index}
              left={left}
              right={right}
              handleSetCurrentIndex={setCurrentIndex}
              handleChangeInterval={handleChangeInterval}
            />
          );
        })}
      </div>
    </div>
  );
};
