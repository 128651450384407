import React from 'react'
import { Avatar, Tooltip, Badge } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FFIcons from './FFIcons'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  badge: {
    margin: theme.spacing(0.5),

  },
  rounded: {
    color: '#fff',
    backgroundColor: theme.palette.grey[100],
    cursor: 'pointer',
    transition: '0.5s',
    '& > *': {
      color: '#fff'
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[300]
    }
  },
  activeAvatar: {
    color: '#fff',
    backgroundColor: theme.palette.grey[300],
    cursor: 'pointer',
    transition: '0.5s',
    '& > *': {
      color: '#fff'
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[500]
    }
  }
}))

export default function FFActionAvatar ({
  tooltip,
  style,
  className,
  onClick,
  color,
  size,
  type,
  badge,
  ...props
}) {
  const classes = useStyles()
  return (
    <Tooltip title={tooltip}>
      <Badge className={classes.badge} badgeContent={badge || 0} color='primary'>
        <Avatar
          variant='rounded'
          onClick={onClick}
          className={badge ? classes.activeAvatar : classes.rounded}
          style={style ? style : {}}
        >
          <FFIcons size={size} type={type} color={color} />
        </Avatar>
      </Badge>
    </Tooltip>
  )
}
