import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

export default props => {
  const size = 'size' in props && props.size === 'small' ? 20 : 100
  let margin = '.5rem'

  if(size === 20){
	  margin = '2px'
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center', margin: margin }}>
      <CircularProgress size={size} color={props.color ? props.color : 'primary' }/>
    </div>
  )
}
