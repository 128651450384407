import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Context from "../context";
import InputAdornment from "@material-ui/core/InputAdornment";
import { getTimeProps } from "antd/lib/date-picker/generatePicker";
const useStyles = makeStyles({
  textField: {
    width: "-webkit-fill-available",
    margin: "4px",
  },
  textBlock: {
    margin: "4px",
  },
});

export default function FFTextFields(props) {
  // console.log(props)

  const { handleChangeFFTextFields } = useContext(Context);
  const classes = useStyles();
  const [value, setValue] = useState();
  const [errors, setError] = useState("");
  const handleChange = (event) => {
    const errors = handleChangeFFTextFields(
      event.target.value,
      props.parameter.id,
      props.name,
      event.target
    );
    setError(errors);
    // console.log(errors);
    setValue(event.target.value);
  };
  useEffect(() => {
    let temp_value = props.value;
    if (temp_value === null) {
      temp_value = "";
    }
    const errors = handleChangeFFTextFields(
      props.value,
      props.parameter.id,
      props.name,
      null
    );
    setError(errors);
    setValue(temp_value);
  }, []);
  return (
    <TextField
      {...props}
      InputProps={{
        startAdornment: props?.measure_unit ? (
          <InputAdornment position="start">{props.measure_unit}</InputAdornment>
        ) : null,
      }}
      error={errors && errors.length > 0 ? true : false}
      helperText={errors}
      className={classes.textField}
      id={props.parameter.id + "_" + props.name + "_text"}
      label={props.label}
      onClick={props.doClick ? (e) => props.doClick(e) : null}
      multiline
      rowsMax="10"
      value={value}
      onChange={handleChange}
      variant="filled"
      disabled={props.dis ? true : false}
    />
  );
}
