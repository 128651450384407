import React, { useState, useContext, useEffect, useRef } from 'react'
import Context from '../context'
import { makeStyles } from '@material-ui/core/styles'

import FFTreeItem from './FFTreeItem'
import TreeView from '@material-ui/lab/TreeView'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import TreeNodeUtils from 'tree-node-utils'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Input from '@material-ui/core/Input'
import FilledInput from '@material-ui/core/FilledInput'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import IconMoreVert from '@material-ui/icons/MoreVert'

import FFSwitch_N from '../components/FFSwitch_N'
import { Select } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 20
  },
  margin: {
    marginTop: '10px',
    marginLeft: '10px',
    marginBottom: '5px'
  },
  padding: {
    // paddingLeft: 20,
    paddingRight: 10
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: '100%'
  },
  formControl: {
    minWidth: 250
  },
  formControl2: {
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  end: {
    flexGrow: 3
  },
  tree: {
    padding: 8,
    margin: 8
  },
  flexEnd: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexFlow: 3
  },
  scroller: {
    // maxHeight: '-webkit-fill-available',
    overflow: 'overlay'
  }
})

export default function FF_Tree_essence (props) {
  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // -------------------------------------------------------------------------------

  const {
    doClickActiveEssence,
    doClickActiveEssenceDialog,
    doGoPositionScroll,
    doClickMetaSelect
  } = useContext(Context)
  const [active, setActive] = useState(props?.active ? props?.active : '')
  const [search, setSearch] = useState('')
  const myRef = useRef(null)
  const config = {
    childrenField: 'children',
    keyField: 'id'
  }

  const treeUtils = new TreeNodeUtils(config)

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  // Клик на меню
  const doClickTreeItem = obj => {
    if (props.dialog !== true) {
      setActive(obj.id)
      if (!props?.doubleClick) {
        doClickActiveEssence(obj.id, obj, props?.type)
      }
    } else if (props.dialog === true) {
      setActive(obj.id)
      doClickActiveEssenceDialog(obj)
    }
  }

  const doubleClick = (e, obj) => {
    // console.log(obj, 'double')
    // console.log(myRef)
    if (props?.doubleClick) {
      doGoPositionScroll(myRef?.current?.scrollTop)
      doClickActiveEssence(obj.id, obj, props?.type)
    }
    e.stopPropagation()
  }

  const doChangeSearch = e => {
    setSearch(e.target.value)
  }

  // console.log(
  //   'qq',
  //   treeUtils.filterNodes(props.essence, obj => obj.label.indexOf('Ба') !== -1)
  // )

  // -------------------------------------------------------------------------------
  //
  //                            Рекурсивное построение дерева
  //
  // -------------------------------------------------------------------------------

  const recurTreeView = (obj, depth, index) => {
    let temp_sort_label =
      'children' in obj && obj.children.length > 0
        ? obj.children.map(obj => obj.label)
        : []
    temp_sort_label.sort()


    return (
      <FFTreeItem
        ref={myRef}
        style={{ margin: 4 }}
        active={
          (obj?.node_id ? obj.id + obj?.node_id : obj.id) === active ||
          (props?.activeList &&
            props?.activeList.filter(activeObj => activeObj.id === obj.id)
              .length)
            ? true
            : false
        }
        nodeId={obj?.node_id ? obj.id + obj?.node_id : obj.id}
        value={obj?.node_id ? obj.id + obj?.node_id : obj.id}
        onDoubleClick={e => doubleClick(e, obj)}
        onClick={e => doClickTreeItem(obj)}
        onKeyDown={e => (e.key === 'Enter' ? doClickTreeItem(obj) : null)}
        labelText={obj[select]}
        key={
          'FFTreeEssence_FFTreeItem_tree_' +
          (obj?.node_id ? obj.id + obj?.node_id : obj.id) +
          (props.dialog ? '_Dialog' : '')
        }
        id={
          'FFTreeEssence_FFTreeItem_tree_' +
          (obj?.node_id ? obj.id + obj?.node_id : obj.id)
        }
        badge={'badge' in obj ? obj.badge : 0}
        obj={obj}
      >
        {temp_sort_label.map(name_label => {
          let obj_child = obj.children.filter(obj => obj.label === name_label)
          if (obj_child.length > 0) {
            return recurTreeView(obj_child[0], depth + 1, index)
          }
        })}
      </FFTreeItem>
    )
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    // console.log(event.target)
    setAnchorEl(event.currentTarget)
  }

  const ITEM_HEIGHT = 48
  const [select, setSelect] = useState(
    props?.metaSelect ? props.metaSelect : 'label'
  )
  const options = [
    { key: 'name', label: 'Системное наименование' },
    { key: 'label', label: 'Наименование' }
  ]
  const handleClose = e => {
    // console.log(e.target.value)
    setAnchorEl(null)
  }

  const onClickMenu = e => {
    let temp = e.target.textContent
    setSelect(options.filter(obj => obj.label === temp)[0].key)
    doClickMetaSelect(options.filter(obj => obj.label === temp)[0].key)
    setAnchorEl(null)
  }
  const [expNodes, setExpNodes] = useState([])
  const recurExpNodes = (branch, tempNode) => {
    if (branch.length) {
      tempNode['node'] = [
        ...tempNode['node'],
        branch[0]?.node_id ? branch[0].id + branch[0]?.node_id : branch[0].id
      ]
    }
    // console.log(branch, tempNode)

    branch.map(obj => {
      // tempNode = [...tempNode, obj.id]
      if (obj.children) {
        recurExpNodes(obj.children, tempNode)
      }
      // return tempNode
    })
    // return tempNode
  }

  useEffect(() => {
    if (props.position && myRef?.current?.scrollTop) {
      myRef.current.scrollTop = props.position
    }
  }, [])

  const expNo = () => {
    let temp = { node: [] }
    if (props.active || props?.activeList) {
      recurExpNodes(
        treeUtils.filterNodes(props.essence, obj => obj.id === props.active),
        temp
      )
    }
    if (props?.activeList) {
      props.activeList.map(objActive => {
        recurExpNodes(
          treeUtils.filterNodes(props.essence, obj => obj.id === objActive.id),
          temp
        )
      })
    }
    // if (props.position) {
    //   myRef.current.scrollTop = props.position
    // }
    return temp['node']
  }
  // }, [])
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------
  return (
    <>
      {/* <Grid container xs={12} className={classes.flexRow}> */}
      <Grid
        container
        item
        xs={12}
        spacing={1}
        style={{ flexBasis: 90, marginBottom: 0 }}
      >
        <Grid item xs={11}>
          <FormControl
            className={classes.root}
            key={'FFTreeEssence_FormControl_search'}
          >
            <InputLabel
              htmlFor='FFTreeEssence_InputLabel_search'
              style={{ marginLeft: 10 }}
              key={'FFTreeEssence_InputLabel_search'}
            >
              Полнотекстовый поиск
            </InputLabel>

            <FilledInput
              label={'Полнотекстовый поиск'}
              id='FFTreeEssence_filledInput_search'
              value={search}
              onChange={doChangeSearch}
              endAdornment={
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            style={{ marginTop: 5 }}
            onClick={handleClick}
          >
            <IconMoreVert />
          </IconButton>
          <Menu
            id='long-menu'
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5
                // width: ''
              }
            }}
          >
            {options.map(option => (
              <MenuItem
                key={option.key}
                selected={option.key === select}
                onClick={e => onClickMenu(e)}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
      <Grid
        className={classes.scroller}
        style={{ margin: 0 }}
        item
        xs={12}
        id={'Grid_tree_essence'}
      >
        <TreeView
          // selected
          // className={[classes.scroller, classes.end]}
          // selected={['fc87c66f-5ebc-4ca4-ae52-83da0efaeac9']}
          defaultExpanded={expNo()}
          key={
            'FFTreeEssence_TreeView_treeEssence' +
            (props.dialog ? '_Dialog' : '')
          }
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={null}
        >
          {treeUtils
            .filterNodes(
              props.essence,
              obj =>
                (obj.label &&
                  obj.label.toUpperCase().indexOf(search.toUpperCase()) !==
                    -1) ||
                (obj.name &&
                  obj.name.toUpperCase().indexOf(search.toUpperCase()) !== -1)
            )
            .map((obj, index) => {
              return recurTreeView(obj, 0, index)
            })}
        </TreeView>
        {/* </Paper> */}
      </Grid>
      {/* </Grid> */}
    </>
  )
}
