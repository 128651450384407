import React, { useRouters } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import App from "./depricated/App";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { blue, red, green } from "@material-ui/core/colors";
import { store, persistor } from "./store/store";
import "./depricated/boot/highchartsSetOptions";
import "./depricated/styles/index.css";
const history = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      // // Purple and green play nicely together.
      // light: red[50],
      // main: red[700],
      light: blue[50],
      main: blue[700],
    },
    secondary: {
      // This is green.A700 as hex.
      main: green[700],
    },
    error: {
      light: red[50],
      main: red[500],
      dark: red[700],
    },
  },

  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    // fontFamily: ['"Arial"'].join(',')
    fontFamily: ['"Roboto"'].join(","),
  },
});

// Open Sans
ReactDOM.render(
  <Router>
    <StylesProvider injectFirst>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <App history={history} />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </StylesProvider>
  </Router>,
  document.getElementById("root")
);
