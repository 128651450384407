import React, {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef
} from 'react'
import Context from './context'
import FFCreatePanel from './TestConfig'
import PropTypes from 'prop-types'
import { makeStyles, withStyles, formatMs } from '@material-ui/core/styles'
import IconError from '@material-ui/icons/Error'
import IconHelp from '@material-ui/icons/Help'
import FFSelectTree from './components/FFSelectTree'

// import FFSelect from './FFSelectSimple'

// import DateTimePicker from '@material-ui/pickers/DateTimePicker'
// import Accordion from '@material-ui/core/Accordion'
// import AccordionSummary from '@material-ui/core/AccordionSummary'
// import AccordionDetails from '@material-ui/core/AccordionDetails'
import {
  Paper,
  Typography,
  TableContainer,
  Grid,
  Divider,
  IconButton,
  Button,
  Tooltip,
  Drawer,
  // Dialog import
  Dialog,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  // Card import
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardAction,
  Collapse,
  Avatar,
  Backdrop,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Chip,
  Badge,
  TextField,
  Switch,
  FormControlLabel,
  StepConnector
} from '@material-ui/core'

import { useTheme } from '@material-ui/core/styles'

import FF_Chart from './components/FF_Charts'
import Skeleton from '@material-ui/lab/Skeleton'
import FF_MappingDeviceArray from './pageComponents/FFMappingDevice/FF_MappingDeviceArray'

import IconSettings from '@material-ui/icons/Settings'
import IconDynamicFeed from '@material-ui/icons/DynamicFeed'
import IconDelete from '@material-ui/icons/Delete'
import IconLibraryAdd from '@material-ui/icons/LibraryAdd'
import IconAdd from '@material-ui/icons/Add'
import IconSave from '@material-ui/icons/Save'
import IconCancel from '@material-ui/icons/Cancel'
import IconRefresh from '@material-ui/icons/Refresh'
import IconAccountTree from '@material-ui/icons/AccountTree'
import IconSettingsInputComponent from '@material-ui/icons/SettingsInputComponent'
import FavoriteIcon from '@material-ui/icons/Favorite'
import IconLaunch from '@material-ui/icons/Launch'
import FFTable from './components/FFTable'

import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import FFSelect from './components/FFSelectSimple'

import FFLoaderComponent from './components/FFLoaderComponent'
import FFToggle from './components/FFToggleSimple2'
import FFTextFields from './components/FFTextFields'
import FFCheckBox from './components/FFCheckBoxSimple2'
import FFTreeEssence from './components/FFTreeEssence'
import FFTreeEssenceChecked from './components/FFTreeEssenceChecked'
import FFPanels from './components/FFPanels'

import IconThumbUp from '@material-ui/icons/ThumbUp'
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter'
import IconSignalWifi4Bar from '@material-ui/icons/SignalWifi4Bar'
import IconInsertComment from '@material-ui/icons/InsertComment'
import IconNetworkCheck from '@material-ui/icons/NetworkCheck'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined'
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill'
import IconCompare from '@material-ui/icons/Compare'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown'
import InlineDateTimePickerDemo from './components/FFDatePicker'
import FFCard from './components/FFCard'
// import KeyboardDateTimePicker from '@material-ui/pickers/'

import FFSwitch_N from './components/FFSwitch_N'
import FFToogleStyle from './components/FFToogleStyle'

import { SnackbarProvider, useSnackbar } from 'notistack'
import TreeNodeUtils from 'tree-node-utils'

import FFContainerColumn from './components/FFContainerColumn'

import clsx from 'clsx'
import { ApiConnect, urlConfiguration } from './ApiConnectNew'
import cookie from 'react-cookies'
import Loader from './Loader'

const uuidv4 = require('uuid/v4')

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  drawer: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    width: 360,
    overflow: 'overlay',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  tableCell: {
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    verticalAlign: 'center',
    textAlign: 'center'
  },
  panelHeaderOpen: {
    borderBottom: '2px solid ' + theme.palette.grey[500],
    background: theme.palette.primary.light,
    borderRadus: '4px 4px 0px 0px',
    height: 40
  },
  panelHeader: {
    // borderBottom: '2px solid ' + theme.palette.grey[500],
    background: theme.palette.grey[0],
    height: 40
  },
  labelText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'wrap'
    // overflow: 'hidden',
    // width: 200
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.4'
  }
}))

function useWindowSize () {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize () {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export default function FF_Essence (props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------
  const classes = useStyles()

  const config = {
    childrenField: 'children',
    keyField: 'id'
  }
  const treeUtils = new TreeNodeUtils(config)

  const defTheme = useTheme()

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // -------------------------------------------------------------------------------

  const strAuthorization = 'Bearer ' + cookie.load('access_token')

  const { isLogin } = useContext(Context)
  const [essence, setEssence] = useState([])
  const [essenceLoading, setEssenceLoading] = useState(false)
  const [activeEssence, setActiveEssence] = useState(null)
  const [activeDevice, setActiveDevice] = useState(null)

  const [toGoMassiv, setToGoMassiv] = useState(true)
  const [struct, setStruct] = useState(null)
  const [activeTopic, setActiveTopic] = useState(null)
  const [infoTopic, setInfoTopic] = useState(null)
  const [infoTopicLoading, setInfoTopicLoading] = useState(false)

  //СОздание нового топика диалог
  const [emptyStream, setEmptyStream] = useState([])
  const [object, setObject] = useState([])
  const [ess, setEss] = useState(null)
  const [parameter, setParameter] = useState([])

  const [openAddTopic, setOpenAddTopic] = useState(false)
  const [atomMeta, setAtomMeta] = useState(null)
  const [te, setTe] = useState(1)
  const [te1, setTe1] = useState(1)
  const [te2, setTe2] = useState(1)
  const [te3, setTe3] = useState(1)
  const [structLoader, setStructLoader] = useState(false)
  const [isOptions, setIsOptions] = useState('')
  const [block1, setBlock1] = useState({ dis: false, open: false })
  const [block2, setBlock2] = useState({ dis: false, open: false })
  const [block3, setBlock3] = useState({ dis: false, open: false })
  const [block12, setBlock12] = useState({ dis: false, open: false })
  const [block22, setBlock22] = useState({ dis: false, open: false })
  // Мета сведения для дерева сущностей
  const [position, setPosition] = useState(0)
  const [select, setSelect] = useState('label')
  // const [good1, setGood1] = useState(0)
  // const [good2, setGood2] = useState(0)

  const [openTree, setOpenTree] = useState(false)

  const refFFSwitchN = useRef(null)

  useEffect(() => {
    if (activeTopic) {
      setInfoTopicLoading(false)
      setInfoTopic(null)

      ApiConnect({
        name: 'getDataPost',
        url: urlConfiguration + '/api/mapping/topic_stream_parameter/info/',
        objToGet: { topic: activeTopic.name },
        setterEssence: setInfoTopic,
        setterLoading: setInfoTopicLoading,
        isLogin: isLogin
      })
    } else {
      setInfoTopic(null)
    }
  }, [activeTopic])

  useEffect(() => {
    if (struct === null) {
      setActiveTopic(null)
    }
  }, [struct])

  useEffect(() => {
    setStructLoader(false)
    activeEssence
      ? ApiConnect({
          name: 'getData',
          url:
            urlConfiguration +
            '/api/mapping/topic_stream_parameter/tree/?controller=' +
            activeEssence.id,
          setterEssence: setStruct,
          setterLoading: setStructLoader,
          isLogin: isLogin
        })
      : setStruct(null)
  }, [activeEssence])

  useEffect(() => {
    ApiConnect({
      name: 'getData',
      url:
        urlConfiguration +
        '/api/mapping/topic_stream_parameter/tree/controllers/',
      setterEssence: setEssence,
      setterLoading: setEssenceLoading,
      isLogin: isLogin
    })
  }, [])

  // -------------------------------------------------------------------------------
  //
  //                                 Снэк Бар
  //
  // -------------------------------------------------------------------------------

  const { enqueueSnackbar } = useSnackbar()
  const handleClickVariant = (variant, msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      }
    )
  }

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  // Нажатие на классификационное значение в меню
  const doClickActiveEssence = (objId, obj, type) => {
    setActiveDevice(null)
    setActiveTopic(null)
    if (type === 'device' && obj.type.indexOf('device-sensor') === 0) {
      setActiveDevice(obj)
    } else if (type === 'essence' && obj.type.indexOf('control') > -1) {
      setActiveEssence(obj)
    } else if (type === 'device' && obj.type === 'topic') {
      setActiveTopic(obj)
    }
  }

  // Сохранение позиции для дерева сущностей
  const doGoPositionScroll = tempPosition => {
    setPosition(tempPosition)
  }

  // Сохранение выбранного значения в дереве сущностей
  const doClickMetaSelect = tempSelect => {
    setSelect(tempSelect)
  }

  const handleChangeFFTextFields = (value, id, name, type) => {
    if (type) {
      let tempMeta = meta
      let tempAtomMeta = atomMeta
      if (name === 'transform' && isNaN(Number(value))) return 'Введите число'
      tempAtomMeta[name].value = value
      if (name === 'numberTopic') {
        tempAtomMeta['transform'].dis = !value
        setTe(te + 1)
      }
      if (name === 'createTopic') {
        setBlock1({ ...block1, open: value })
        setTe(te + 1)
      }
      if (name === 'linkTopicToStream') {
        setBlock2({ ...block2, open: value })
        setTe1(te1 + 1)
      }
      if (name === 'linkStreamToParameter') {
        setBlock3({ ...block3, open: value })
        tempAtomMeta['object'].options = object
        setTe2(te2 + 1)
      }
      if (name === 'sourceStream') {
        setTe1(te1 + 1)
        tempAtomMeta['stream'].options = emptyStream
        tempAtomMeta['stream'].value = value === 0 ? uuidv4() : ''
      }

      setAtomMeta(tempAtomMeta)
      setMeta(tempMeta)
    }
    return ''
  }

  const doChangeSelect = (par, objId, obj) => {
    let tempAtomMeta = atomMeta
    if (par === 'stream' && obj.type === 'stream') {
      tempAtomMeta[par].value = objId
      // tempAtomMeta['sourceStream'].value = tempAtomMeta['sourceStream'].value + 1
    } else if (par === 'stream') {
      tempAtomMeta[par].value = ''
      setTe1(te1 + 1)
    }
    if (par === 'object') {
      tempAtomMeta[par].value = objId
      setEss(null)
      setParameter(null)
      tempAtomMeta['essence'].value = ''
      tempAtomMeta['parameter'].value = ''
      ApiConnect({
        name: 'getData',
        url:
          urlConfiguration +
          '/api/mapping/topic_stream_parameter/tree/object_children/?obj=' +
          objId,
        setterEssence: setEss,
        setterLoading: null,
        isLogin: isLogin
      })
    }
    if (par === 'essence') {
      tempAtomMeta[par].value = objId
      setParameter(null)
      tempAtomMeta['parameter'].value = ''
      ApiConnect({
        name: 'getData',
        url:
          urlConfiguration +
          '/api/mapping/topic_stream_parameter/tree/essence_parameters_empty/?essence=' +
          objId,
        setterEssence: setParameter,
        setterLoading: null,
        isLogin: isLogin
      })
    }

    setAtomMeta(tempAtomMeta)
  }

  const [meta, setMeta] = useState([
    [
      {
        width: 2,
        type: 'textBox',
        name: 'client',
        label: 'client',
        default: '/client',
        dis: true
      },
      {
        width: 2,
        type: 'textBox',
        name: 'controller',
        label: 'controller',
        default: 'controller',
        dis: true
      },
      {
        width: 2,
        type: 'textBox',
        name: 'devices',
        label: 'devices',
        default: '/devices',
        dis: true
      },
      {
        width: 2,
        type: 'selectBox',
        name: 'sensor',
        label: 'sensor',
        default: '/sensor',
        options: [],
        dis: false
      },
      {
        width: 2,
        type: 'textBox',
        name: 'controls',
        label: 'controls',
        default: '/controls',
        dis: true
      },
      {
        width: 2,
        type: 'textBox',
        name: 'user',
        label: 'user',
        default: '/',
        dis: false
      }
    ],
    [
      // {
      //   width: 3,
      //   type: 'checkBox',
      //   name: 'createTopic',
      //   label: 'Создать топик',
      //   default: false
      // },
      {
        width: 3,
        type: 'checkBox',
        name: 'subscribeTopic',
        label: 'Подписаться на топик',
        default: false
      },
      {
        width: 3,
        type: 'checkBox',
        name: 'numberTopic',
        label: 'Числовой топик',
        default: false
      },
      {
        width: 3,
        type: 'textBox',
        name: 'transform',
        label: 'Koэф. трансформации',
        default: 0,
        dis: true
      }
    ]
  ])

  const temp = {
    title: {
      label: 'Создание'
    },
    createTopic: {
      type: 'checkBox',
      name: 'createTopic',
      label: 'Создать топик',
      default: false
    },
    linkTopicToStream: {
      name: 'linkTopicToStream',
      label: 'Создать привязку стрима к топику',
      default: false
    },
    sourceStream: {
      name: 'sourceStream',
      label: 'Источник стрима',
      default: 0
    },
    stream: {
      name: 'stream',
      label: 'Стрим',
      default: uuidv4(),
      options: []
    },
    descriptionStream: {
      name: 'descriptionStream',
      label: 'Описание стрима',
      default: ''
    },
    linkStreamToParameter: {
      name: 'linkStreamToParameter',
      label: 'Создать привязку параметра к стриму',
      default: false
    },
    object: {
      name: 'object',
      label: 'Объект',
      default: '',
      options: []
    },
    essence: {
      name: 'essence',
      label: 'Сущность',
      default: '',
      options: []
    },
    parameter: {
      name: 'parameter',
      label: 'Параметр',
      default: '',
      options: []
    }
  }

  // Удаление топика
  const doDeleteTopic = () => {
    if (infoTopic && activeTopic) {
      let objToGo = {}
      objToGo.id = [activeTopic.name]
      setActiveEssence(null)
      ApiConnect({
        name: 'deleteDate',
        url:
          urlConfiguration +
          '/api/mapping/topic_stream_parameter/topic_stream/',
        objToDelete: objToGo,
        setterEssence: () => refreshTree(),
        isLogin: isLogin,
        clickHandle: handleClickVariant
      })
    }
  }

  const refreshTree = () => {
    setStructLoader(false)
    ApiConnect({
      name: 'getData',
      url:
        urlConfiguration +
        '/api/mapping/topic_stream_parameter/tree/?controller=' +
        activeEssence.id,
      setterEssence: setStruct,
      setterLoading: setStructLoader,
      isLogin: isLogin
    })
  }

  const doDeleteTopicMassive = () => {
    const topicList = activeDevice?.children
      .filter(obj => obj?.type === 'topic')
      .map(obj => obj.id)
    setActiveDevice(null)
    ApiConnect({
      name: 'deleteDate',
      url:
        urlConfiguration + '/api/mapping/topic_stream_parameter/topic_stream/',
      objToDelete: { id: topicList },
      setterEssence: () => refreshTree(),
      isLogin: isLogin,
      clickHandle: handleClickVariant
    })
  }

  // Измнение стрима а именно его параметров
  const doEditStream = stream => {
    setBlock1({ dis: true, open: false })
    setBlock2({ dis: false, open: true })
    setBlock3({ dis: true, open: false })
    setBlock12({ dis: false, open: false })
    setBlock22({ dis: true, open: false })
    ApiConnect({
      name: 'getData',
      url:
        urlConfiguration +
        '/api/mapping/topic_stream_parameter/stream/empty/list/',
      setterEssence: setEmptyStream,
      setterLoading: null,
      isLogin: isLogin
    })

    ApiConnect({
      name: 'getData',
      url:
        urlConfiguration +
        '/api/mapping/topic_stream_parameter/tree/client_object/',
      setterEssence: setObject,
      setterLoading: null,
      isLogin: isLogin
    })

    let tempAtomMeta = {}
    let tempMeta = meta
    tempMeta.map(row => {
      row.map(col => {
        tempAtomMeta[col.name] = {}
        tempAtomMeta[col.name].value = col.default
        tempAtomMeta[col.name].label = col.label
        tempAtomMeta[col.name].dis = col?.dis ? col.dis : false

        if (col.name === 'sensor') col.options = struct[0].children
        if (col.name === 'controller')
          tempAtomMeta[col.name].value = struct[0].sn
      })
    })
    Object.keys(temp).map(name => {
      tempAtomMeta[name] = {}
      tempAtomMeta[name].value = temp[name].default
      tempAtomMeta[name].name = temp[name].name
      tempAtomMeta[name].label = temp[name].label
    })
    tempAtomMeta['stream'].value = stream.label
    tempAtomMeta['linkTopicToStream'].value = true
    if (activeEssence?.type !== 'virtual-controller') {
      let topicSplit = activeTopic.name.split('/')
      if (topicSplit.length === 7) {
        tempAtomMeta['client'].value = '/' + topicSplit[1]
        tempAtomMeta['controller'].value = '/' + topicSplit[2]
        tempAtomMeta['devices'].value = '/' + topicSplit[3]
        tempAtomMeta['sensor'].value = topicSplit[4]
        tempAtomMeta['controls'].value = '/' + topicSplit[5]
        tempAtomMeta['user'].value = '/' + topicSplit[6]
      }
    } else {
      tempAtomMeta['client'].value = activeTopic.name
      tempAtomMeta['client'].dis = false
      tempAtomMeta['client'].label = 'Топик'
    }
    tempAtomMeta['descriptionStream'].value = stream.description
    tempAtomMeta['createTopic'].label = 'Редактировать топик'
    tempAtomMeta['linkTopicToStream'].label = 'Редактировать стрим'
    tempAtomMeta['linkStreamToParameter'].label = 'Редактировать привязку'
    tempAtomMeta['title'].label = 'Редактировать'

    tempAtomMeta['subscribeTopic'].value = infoTopic?.is_subscribe
      ? infoTopic?.is_subscribe
      : false
    tempAtomMeta['numberTopic'].value = infoTopic?.is_numeric
      ? infoTopic?.is_numeric
      : false
    tempAtomMeta['transform'].value = infoTopic?.tc
    tempAtomMeta['transform'].dis = !tempAtomMeta['numberTopic'].value
    tempAtomMeta['toGo'] = () => {
      let objToGo = {
        id: stream.id,
        description: tempAtomMeta['descriptionStream'].value
      }
      if (tempAtomMeta['linkTopicToStream'].value) {
        ApiConnect({
          name: 'updateDate',
          url:
            urlConfiguration +
            '/api/mapping/topic_stream_parameter/stream_connection/',
          objToUpdate: objToGo,
          // setterEssence: setInfoTopic,
          // setterLoading: setInfoTopicLoading,
          isLogin: isLogin,
          clickHandle: handleClickVariant
        })
        setInfoTopicLoading(false)
        setInfoTopic(null)
        setTimeout(() => {
          ApiConnect({
            name: 'getDataPost',
            url: urlConfiguration + '/api/mapping/topic_stream_parameter/info/',
            objToGet: { topic: activeTopic.name },
            setterEssence: setInfoTopic,
            setterLoading: setInfoTopicLoading,
            isLogin: isLogin
          })
        }, 550)
      }
    }

    setMeta(tempMeta)
    setAtomMeta(tempAtomMeta)
    setOpenAddTopic(true)
  }

  // Создание привязки стрима к параметру
  const doAddParameter = stream => {
    setBlock1({ dis: true, open: true })
    setBlock2({ dis: true, open: true })
    setBlock3({ dis: false, open: true })
    setBlock12({ dis: false, open: false })
    setBlock22({ dis: false, open: false })

    ApiConnect({
      name: 'getData',
      url:
        urlConfiguration +
        '/api/mapping/topic_stream_parameter/stream/empty/list/',
      setterEssence: setEmptyStream,
      setterLoading: null,
      isLogin: isLogin
    })

    ApiConnect({
      name: 'getData',
      url:
        urlConfiguration +
        '/api/mapping/topic_stream_parameter/tree/client_object/',
      setterEssence: setObject,
      setterLoading: null,
      isLogin: isLogin
    })

    let tempAtomMeta = {}
    let tempMeta = meta
    tempMeta.map(row => {
      row.map(col => {
        tempAtomMeta[col.name] = {}
        tempAtomMeta[col.name].value = col.default
        tempAtomMeta[col.name].label = col.label
        tempAtomMeta[col.name].dis = col?.dis ? col.dis : false

        if (col.name === 'sensor') col.options = struct[0].children
        if (col.name === 'controller')
          tempAtomMeta[col.name].value = struct[0].sn
      })
    })
    Object.keys(temp).map(name => {
      tempAtomMeta[name] = {}
      tempAtomMeta[name].value = temp[name].default
      tempAtomMeta[name].name = temp[name].name
      tempAtomMeta[name].label = temp[name].label
    })
    tempAtomMeta['stream'].value = stream.label
    tempAtomMeta['linkStreamToParameter'].value = true
    if (activeEssence?.type !== 'virtual-controller') {
      let topicSplit = activeTopic.name.split('/')
      if (topicSplit.length === 7) {
        tempAtomMeta['client'].value = '/' + topicSplit[1]
        tempAtomMeta['controller'].value = '/' + topicSplit[2]
        tempAtomMeta['devices'].value = '/' + topicSplit[3]
        tempAtomMeta['sensor'].value = topicSplit[4]
        tempAtomMeta['controls'].value = '/' + topicSplit[5]
        tempAtomMeta['user'].value = '/' + topicSplit[6]
      }
    } else {
      tempAtomMeta['client'].value = activeTopic.name
      tempAtomMeta['client'].dis = false
      tempAtomMeta['client'].label = 'Топик'
    }
    tempAtomMeta['descriptionStream'].value = stream.description
    tempAtomMeta['subscribeTopic'].value = infoTopic?.is_subscribe
      ? infoTopic?.is_subscribe
      : false
    tempAtomMeta['numberTopic'].value = infoTopic?.is_numeric
      ? infoTopic?.is_numeric
      : false
    tempAtomMeta['transform'].value = infoTopic?.tc
    tempAtomMeta['transform'].dis = !tempAtomMeta['numberTopic'].value
    tempAtomMeta['toGo'] = () => {
      let objToGo = {
        stream: tempAtomMeta['stream'].value,
        essence: tempAtomMeta['essence'].value,
        parameter: tempAtomMeta['parameter'].value
      }
      // console.log(tempAtomMeta, 'sssss')
      if (tempAtomMeta['linkStreamToParameter'].value) {
        ApiConnect({
          name: 'updateDate',
          url:
            urlConfiguration +
            '/api/mapping/topic_stream_parameter/stream_parameter_connection/',
          objToUpdate: objToGo,
          // setterEssence: setInfoTopic,
          // setterLoading: setInfoTopicLoading,
          isLogin: isLogin,
          clickHandle: handleClickVariant
        })
        setInfoTopicLoading(false)
        setInfoTopic(null)

        setTimeout(() => {
          ApiConnect({
            name: 'getDataPost',
            url: urlConfiguration + '/api/mapping/topic_stream_parameter/info/',
            objToGet: { topic: activeTopic.name },
            setterEssence: setInfoTopic,
            setterLoading: setInfoTopicLoading,
            isLogin: isLogin
          })
        }, 550)
      }
    }
    setMeta(tempMeta)
    setAtomMeta(tempAtomMeta)
    setOpenAddTopic(true)
  }

  // Создание привязки топика к стриму
  const doAddStream = () => {
    setBlock1({ dis: true, open: true })
    setBlock2({ dis: false, open: true })
    setBlock3({ dis: false, open: false })
    setBlock12({ dis: false, open: false })
    setBlock22({ dis: false, open: false })
    ApiConnect({
      name: 'getData',
      url:
        urlConfiguration +
        '/api/mapping/topic_stream_parameter/stream/empty/list/',
      setterEssence: setEmptyStream,
      setterLoading: null,
      isLogin: isLogin
    })

    ApiConnect({
      name: 'getData',
      url:
        urlConfiguration +
        '/api/mapping/topic_stream_parameter/tree/client_object/',
      setterEssence: setObject,
      setterLoading: null,
      isLogin: isLogin
    })

    let tempAtomMeta = {}
    let tempMeta = meta
    tempMeta.map(row => {
      row.map(col => {
        tempAtomMeta[col.name] = {}
        tempAtomMeta[col.name].value = col.default
        tempAtomMeta[col.name].label = col.label
        tempAtomMeta[col.name].dis = col?.dis ? col.dis : false

        if (col.name === 'sensor') col.options = struct[0].children
        if (col.name === 'controller')
          tempAtomMeta[col.name].value = struct[0].sn
      })
    })
    Object.keys(temp).map(name => {
      tempAtomMeta[name] = {}
      tempAtomMeta[name].value = temp[name].default
      tempAtomMeta[name].name = temp[name].name
      tempAtomMeta[name].label = temp[name].label
    })
    if (activeEssence?.type !== 'virtual-controller') {
      let topicSplit = activeTopic.name.split('/')
      if (topicSplit.length === 7) {
        tempAtomMeta['client'].value = '/' + topicSplit[1]
        tempAtomMeta['controller'].value = '/' + topicSplit[2]
        tempAtomMeta['devices'].value = '/' + topicSplit[3]
        tempAtomMeta['sensor'].value = topicSplit[4]
        tempAtomMeta['controls'].value = '/' + topicSplit[5]
        tempAtomMeta['user'].value = '/' + topicSplit[6]
      }
    } else {
      tempAtomMeta['client'].value = activeTopic.name
      tempAtomMeta['client'].dis = false
      tempAtomMeta['client'].label = 'Топик'
    }
    tempAtomMeta['subscribeTopic'].value = infoTopic?.is_subscribe
      ? infoTopic?.is_subscribe
      : false
    tempAtomMeta['numberTopic'].value = infoTopic?.is_numeric
      ? infoTopic?.is_numeric
      : false
    tempAtomMeta['transform'].value = infoTopic?.tc
    tempAtomMeta['transform'].dis = !tempAtomMeta['numberTopic'].value
    tempAtomMeta['toGo'] = () => {
      let objToGo = {}
      objToGo.topic = activeTopic.name
      objToGo.stream = {
        is_new: tempAtomMeta['sourceStream'].value == 2 ? false : true,
        id:
          tempAtomMeta['sourceStream'].value == 2
            ? tempAtomMeta['stream'].value
            : '',
        name: tempAtomMeta['stream'].value,
        description: tempAtomMeta['descriptionStream'].value
      }
      objToGo.parameter = {
        is_create: tempAtomMeta['linkStreamToParameter'].value,
        essence: tempAtomMeta['essence'].value,
        parameter: tempAtomMeta['parameter'].value
      }
      ApiConnect({
        name: 'createData',
        url:
          urlConfiguration +
          '/api/mapping/topic_stream_parameter/create/topic_stream/',
        objToCreate: objToGo,
        // setterEssence: setInfoTopic,
        // setterLoading: setInfoTopicLoading,
        isLogin: isLogin,
        clickHandle: handleClickVariant
      })
      setInfoTopicLoading(false)
      setInfoTopic(null)

      setTimeout(() => {
        ApiConnect({
          name: 'getDataPost',
          url: urlConfiguration + '/api/mapping/topic_stream_parameter/info/',
          objToGet: { topic: activeTopic.name },
          setterEssence: setInfoTopic,
          setterLoading: setInfoTopicLoading,
          isLogin: isLogin
        })
      }, 550)
    }
    setMeta(tempMeta)
    setAtomMeta(tempAtomMeta)
    setOpenAddTopic(true)
  }

  // Редактирование топика
  const doEditTopic = () => {
    setBlock1({ dis: false, open: true })
    setBlock2({ dis: true, open: false })
    setBlock3({ dis: true, open: false })
    setBlock12({ dis: true, open: false })
    setBlock22({ dis: false, open: false })

    let tempAtomMeta = {}
    let tempMeta = meta
    tempMeta.map(row => {
      row.map(col => {
        tempAtomMeta[col.name] = {}
        tempAtomMeta[col.name].value = col.default
        tempAtomMeta[col.name].label = col.label
        tempAtomMeta[col.name].dis = col?.dis ? col.dis : false

        if (col.name === 'sensor') col.options = struct[0].children
        if (col.name === 'controller')
          tempAtomMeta[col.name].value = struct[0].sn
      })
    })
    Object.keys(temp).map(name => {
      tempAtomMeta[name] = {}
      tempAtomMeta[name].value = temp[name].default
      tempAtomMeta[name].name = temp[name].name
      tempAtomMeta[name].label = temp[name].label
    })
    if (activeEssence?.type !== 'virtual-controller') {
      let topicSplit = activeTopic.name.split('/')
      if (topicSplit.length === 7) {
        tempAtomMeta['client'].value = '/' + topicSplit[1]
        tempAtomMeta['controller'].value = '/' + topicSplit[2]
        tempAtomMeta['devices'].value = '/' + topicSplit[3]
        tempAtomMeta['sensor'].value = topicSplit[4]
        tempAtomMeta['controls'].value = '/' + topicSplit[5]
        tempAtomMeta['user'].value = '/' + topicSplit[6]
      }
    } else {
      tempAtomMeta['client'].value = activeTopic.name
      tempAtomMeta['client'].dis = false
      tempAtomMeta['client'].label = 'Топик'
    }
    tempAtomMeta['subscribeTopic'].value = infoTopic?.is_subscribe
      ? infoTopic?.is_subscribe
      : false
    tempAtomMeta['numberTopic'].value = infoTopic?.is_numeric
      ? infoTopic?.is_numeric
      : false
    tempAtomMeta['transform'].value = infoTopic?.tc
    tempAtomMeta['transform'].dis = !tempAtomMeta['numberTopic'].value
    // console.log('ed', tempAtomMeta, activeTopic, infoTopic)

    tempAtomMeta['createTopic'].label = 'Редактировать топик'
    tempAtomMeta['linkTopicToStream'].label = 'Редактировать стрим'
    tempAtomMeta['linkStreamToParameter'].label = 'Редактировать привязку'
    tempAtomMeta['title'].label = 'Редактировать'

    tempAtomMeta['toGo'] = () => {
      let objToGo = {}
      objToGo.topic = activeTopic.name
      if (isNaN(Number(tempAtomMeta['transform'].value))) {
        return handleClickVariant(
          'error',
          'Введите число для коэф. трансформации'
        )
      }
      objToGo.parameters = {
        id_deleted: false,
        is_subscribe: tempAtomMeta['subscribeTopic'].value,
        tc: Number(tempAtomMeta['transform'].value),
        is_numeric: tempAtomMeta['numberTopic'].value,
        comment: new Date().toLocaleString()
      }

      ApiConnect({
        name: 'updateDate',
        url: urlConfiguration + '/api/mapping/topic_stream_parameter/topic/',
        objToUpdate: objToGo,
        isLogin: isLogin,
        clickHandle: handleClickVariant
      })
    }

    setMeta(tempMeta)
    setAtomMeta(tempAtomMeta)
    setOpenAddTopic(true)
  }

  // Создание нового топика
  const doAddEssence = () => {
    setBlock1({ dis: false, open: true })
    setBlock2({ dis: false, open: false })
    setBlock3({ dis: false, open: false })
    setBlock12({ dis: false, open: false })
    setBlock22({ dis: false, open: false })

    // setBlock1({dis: true, open: true})
    // setBlock2({dis: true, open: false})
    // setBlock3({dis: false, open: true})
    ApiConnect({
      name: 'getData',
      url:
        urlConfiguration +
        // '/api/mapping/topic_stream_parameter/tree/essence_parameters_empty/?essence=' + activeEssence.id,
        '/api/mapping/topic_stream_parameter/stream/empty/list/',
      setterEssence: setEmptyStream,
      setterLoading: null,
      isLogin: isLogin
    })

    ApiConnect({
      name: 'getData',
      url:
        urlConfiguration +
        // '/api/mapping/topic_stream_parameter/tree/essence_parameters_empty/?essence=' + activeEssence.id,
        '/api/mapping/topic_stream_parameter/tree/client_object/',
      setterEssence: setObject,
      setterLoading: null,
      isLogin: isLogin,
      clickHandle: handleClickVariant
    })

    let tempAtomMeta = {}
    let tempMeta = meta
    tempMeta.map(row => {
      row.map(col => {
        tempAtomMeta[col.name] = {}
        tempAtomMeta[col.name].value = col.default
        tempAtomMeta[col.name].label = col.label
        tempAtomMeta[col.name].dis = col?.dis ? col.dis : false

        if (col.name === 'sensor') col.options = struct[0].children
        if (col.name === 'controller')
          tempAtomMeta[col.name].value = struct[0].sn
      })
    })
    Object.keys(temp).map(name => {
      tempAtomMeta[name] = {}
      tempAtomMeta[name].value = temp[name].default
      tempAtomMeta[name].name = temp[name].name
      tempAtomMeta[name].label = temp[name].label
    })
    if (activeEssence?.type === 'virtual-controller') {
      tempAtomMeta['client'].value = '/'
      tempAtomMeta['client'].dis = false
      tempAtomMeta['client'].label = 'Топик'
    }
    tempAtomMeta['toGo'] = () => {
      let objToGo = {}
      objToGo.topic = {
        name:
          activeEssence?.type !== 'virtual-controller'
            ? tempAtomMeta['client'].value +
              '/' +
              tempAtomMeta['controller'].value +
              tempAtomMeta['devices'].value +
              '/' +
              tempAtomMeta['sensor'].value +
              tempAtomMeta['controls'].value +
              tempAtomMeta['user'].value
            : tempAtomMeta['client'].value,
        is_subscribe: tempAtomMeta['subscribeTopic'].value,
        tc: Number(tempAtomMeta['transform'].value),
        is_numeric: tempAtomMeta['numberTopic'].value,
        controller: struct[0].id
      }
      objToGo.stream = {
        is_create: tempAtomMeta['linkTopicToStream'].value,
        is_new: tempAtomMeta['sourceStream'].value == 2 ? false : true,
        id:
          tempAtomMeta['sourceStream'].value == 2
            ? tempAtomMeta['stream'].value
            : '',
        name: tempAtomMeta['stream'].value,
        description: tempAtomMeta['descriptionStream'].value
      }
      objToGo.parameter = {
        is_create: tempAtomMeta['linkStreamToParameter'].value,
        essence: tempAtomMeta['essence'].value,
        parameter: tempAtomMeta['parameter'].value
      }
      ApiConnect({
        name: 'createData',
        url: urlConfiguration + '/api/mapping/topic_stream_parameter/create/',
        objToCreate: objToGo,
        isLogin: isLogin,
        clickHandle: handleClickVariant
      })
      // setStruct(null)
      setStructLoader(false)
      setTimeout(() => {
        ApiConnect({
          name: 'getData',
          url:
            urlConfiguration +
            '/api/mapping/topic_stream_parameter/tree/?controller=' +
            activeEssence.id,
          setterEssence: setStruct,
          setterLoading: setStructLoader,
          isLogin: isLogin
        })
      }, 500)
    }
    setMeta(tempMeta)
    setAtomMeta(tempAtomMeta)
    setOpenAddTopic(true)
  }

  const doUpdate = () => {
    setInfoTopicLoading(false)
    setInfoTopic(null)

    setTimeout(() => {
      ApiConnect({
        name: 'getDataPost',
        url: urlConfiguration + '/api/mapping/topic_stream_parameter/info/',
        objToGet: { topic: activeTopic.name },
        setterEssence: setInfoTopic,
        setterLoading: setInfoTopicLoading,
        isLogin: isLogin
      })
    }, 550)
  }
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return (
    <React.Fragment>
      {toGoMassiv ? (
        <Context.Provider
          value={{
            doClickActiveEssence,
            doGoPositionScroll,
            doClickMetaSelect,
            handleChangeFFTextFields
          }}
        >
          {/* {'  --------------------------------------------------  '} */}
          {/* {'                                                      '} */}
          {/* {'       Диалог для создания нового топика п. 12        '} */}
          {/* {'                                                      '} */}
          {/* {'  --------------------------------------------------  '} */}
          {struct && struct.length && atomMeta ? (
            <Dialog
              open={openAddTopic}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
              maxWidth={'md'}
            >
              <DialogTitle id='alert-dialog-title'>
                {atomMeta['title'].label}
              </DialogTitle>
              <DialogContent>
                <ExpansionPanel expanded={block1.open} disabled={block1.dis}>
                  <ExpansionPanelSummary
                    className={
                      block1.open
                        ? classes.panelHeaderOpen
                        : classes.panelHeader
                    }
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <FFCheckBox
                      value={block1.open}
                      label={atomMeta['createTopic'].label}
                      id={atomMeta['createTopic'].name}
                      name={atomMeta['createTopic'].name}
                    />
                    {block1.open ? (
                      <Tooltip title='Проверить на уникальность'>
                        <IconButton
                          aria-label='filter'
                          onClick={e =>
                            ApiConnect({
                              name: 'createData',
                              url:
                                urlConfiguration +
                                '/api/mapping/topic_stream_parameter/check_unique/topic/',
                              objToCreate: {
                                topic:
                                  activeEssence?.type !== 'virtual-controller'
                                    ? atomMeta['client'].value +
                                      '/' +
                                      atomMeta['controller'].value +
                                      atomMeta['devices'].value +
                                      '/' +
                                      atomMeta['sensor'].value +
                                      atomMeta['controls'].value +
                                      atomMeta['user'].value
                                    : atomMeta['client'].value
                              },
                              // setterEssence: setPanels,
                              // setterLoading: null,
                              clickHandle: handleClickVariant,
                              isLogin: isLogin
                            })
                          }
                          className={classes.margin}
                        >
                          <ThumbsUpDownIcon color='primary' fontSize='small' />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid
                      container
                      xs={12}
                      className={block1.dis ? classes.disabled : null}
                    >
                      {meta.map((row, indexRow) => {
                        return (
                          <Grid
                            container
                            xs={12}
                            className={
                              block12.dis && indexRow === 0
                                ? classes.disabled
                                : null
                            }
                          >
                            {row.map(col => {
                              return (
                                <Grid
                                  item
                                  xs={
                                    activeEssence?.type ===
                                      'virtual-controller' &&
                                    col.name === 'client'
                                      ? 12
                                      : col.width
                                  }
                                  style={{ alignSelf: 'center' }}
                                  key={
                                    col.name +
                                    atomMeta[col.name].value +
                                    atomMeta[col.name].dis
                                  }
                                >
                                  {col.type === 'textBox' &&
                                  (activeEssence?.type !==
                                    'virtual-controller' ||
                                    (activeEssence?.type ===
                                      'virtual-controller' &&
                                      col.name === 'client') ||
                                    col.name === 'transform') ? (
                                    <FFTextFields
                                      value={atomMeta[col.name].value}
                                      label={col.label}
                                      dis={atomMeta[col.name].dis}
                                      parameter={{ id: col.name }}
                                      name={col.name}
                                    />
                                  ) : col.type === 'checkBox' ? (
                                    // activeEssence?.type !== 'virtual-controller'
                                    <FFCheckBox
                                      value={atomMeta[col.name].value}
                                      label={col.label}
                                      icon={
                                        col.name === 'subscribeTopic' ? (
                                          <NotificationsNoneIcon />
                                        ) : null
                                      }
                                      checkedIcon={
                                        col.name === 'subscribeTopic' ? (
                                          <NotificationsActiveIcon />
                                        ) : null
                                      }
                                      id={col.name}
                                      name={col.name}
                                    />
                                  ) : col.type === 'selectBox' &&
                                    activeEssence?.type !==
                                      'virtual-controller' ? (
                                    <FFSelect
                                      id={col.name}
                                      value={atomMeta[col.name].value}
                                      options={col.options}
                                      name={col.name}
                                      label={col.label}
                                    />
                                  ) : null}
                                </Grid>
                              )
                            })}
                          </Grid>
                        )
                      })}
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={block2.open} disabled={block2.dis}>
                  <ExpansionPanelSummary
                    className={
                      block2.open
                        ? classes.panelHeaderOpen
                        : classes.panelHeader
                    }
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel2a-content'
                    id='panel2a-header'
                  >
                    <FFCheckBox
                      value={block2.open}
                      label={atomMeta['linkTopicToStream'].label}
                      id={atomMeta['linkTopicToStream'].name}
                      name={atomMeta['linkTopicToStream'].name}
                    />
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid
                      container
                      xs={12}
                      className={block2.dis ? classes.disabled : null}
                    >
                      <Grid
                        item
                        xs={12}
                        className={block22.dis ? classes.disabled : null}
                        ref={refFFSwitchN}
                      >
                        <FFSwitch_N
                          id={atomMeta['sourceStream'].name}
                          name={atomMeta['sourceStream'].name}
                          meta={{
                            // label: atomMeta.labelValue,
                            label: [
                              'Сгенерировать',
                              'Задать вручную',
                              'Выбрать существующий'
                            ],
                            // width: parseInt((currentWidth - 4) / atomMeta.label.length),
                            width: refFFSwitchN?.current?.offsetWidth
                              ? parseInt(
                                  (refFFSwitchN?.current?.offsetWidth - 4) / 3
                                )
                              : parseInt((600 - 4) / 3),
                            value: atomMeta['sourceStream'].value
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        key={te1 + 'SourceStream'}
                        className={block22.dis ? classes.disabled : null}
                      >
                        {atomMeta['sourceStream'].value != 2 ? (
                          <FFTextFields
                            value={atomMeta['stream'].value}
                            label={atomMeta['stream'].label}
                            dis={false}
                            parameter={{ id: atomMeta['stream'].name }}
                            name={atomMeta['stream'].name}
                          />
                        ) : (
                          <Context.Provider value={{ doChangeSelect }}>
                            <FFSelectTree
                              stateMeta={{ open: false, active: -1, label: '' }}
                              idCls={atomMeta['stream'].name}
                              name={atomMeta['stream'].name}
                              selectValue={atomMeta['stream'].value}
                              selectMetaLabel={atomMeta['stream'].label}
                              selectNodes={emptyStream ? emptyStream : []}
                            />
                          </Context.Provider>

                          // <FFSelect
                          //   value={atomMeta['stream'].value}
                          //   label={atomMeta['stream'].label}
                          //   options={atomMeta['stream'].options}
                          //   name={atomMeta['stream'].name}
                          //   id={atomMeta['stream'].name}
                          // />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <FFTextFields
                          value={atomMeta['descriptionStream'].value}
                          label={atomMeta['descriptionStream'].label}
                          dis={false}
                          parameter={{
                            id: atomMeta['descriptionStream'].name
                          }}
                          name={atomMeta['descriptionStream'].name}
                        />
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel expanded={block3.open} disabled={block3.dis}>
                  <ExpansionPanelSummary
                    className={
                      block3.open
                        ? classes.panelHeaderOpen
                        : classes.panelHeader
                    }
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel3a-content'
                    id='panel3a-header'
                  >
                    <FFCheckBox
                      value={block3.open}
                      label={atomMeta['linkStreamToParameter'].label}
                      id={atomMeta['linkStreamToParameter'].name}
                      name={atomMeta['linkStreamToParameter'].name}
                    />
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid
                      container
                      xs={12}
                      className={block3.dis ? classes.disabled : null}
                    >
                      <Context.Provider value={{ doChangeSelect }}>
                        <Grid item xs={12}>
                          <FFSelectTree
                            stateMeta={{ open: false, active: -1, label: '' }}
                            idCls={atomMeta['object'].name}
                            name={atomMeta['object'].name}
                            selectValue={atomMeta['object'].value}
                            selectMetaLabel={atomMeta['object'].label}
                            selectNodes={object ? object : []}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          key={
                            (ess?.children ? ess.children : []).length +
                            'FFSelectTreeEssence'
                          }
                        >
                          <FFSelectTree
                            stateMeta={{ open: false, active: -1, label: '' }}
                            idCls={atomMeta['essence'].name}
                            name={atomMeta['essence'].name}
                            selectValue={atomMeta['essence'].value}
                            selectMetaLabel={atomMeta['essence'].label}
                            selectNodes={ess?.children ? ess.children : []}
                          />
                        </Grid>
                      </Context.Provider>

                      <Grid
                        item
                        xs={12}
                        key={
                          (parameter ? parameter : []).length +
                          'FFSelectTreeParameter'
                        }
                      >
                        <FFSelect
                          value={atomMeta['parameter'].value}
                          label={atomMeta['parameter'].label}
                          options={parameter ? parameter : []}
                          name={atomMeta['parameter'].name}
                          id={atomMeta['parameter'].name}
                        />
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={e => {
                    if (atomMeta?.toGo) {
                      atomMeta.toGo()
                    }
                    setOpenAddTopic(false)
                  }}
                  color='primary'
                  autoFocus
                >
                  {'Применить'}
                </Button>
                <Button onClick={e => setOpenAddTopic(false)} color='primary'>
                  {'Отменить'}
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}

          <Grid container>
            <Grid item xs={8} align='left'>
              <Tooltip title='Отобразить дерево'>
                <IconButton
                  aria-label='filter'
                  onClick={e => setOpenTree(true)}
                  className={classes.margin}
                >
                  <IconAccountTree color='primary' fontSize='large' />
                </IconButton>
              </Tooltip>
              {activeEssence ? (
                <Chip
                  color={'default'}
                  label={activeEssence.label}
                  onDelete={e => setActiveEssence(null)}
                />
              ) : null}
              {infoTopic ? (
                <Chip
                  style={{ marginLeft: defTheme.spacing(1) }}
                  icon={
                    <NotificationsActiveIcon
                      style={{
                        color: infoTopic.is_subscribe
                          ? defTheme.palette.error.main
                          : ''
                      }}
                    />
                  }
                  label={infoTopic?.label}
                />
              ) : null}
            </Grid>
            <Grid item xs={4} align='right'>
              {true ? (
                <Tooltip title='Массовое редактирование типиков'>
                  <IconButton
                    aria-label='filter'
                    onClick={e => setToGoMassiv(!toGoMassiv)}
                    className={classes.margin}
                  >
                    <IconDynamicFeed color='primary' fontSize='large' />
                  </IconButton>
                </Tooltip>
              ) : null}
              {activeEssence ? (
                <Tooltip title='Создать новый топик'>
                  <IconButton
                    aria-label='filter'
                    onClick={e => doAddEssence()}
                    className={classes.margin}
                  >
                    <IconAdd color='primary' fontSize='large' />
                  </IconButton>
                </Tooltip>
              ) : null}
              {activeTopic ? (
                <Tooltip title='Редактировать выбранный топик'>
                  <IconButton
                    aria-label='filter'
                    onClick={e => doEditTopic()}
                    className={classes.margin}
                  >
                    <IconSettings color='primary' fontSize='large' />
                  </IconButton>
                </Tooltip>
              ) : null}
              {activeTopic ? (
                <Tooltip title='Удалить выбранный топик'>
                  <IconButton
                    aria-label='filter'
                    onClick={e => doDeleteTopic()}
                    // className={classes.margin}
                    className={classes.margin}
                  >
                    <IconDelete color='error' fontSize='large' />
                  </IconButton>
                </Tooltip>
              ) : null}
              {activeDevice ? (
                <Tooltip title='Удалить топики для выбранного устройства'>
                  <IconButton
                    aria-label='filter'
                    onClick={e => doDeleteTopicMassive()}
                    // className={classes.margin}
                    className={classes.margin}
                  >
                    <IconDelete color='error' fontSize='large' />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Grid>
          </Grid>
          <Drawer
            anchor={'left'}
            open={openTree}
            onClose={e => setOpenTree(false)}
          >
            <Paper
              elevation={3}
              id={'bodyTree_paper'}
              className={classes.drawer}
            >
              <FFLoaderComponent
                key={'treeEssence'}
                loading={essenceLoading}
                isLoader={<Loader />}
                component={
                  <FFTreeEssence
                    essence={essence}
                    type={'essence'}
                    doubleClick={1}
                    activeList={activeEssence ? [activeEssence] : []}
                    active={activeEssence?.id}
                  />
                }
              />
            </Paper>
          </Drawer>
          <FFContainerColumn
            components={[
              struct
                ? {
                    width: 3,
                    component: (
                      <FFLoaderComponent
                        key={'treeEssence' + structLoader}
                        loading={structLoader}
                        isLoader={<Loader />}
                        component={
                          <FFTreeEssence
                            essence={struct}
                            type={'device'}
                            doubleClick={1}
                            activeList={[]}
                            active={activeTopic?.id}
                          />
                        }
                      />
                    )
                  }
                : {},
              {
                width: struct ? 9 : 12,
                component: (
                  <FFLoaderComponent
                    key={'Panels&Active' + activeEssence?.id + activeTopic?.id}
                    loading={activeEssence?.id}
                    isLoader={
                      <Typography
                        variant='h1'
                        component='h2'
                        style={{ color: 'lightgray', marginTop: '20%' }}
                      >
                        Выберите контроллер
                      </Typography>
                    }
                    component={
                      <FFLoaderComponent
                        key={'Panels&Active' + activeTopic?.id}
                        loading={activeTopic}
                        isLoader={
                          <Typography
                            variant='h1'
                            component='h2'
                            style={{ color: 'lightgray', marginTop: '20%' }}
                          >
                            Выберите топик
                          </Typography>
                        }
                        component={
                          <FFLoaderComponent
                            key={
                              'Panels&Active' +
                              activeTopic?.id +
                              infoTopicLoading
                            }
                            loading={infoTopicLoading && infoTopic}
                            isLoader={<Loader />}
                            component={
                              <FF_InfoTopic
                                essence={infoTopic}
                                doAddStream={() => doAddStream()}
                                doEditStream={stream => doEditStream(stream)}
                                doAddParameter={stream =>
                                  doAddParameter(stream)
                                }
                                isLogin={isLogin}
                                handleClickVariant={handleClickVariant}
                                doUpdate={doUpdate}
                              />
                            }
                          />
                        }
                      />
                    }
                  />
                )
              }
            ]}
          />
        </Context.Provider>
      ) : (
        <FF_MappingDeviceArray toGoBack={() => setToGoMassiv(!toGoMassiv)} />
      )}
    </React.Fragment>
  )
}

const FF_InfoTopic = props => {
  const classes = useStyles()

  const [essence, setEssence] = useState(props.essence)
  const [openArchive, setOpenArchive] = useState(-1)
  const [comment, setComment] = useState('')

  const [openDelete, setOpenDelete] = useState(-1)
  const [connector, setConnector] = useState(null)

  const handleChangeFFTextFields = (value, id, name, type) => {
    if (type === 'toogle') {
      setOpenArchive(value === 1 ? Number(id.replace('toggle_', '')) : -2)
    } else if (name === 'comment') {
      setComment(value)
    }
    return ''
  }

  const switchArchiveAgree = () => {
    let temp = essence
    temp.streams[openArchive].archive.is_archive = !temp.streams[openArchive]
      .archive.is_archive

    ApiConnect({
      name: 'updateDate',
      url:
        urlConfiguration +
        '/api/mapping/topic_stream_parameter/topic_stream/archive/',
      objToUpdate: {
        id: essence['streams'][openArchive].id,
        comment: comment
      },
      isLogin: props.isLogin,
      clickHandle: props.handleClickVariant
    })
    setEssence(temp)
    setOpenArchive(-1)
    props.doUpdate()
  }

  const doDeleteLinkStreamToParameter = con => {
    setConnector(con)
    setOpenDelete(1)
  }

  return (
    <Context.Provider value={{ handleChangeFFTextFields }}>
      <FFDialog
        key={openArchive + 'fds'}
        title={'Предупреждение'}
        open={openArchive < 0 ? false : true}
        setOpen={val => {
          setOpenArchive(val)
          setComment('')
        }}
        disagree={() => {
          setOpenArchive(-1)
          setComment('')
        }}
        agree={switchArchiveAgree}
        component={
          <Grid item container xs={12}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              {`Вы уверены, что хотите архивировать пару ${essence?.label} и ${essence?.streams[openArchive]?.label}? Данное действие необратимо. Данные по этому стриму перестанут поступать в базу Портала.`}
            </Grid>
            <Grid item xs={12}>
              <FFTextFields
                value={comment}
                label={'Введите комментарий'}
                dis={false}
                parameter={{ id: 'comment' }}
                name={'comment'}
              />
            </Grid>
          </Grid>
        }
      />
      <FFDialog
        key={openDelete + 'fds'}
        title={'Предупреждение'}
        open={openDelete < 0 ? false : true}
        setOpen={val => {
          setOpenDelete(val)
          setConnector(null)
        }}
        disagree={() => {
          setOpenDelete(-1)
          setConnector(null)
        }}
        agree={() => {
          ApiConnect({
            name: 'deleteDate',
            url:
              urlConfiguration +
              '/api/mapping/topic_stream_parameter/stream_parameter_connection/?essence=' +
              connector['essence'].id +
              '&parameter=' +
              connector['parameter'].id,
            objToUpdate: null,
            isLogin: props.isLogin,
            clickHandle: props.handleClickVariant
          })
          props.doUpdate()
          setOpenDelete(-1)
          setConnector(null)
        }}
        component={
          <Grid item container xs={12}>
            {`Вы уверены, что хотите удалить привязку`}
          </Grid>
        }
      />
      <Grid item xs={12} container style={{ overflowY: 'auto' }}>
        <Grid item xs={12}>
          <Table
            stickyHeader
            size='small'
            aria-label='a dense table'
            className={classes.tableCell}
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                  Архивный
                </TableCell>
                <TableCell className={classes.tableCell}>Стрим</TableCell>
                <TableCell className={classes.tableCell}>Параметр</TableCell>
                <TableCell className={classes.tableCell}>Сущность</TableCell>
                <TableCell className={classes.tableCell}>Объект</TableCell>
                <TableCell className={classes.tableCell}>Клиент</TableCell>
                <TableCell className={classes.tableCell} style={{ padding: 0 }}>
                  Действие
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={'new'}>
                <TableCell
                  colSpan={6}
                  align='right'
                  className={classes.tableCell}
                >
                  <Typography
                    variant='h6'
                    component='h6'
                    style={{ color: 'lightgray' }}
                  >
                    {'Добавить привязку стрима к топику'}
                  </Typography>
                </TableCell>

                <TableCell className={classes.tableCell}>
                  <Tooltip title='Добавить привязку'>
                    <IconButton
                      aria-label='filter'
                      onClick={e => props.doAddStream()}
                      // className={classes.margin}
                      style={{ padding: 0 }}
                    >
                      <IconAdd color='primary' fontSize='large' />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
              {essence.streams.map((stream, indexStream) => {
                return (
                  <>
                    <TableRow
                      className={classes.tableCell}
                      key={stream.id + indexStream + 'head+add'}
                    >
                      <TableCell
                        rowSpan={stream.connections.length + 1}
                        className={classes.tableCell}
                        style={{ verticalAlign: 'top' }}
                      >
                        <FFToggle
                          key={'toogle' + openArchive}
                          id={'toggle_' + indexStream}
                          name={'toggle_' + indexStream}
                          value={stream.archive.is_archive}
                          label={''}
                        />{' '}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ verticalAlign: 'top' }}
                        rowSpan={stream.connections.length + 1}
                        align='right'
                      >
                        <Tooltip title={stream.label}>
                          <Typography
                            component={'p'}
                            variant='body2'
                            className={classes.labelText}
                          >
                            {stream.label}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        colSpan={stream.archive.is_archive === false ? 4 : 5}
                        align='right'
                        className={classes.tableCell}
                      >
                        <Typography
                          variant='h6'
                          component='h6'
                          style={{ color: 'lightgray' }}
                        >
                          {stream.archive.is_archive === false
                            ? 'Редактировать стрим / Добавить привязку стрима к параметру'
                            : 'Стрим заархивирован, ' +
                              (stream?.archive?.comment_archive.length > 0
                                ? 'комментарий: ' +
                                  stream?.archive?.comment_archive
                                : 'нет комментариев')}
                        </Typography>
                      </TableCell>
                      {stream.archive.is_archive === false ? (
                        <TableCell
                          className={classes.tableCell}
                          // style={{
                          //   display:
                          //     stream.archive.is_archive === false
                          //       ? 'contents'
                          //       : ''
                          // }}
                        >
                          {stream.archive.is_archive === false ? (
                            <>
                              <Tooltip title='Редактировать стрим'>
                                <IconButton
                                  aria-label='filter'
                                  onClick={() => props.doEditStream(stream)}
                                  style={{
                                    padding: 0,
                                    marginRight: 4,
                                    marginTop: 4
                                  }}
                                >
                                  <IconSettings
                                    color='primary'
                                    fontSize='large'
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title='Добавить привязку'>
                                <IconButton
                                  aria-label='filter'
                                  onClick={() => props.doAddParameter(stream)}
                                  style={{ padding: 0, marginTop: 4 }}
                                >
                                  <IconAdd color='primary' fontSize='large' />
                                </IconButton>
                              </Tooltip>{' '}
                            </>
                          ) : null}
                        </TableCell>
                      ) : null}
                    </TableRow>

                    {stream.connections.map((connect, indexConnect) => {
                      return (
                        <>
                          <TableRow
                            key={stream.id + indexConnect + indexStream}
                          >
                            <TableCell
                              align='right'
                              className={classes.tableCell}
                            >
                              <Tooltip title={connect.parameter.label}>
                                <Typography
                                  component={'p'}
                                  variant='body2'
                                  className={classes.labelText}
                                >
                                  {connect.parameter.label}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              align='right'
                              className={classes.tableCell}
                            >
                              <Tooltip title={connect.essence.label}>
                                <Typography
                                  component={'p'}
                                  variant='body2'
                                  className={classes.labelText}
                                >
                                  {connect.essence.label}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              align='right'
                              className={classes.tableCell}
                            >
                              <Tooltip title={connect.object.label}>
                                <Typography
                                  component={'p'}
                                  variant='body2'
                                  className={classes.labelText}
                                >
                                  {connect.object.label}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              align='right'
                              className={classes.tableCell}
                            >
                              <Tooltip title={connect.client.label}>
                                <Typography
                                  component={'p'}
                                  variant='body2'
                                  className={classes.labelText}
                                >
                                  {connect.client.label}
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Tooltip title='Удалить привязку'>
                                <IconButton
                                  aria-label='filter'
                                  onClick={e =>
                                    doDeleteLinkStreamToParameter(connect)
                                  }
                                  // className={classes.margin}
                                  style={{ padding: 0 }}
                                >
                                  <IconDelete color='error' fontSize='large' />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    })}
                  </>
                )
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Context.Provider>
  )
}

const FFDialog = props => {
  const [open, setOpen] = useState(props.open)

  const handleClose = () => {
    props.setOpen(-1)
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
        <DialogContent>{props.component}</DialogContent>
        <DialogActions>
          <Button onClick={e => props.agree()} color='primary' autoFocus>
            {'Применить'}
          </Button>
          <Button onClick={e => props.disagree()} color='primary'>
            {'Отменить'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
