import React, { useState, useContext, useEffect, useRef } from 'react'
import Context from '../context'
import { makeStyles } from '@material-ui/core/styles'

import FFTreeItem from './FFTreeItem'
import TreeView from '@material-ui/lab/TreeView'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import TreeNodeUtils from 'tree-node-utils'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'

import IconMoreVert from '@material-ui/icons/MoreVert'

import FFTextField from './FFTextField'

const useStyles = makeStyles(theme => ({
  gridTree: {
    overflow: 'auto',
    overflowX: 'hidden',
    margin: theme.spacing(1)
  },
  divSearch: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flexGrow: 1
  },
  divTools: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch'
  }
}))

export default function FF_Tree_essence (props) {
  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // -------------------------------------------------------------------------------

  const {
    doClickActiveEssence,
    doClickActiveEssenceDialog,
    doGoPositionScroll,
    doClickMetaSelect
  } = useContext(Context)
  const [active, setActive] = useState(props?.active ? props?.active : '')
  const [search, setSearch] = useState('')
  const myRef = useRef(null)
  const config = {
    childrenField: 'children',
    keyField: 'id'
  }

  const treeUtils = new TreeNodeUtils(config)

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  // Клик на меню
  const doClickTreeItem = obj => {
    if (props.dialog !== true) {
      setActive(obj.id)
      if (!props?.doubleClick) {
        doClickActiveEssence(obj.id, obj, props?.type)
      }
    } else if (props.dialog === true) {
      setActive(obj.id)
      doClickActiveEssenceDialog(obj)
    }
  }

  const doubleClick = (e, obj) => {
    // console.log(obj, 'double')
    // console.log(myRef)
    if (props?.doubleClick) {
      doGoPositionScroll(myRef?.current?.scrollTop)
      doClickActiveEssence(obj.id, obj, props?.type)
    }
    e.stopPropagation()
  }

  const changeValue = (value, id, name, type) => {
    setSearch(value)
  }

  // -------------------------------------------------------------------------------
  //
  //                            Рекурсивное построение дерева
  //
  // -------------------------------------------------------------------------------

  const recurTreeView = (obj, depth, index) => {
    let temp_sort_label =
      'children' in obj && obj.children.length > 0
        ? obj.children.map(obj => obj.label)
        : []
    temp_sort_label.sort()

    return (
      <FFTreeItem
        nodeId={obj?.node_id ? obj.id + obj?.node_id : obj.id}
        value={obj?.node_id ? obj.id + obj?.node_id : obj.id}
        onDoubleClick={e => doubleClick(e, obj)}
        onClick={e => doClickTreeItem(obj)}
        onKeyDown={e => (e.key === 'Enter' ? doClickTreeItem(obj) : null)}
        labelText={obj[select]}
        key={
          'FFTreeEssence_FFTreeItem_tree_' +
          (obj?.node_id ? obj.id + obj?.node_id : obj.id) +
          (props.dialog ? '_Dialog' : '')
        }
        id={
          'FFTreeEssence_FFTreeItem_tree_' +
          (obj?.node_id ? obj.id + obj?.node_id : obj.id)
        }
        badge={'badge' in obj ? obj.badge : 0}
        obj={obj}
      >
        {temp_sort_label.map(name_label => {
          let obj_child = obj.children.filter(obj => obj.label === name_label)
          if (obj_child.length > 0) {
            return recurTreeView(obj_child[0], depth + 1, index)
          }
        })}
      </FFTreeItem>
    )
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const [select, setSelect] = useState(
    props?.metaSelect ? props.metaSelect : 'label'
  )
  const options = [
    { key: 'name', label: 'Системное наименование' },
    { key: 'label', label: 'Наименование' }
  ]

  const handleClose = e => {
    setAnchorEl(null)
  }

  const onClickMenu = e => {
    let temp = e.target.textContent
    setSelect(options.filter(obj => obj.label === temp)[0].key)
    doClickMetaSelect(options.filter(obj => obj.label === temp)[0].key)
    setAnchorEl(null)
  }
  const recurExpNodes = (branch, tempNode) => {
    if (branch.length) {
      tempNode['node'] = [
        ...tempNode['node'],
        branch[0]?.node_id ? branch[0].id + branch[0]?.node_id : branch[0].id
      ]
    }

    branch.map(obj => {
      if (obj.children) {
        recurExpNodes(obj.children, tempNode)
      }
    })
  }

  useEffect(() => {
    if (props.position && myRef?.current?.scrollTop) {
      myRef.current.scrollTop = props.position
    }
  }, [])

  const expNo = () => {
    let temp = { node: [] }
    if (props.active || props?.activeList) {
      recurExpNodes(
        treeUtils.filterNodes(props.essence, obj => obj.id === props.active),
        temp
      )
    }
    if (props?.activeList) {
      props.activeList.map(objActive => {
        recurExpNodes(
          treeUtils.filterNodes(props.essence, obj => obj.id === objActive.id),
          temp
        )
      })
    }
    console.log(props.activeList, temp);
    return temp['node']
  }
  // }, [])
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return (
    <React.Fragment>
      <div className={classes.divTools}>
        <div className={classes.divSearch}>
          <Context.Provider value={{ changeValue }}>
            <FFTextField
              id={'searchTree'}
              name={'search'}
              value={search}
              label={'Полнотекстовый поиск'}
            />
          </Context.Provider>
        </div>
        <div className={classes.divTools}>
          <IconButton
            onClick={handleClick}
          >
            <IconMoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            {options.map(option => (
              <MenuItem
                key={option.key}
                selected={option.key === select}
                onClick={e => onClickMenu(e)}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      <Grid className={classes.gridTree} item xs={12} id={'Grid_tree_essence'}>
        <TreeView
          defaultExpanded={expNo()}
          key={
            'FFTreeEssence_TreeView_treeEssence' +
            (props.dialog ? '_Dialog' : '')
          }
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={null}
        >
          {treeUtils
            .filterNodes(
              props.essence,
              obj =>
                (obj.label &&
                  obj.label.toUpperCase().indexOf(search.toUpperCase()) !==
                    -1) ||
                (obj.name &&
                  obj.name.toUpperCase().indexOf(search.toUpperCase()) !== -1)
            )
            .map((obj, index) => {
              return recurTreeView(obj, 0, index)
            })}
        </TreeView>
      </Grid>
    </React.Fragment>
  )
}
