import React, { useEffect, useState, useContext } from 'react'

import { Grid } from '@material-ui/core/'
import { makeStyles, withStyles } from '@material-ui/core/styles'

/*
 **-------------------- My Components ---------------------------
 */

import FFTextField from '../../../libft/FFTextField'
import FFActionButton from '../../../libft/FFActionButton'
import FFSelect from '../../../libft/FFSelect'
import FFLoader from '../../../libft/FFLoader'
import FFLoaderComponent from '../../../libft/FFLoaderComponent'

import Context from '../../../context'
import { Alert, AlertTitle } from '@material-ui/lab'
const uuidv4 = require('uuid/v4')

export default function FFEditAlgorithmFormula ({
  deviceScenario,
  devicesScenario,
  parameters,
  ...props
}) {
  const [valueParameterPower, setValueParameterPower] = useState('')
  const [tempFormula, setTempFormula] = useState(null)
  const [currentDevice, setCurrentDevice] = useState([])
  const [te, setTe] = useState(0)
  const [te3, setTe3] = useState(0)

  const { toGoSaveAlgorithm } = useContext(Context)

  useEffect(() => {
    if (parameters) {
      let tempPower = parameters.filter(obj => obj.id === deviceScenario.device)
      if (tempPower.length > 0) {
        let value = tempPower[0].parameter_values.filter(
          obj => obj.label.indexOf('Базовое потребление мощности') > -1
        )
        if (value.length) {
          setValueParameterPower(value[0].values.value)
        }
      }
    }
  }, [parameters])

  useEffect(() => {
    if (te) {
      let temp = tempFormula
      currentDevice.map((obj, index) => {
        if (obj && obj != '"":""') {
          temp = temp
            ? temp.replace(
                new RegExp(String.fromCharCode(65 + index - 1), 'g'),
                obj
              )
            : ''
        }
      })
      deviceScenario.formula = temp
      toGoSaveAlgorithm(deviceScenario)
    }
  }, [te])

  useEffect(() => {
    let formula = deviceScenario.formula
    let param = Array.from(
      new Set(
        deviceScenario.formula
          ? deviceScenario.formula.split('[').map(obj => {
              if (obj.split(']').length > 1) {
                return obj.split(']')[0]
              }
            })
          : [null]
      )
    )

    param.map((obj, index) => {
      if (obj) {
        formula = formula.replace(
          new RegExp(obj, 'g'),
          String.fromCharCode(65 + index - 1)
        )
      }
    })
    setTempFormula(formula)
    setTe3(te3 + 1)
    setTe(te + 1)
    param.push('"":""')
    setCurrentDevice(param)
  }, [])

  const deleteOperand = index => {
    let temp = tempFormula
    temp = temp.replace(
      new RegExp('[' + String.fromCharCode(65 + index - 1) + ']', 'g'),
      ''
    )
    temp = temp.replace(/\[\]/gi, '')
    let tempOperand = currentDevice
    tempOperand.splice(index, 1)
    if (tempOperand[tempOperand.length - 1] !== '"":""') {
      tempOperand.push('"":""')
    }
    setTe(te + 1)
    setTempFormula(temp)
    setCurrentDevice(tempOperand)
  }

  const changeValue = (value, id, name, element) => {
    if (name === 'parameters') {
      let tempDevice = currentDevice
      tempDevice[id] = `${value}:""`
      setCurrentDevice(tempDevice)
      setTe(te + 1)
    } else if (name === 'parameter') {
      let tempDevice = currentDevice
      tempDevice[id] = `${tempDevice[id].split(':')[0]}:${value}`
      if (tempDevice[tempDevice.length - 1] !== '"":""') {
        tempDevice.push('"":""')
        setTe(te + 1)
      }
      setCurrentDevice(tempDevice)
    } else if (name === 'formula') {
      setTempFormula(value)
      setTe(te + 1)
    }
    return ''
  }

  return (
    <Context.Provider value={{ changeValue }}>
      <FFLoaderComponent
        key={'FFLoaderComponent'}
        loading={parameters}
        isLoader={<FFLoader />}
        component={
          <Grid item xs={12} container>
            <Grid item xs={12} container direction={'column'}>
              <FFTextField
                value={deviceScenario.label}
                id={'deviceScenario.device'}
                name={'label'}
                label={'Наименование'}
                is_disabled={true}
              />
              <FFTextField
                value={'Формула'}
                id={'deviceScenario.algorithm'}
                name={'algorithm'}
                label={'Алгоритм'}
                is_disabled={true}
              />
              <FFTextField
                value={valueParameterPower}
                id={'valueParameterPower'}
                name={'valueParameterPower'}
                label={'Базовое потребление мощности'}
                is_disabled={true}
              />
              <FFTextField
                key={te3}
                value={tempFormula}
                id={'deviceScenario.formula'}
                name={'formula'}
                label={'Формула'}
              />
              <Alert severity='info' style={{ margin: 8 }}>
                <AlertTitle>Описание</AlertTitle>
                Функция — <strong> {'daylight(coord, date)'}</strong>: coord -
                параметр сущности характеризующий координаты сущности, date -
                формат описания даты, может быть {'{{date:today}}'} или{' '}
                {'{{date:yesterday}}'}
                <hr />
                <strong>Пример: </strong> 21.04+8.19*daylight([A],{' '}
                {'{{date:today}}'})-0.43*[B]
              </Alert>
            </Grid>
            <Grid item xs={12} container>
              {currentDevice.map((obj, index) => {
                if (obj && parameters) {
                  return (
                    <Grid item xs={12} container>
                      <Grid item xs={1} container direction={'column'}>
                        <FFTextField
                          value={String.fromCharCode(65 + index - 1)}
                          id={'operand_' + index}
                          name={'operand_' + index}
                          label={'#'}
                          is_disabled={true}
                        />
                      </Grid>
                      <Grid item xs={5} container direction={'column'}>
                        <FFSelect
                          value={obj.split(':')[0]}
                          id={index}
                          key={uuidv4()}
                          name={'parameters'}
                          label={'Сущность'}
                          options={parameters}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        container
                        direction={'column'}
                        style={{ overflow: 'hidden' }}
                      >
                        {console.log(
                          parameters?.filter(
                            ess => ess.id === obj.split(':')[0]
                          )[0]?.parameter_values
                        )}
                        <FFSelect
                          value={obj.split(':')[1]}
                          id={index}
                          key={uuidv4()}
                          name={'parameter'}
                          label={'Параметры'}
                          options={parameters
                            ?.filter(ess => ess.id === obj.split(':')[0])[0]
                            ?.parameter_values.sort((a, b) => {
                              if (a.label > b.label) return 1
                              return -1
                            })}
                        />
                      </Grid>
                      <Grid item xs={1} container direction={'column'}>
                        <FFActionButton
                          type={'delete'}
                          onClick={() => deleteOperand(index)} //goDelete}
                          tooltip={'Удалить операнд'}
                          color={'error'}
                        />
                      </Grid>
                    </Grid>
                  )
                }
              })}
            </Grid>
          </Grid>
        }
      />
    </Context.Provider>
  )
}
