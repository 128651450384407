

import React, {useState} from 'react'

import FF_Essence_parameterRouterValueList from './parameterRouter/FF_Essence_parameterRouterValueList'
import FF_Essence_parameterRouterValueRange from './parameterRouter/FF_Essence_parameterRouterValueRange'
import FF_Essence_parameterRouterIntervalRange from './parameterRouter/FF_Essence_parameterRouterIntervalRange'
import FF_Essence_parameterRouterList from './parameterRouter/FF_Essence_parameterRouterList'
import FF_Essence_parameterRouterConstant from './parameterRouter/FF_Essence_parameterRouterConstant'
import FF_Essence_parameterRouterDict from './parameterRouter/FF_Essence_parameterRouterDict'
import FF_Essence_parameterRouterComplex from './parameterRouter/FF_Essence_parameterRouterComplex'



export default function FFEssenceCardParameterRouter (props) {
	// -------------------------------------------------------------------------------
	//
	//                                   Стили
	//
	// -------------------------------------------------------------------------------

	// -------------------------------------------------------------------------------
	//
	//                                 Состояния
	//
	// ---------------------------- ---------------------------------------------------

	const [parameter, setParameter] = useState(props.parameter)

	// -------------------------------------------------------------------------------
	//
	//                                 Обработчики событий
	//
	// -------------------------------------------------------------------------------

	const getBack = data => {
	  let temp = parameter
	  temp.meta = temp.meta ? temp.meta : {}
	  temp.meta[data.name] = data.meta
	  temp.value = data.value
	  props.getBack(temp, props.parameterId)
	  setParameter(temp)
	}

	// -------------------------------------------------------------------------------
	//
	//                                    Рендеринг
	//
	// -------------------------------------------------------------------------------

	try {
	  if ('p-format-data-value-list' === parameter.format.value) {
		return (
		  <FF_Essence_parameterRouterValueList
			meta={
			  parameter.meta && parameter.meta['p-format-data-value-list']
				? parameter.meta['p-format-data-value-list']
				: null
			}
			type={'p-format-data-value-list'}
			label={parameter.label}
			type={
			  parameter.type && parameter.type.name ? parameter.type.name : null
			}
			source={
			  parameter.source && parameter.source ? parameter.source : null
			}
			name={parameter.name}
			id={props.parameterId}
			key={'PFormatDataValueList' + props.parameterId}
			value={parameter.value}
			getBack={getBack}
		  />
		)
	  } else if ('p-format-data-list' === parameter.format.value) {
		try {
		  return (
			<FF_Essence_parameterRouterList
			  meta={
				parameter.meta && parameter.meta['p-format-data-list']
				  ? parameter.meta['p-format-data-list']
				  : null
			  }
			  type={'p-format-data-list'}
			  label={parameter.label}
			  type={
				parameter.type && parameter.type.name ? parameter.type.name : null
			  }
			  source={
				parameter.source && parameter.source ? parameter.source : null
			  }
			  name={parameter.name}
			  id={props.parameterId}
			  key={'PFormatDataList' + props.parameterId}
			  value={parameter.value}
			  getBack={getBack}
			/>
		  )
		} catch (e) {
		  return <span>"{parameter.label}" - В этом параметре ошибка ... </span>
		}
	  } else if ('p-format-data-complex' === parameter.format.value) {
		return (
		  <FF_Essence_parameterRouterComplex
			meta={
			  parameter.meta && parameter.meta['p-format-data-complex']
				? parameter.meta['p-format-data-complex']
				: null
			}
			type={'p-format-data-complex'}
			label={parameter.label}
			type={
			  parameter.type && parameter.type.name ? parameter.type.name : null
			}
			source={
			  parameter.source && parameter.source ? parameter.source : null
			}
			name={parameter.name}
			id={props.parameterId}
			key={'PFormatDataComplex' + props.parameterId}
			value={parameter.value}
			getBack={getBack}
		  />
		)
	  } else if ('p-format-data-dict' === parameter.format.value) {
		return (
		  <FF_Essence_parameterRouterDict
			meta={
			  parameter.meta && parameter.meta['p-format-data-dict']
				? parameter.meta['p-format-data-dict']
				: null
			}
			type={'p-format-data-dict'}
			label={parameter.label}
			type={
			  parameter.type && parameter.type.name ? parameter.type.name : null
			}
			source={
			  parameter.source && parameter.source ? parameter.source : null
			}
			name={parameter.name}
			id={props.parameterId}
			key={'PFormatDataDict' + props.parameterId}
			value={parameter.value}
			getBack={getBack}
		  />
		)
	  } else if ('p-format-data-value-range' === parameter.format.value) {
		return (
		  <FF_Essence_parameterRouterValueRange
			meta={
			  parameter.meta && parameter.meta['p-format-data-value-range']
				? parameter.meta['p-format-data-value-range']
				: null
			}
			type={'p-format-data-value-range'}
			source={
			  parameter.source && parameter.source ? parameter.source : null
			}
			label={parameter.label}
			name={parameter.name}
			id={props.parameterId}
			key={'PFormatDataValueRange' + props.parameterId}
			value={parameter.value}
			getBack={getBack}
		  />
		)
	  } else if ('p-format-data-interval-range' === parameter.format.value) {
		return (
		  <FF_Essence_parameterRouterIntervalRange
			meta={
			  parameter.meta && parameter.meta['p-format-data-interval-range']
				? parameter.meta['p-format-data-interval-range']
				: null
			}
			type={'p-format-data-interval-range'}
			source={
			  parameter.source && parameter.source ? parameter.source : null
			}
			label={parameter.label}
			name={parameter.name}
			id={props.parameterId}
			key={'PFormatDataIntervalRange' + props.parameterId}
			value={parameter.value}
			getBack={getBack}
		  />
		)
	  } else if ('p-format-data-value-constant' === parameter.format.value) {
		return (
		  <FF_Essence_parameterRouterConstant
			type={'p-format-data-value-constant'}
			key={'PFormatDataValueConstant' + props.parameterId}
			label={parameter.label}
			name={parameter.name}
			type={
			  parameter.type && parameter.type.name ? parameter.type.name : null
			}
			source={
			  parameter.source && parameter.source ? parameter.source : null
			}
			id={props.parameterId}
			value={parameter.value}
			getBack={getBack}
			meta={parameter.meta}
		  />
		)
	  } else {
		return (
		  <span>
			"{parameter.label}" - Это пока сложно распарсить, но мы научимся ...{' '}
		  </span>
		)
	  }
	} catch (e) {
	  return <span>"{parameter.label}" - В этом параметре ошибка ... </span>
	}
  }
