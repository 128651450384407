import React, { useState, useEffect, Fragment } from 'react'
import Context from '../../../../context'

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Typography
} from '@material-ui/core'

import FFTextFields from '../../../../components/FFTextFields'
import FF_Classifier_step3_formatData_dict from './FF_Classifier_step3_formatData_dict'
import FFToogleStyle from '../../../../components/FFToogleStyle'

import FF_Classifier_step3_typeData_bool from './FF_Classifier_step3_typeData_bool'
import { validation_data } from '../../../../supportFunction'

export default function FF_Essence_parameterRouterDict (props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // ---------------------------- ---------------------------------------------------
  const [meta, setMeta] = useState(props.meta)
  const [metaList, setMetaValue] = useState(
    props.meta && props.meta.value ? props.meta.value : []
  )
  const [value, setValue] = useState(
    props.value ? props.value[props.meta['key']] : {}
  )
  const [te, setTe] = useState(0)
  const [currentIdRange, setCurrentIdRange] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)
  const type = props.type
  const source = props.source

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    // console.log(props)
    // if (props.value) {
    //   setValue(props.value)
    // } else if (Number(meta.length) >= 0) {
    //   if (
    //     [
    //       'p-type-data-bool',
    //       'p-type-data-float',
    //       'p-type-data-int',
    //       'p-type-data-timestamp',
    //       'p-type-data-ushort'
    //     ].indexOf(type) > -1
    //   ) {
    //     setValue()
    //   } else {
    //     setValue(Array.from({ length: meta.length }, (v, i) => ''))
    //   }
    // } else {
    //   setValue([])
    // }
  }, [])

  const doChangeSelect = (e, i) => {
    setValue(e.target.value)
    let temp2 = {}
    temp2[meta['key']] = e.target.value
    props.getBack({ name: 'p-format-data-dict', value: temp2, meta: meta })
    setTe(te + 1)
  }

  const pFormatParent = (nameAttr, data, flagLength) => {
    setMeta(data)
    setMetaValue(data.value)
    let temp2 = {}
    temp2[meta['key']] = value
    props.getBack({ name: 'p-format-data-dict', value: temp2, meta: meta })
  }

  const handleChangeFFTextFields = (value2, id, name, element) => {
    if (element) {
      console.log(value2, name, value)
      if (String(name).indexOf('bool_') > -1) {
        let temp = value
        if (temp === null) {
          temp = {}
        }
        temp[meta['key']] = value2
        setValue(temp)
        // props.getBack({ name: 'p-format-data-list', value: temp, meta: meta })
        // console.log(
        //   value2,
        //   name + '_name',
        //   id,
        //   temp,
        //   '----------<',
        //   Number(name.replace('bool_', ''))
        // )
      } else if (validation_data(type, value2) === '') {
        if (
          [
            'p-type-data-bool',
            'p-type-data-float',
            'p-type-data-int',
            'p-type-data-timestamp',
            'p-type-data-ushort'
          ].indexOf(type) > -1
        ) {
          value2 = Number(value2)
        }
        setValue(value2)
        let temp2 = {}
        temp2[meta['key']] = value2
        props.getBack({ name: 'p-format-data-dict', value: temp2, meta: meta })
      } else {
        // let temp2 = {}
        // temp2[meta['key']] = 'ValidationError'
        // props.getBack({ name: 'p-format-data-dict', value: temp2, meta: meta })
        return validation_data(type, value2)
      }
    }
    return ''
  }

  const pFormatDataValueList = (nameAttr, data, flagLength) => {
    let temp = meta
    temp[nameAttr] = data
    setMeta(temp)
    setTe(te + 1)

    // if (flagLength === 'length') {
    //   setValue(Array.from({ length: data.length }, (v, i) => ''))
    // }
    props.getBack({ name: 'p-format-data-dict', value: value, meta: temp })
  }

  const handleChange = (event, newValue) => {
    // console.log(currentIndex, newValue)
    let temp = {}
    temp[meta['key']] = newValue
    setTe(te + 1)
    props.getBack({ name: 'p-format-data-dict', value: temp, meta: meta })
    // setValue(newValue)
  }

  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={2}>
        <Typography variant={"body1"}>{props.label}</Typography>
        <Typography variant={"body2"} color={"primary"}>{`(${props.name})`}</Typography>
      </Grid>
      <Grid
        item
        xs={5}
        style={{ textAlign: 'center' }}
        key={'GridValues' + props.id}
      >
        <Typography>{'Источник данных - ' + source.label}</Typography>
        {meta['p-format-data'] === 'p-format-data-value-list' ? (
          <FormControl
            variant='filled'
            style={{ margin: 4, width: '100%' }}
            key={'FormControl' + props.id + '_' + (value ? value : '')}
          >
            <InputLabel>Значение</InputLabel>
            <Select
              key={'Select' + props.id + '_' + value}
              value={value ? value : ''}
              onChange={e => doChangeSelect(e)}
            >
              {metaList
                ? metaList.map(objChild => {
                    return (
                      <MenuItem
                        value={objChild.id}
                        id={objChild.id}
                        key={'SelectMenuItem_' + objChild.id}
                      >
                        {objChild.value}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
          </FormControl>
        ) : meta['p-format-data'] === '' && type !== 'p-type-data-bool' ? (
          <Context.Provider value={{ handleChangeFFTextFields }}>
            <FFTextFields
              style={{ width: '100%' }}
              key={'FFTextFields' + 'const'}
              value={value}
              is_filter={false}
              name={'const'}
              label={'Значение для словаря'}
              parameter={{ id: 'dict' }}
              dis={false}
            />
          </Context.Provider>
        ) : meta['p-format-data'] === 'p-format-data-value-range' ? (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Context.Provider value={{ handleChangeFFTextFields }}>
                  <FFTextFields
                    style={{ width: '100%' }}
                    key={'FFTextFields' + value}
                    value={value}
                    is_filter={false}
                    id={'textDict' + props.id}
                    name={'dict'}
                    label={'Значение для словаря '}
                    parameter={{ id: 'textDict' + props.id }}
                    dis={false}
                  />
                </Context.Provider>
              </Grid>
              <Grid item>{meta.value.left.value}</Grid>
              <Grid item xs={10} style={{ alignSelf: 'center' }} spacing={1}>
                <Slider
                  aria-labelledby='discrete-slider'
                  value={value[currentIndex]}
                  valueLabelDisplay='auto'
                  step={Number(meta.value.step)}
                  marks
                  min={
                    Number(meta.value.left.value) <=
                    Number(meta.value.right.value)
                      ? Number(meta.value.left.value)
                      : 0
                  }
                  onChange={handleChange}
                  max={Number(meta.value.right.value)}
                />
              </Grid>
              <Grid item>{meta.value.right.value}</Grid>
            </Grid>
          </>
        ) : type === 'p-type-data-bool' ? (
          <Context.Provider value={{ handleChangeFFTextFields }}>
            <FFToogleStyle
              key={te}
              value={value ? value : false}
              off={
                meta && meta['bool-value'] && meta['bool-value']['label-false']
                  ? meta['bool-value']['label-false']
                  : 'Выключено'
              }
              on={
                meta && meta['bool-value'] && meta['bool-value']['label-true']
                  ? meta['bool-value']['label-true']
                  : 'Включено'
              }
              meta={meta && meta['bool-value']}
              name={'bool_dict'}
              id={props.id + 'dict'}
            />
          </Context.Provider>
        ) : null}
      </Grid>
      <Grid item xs={5} style={{ textAlign: 'center' }}>
        <Typography>{'Формат данных - Список'}</Typography>
        <Context.Provider value={{ pFormatParent }}>
          <FF_Classifier_step3_formatData_dict
            key={'FF_Classifier_step3_formatData_dict' + props.id}
            id={props.id}
            value={JSON.parse(JSON.stringify({ value: meta }))}
            props={'meta'}
          />
        </Context.Provider>
        {type === 'p-type-data-bool' ? (
          <Context.Provider value={{ pFormatDataValueList }}>
            <FF_Classifier_step3_typeData_bool
              value={{
                value: meta && meta['bool-value'] ? meta['bool-value'] : null
              }}
            />
          </Context.Provider>
        ) : null}
      </Grid>
    </Grid>
  )
}
