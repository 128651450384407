import React, { useEffect, useState, useContext } from 'react'
// import ReactDOM from 'react-dom'
// import { Redirect } from 'react-router-dom'
import clsx from 'clsx'

// import { makeStyles } from "@material-ui/core/styles";
// import React from 'react';
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
// import Loader from './Loader'
import Context from './context'
// import FFTreeEssence from './App2'

import FFLoaderComponent from './components/FFLoaderComponent'

import FFTransportList from './components/FFTransportList'
import Tooltip from '@material-ui/core/Tooltip'
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Select from '@material-ui/core/Select'
import { Switch, Checkbox } from '@material-ui/core'

// import Input from '@material-ui/core/Input'

// import Table from '@material-ui/core/Table'
// import TableBody from '@material-ui/core/TableBody'
// import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
// import TableHead from '@material-ui/core/TableHead'
// import TableRow from '@material-ui/core/TableRow'
import Badge from '@material-ui/core/Badge'
// import Avatar from '@material-ui/core/Avatar'
// import Chip from '@material-ui/core/Chip'
// import Divider from '@material-ui/core/Divider'
// import TablePagination from '@material-ui/core/TablePagination'
// import FFPaginationAction from './components/FFPaginationAction'
import { SnackbarProvider, useSnackbar } from 'notistack'
// import FFListSwitch from './components/FFListSwitch';
// import FFSelect from './components/FFSelect'
// import FFTextFields from './components/FFTextFields'
// import FFSelectMulti from './components/FFSelectMulti'
import FFRouterComponents from './components/FFRouterComponents'
// import FFTextFields from './components/FFTextFields'
// import FFSelect from './components/FFSelect'

// import FFCheckList from './components/FFCheckList'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import FFPanelMenu from './components/FFPanelMenu'
// import FFPersistentDrawerRight from './components/FFPersistentDrawerRight'
import Grid from '@material-ui/core/Grid'
// import TextField from '@material-ui/core/TextField'
// import MoreVertIcon from '@material-ui/icons/MoreVert'
// import Drawer from '@material-ui/core/Drawer'
import FFTreeEssence from './components/FFTreeEssence'
// import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
// import FFTester from './FFTester'
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import IconButton from '@material-ui/core/IconButton'

import IconSettings from '@material-ui/icons/Settings'

import IconDelete from '@material-ui/icons/Delete'
// import IconFilter from '@material-ui/icons/FilterList'
// import IconSearch from '@material-ui/icons/Search'
import IconLibraryAdd from '@material-ui/icons/LibraryAdd'
import IconAdd from '@material-ui/icons/Add'
import IconSave from '@material-ui/icons/Save'
import IconRefresh from '@material-ui/icons/Refresh'
import IconSystemUpdateAlt from '@material-ui/icons/SystemUpdateAlt'
// import IconBuild from '@material-ui/icons/Build'
// import IconReplay from '@material-ui/icons/Replay'
// import IconCancel from '@material-ui/icons/Cancel'
// import IconUpdate from '@material-ui/icons/Update'

// import FFFilterLabel from './components/FFFilterLabel'
// import FFToggle from './components/FFToggle'

// import CardHeader from '@material-ui/core/CardHeader'
// import TreeItem from '@material-ui/lab/TreeItem'
// import TreeView from '@material-ui/lab/TreeView'
// import Essence from './essence_action_info.json'
// import CancelIcon from '@material-ui/icons/Cancel';
// import Context from './context'
// import FileCls from './cls.json'
// import FileParameter from './parameter.json'
import { Button } from '@material-ui/core'
// import SwipeableViews from 'react-swipeable-views';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
// import ArrowRightIcon from '@material-ui/icons/ArrowRight'
// import Remove from '@material-ui/icons/Remove'
// import FileBaseLine from './fileSrc/scenarios_model.json'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

import { withStyles } from '@material-ui/core/styles'

import { green } from '@material-ui/core/colors'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import DoneOutline from '@material-ui/icons/DoneOutline'
// import FFPanelMenu from "./components/FFPanelMenu";

// import SortableTree from 'react-sortable-tree/dist/index.esm.js'
// import 'react-sortable-tree/style.css'

import { ApiConnect, urlConfiguration } from './ApiConnectNew'

import cookie from 'react-cookies'
import Loader from './Loader'
import FFDialogDelete from './libft/FFDialogDelete'
import { getIsFull } from '../features'
import { useSelector } from 'react-redux'

const uuidv4 = require('uuid/v4')

const useStyles = makeStyles({
  margin: {
    marginTop: '10px',
    marginLeft: '10px',
    marginBottom: '5px'
  },
  padding: {
    // paddingLeft: 20,
    paddingRight: 10
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: '100%'
  },
  formControl: {
    minWidth: 250
  },
  formControl2: {
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  end: {
    flexGrow: 3
  },
  tree: {
    padding: 8,
    margin: 8
  },
  flexEnd: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexFlow: 3
  },
  scroller: {
    // maxHeight: '-webkit-fill-available',
    overflow: 'overlay'
  }
})

export default function FF_TemplateNew () {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // -------------------------------------------------------------------------------

  const metaEssence = {
    label: {
      label: 'Наименование',
      is_unique: true,
      is_required: true,
      is_type: 'string'
    },
    name: {
      label: 'Системное наименование',
      is_unique: true,
      is_required: true,
      is_type: 'string'
    },
    description: {
      label: 'Описание',
      is_unique: false,
      is_required: false,
      is_type: 'string'
    }
  }

  const strAuthorization = 'Bearer ' + cookie.load('access_token')
  const { isLogin } = useContext(Context)
  const [loading, setLoading] = useState(true)
  const [essence, setEssence] = useState([])
  const [essenceLoading, setEssenceLoading] = useState(false)
  const [activeEssence, setActiveEssence] = useState()
  const [children, setChildren] = useState({})
  const [isEdit, setIsEdit] = useState(0)
  const [te, setTe] = useState(0)
  const [classifier, setClassifier] = useState([])
  const [parameter, setParameter] = useState([])
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false)
  const isFull = useSelector(getIsFull);

  useEffect(() => {
    getData(
      '/api/config/ontological_classifier/tree/?is_level=True',
      setClassifier,
      null
    )
    getData('/api/?entity=parameter_registry&is_full=False', setParameter, null)
    doRefresh()
  }, [])

  // -------------------------------------------------------------------------------
  //
  //                                 Запросы в Бэк
  //
  // -------------------------------------------------------------------------------

  const getData = (url, setterEssence, setterLoading) => {
    fetch(urlConfiguration + url, {
      method: 'get',
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        return response.json()
      })
      .then(temp => {
        if (setterEssence === setActiveEssence) temp = temp[0]
        if (setterEssence === setClassifier) temp = temp.children
        setterEssence(temp)
        setTe(uuidv4())
        if (setterLoading) {
          setterLoading(true)
        }
      })
  }

  const createData = (url, objToCreate) => {
    fetch(urlConfiguration + url, {
      method: 'post',
      body: JSON.stringify(objToCreate),
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        return response.json()
      })
      .then(temp => {
        handleClickVariant(
          temp['msg'].indexOf('спешно') !== -1 ? 'success' : 'error',
          temp['msg']
        )
      })
  }

  const deleteDate = (url, objToDelete) => {
    fetch(urlConfiguration + url, {
      method: 'delete',
      body: JSON.stringify(objToDelete),
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        return response.json()
      })
      .then(temp => {
        handleClickVariant(
          temp['msg'].indexOf('спешно') !== -1 ? 'success' : 'error',
          temp['msg']
        )
      })
  }

  const updateDate = (url, objToCreate) => {
    fetch(urlConfiguration + url, {
      method: 'put',
      body: JSON.stringify(objToCreate),
      headers: new Headers({
        Authorization: strAuthorization,
        'Content-Type': 'application/json'
      })
    })
      .then(response => {
        if (response.status === 401) {
          isLogin(false)
        }
        return response.json()
      })
      .then(temp => {
        handleClickVariant(
          temp['msg'].indexOf('спешно') !== -1 ? 'success' : 'error',
          temp['msg']
        )
      })
  }

  const { enqueueSnackbar } = useSnackbar()
  const handleClickVariant = (variant, msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      }
    )
  }
  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  // Нажатие на классификационное значение в меню
  const doClickActiveEssence = (objId, obj) => {
    if (essence.id !== objId) {
      setChildren(obj)
      getData(
        '/api/?entity=template_essence&id=' + objId,
        setActiveEssence,
        null
      )
    } else {
      setActiveEssence(null)
    }
  }

  // Обработчик на кнопку добавления
  const doAddEssence = () => {
    // console.log(activeEssence)
    let tempId = uuidv4()
    let temp = {
      id: tempId,
      name: 'Template_' + tempId.split('-')[0],
      label: 'Template_' + tempId.split('-')[0],
      label_eng: 'Template_' + tempId.split('-')[0],
      description: 'Созданный шаблон',
      active_parameters: [],
      active_classifiers: [],
      is_deleted: false,
      value: 'Template_' + tempId.split('-')[0]
    }
    createData('/api/?entity=template_essence', temp)
    doRefresh()
  }

  // Обработчик для копирования
  const doAddCopyEssence = () => {
    if (activeEssence) {
      let temp = JSON.parse(JSON.stringify(activeEssence))
      let tempId = uuidv4()
      temp.id = tempId
      temp.name = 'name' + '_' + tempId.split('-')[0]
      temp.label = '0_New_label' + '_' + tempId.split('-')[0]
      temp.label_eng = 'label_eng' + '_' + tempId.split('-')[0]

      createData('/api/?entity=template_essence', temp)
      doRefresh()
    } else {
      handleClickVariant('warning', 'Выберите копируемое значение')
    }
  }

  // Обработчик для обновления
  const doRefresh = () => {
    setTimeout(() => {
      getData(
        '/api/?entity=template_essence&is_full=True',
        setEssence,
        setEssenceLoading
      )
      // setTe(te + 1)
    }, 100)
  }

  // Обработчик для удаления
  const doDeleteEssence = () => {
    if (activeEssence) {
      deleteDate('/api/?entity=template_essence&id=' + activeEssence.id, {
        id: activeEssence.id
      })
    }
    setActiveEssence(null)
    doRefresh()
  }

  const doSaveEssence = () => {
    updateDate('/api/?entity=template_essence', activeEssence)
    doRefresh()
  }

  // Обновление параметров по шаблону
  const doUpdateParameter = () => {
    updateDate(
      '/api/config/template/list_essence_update/?template=' + activeEssence.id,
      null
    )
  }

  // Обработчик для текстовых полей
  const changeTextField = (value, parameter_id, name, element) => {
    let temp = activeEssence
    temp[name] = value
    setActiveEssence(temp)
    return ''
  }

  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return (
    <Context.Provider
      value={{
        doClickActiveEssence,
        changeTextField
      }}
    >
      {isOpenDialogDelete && (
        <FFDialogDelete
          open={isOpenDialogDelete}
          setOpen={setIsOpenDialogDelete}
          isAgree={e => doDeleteEssence()}
        />
      )}
      <Grid container>
        <Grid item xs={4}></Grid>
        <Grid item xs={8} align='right'>
          {true === true ? (
            <Tooltip title='Создать шаблон'>
              <IconButton
                aria-label='filter'
                onClick={e => doAddEssence()}
                className={classes.margin}
                disabled={!isFull}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconAdd color={!isFull ? 'gray' : 'primary'} fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {activeEssence ? (
            <Tooltip title='Обновить сущности'>
              <IconButton
                aria-label='filter'
                onClick={e => doUpdateParameter()}
                className={classes.margin}
                disabled={!isFull}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconSystemUpdateAlt color={!isFull ? 'gray' : 'primary'} fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {activeEssence ? (
            <Tooltip title='Копировать существующий шаблон'>
              <IconButton
                aria-label='filter'
                onClick={e => doAddCopyEssence()}
                className={classes.margin}
                disabled={!isFull}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconLibraryAdd color={!isFull ? 'gray' : 'primary'} fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {activeEssence ? (
            <Tooltip title='Сохранить'>
              <IconButton
                aria-label='filter'
                onClick={e => doSaveEssence()}
                disabled={!isFull}
                className={classes.margin}
              >
                <IconSave color={!isFull ? 'gray' : 'primary'} fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {true === true ? (
            <Tooltip title='Обновить'>
              <IconButton
                aria-label='filter'
                onClick={e => doRefresh()}
                className={classes.margin}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconRefresh color='primary' fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
          {activeEssence ? (
            <Tooltip title='Удалить выбранный шаблон'>
              <IconButton
                aria-label='filter'
                onClick={e => setIsOpenDialogDelete(true)}
                className={classes.margin}
                disabled={!isFull}
                style={{ visibility: isEdit === 0 ? 'visible' : 'hidden' }}
              >
                <IconDelete color={!isFull ? 'gray' : 'error'} fontSize='large' />
              </IconButton>
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>

      <TableContainer className={[classes.flex, classes.end]}>
        <Grid
          container
          id={'body_grid'}
          className={[classes.flexRow, classes.end]}
        >
          <Grid
            item
            xs={3}
            id={'bodyTree_grid'}
            style={{ padding: 10 }}
            className={clsx(classes.flex, classes.end)}
          >
            <Paper
              elevation={3}
              id={'bodyTree_paper'}
              className={clsx(classes.flex, classes.end)}
              style={{ padding: 10 }}
            >
              <FFLoaderComponent
                // key={te}
                loading={essenceLoading}
                isLoader={<Loader />}
                component={<FFTreeEssence essence={essence} />}
              />
            </Paper>
          </Grid>
          <Grid
            item
            xs={9}
            id={'bodyMain_grid'}
            className={[classes.flex, classes.end]}
          >
            <FFLoaderComponent
              loading={activeEssence ? true : false}
              isLoader={
                <Paper
                  elevation={3}
                  style={{ margin: 10, padding: 8 }}
                  className={clsx(classes.flex, classes.end)}
                >
                  <Typography
                    variant='h1'
                    component='h2'
                    style={{ color: 'lightgray', marginTop: '20%' }}
                  >
                    Шаблон не выбран
                  </Typography>
                </Paper>
              }
              component={
                <FF_Template_Card
                  key={
                    activeEssence
                      ? 'FF_Template_Card_' + activeEssence.id + '_' + te
                      : 'FF_Template_Card_temp'
                  }
                  classifier={classifier}
                  parameter={parameter}
                  essence={activeEssence ? activeEssence : null}
                  children={children}
                  metaEssence={metaEssence}
                />
              }
            />
          </Grid>
        </Grid>
      </TableContainer>
    </Context.Provider>
  )
}

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Card
          p={3}
          style={{ marginRight: '10px', marginLeft: '10px', padding: 10 }}
        >
          {children}
        </Card>
      )}
    </Typography>
  )
}

function TabPanelHor (props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const FF_Template_Card = props => {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  const classes = useStyles()

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // ---------------------------- ---------------------------------------------------

  const { changeTextField } = useContext(Context)
  const [value2, setValue2] = React.useState(0)
  const [errorChange, setErrorChange] = useState('')

  let metaTable = [
    {
      widthColumn: 6,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['name', 'label'],
          // dis: true,
          label_attr: ['Системное наименование', 'Наименование']
        }
      ]
    },
    {
      widthColumn: 6,
      data: [
        {
          type: 'ffListTextFields',
          style: {},
          name_attr: ['description'],
          // dis: false,
          label_attr: ['Описание']
        }
      ]
    }
  ]

  // console.log(props.metaEssence)
  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {}, [])

  // Событие на изменение текстового
  const handleChangeFFTextFields = (value, parameter_id, name, element) => {
    if (props.metaEssence[name]['is_required'] && value === '') {
      setErrorChange('Заполните все обязательные поля')
      return 'Обязательное поле не заполнено!'
    }
    setErrorChange('')
    changeTextField(value, parameter_id, name, element)
    return ''
  }

  // Переключалка для горизонтального таба
  const handleChangeHoro = (event, newValue) => {
    setValue2(newValue)
  }

  // Событие на перенос списка
  const handleTransporterList = (value, id, type) => {
    let baseList =
      type === 'active_classifiers' ? props.classifier : props.parameter
    let tempListValue = value.map(objInd => baseList[objInd].id)
    changeTextField(tempListValue, '', type, '')
  }
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return props.essence ? (
    <Context.Provider
      value={{ handleChangeFFTextFields, handleTransporterList }}
    >
      <Paper
        elevation={3}
        style={{ margin: 8, padding: 8 }}
        // className={classes.end}
        className={[classes.tree, classes.scroller, classes.end]}
      >
        <Grid container item xs={12} spacing={1}>
          {metaTable.map(metaColumn => {
            // console.log(metaColumn)
            return (
              <Grid item xs={metaColumn.widthColumn}>
                {metaColumn.data.map(metaCell => {
                  return (
                    <FFRouterComponents
                      style={{ margin: 10 }}
                      parameter={props.essence}
                      meta_cell={metaCell}
                    />
                  )
                })}
              </Grid>
            )
          })}
          <Grid item xs={12}>
            <Tabs
              value={value2}
              onChange={handleChangeHoro}
              indicatorColor='primary'
              textColor='primary'
              variant='fullWidth'
              className={classes.main}
              aria-label='full width tabs example'
            >
              <Tab label='Классификаторы' {...a11yProps(0)} />
              <Tab label='Параметры' {...a11yProps(1)} />
            </Tabs>
            <TabPanelHor value={value2} index={0}>
              <FFTransportList
                cls={props.essence.active_classifiers}
                cls_main={props.classifier}
                type={'active_classifiers'}
                id={props.essence.id}
                label={[
                  'Реестр классификаторов',
                  'Используемые классификаторы'
                ]}
              />
            </TabPanelHor>
            <TabPanelHor value={value2} index={1}>
              <FFTransportList
                cls={props.essence.active_parameters}
                cls_main={props.parameter}
                type={'active_parameters'}
                id={props.essence.id}
                label={['Реестр параметров', 'Используемые параметры']}
              />
            </TabPanelHor>
          </Grid>
        </Grid>
      </Paper>
    </Context.Provider>
  ) : null
}
