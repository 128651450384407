
import React, { useEffect, useState, useContext } from 'react'

import Context from '../../../context'

import PropTypes from 'prop-types'

import {
  Tabs,
  Tab,
  Typography,
  Box,
  Card,
} from '@material-ui/core'


/*
 **-------------------- My Components ---------------------------
 */
import FFTextField from '../../../libft/FFTextField'
import FFContainer from '../../../libft/FFContainer'


/*
 **------------------- My Page Components -------------------------
 */
import FFEssenceCardParameters from './FFEssenceCardParameters'
import FFEssenceCardClassifier from './FFEssenceCardClassifier'


export default function FFEssenceCard (props){


	// -------------------------------------------------------------------------------
	//
	//                                 Состояния
	//
	// ---------------------------- ---------------------------------------------------

	const [value2, setValue2] = React.useState(0)
	const [errorChange, setErrorChange] = useState('')

	let metaTable = [
	  {
		widthColumn: 6,
		data: {
		  name_attr: ['name', 'label', 'label_eng'],
		  label_attr: [
			'Системное наименование',
			'Наименование',
			'Наименование на латинском'
		  ],
		  is_disabled: [false, false, false]
		}
	  },
	  {
		widthColumn: 6,
		data: {
		  name_attr: ['templateLabel', 'description'],
		  label_attr: ['Используемый шаблон', 'Описание'],
		  is_disabled: [true, false]
		}
	  }
	]

	// -------------------------------------------------------------------------------
	//
	//                                 Обработчики событий
	//
	// -------------------------------------------------------------------------------

	// Переключалка для горизонтального таба
	const handleChangeHoro = (event, newValue) => {
	  setValue2(newValue)
	}

	// Событие на перенос списка
	const handleTransporterList = (value, id, type) => {
	  let baseList =
		type === 'active_classifiers' ? props.classifier : props.parameter
	  let tempListValue = value.map(objInd => baseList[objInd].id)
	  // changeTextField(tempListValue, '', type, '')
	}

	const getBack = data => {
	  props.getBack(data)
	}
	// Событие на изменение текстового
	const changeValue = (value, parameter_id, name, element) => {
	  if (element) {
		if (props.metaEssence[name]['is_required'] && value === '') {
		  setErrorChange('Заполните все обязательные поля')
		  return 'Обязательное поле не заполнено!'
		}
		setErrorChange('')
		let temp = props.essence
		temp[name] = value
		props.getBack(temp)
	  }
	  return ''
	}
	// -------------------------------------------------------------------------------
	//
	//                                    Рендеринг
	//
	// -------------------------------------------------------------------------------

	return props.essence ? (
	  <Context.Provider value={{ handleTransporterList, changeValue }}>
		<FFContainer
		  is_row={false}
		  id={'essence_card'}
		  components={[
			{
			  width: 12,
			  elevation: 2,
			  style: { alignItems: 'stretch' },
			  component: (
				<FFContainer
				  is_row={true}
				  id={'essence_card_meta'}
				  components={metaTable.map(metaColumn => {
					return {
					  width: metaColumn.widthColumn,
					  elevation: 0,
					  component: (
						<React.Fragment>
						  {metaColumn.data.name_attr.map((nameAttr, indexRow) => {
							return (
							  <FFTextField
								key={metaColumn.data.name_attr[indexRow]}
								label={metaColumn.data.label_attr[indexRow]}
								value={
								  props.essence[
									metaColumn.data.name_attr[indexRow]
								  ]
								}
								name={metaColumn.data.name_attr[indexRow]}
								is_disabled={
								  metaColumn.data.is_disabled[indexRow]
								}
							  />
							)
						  })}
						</React.Fragment>
					  )
					}
				  })}
				/>
			  )
			},
			{
			  width: 12,
			  elevation: 2,
			  style: { alignItems: 'stretch' },
			  component: (
				<React.Fragment>
				  <Tabs
					value={value2}
					onChange={handleChangeHoro}
					indicatorColor='primary'
					textColor='primary'
					variant='fullWidth'
					aria-label='full width tabs example'
				  >
					<Tab label='Классификаторы' {...a11yProps(0)} />
					<Tab label='Параметры' {...a11yProps(1)} />
				  </Tabs>
				  <TabPanelHor value={value2} index={0}>
					<FFEssenceCardClassifier
					  key={
						'FF_Essence_classifier' +
						props.essence.id +
						props.classifier.length
					  }
					  essence={props.essence}
					  classifier={props.classifier}
					/>
				  </TabPanelHor>
				  <TabPanelHor value={value2} index={1}>
					<FFEssenceCardParameters
					  key={'FFEssenceParameters' + props.essence.id}
					  essence={props.essence}
					  getBack={getBack}
					/>
				  </TabPanelHor>
				</React.Fragment>
			  )
			}
		  ]}
		/>
	  </Context.Provider>
	) : null
  }



function TabPanel (props) {
	const { children, value, index, ...other } = props

	return (
	  <Typography
		component='div'
		role='tabpanel'
		hidden={value !== index}
		id={`vertical-tabpanel-${index}`}
		aria-labelledby={`vertical-tab-${index}`}
		{...other}
	  >
		{value === index && (
		  <Card
			p={3}
			style={{ marginRight: '10px', marginLeft: '10px', padding: 10 }}
		  >
			{children}
		  </Card>
		)}
	  </Typography>
	)
  }

  function TabPanelHor (props) {
	const { children, value, index, ...other } = props

	return (
	  <Typography
		component='div'
		role='tabpanel'
		hidden={value !== index}
		id={`vertical-tabpanel-${index}`}
		aria-labelledby={`vertical-tab-${index}`}
		{...other}
	  >
		{value === index && <Box p={3}>{children}</Box>}
	  </Typography>
	)
  }

  TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
  }

  function a11yProps (index) {
	return {
	  id: `vertical-tab-${index}`,
	  'aria-controls': `vertical-tabpanel-${index}`
	}
  }

