import React, { useState, useContext, useEffect } from 'react'

import Context from '../context'
import '../styles/FFToogleStyle.css'

export default function FFToogleStyle ({ value, on, off, name, id, meta }) {

  const { handleChangeFFTextFields } = useContext(Context)
  // const tru = meta && 'value-true' in meta ? meta['value-true'] : true;
  // const fal = meta && 'value-false' in meta ? meta['value-false'] : false;
  const [state, setState] = useState(value ? value : false)
  // const current = state ? tru : fal


  const onChangeToogle = e => {
    // console.log('click', Number(!state), name, id, 'FFToogleStyle')
    handleChangeFFTextFields(Number(!state), id, name, 'FFToogleStyle')
    setState(!state)
  }

  return (
    <div class='switch switch-blue'>
      <input
        type='radio'
        class='switch-input'
        id={'on' + id}
        checked={state}
        onChange={e => onChangeToogle()}
      />
      <label for={'on' + id} class='switch-label switch-label-on'>
        {on}
      </label>
      <input
        type='radio'
        class='switch-input'
        id={'off' + id}
        checked={!state}
        onChange={e => onChangeToogle()}
      />
      <label for={'off' + id} class='switch-label switch-label-off'>
        {off}
      </label>

      <span class='switch-selection'></span>
    </div>
  )
}
