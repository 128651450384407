// -------------------------------------------------------------------------------
//
//                           Функция для проверки валидации
//
// -------------------------------------------------------------------------------

export const validation_data = (type, value, props) => {
  // console.log(props)
  if (type && type === 'p-type-data-bool' && !(value === 1 || value == 0)) {
    return 'Введите булевое значение (0 или 1)'
  } else if (type && type === 'p-type-data-float' && isNaN(Number(value))) {
    return 'Введите дробное число'
  } else if (type && type === 'p-type-data-int' && isNaN(Number(value))) {
    return 'Введите целое число'
  } else if (type && type === 'p-type-data-timestamp' && isNaN(Number(value))) {
    return 'Введите целое число'
  } else if (
    type &&
    type === 'p-type-data-ushort ' &&
    isNaN(Number(value)) &&
    Number(value) >= 0 &&
    Number(value) < 256
  ) {
    return 'Введите целое число - [0 - 255]'
  } else if (
    type &&
    type === 'p-type-data-date' &&
    !new RegExp(/(^\d)\d\d\d(-)\d\d(-)\d\d/).test(value)
  ) {
    return 'Введите дату вида: "ГГГГ-ММ-ДД"'
  } else if (
    type &&
    type === 'p-type-data-datetime' &&
    (!new RegExp(/(^\d)\d\d\d(-)\d\d(-)\d\d \d\d:\d\d:\d\d/).test(value) ||
      value === '')
  ) {
    return 'Введите дату вида: "ГГГГ-ММ-ДД ЧЧ:ММ:СС"'
  } else if (
    type &&
    type === 'p-type-data-time' &&
    !new RegExp(/(^\d)\d:\d\d:\d\d/).test(value)
  ) {
    return 'Введите время вида: "ЧЧ:ММ:СС"'
  } else {
    return ''
  }
}
