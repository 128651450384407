import React, { useState, useContext, useEffect } from 'react'

import Context from '../context'
import '../styles/FFSwitch_N.css'
import { useTheme } from '@material-ui/core/styles'



export default function FFSwitch_N ({ id, meta, name, ...props }) {

  const theme = useTheme()
  const [state, setState] = useState(
    Number(meta.value) !== NaN && meta.label && meta.value < meta.label.length
      ? Number(meta.value)
      : 0.5
  )
  const count = meta.label.length

  const labelSlider = meta.label ? meta.label : []
  const [slider, setSlider] = useState({})

  const { changeValue } = useContext(Context)

  const calcPosition = num => {
    return {
      left: `calc(${num} * (100% / 2 + 8px) + (${(num === 0 ? 1 : -1)} * 2px))`,
      color: num === state ? theme.palette.primary.main : theme.palette.grey[500]
    }
  }

  const onChangeToogle = (e, num) => {
    changeValue(num, id, name, 'FFSwitch_N')
    setState(num)
  }

  useEffect(() => {
	setSlider(calcPosition(state))
  }, [state])

  return (
    <div
      className='switchN switch-blue'
      style={{ '--width-slider': 'calc(100% / 2 - 8px)', '--count-slider': count, '--color-main': theme.palette.grey[300] }}

    >
      {labelSlider.map((label, i) => {
        if (i < count) {
          return (
            <React.Fragment key={id + 'switchComp' + i}>
              <input
                type='radio'
                className='switch-inputN'
                key={id + 'switchInputN_' + i}
                id={i + id}
                checked={state === i ? true : false}
                onChange={e => onChangeToogle(e, i)}
              />
              <label
                for={i + id}
                key={id + 'switchLabelN_' + i}
                className={'switch-labelN switch-label-' + i}
                style={calcPosition(i)}
              >
                {label}
              </label>
            </React.Fragment>
          )
        }
      })}
      <span className='switch-selectionN' style={slider}>
        {state === 0.5 ? (
          <span
            style={{
              transform: 'translate(-14px, calc(100% / 5)) scale(1)',
              position: 'absolute'
            }}
          >
            N / A
          </span>
        ) : (
          ''
        )}
      </span>
    </div>
  )
}
