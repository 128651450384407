import React, {useContext, useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Context from '../context'

const useStyles = makeStyles({

    select: {
        width: "100%",
        margin: "3px",
    }
});

export default function FFSelect(props){

    const classes = useStyles();
    const {handleChangeFFSelect} = useContext(Context)
// props.value_cls.id
    const [temp, setTemp] = useState('')
    const [cls, setCls] = useState([])

    const handleChange = event => {
        handleChangeFFSelect(
            props.parameter.id,
            props.name_attr,
            props.parent_cls.id,
            props.parent_cls.label,
            event.target.value,
            event._targetInst.stateNode.innerText);
        setTemp(event.target.value);
      };

    const get_cls = (id, cls, values) =>{
    const a = cls.filter(todo => {
            return todo.id === id
        })[0].children;

        setCls(a.map(todo => {
            return {
                value: todo.id,
                label: todo.label,
            }
        }));
    }

    useEffect(() =>{
        get_cls(props.parent_cls.id, props.cls);
        setTemp(props.value_cls ? props.value_cls.id : '');
    }, [])


    return (
    <span key={props.parameter.id + '_' + props.parent_cls.id + '_formControl'}>
      <FormControl className={classes.select} variant="filled">
        <InputLabel >{props.parent_cls.label}</InputLabel>
        <Select
          labelId="ffselect-label"
          value={temp}
          onChange={handleChange}>

          {cls.map((obj, index) => {
            return <MenuItem key={'MenuItem' + index + '_' + props.parameter.id} value={obj.value}>{obj.label}</MenuItem>
          })}

        </Select>
      </FormControl>

    </span>
    );
}


    //
    //----------------------------Мульти Select--------------------------------
    //

    // import Select from 'react-select';
    // function SimpleSelect(props){
    //     const classes = useStyles();
    //     const [cls, setCls] = useState([])
    //     const [stateCls, setStateCls] = useState({})


    //     const get_cls = (id, cls, values) =>{
    //         const a = cls.filter(todo => {
    //             return todo.id === id
    //         })[0].children;

    //         setCls(a.map(todo => {
    //             return {
    //                 value: todo.id,
    //                 label: todo.label,
    //             }
    //         }));
    //     }

    //     useEffect(() =>{
    //         get_cls(props.id_cls, props.cls);
    //     }, [])
    //     return (
    //         // console.log(stateCls),
    //         <Select
    //             defaultValue={[{value: props.value_cls.id, label: props.value_cls.label}]}
    //             isMulti
    //             name={props.cls.id}
    //             options={cls}
    //             className={"basic-multi-select " + classes.select}
    //             classNamePrefix="select"
    //             isDisabled={true}
    //             onChange={(e) => e ? console.log(e.length): console.log('Not rows')}
    //         />
    //     )
    // };
