import React, { useContext, useState } from 'react'
// import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox'
import Context from '../context'
import { FormControlLabel } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
export default function FFSwitch (props) {
  const { handleChangeFFTextFields } = useContext(Context)
  const [checked, setChecked] = useState(props.value)

  const toggleChecked = () => {
    setChecked(prev => !prev)
    handleChangeFFTextFields(!checked, props.id, props.name, 'checkBox')
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={props?.icon ? props.icon : <CheckBoxOutlineBlankIcon/>}
          checkedIcon={props?.checkedIcon ? props?.checkedIcon : <CheckBoxIcon/>}
          checked={checked}
          onChange={toggleChecked}
          color='primary'
        />
      }
      label={props.label}
    />
  )
}
