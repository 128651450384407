import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'

import { fade, makeStyles } from '@material-ui/core/styles'
import TreeItem from '@material-ui/lab/TreeItem'
import IconHttps from '@material-ui/icons/Https'
import IconBrightnessAuto from '@material-ui/icons/BrightnessAuto'
import Checkbox from '@material-ui/core/Checkbox'
import { Grid } from '@material-ui/core'

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    '&:focus > $content': {
      color: 'blue',
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`
    }
  },
  active: {
    color: 'blue',
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`
  },

  content: {
    // borderTopRightRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),
    // paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: 12,
    paddingLeft: 12,
    borderLeft: `1px solid ${fade(theme.palette.text.primary, 0.4)}`,
    '& $content': {}
  },
  // expanded: {},
  // label: {
  //   fontWeight: 'inherit',
  //   color: 'inherit'
  // },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0)
  }
  // labelIcon: {},
  // labelText: {
  //   fontWeight: 0.1
  // }
}))

export default function FFTreeItemChecked (props) {
  const classes = useTreeItemStyles()
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    badge,
    obj,
    indeterminate,
    active,
    ...other
  } = props
  const [checked, setChecked] = useState(props.checked)

  const onChecked = e => {
    props.checkedFFItem(e, !checked)
    setChecked(!checked)
  }

  return (
    <TreeItem
      label={
        <Grid
          className={classes.labelRoot}
          style={
            active
              ? {
                  color: 'blue',
                  backgroundColor: 'var(--tree-view-bg-color, #bdbdbd)',
                  paddingRight: 2,
                  borderTopRightRadius: 2,
                  borderTopRightRadius: 2
                }
              : {}
          }
        >
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            style={{ padding: '0px 9px 0px 0px' }}
            color='primary'
            onChange={e => onChecked(e)}
          />
          <Grid item xs={10}>
            <Typography
              component={'div'}
              variant='body2'
              className={classes.labelText}
            >
              {labelText}
            </Typography>
          </Grid>
        </Grid>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label
      }}
      {...other}
    />
  )
}

// import React, { useState } from 'react'
// import Typography from '@material-ui/core/Typography'
// import Chip from '@material-ui/core/Chip'

// import { fade, makeStyles } from '@material-ui/core/styles'
// import TreeItem from '@material-ui/lab/TreeItem'
// import IconHttps from '@material-ui/icons/Https'
// import IconBrightnessAuto from '@material-ui/icons/BrightnessAuto'
// import Checkbox from '@material-ui/core/Checkbox';
// import { Grid } from '@material-ui/core'

// const useTreeItemStyles = makeStyles(theme => ({
//   root: {
//     color: theme.palette.text.primary,
//     '&:focus > $content': {
//       color: 'blue',
//       backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`
//     }
//   },
//   active: {
//     color: 'blue',
//     backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`
//   },

//   content: {
//     // borderTopRightRadius: theme.spacing(2),
//     // borderBottomRightRadius: theme.spacing(2),
//     // paddingRight: theme.spacing(1),
//     fontWeight: theme.typography.fontWeightMedium,
//     '$expanded > &': {
//       fontWeight: theme.typography.fontWeightRegular
//     }
//   },
//   group: {
//     marginLeft: 12,
//     paddingLeft: 12,
//     borderLeft: `1px solid ${fade(theme.palette.text.primary, 0.4)}`,
//     '& $content': {}
//   },
//   // expanded: {},
//   // label: {
//   //   fontWeight: 'inherit',
//   //   color: 'inherit'
//   // },
//   labelRoot: {
//     display: 'flex',
//     alignItems: 'center',
//     padding: theme.spacing(0.5, 0)
//   }
//   // labelIcon: {},
//   // labelText: {
//   //   fontWeight: 0.1
//   // }
// }))

// export default function FFTreeItemChecked (props) {
//   const classes = useTreeItemStyles()
//   const {
//     labelText,
//     labelIcon: LabelIcon,
//     labelInfo,
//     color,
//     bgColor,
//     badge,
//     obj,
// 	active,
//     ...other
//   } = props
//   const [checked, setChecked] = useState(props.checked)

//   const onChecked = (e) => {
// 	props.checkedFFItem(e, !checked)
// 	setChecked(!checked)
//   }

//   return (
//     <TreeItem
//       label={
//         <Grid
//           className={classes.labelRoot}
//           style={
//             active
//               ? {
//                   color: 'blue',
//                   backgroundColor: 'var(--tree-view-bg-color, #bdbdbd)',
//                   paddingRight: 2,
//                   borderTopRightRadius: 2,
//                   borderTopRightRadius: 2
//                 }
//               : {}
//           }
//         >
//           {/* <LabelIcon color="inherit" className={classes.labelIcon} /> */}
// 		  <Checkbox
// 			  checked={checked}
// 			  style={{padding: '0px 9px 0px 0px'}}
// 			  color="primary"
//               onChange={(e) => onChecked(e)}
//             //   inputProps={{ 'aria-label': 'primary checkbox' }}
//             />
//           <Grid item xs={10}>
//             <Typography
//               component={'div'}
//               variant='body2'
//               className={classes.labelText}
//             >
//               {labelText}
//             </Typography>
//           </Grid>

//           <Grid>
//             {badge !== 0 ? (
//               <Chip color='primary' size='small' label={badge} />
//             ) : null}
//             {obj && 'is_default_value' in obj ? (
//               <IconBrightnessAuto
//                 color='action'
//                 visibility={obj.is_default_value ? 'visible' : 'hidden'}
//               />
//             ) : null}

//             {obj && 'is_system' in obj && obj.is_system ? (
//               <IconHttps
//                 color='action'
//                 visibility={obj.is_system ? 'visible' : 'hidden'}
//               />
//             ) : null}
//           </Grid>
//         </Grid>
//       }
//       classes={{
//         root: classes.root,
//         content: classes.content,
//         expanded: classes.expanded,
//         group: classes.group,
//         label: classes.label
//       }}
//       {...other}
//     />
//   )
// }
