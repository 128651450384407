import React, { useRef, memo } from "react";
import { HotTable } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "./style.css";
import { dcId, essenceEssenceColHeaders, essenceDcColHeaders } from "./consts";

registerAllModules();

const filterByTypeEquals = (essences, type) => {
  return essences.filter((essence) => essence.type === type).map(essence => essence.path);
}

const filterByTypeNotEquals = (essences, type) => {
  return essences.filter((essence) => essence.type !== type).map(essence => essence.path);
}

export const Table = memo(({ essences, data, handleUpdate, isEssenceToDc}) => {
  const hotTable = useRef();
  const essencePaths = filterByTypeNotEquals(essences, dcId);
  const dcPaths = filterByTypeEquals(essences, dcId);
  const cellOptions = [];

  const copyData = JSON.parse(JSON.stringify(data));

  copyData.forEach((pair, iRow) => {
    pair.forEach((essence, iCol) => {
      if (!essence || essence === "") {
        if (iRow !== copyData.length - 1) {
          cellOptions.push({
            row: iRow,
            col: iCol,
            className: "handsontable-cell-mapping-error",
          });
        }
      }
    });
  });

  const onBeforeHotChange = (changes) => {
    if (hotTable?.current?.hotInstance && changes) {
      const hot = hotTable?.current?.hotInstance;
      let filter = hot.getPlugin('filters');
      const lastConditionsState = filter.conditionCollection.exportAllConditions();
      filter.clearConditions();
      filter.filter();
      const data = hot.getData();
      filter.conditionCollection.importAllConditions(lastConditionsState);
      filter.filter();
      handleUpdate([...data]);
    }
  };

  const dropFilters = () => {
    if (hotTable?.current?.hotInstance) {
      const hot = hotTable?.current?.hotInstance;
      let filter = hot.getPlugin('filters');
      filter.clearConditions();
      filter.filter();
    }
  }

  return (
    <HotTable
      ref={hotTable}
      licenseKey="non-commercial-and-evaluation"
      data={copyData}
      afterChange={onBeforeHotChange}
      filters={true}
      dropdownMenu={[
        { name: 'Сбросить фильтры',
          callback: () => dropFilters()
        },'filter_by_condition', 'filter_by_value', 'filter_action_bar']}
      columnSorting={true}
      width="100%"
      autoColumnSize={true}
      stretchH={"all"}
      cell={cellOptions}
      minSpareRows={1}
      colHeaders={isEssenceToDc ? essenceDcColHeaders : essenceEssenceColHeaders}
      columns={[
        {
          type: "autocomplete",
          source: essencePaths,
          strict: false,
        },
        {
          type: "autocomplete",
          source: isEssenceToDc ? dcPaths : essencePaths,
          strict: false,
        },
      ]}
    />
  );
});
