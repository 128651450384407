export const transformSliderConfig = (options) => {
  const opt = {
    left: options?.value?.left?.value,
    right: options?.value?.right?.value,
    length: options?.length,
    step: options?.value?.step,
  };

  return Object.keys(opt).reduce((prev, key) => {
    if (!Number.isNaN(opt[key])) {
      prev[key] = Number(opt[key]);
    } else {
      prev[key] = 1;
    }
    return prev;
  }, {});
};

export const getInterval = (interval, left, right) => {
  let l = left;
  let r = right;
  if (Array.isArray(interval) && interval.length === 2) {
    if (!Number.isNaN(interval[0]) && !Number.isNaN(interval[0])) {
      l = Number(interval[0]);
      r = Number(interval[1]);

      if (l < left) {
        l = left;
      }

      if (r > right) {
        r = right;
      }
    }
  }

  return [l, r];
};

export const getIntervals = (intervals, len, left, right) => {
  return Array(len)
    .fill(0)
    .map((_, index) => {
      if (intervals?.[index]) {
        return getInterval(intervals[index], left, right);
      }

      return getInterval(undefined, left, right);
    });
};
