import React, { useState } from 'react'
import Context from '../../../../context'

import {
  Grid,
  Typography
} from '@material-ui/core'

import FFTextFields from '../../../../components/FFTextFields'

export default function FF_Essence_parameterRouterComplex (props) {
  // console.log(props)
  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // ---------------------------- ---------------------------------------------------
  const [value, setValue] = useState(props.value ? JSON.stringify(props.value) : '{"value": "test"}')
  const [te, setTe] = useState(0)
  const [meta, setMeta] = useState(props.meta ? props.meta : '')
  const source = props.source

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  const handleChangeFFTextFields = (value, id, name, element) => {
    if (element) {
      if (value === '') {
        return 'Введите значение'
      }
      try {
        let temp = JSON.parse(value)
      } catch (e) {
        props.getBack({ name: 'p-format-data-complex', value:'ValidationError', meta: meta })
        return 'Ошибка в JSON'
	  }
	  if(name === 'meta'){
		  setMeta(JSON.parse(value))
	  }
	  else if (name == 'value'){
		  setValue(value)
	  }
	  props.getBack({ name: 'p-format-data-complex', value: JSON.parse(value), meta: meta })
    }
    return ''
  }

  //----------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={2}>
        <Typography>{props.label}</Typography>
      </Grid>
      <Grid item xs={5} style={{ textAlign: 'center' }}>
        <Typography>{'Источник данных - ' + source.label}</Typography>
        <Context.Provider value={{ handleChangeFFTextFields }}>
          <FFTextFields
            style={{ width: '100%' }}
            key={'FFTextFieldsConstant' + te}
            value={value}
            is_filter={false}
            name={'value'}
            label={'Значение'}
            parameter={{ id: 'value' }}
            dis={false}
          />
        </Context.Provider>
      </Grid>
      <Grid item xs={5} style={{ textAlign: 'center' }}>
        <Typography>{'Формат данных - Составное'}</Typography>
        <Context.Provider value={{ handleChangeFFTextFields }}>
          <FFTextFields
            style={{ width: '100%' }}
            key={'FFTextFieldsConstant'}
            value={JSON.stringify(meta)}
            is_filter={false}
            name={'meta'}
            label={'Составное значение'}
            parameter={{ id: 'meta' }}
            dis={false}
          />
        </Context.Provider>
      </Grid>
    </Grid>
  )
}
