import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import TableHead from '@material-ui/core/TableHead'
import { Grid, FormControlLabel, Switch, Tooltip, Badge } from '@material-ui/core'
import { IconStack } from 'rsuite'

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}))

function TablePaginationActions (props) {
  const classes = useStyles1()
  const theme = useTheme()
  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

// function createData (name, calories, fat) {
//   return { name, calories, fat }
// }

const useStyles2 = makeStyles(theme => ({
  table: {
    minWidth: '-webkit-fill-available'
  },
  ceil: {
    fontSize: 'smaller',
    borderRight: '1px solid' + theme.palette.grey[300],
    padding: 4
  },
  HIGH: {
    background: theme.palette.error.main
  },
  LOW: {
    background: theme.palette.warning.main
  }
}))

export default function CustomPaginationActionsTable (props) {
  const rows = props.data
  const classes = useStyles2()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [mode, setMode] = useState(false)
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Grid item container xs={12} style={{ margin: 4, background: 'white' }}>
      <Paper elevation={0} style={{ width: props?.width ? props.width : '100%' }}>
        <Table
          key={'Table_' + props.id}
          className={classes.table}
          // stickyHeader
          aria-label='custom pagination table'
          //   size={props?.size ? props.size : 'small'}
        >
          <TableHead>
            <TableRow>
              {Object.keys(props.listColumn[Number(mode)]).map(nameCol => {
                return (
                  <TableCell className={classes.ceil} style={{fontWeight: 'bold'}} align='left'>
                    {props.listColumn[Number(mode)][nameCol]}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => {
              return (
                <Tooltip title={row.priority}>
                  <TableRow
                    key={'Row_' + index}
                    className={classes[row.priority]}
                  >
                    {Object.keys(props.listColumn[Number(mode)]).map(
                      nameCol => {
                        return (
                          <TableCell className={classes.ceil} align='left'>
                            {nameCol !== 'timestamp'
                              ? row[nameCol]
                              : new Date(row[nameCol] + '+0300').toLocaleString()}
                          </TableCell>
                        )
                      }
                    )}
                  </TableRow>
                </Tooltip>
              )
            })}

            {emptyRows > 0 && (
              <TableRow
              // style={{ height: 53 * emptyRows }}
              >
                <TableCell
                  colSpan={Object.keys(props.listColumn[Number(mode)]).length}
                />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                {props.listColumn.length > 1 ?
                <FormControlLabel
                  control={
                    <Switch
                      size='small'
                      checked={mode}
                      onChange={e => setMode(!mode)}
                      color='primary'
                    />
                  }
                  label='Сообщение'
                /> : null}
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'Все', value: -1 }]}
                colSpan={Object.keys(props.listColumn[Number(mode)]).length - 1}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Кол-во строк на странице' },
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </Grid>
  )
}
