import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Typography, CircularProgress } from "@material-ui/core";
import classnames from "classnames";
import { getScada, updateData } from "../slice";
import { Table } from "../../../components/table";

export const useStyles = makeStyles((theme) => ({
  helperText: {
    color: theme.palette.divider,
    fontWeight: "bold",
    alignSelf: "center",
    textAlign: "center",
  },
  container: {
    position: "relative",
    display: "flex",
    flexFlow: "nowrap column",
    overflow: "hidden",
    flexGrow: 1,
    alignItems: "stretch",
    justifyContent: "stretch",
    margin: theme.spacing(1),
  },
  body: {
    display: "grid",
    flexGrow: 1,
    padding: 8,
    overflow: "hidden",
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const MappingTable = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { consumer, essences, statusEssences, data } =
    useSelector(getScada);

  const handleUpdate = (_data) => {
    dispatch(updateData(_data));
  };

  if (!consumer) {
    return (
      <Typography
        variant="h1"
        component="h2"
        className={classnames(
          classes.container,
          classes.center,
          classes.helperText
        )}
      >
        Выберите сущность
      </Typography>
    );
  }

  if (statusEssences === "pending") {
    return (
      <div className={classnames(classes.container, classes.center)}>
        <CircularProgress color="primary" size={150} thickness={1} />
      </div>
    );
  }

  if (!essences) {
    return (
      <Typography
        variant="h1"
        component="h2"
        className={classnames(
          classes.container,
          classes.center,
          classes.helperText
        )}
      >
        Нет доступных сущностей для маппинга
      </Typography>
    );
  }

  return (
    <Table essences={essences} data={data} handleUpdate={handleUpdate} isEssenceToDc={true} />
  );
};
