import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import InboxIcon from '@material-ui/icons/Inbox'
import DraftsIcon from '@material-ui/icons/Drafts'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}))

const ListItemError = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}))(ListItem)

export default function SelectedListItem ({ list }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <List component='nav' aria-label='secondary mailbox folder'>
        {list.map((obj, index) => {
          if (obj.is_deleted) {
            return (
              <>
                <ListItemError button>
                  <ListItemText
                    primary={`${index + 1}, ${obj.label} (${obj.name})`}
                  />
                </ListItemError>
                <Divider />
              </>
            )
          } else {
            return (
              <>
                <ListItem button>
                  <ListItemText
                    primary={`${index + 1}, ${obj.label} (${obj.name})`}
                  />
                </ListItem>
                <Divider />
              </>
            )
          }
        })}
      </List>
    </div>
  )
}
