import React, { useState, useEffect } from 'react'
import { forwardRef } from 'react'
/*
 **------------------------- CORE ------------------------------
 */

import MaterialTable from 'material-table'
import { useTheme } from '@material-ui/core/styles'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

/*
 **------------------------ ICONs ------------------------------
 */
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

/*
 **------------------------- UTILs ------------------------------
 */
import downloadCsv from 'download-csv'

const localization = {
  body: {
    emptyDataSourceMessage: 'Никаких записей для отображения',
    addTooltip: 'Добавить',
    deleteTooltip: 'Удалить',
    editTooltip: 'Редактировать',
    filterRow: {
      filterTooltip: 'Фильтр'
    },
    editRow: {
      deleteText: 'Вы уверены, что удалите эту строку?',
      cancelTooltip: 'Отменить',
      saveTooltip: 'Сохранить'
    }
  },
  grouping: {
    placeholder: 'Перетаскивание заголовков ...'
  },
  header: {
    actions: 'Действия'
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} of {count}',
    labelRowsSelect: 'Строки',
    labelRowsPerPage: 'Строки на странице:',
    firstAriaLabel: 'Первая',
    firstTooltip: 'Первая',
    previousAriaLabel: 'Предыдущая',
    previousTooltip: 'Предыдущая',
    nextAriaLabel: 'Следующая',
    nextTooltip: 'Следующая',
    lastAriaLabel: 'Последняя',
    lastTooltip: 'Последняя'
  },
  toolbar: {
    addRemoveColumns: 'Добавление и удаление столбцов',
    nRowsSelected: '{0} выбраннык строки(а)',
    showColumnsTitle: 'Показать Столбцы',
    showColumnsAriaLabel: 'Показать Столбцы',
    exportTitle: 'Экспорт',
    exportAriaLabel: 'Экспорт',
    exportName: 'Экспорт в формате CSV',
    searchTooltip: 'Поиск',
    searchPlaceholder: 'Поиск'
  }
}

export default function Editable ({ data, setData, ...props }) {
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => (
      <Check
        {...props}
        ref={ref}
        style={{ color: defTheme.palette.primary.main }}
      />
    )),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => (
      <SaveAlt
        {...props}
        ref={ref}
        style={{ color: defTheme.palette.primary.main }}
      />
    )),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Clear
        {...props}
        ref={ref}
        style={{ color: defTheme.palette.error.main }}
      />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  }

  const defTheme = useTheme()
  const { useState } = React

  const columns = [
    { title: 'Сущуствование контроллера', field: 'controller_is_exist', type: 'boolean' },
    { title: 'Соответствие контоллера', field: 'is_match_controller', type: 'boolean' },
    { title: 'Сис. наименование контроллера', field: 'controller' },
    { title: 'Уникальный', field: 'is_uniq', type: 'boolean' },
    { title: 'Топик', field: 'topic' },
    { title: 'Числовой', field: 'is_numeric', type: 'boolean' },
    { title: 'КТ', field: 'tc', type: 'numeric' },
    { title: 'Подписка', field: 'is_subscribe', type: 'boolean' },
    { title: 'Стрим', field: 'stream' },
    { title: 'Описание', field: 'description' },
    { title: 'Привязанный параметр', field: 'linkToParameter' }
  ]

  const theme = createMuiTheme({
    palette: {
      primary: defTheme.palette.primary,
      secondary: defTheme.palette.primary
    }
  })

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        title='Editable Preview'
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          exportCsv: (columns, data) => {
            let col = {
              topic: 'topic',
              tc: 'tc',
              controller: 'controller',
              is_numeric: 'is_numeric',
              is_subscribe: 'is_subscribe',
              stream: 'stream',
              description: 'description'
            }

            downloadCsv(
              [
                {
                  controller: 'Controller2',
                  topic: 'TempTopic',
                  ts: 1,
                  is_numeric: false,
                  is_subscribe: true,
                  stream: 'TempStream',
                  description: 'TempDescription'
                }
              ],
              col
            )
          },
          padding: 'dense',
          draggable: false,
          search: false,
          exportDelimiter: ';',
          pageSize: data.length,
          pageSizeOptions: [5, 10, data.length],
          actionsColumnIndex: -1
        }}
        icons={tableIcons}
        title={props.title}
        localization={localization}
        actions={[
          {
            icon: 'add',
            tooltip: 'Добавить привязку',
            onClick: (event, rowData) => {
              props.doGo(rowData)
            }
          }
        ]}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...data]
                const index = oldData.tableData.id
                dataUpdate[index] = newData
                setData([...dataUpdate])

                resolve()
              }, 1000)
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...data]
                const index = oldData.tableData.id
                dataDelete.splice(index, 1)
                setData([...dataDelete])

                resolve()
              }, 1000)
            })
        }}
      />
    </MuiThemeProvider>
  )
}
