import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography, Slider } from '@material-ui/core'

/*
 **-------------------- Highcharts ---------------------------
 */
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

/*
 **-------------------- My Components ---------------------------
 */
import FFTextField from '../../../libft/FFTextField'
import FFContainer from '../../../libft/FFContainer'
import FFSelectMulti from '../../../libft/FFSelectMulti'

import Context from '../../../context'
import { ApiConnect, urlConfiguration } from '../../../ApiConnectNew'
import { SnackbarProvider, useSnackbar } from 'notistack'
import moment from 'moment'
import 'moment/locale/ru'
const uuidv4 = require('uuid/v4')

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
}))

export default function FF_History ({
  devices,
  setDataToModifyHistory,
  ...props
}) {
  const classes = useStyles()
  const [activeDevices, setActiveDevices] = useState([])
  const [startCalc, setStartCalc] = useState(null)
  const [stopCalc, setStopCalc] = useState(null)
  const [metaChart, setMetaChart] = useState({ value: null, id: uuidv4() })
  const [dataSeries, setDataSeries] = useState(null)
  const [step, setStep] = useState(0)
  const [valueTime, setValueTIme] = useState([5, 16])
  const [series, setSeries] = useState([])

  useEffect(() => {
    let xAxis = []
    let i = -1
    while (++i < 24) xAxis.push(`${i < 10 ? 0 : ''}${i}:00`)
    let tempMetaChart = {
      chart: {
        type: 'line'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: xAxis
      },
      yAxis: {
        title: {
          text: 'кВтч'
        }
      },
      series: series
    }
    setMetaChart({ value: tempMetaChart, id: uuidv4() })
    let tempToData = {}
    series.forEach(serie => {
      tempToData[serie.id] = serie.data
    })
    setDataToModifyHistory({
      date_start: moment(startCalc).format("YYYY-MM-DD"),
      date_finish: moment(stopCalc).format("YYYY-MM-DD"),
      devices: tempToData
    })
  }, [series])

  useEffect(() => {
    let tempToData = {}
    series.forEach(serie => {
      tempToData[serie.id] = serie.data
    })
    setDataToModifyHistory({
      date_start: moment(startCalc).format("YYYY-MM-DD"),
      date_finish: moment(stopCalc).format("YYYY-MM-DD"),
      devices: tempToData
    })
  }, [startCalc, stopCalc])
  useEffect(() => {
    let tempSeries = []
    if (dataSeries) {
      Object.keys(dataSeries).map(id => {
        let temp = activeDevices.filter(obj => obj.id === id)
        if (temp.length) {
          tempSeries = [
            { name: temp[0].label, data: dataSeries[id], id: id },
            ...tempSeries
          ]
        }
      })
    }
    setSeries(tempSeries)
  }, [dataSeries])

  useEffect(() => {
    if (startCalc && activeDevices.length) {
      ApiConnect({
        name: 'getDataPost',
        url: urlConfiguration + '/api/base_line/correction/',
        objToGet: {
          devices: activeDevices.map(obj => {
            return obj.id
          }),
          date: startCalc
        },
        setterEssence: temp => {
          setDataSeries(temp['result'])
        },
        setterLoading: null,
        isLogin: props.isLogin,
        clickHandle: props.handleClickVariant
      })
    }
  }, [startCalc, activeDevices])

  const changeValue = (value, id, name, element) => {
    if (name === 'devices') {
      setActiveDevices(value)
    } else if (name === 'startCalc') {
      setStartCalc(value)
    } else if (name === 'stopCalc') {
      setStopCalc(value)
    } else if (name === 'step') {
      setStep(isNaN(Number(value)) ? value : Number(value))
      if (isNaN(Number(value))) return 'Введите число'
    }
    return ''
  }

  const doGo = () => {
    if (!isNaN(Number(step))) {
      let tempSeries = series
      let i = valueTime[0]
      while (i < valueTime[1]) {
        tempSeries.forEach(obj => {
          obj.data[i] = Number(step)
        })
        i += 1
      }
      setSeries([...tempSeries])
    }
  }
  return (
    <Context.Provider value={{ changeValue }}>
      <FFContainer
        is_row={false}
        id={'all'}
        components={[
          {
            width: 12,
            elevation: 0,
            shrink: 12,
            isRow: true,
            component: (
              <React.Fragment>
                <FFSelectMulti
                  style={{ display: 'flex', flexGrow: 12 }}
                  value={activeDevices}
                  options={devices}
                  label={'Список оборудования'}
                  id={'FF_HistoryDevices'}
                  name={'devices'}
                />
                <FFTextField
                  value={startCalc}
                  id={'FF_HistoryStartCalc'}
                  name={'startCalc'}
                  isDate={'date'}
                  label={'Начало расчета'}
                />
                <FFTextField
                  value={stopCalc}
                  id={'FF_HistoryStopCalc'}
                  name={'stopCalc'}
                  isDate={'date'}
                  label={'Окончание расчета'}
                />
              </React.Fragment>
            )
          },
          {
            width: 12,
            shrink: 12,
            elevation: 0,
            isRow: true,
            component: (
              <React.Fragment>
                <Typography className={classes.margin}>
                  {'Временной интервал'}
                </Typography>
                <Slider
                  value={valueTime}
                  className={classes.margin}
                  style={{ display: 'flex', flexGrow: 12, marginTop: 20 }}
                  aria-labelledby='range-slider'
                  step={1}
                  marks
                  min={0}
                  max={23}
                  onChange={(e, newVal) => setValueTIme(newVal)}
                  valueLabelDisplay='on'
                />
                <FFTextField
                  value={step}
                  id={'FF_HistoryStep'}
                  name={'step'}
                  label={'Значение'}
                />
                <Button
                  className={classes.margin}
                  size='small'
                  variant='outlined'
                  color='primary'
                  onClick={e => doGo()}
                >
                  {'Установить значение'}
                </Button>
              </React.Fragment>
            )
          },
          {
            width: 12,
            elevation: 2,
            isRow: true,
            component: (
              <HighchartsReact options={metaChart.value} key={metaChart.id} />
            )
          }
        ]}
      />
    </Context.Provider>
  )
}
