import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'

import FFIcons from './FFIcons'


export default function FFActionButton ({
  tooltip,
  style,
  className,
  onClick,
  color,
  size,
  type,
  disabled,
  ...props
}) {
  return (
    <Tooltip title={tooltip}>
      <IconButton
        onClick={onClick}
        className={className ? className : {}}
        style={style ? style : {}}
        disabled={disabled}
      >
        <FFIcons size={size} type={type} color={color} />
      </IconButton>
    </Tooltip>
  )
}
