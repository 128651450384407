import React, { useContext, useState, useEffect } from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import FFPanelMenu from "./components/FFPanelMenu";
import { useSelector, useDispatch } from "react-redux";
// import FF_Parameters from './FF_Parameters'
// import FF_Template from './FF_Template'
import FF_TemplateNew from "./FF_TemplateNew";

import FF_Manage from "./FF_ManageNew";
import FF_MaxHours from "./pages/analitics/FF_MaxHours";

// import FF_Essence from './FF_EssenceNew'

// import FF_Imba from './FF_Imba'
import FF_MappingDevice from "./FF_MappingDevice";

// import FF_MetaPage from './FF_Test'
// import FF_ConfigPanel from './TestConfig'
import FF_ConfigPanel from "./FF_PanelConfig";

// import FFToogleStyle from './components/tree.js'
// import FF_date from './commonComponents/dtPicker'
// import FFTester from './FFTester'
// import FF_BaseLine from './FF_BaseLine'
import FF_BaseLine from "./pages/configuration/baseLineNew/FF_BaseLine";
import FF_Essence from "./pages/configuration/essence/FF_Essence";

import FF_Model from "./FF_Model";

import FF_Parameters from "./FF_ParametersNew";
// import FF_Report from './reports/Wrapper'

import Context from "./context";

import cookie from "react-cookies";
import Loader from "./Loader";

// import { Switch, Route } from 'react-router-dom'
// import { browserHistory } from 'react-router';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";

import FF_Login from "./Login";
import FF_Classifier from "./FF_Classifier";
import { AsyncTree } from "./components/tree-async/tree-async";
import { ScadaPage } from "../pages/scada/index-essence-to-essence";
import { ScadaPage  as ScadaPageEssenceToDc } from "../pages/scada/index-essence-to-dc";
import { getIsLogin, setIsFull, setIsLogin } from "../features";
import { jwtDecode } from "jwt-decode";

// const routes = {
//   '/templates': () => <FF_Template />,
//   '/parameters': () => <FF_Parameters />,
//   '/essence': () => <FF_Essence />,
//   // "/login": () => <Login />,
//   '/': () => <FF_BaseLine />
//   // "/": () => <FF_Login />
// }

const App = (props) => {
  // const routeResult = useRoutes(routes)
  // console.log(props.history)
  const isLoginAuth = useSelector(getIsLogin);
  const dispatch = useDispatch();

  const [se, setSe] = useState("");

  useEffect(() => {
    if (isLoginAuth && cookie.load('access_token')) {
      const decoded = jwtDecode(cookie.load('access_token'));
      if (decoded['realm_access']['roles'].includes("CONFIGURATOR_APP_full")) {
        dispatch(setIsFull(true));
      }
    }
  }, [isLoginAuth]);

  const handleSetIsLoginAuth = (isLogin) => {
    dispatch(setIsLogin(isLogin));
  };


  useEffect(() => {
    const storeStr = localStorage.getItem("persist:ff-storage");
    if (storeStr) {
      try {
        const store = JSON.parse(storeStr);
        if (store?.auth) {
          const auth = JSON.parse(store.auth);
          if (auth.token) {
            cookie.save("access_token", auth.token);
            console.log(auth.token);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (cookie.load("access_token")) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, []);

  const fo = () => {
    let parameterByEssences = { clickChecked: true };
    localStorage.setItem(
      "parameterByEssences",
      JSON.stringify(parameterByEssences)
    );
    props.history.push("/essence");
    props.history.go("/essence");
  };


  return (
    <Router>
      <Context.Provider value={{ isLogin: handleSetIsLoginAuth }} key={isLoginAuth}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {/* { login ? (<FFPanelMenu key={"FFPanelMenu_parameter_register" + login} />) : null} */}
          {isLoginAuth && cookie.load('login') ? (
            <div className={"flex"}>
              {/* {console.log(document.location)} */}
              <FFPanelMenu
                key={
                  "FFPanelMenu_parameter_register" +
                  isLoginAuth +
                  document.location.pathname
                }
              />
              {/* <FF_date/> */}
              <Switch>
                <Route path="/mapping_essence_to_essence">
                  <ScadaPage />
                </Route>
                <Route path="/mapping_essence_to_dc">
                  <ScadaPageEssenceToDc />
                </Route>
                <Route exact path="/">
                  <FF_Essence />
                </Route>
                <Route exact path="/manage">
                  <FF_Manage />
                </Route>
                <Route path="/imba">
                  <AsyncTree />
                </Route>
                <Route exact path="/mapping_device">
                  <FF_MappingDevice />
                </Route>
                {/* <Route exact path='/power_inputs'>
                  <FF_PowerInputs history={props.history} />
                </Route> */}
                <Route exact path="/max_hours">
                  <FF_MaxHours history={props.history} />
                </Route>
                <Route exact path="/classifier">
                  <FF_Classifier />
                </Route>
                <Route exact path="/model">
                  <FF_Model />
                </Route>
                {/* <Route exact path='/reports'>
                  <FF_Report />
                </Route> */}
                <Route path="/essence">
                  <FF_Essence isLoginNo={handleSetIsLoginAuth} se={se} />
                </Route>
                <Route exact path="/templates">
                  <FF_TemplateNew />
                </Route>
                <Route path="/base_line">
                  <FF_BaseLine />
                </Route>
                {/* <Route path='/test'>
                  <FF_MetaPage />
                </Route> */}
                <Route path="/config_panel">
                  <FF_ConfigPanel />
                </Route>

                <Route path="/parameters_v2">
                  <FF_Parameters
                    history={props.history}
                    goEssence={(e) => fo()}
                  />
                </Route>
                <Route path="/parameters_v1">
                  <FF_Parameters />
                </Route>
              </Switch>
            </div>
          ) : (
            <>
              {/* {localStorage.clear()} */}
              <FF_Login key={isLoginAuth} />
            </>
          )}
        </SnackbarProvider>
      </Context.Provider>
    </Router>
  );
};

export default App;
