import React, { useEffect } from "react";
import { SelectConsumers, MappingTable } from "./components";
import { Button, makeStyles } from "@material-ui/core";
import { getFetchConsumers, postSync, syncCheck, saveFetchPairs, getScada } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import { StatusAlert } from "../status-alert";
import Snackbar from '@material-ui/core/Snackbar';
import  {setShowAlert, setShowSaveResult} from './slice';
import { SaveAlert } from "../save-alert";
import { getIsFull } from "../auth-login/slice";

export const useStyles = makeStyles((theme) => ({
  body: {
    flexGrow: 1,
    display: "flex",
    overflow: 'hidden',
    height: '100%',
  },
  header: {
    display: "flex",
    gap: 10,
  },
  buttons: {
    marginRight: 0,
    marginLeft: "auto",
    display: "flex",
    gap: 10,
  }
}));

export const Scada = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { syncStatus, showAlert, saveResult, showSaveResult } = useSelector(getScada);
  const isFull = useSelector(getIsFull);

  useEffect(() => {
    dispatch(getFetchConsumers());
  }, []);

  const handleSave = () => {
    dispatch(saveFetchPairs());
  };

  const handleSync = () => {
    dispatch(postSync());
  }

  const handleSyncCheck = () => {
    dispatch(syncCheck());
  }

  const handleCloseStatus = (event, reason) => {
    dispatch(setShowAlert(false));
  };

  const handleCloseSave = (event, reason) => {
    dispatch(setShowSaveResult(false));
  };

  return (
    <>
      <div className={classes.header}>
        <SelectConsumers />
        <Button onClick={handleSave} disabled={!isFull}
              color={!isFull ? 'gray' : 'primary'} variant="outlined">
          Сохранить
        </Button>
        <div className={classes.buttons}>
          <Button onClick={handleSync} disabled={!isFull}
              color={!isFull ? 'gray' : 'primary'} variant="outlined">
            Синхронизация
          </Button>
          <Button onClick={handleSyncCheck} color="primary" variant="outlined">
            Статус
          </Button>
        </div>
        <Snackbar open={showAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={5000} onClose={handleCloseStatus}>
          <StatusAlert syncStatus={syncStatus}/>
        </Snackbar>
        <Snackbar open={showSaveResult} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={5000} onClose={handleCloseSave}>
          <SaveAlert saveResult={saveResult}/>
        </Snackbar>
      </div>
      <div className={classes.body}>
        <MappingTable />
      </div>
    </>
  );
};
