import { pathOr } from "ramda";

/**
 * Преобразует массив объектов в дерево за O(n)
 *
 * @param {Array} items плоский список нод
 * @param {Object} config ключи для id и parentId
 * @returns {Array}
 */
export function listToTree(items, config) {
  if (!Array.isArray(items)) {
    return [];
  }

  const conf = { id: "id", parentId: "parentId", ...config };
  const rootItems = [];
  const lookup = {};
  for (const item of items) {
    const itemId = item[conf.id];
    const parentId = pathOr(null, [conf.parentId], item);
    if (!Object.prototype.hasOwnProperty.call(lookup, itemId)) {
      lookup[itemId] = { children: [] };
    }

    lookup[itemId] = { children: lookup[itemId].children, ...item };

    const TreeItem = lookup[itemId];

    if (parentId === null) {
      rootItems.push(TreeItem);
    } else {
      if (!Object.prototype.hasOwnProperty.call(lookup, parentId)) {
        lookup[parentId] = { children: [] };
      }

      lookup[parentId].children.push(TreeItem);
    }
  }

  return rootItems;
}
