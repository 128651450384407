import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Context from '../context'
import InputAdornment from '@material-ui/core/InputAdornment'

const useStyles = makeStyles(theme => ({
  textField: {
    margin: theme.spacing(1)
  }
}))

export default function FFTextField ({
  id,
  name,
  value,
  label,
  isDate,
  is_multiline,
  is_disabled,
  style,
  ...props
}) {
  const { changeValue } = useContext(Context)
  const classes = useStyles()
  const [valueTemp, setValueTemp] = useState(value ? value : '')
  const [errors, setError] = useState('')

  //Cобыие на изменение
  const handleChange = event => {
    setError(changeValue(event.target.value, id, name, event.target))
    setValueTemp(event.target.value)
  }
  return (
    <TextField
      //Внещний вид
      variant='filled'
      // InputProps={{
      //   startAdornment: props?.measure_unit ? (
      //     <InputAdornment position='start'>{props.measure_unit}</InputAdornment>
      //   ) : null
      // }}
      type={isDate ? isDate : ''}
      InputLabelProps={
        isDate
          ? {
              shrink: true
            }
          : null
      }
      className={classes.textField}
      style={style}
      id={`FFTextField_${id}_${name}`}
      label={label ? label : 'Наименование'}
      value={valueTemp ? valueTemp : ''}
      onChange={handleChange}
      // Мульти строки
      multiline={is_multiline ? is_multiline : false}
      rowsMax='10'
      // Блокировка компонента
      disabled={is_disabled ? is_disabled : false}
      // Ошибки
      error={errors && errors.length > 0 ? true : false}
      helperText={errors}
      // Странная история
      onClick={props.doClick ? e => props.doClick(e) : null}
    />
  )
}
