import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Select from '@material-ui/core/Select'
import Context from '../context'
import {
  Slider,
  Grid,
  Typography,
  Button,
  IconButton,
  TextField
} from '@material-ui/core'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'
import AddBoxIcon from '@material-ui/icons/AddBoxOutlined'

const useTreeItemStyles = makeStyles(theme => ({
  sliderGrid: {
    margin: '4px',
    width: '-webkit-fill-available',
    // padding: '4px',
    borderRadius: '15px',
    border: '1px solid ' + theme.palette.primary.main,
    textAlign: 'center'
  }
}))

export default function FFSlider (props) {
  const classes = useTreeItemStyles()

  const { handleChangeFFTextFields } = useContext(Context)
  const [tempValue, setTempValue] = useState(0)
  const [error, setError] = useState(null)
  const [value, setValue] = useState(
    props.type === 'Two'
      ? Array.isArray(props.value) &&
        props.value.length === 2 &&
        isNaN(Number(props.value[0])) === false &&
        isNaN(Number(props.value[1])) === false
        ? props.value
        : [0, 10]
      : props.type === 'One'
      ? isNaN(Number(props.value))
        ? 0
        : Number(props.value)
      : null
  )
  const label = props.label
  const min =
    'options' in props &&
    'left' in props.options &&
    'value' in props.options.left &&
    isNaN(Number(props.options.left.value)) === false
      ? Number(props.options.left.value)
      : 0
  const max =
    'options' in props &&
    'right' in props.options &&
    'value' in props.options.right &&
    isNaN(Number(props.options.right.value)) === false
      ? Number(props.options.right.value)
      : 10
  const step =
    'options' in props &&
    'step' in props.options &&
    isNaN(Number(props.options.step)) === false
      ? Number(props.options.step)
      : 1

  useEffect(() => {
    setTempValue(value)
  }, [value])

  const handleChange = (e, val) => {
    setValue(val)
    // console.log(val)
    setTempValue(val)
    handleChangeFFTextFields(
      val,
      props.id,
      'range' + props.type,
      'range' + props.type
    )
  }

  const onChangeText = val => {
    setTempValue(val)
    setError(null)
    // if (isNaN(Number(val)) === false) {
    //   setValue(Number(val))
    // }
    // else {
    //   setValue(Number(val))
    // }
  }
  const onKeyDownGo = event => {
    if (
      event.key === 'Enter' &&
      isNaN(Number(tempValue)) === false &&
      Number(tempValue) <= max &&
      Number(tempValue) >= min
    ) {
      setValue(Number(tempValue))
      handleChangeFFTextFields(
        Number(tempValue),
        props.id,
        'range' + props.type,
        'range' + props.type
      )
    }
    else if(event.key === 'Enter'){
      setError('Введите число')
    }
    // else if (event.key === 'Enter') {
    //   setValue(min)
    // }
    if (event.key === 'Escape') {
      setTempValue(0)
    }
  }
  // console.log(props, value, max, min, step)

  return (
    <Grid container item xs={12} className={classes.sliderGrid}>
      <Grid item xs={12}>
        <Typography>{props.label}</Typography>
      </Grid>
      <Grid item container xs={12} style={{ margin: '35px 0px 10px' }}>
        <Grid item xs={2}>
          <Typography>{min}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Slider
            aria-labelledby='discrete-slider'
            value={value}
            valueLabelDisplay='auto'
            step={step}
            marks={true}
            min={min}
            onChangeCommitted={handleChange}
            max={max}
            valueLabelDisplay={value >= min && value <= max ? 'on' : 'off'}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography>{max}</Typography>
        </Grid>
      </Grid>
      {props?.type === 'Two' ? null : (
        <Grid item container xs={12} style={{marginBottom: 4}}>
          <Grid item xs={2}>
            <IconButton
              aria-label='delete'
              color='primary'
              onClick={e => handleChange(e, value - step)}
            >
              <IndeterminateCheckBoxIcon />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <TextField
              onKeyDown={onKeyDownGo}
              id='filled-basic'
              label='Значение'
              variant='filled'
              error={error ? true : false}
              placeholder={error}
              value={tempValue}
              onChange={e => onChangeText(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              aria-label='delete'
              color='primary'
              onClick={e => handleChange(e, value + step)}
            >
              <AddBoxIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
