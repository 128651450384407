import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Context from '../context'

const useStyles = makeStyles({
  select: {
	width: "-webkit-fill-available",
	margin: "4px",
  }
})

export default function FFSelect (props) {
  const classes = useStyles()

  const { handleChangeFFTextFields } = useContext(Context)

  const [value, setValue] = useState(props.value)
  const label = props.label
  const options = props.options

  const handleChange = val => {
    setValue(String(val))
    handleChangeFFTextFields(String(val), props.id, props.name, 'select')
  }

  return (
    <FormControl className={classes.select} style={props?.style ? props.style : {}}variant='filled'>
      <InputLabel>{label}</InputLabel>
      {/* {console.log('Select', props)} */}
      <Select
        labelId='ffselect-label'
        value={String(value)}
        onChange={e => handleChange(e.target.value)}
      >
        {options ? options.map(obj => {
          return <MenuItem value={String(obj.id)}>{obj.label}</MenuItem>
        }) : null}
      </Select>
    </FormControl>
  )
}
