import React, { useEffect, useState } from 'react'
// import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip'
import FFToggle from './FFToggle'

export default function FFListToggle (props) {
  const [attr, setAttr] = useState([])
  useEffect(() => {
    let q = []
    let q_val = []
    let is_attr = []
    q = Object.keys(props.parameter)
    q_val = Object.values(props.parameter)
    let i = 0
    while (i < q.length) {
      if (props.name_attr.indexOf(q[i]) !== -1) {
        is_attr.push({ name: q[i], value: q_val[i] })
      }
      i++
    }

    setAttr(is_attr)

  }, [])

  return attr.map((obj, index) => {
    return (
      <React.Fragment key={'FFToggle' + index}>
        {index + 1 === 0 ||
        ('is_line' in props && props['is_line'] === true) ? null : (
          <br />
        )}
        <FFToggle
          is_filter={props.is_filter}
          type_children={props.type_children}
          parameter={props.parameter}
          name={obj.name}
          value={obj.value}
          label={props.label_attr ? props.label_attr[obj.name].label : obj.name}
          labelToolTip={
            props.label_attr && props.label_attr[obj.name].labelToolTip
              ? props.label_attr[obj.name].labelToolTip
              : obj.name
          }
        />
      </React.Fragment>
    )
  })
}
