import React, { useState, useEffect } from "react";
import { Radio, TextField } from "@material-ui/core";

export const IntervalItem = ({
  checked,
  value,
  left,
  right,
  index,
  handleSetCurrentIndex,
  handleChangeInterval,
}) => {
  const [currentValue, setCurrentValue] = useState([left, right]);

  useEffect(() => {
    if (Array.isArray(value) && value.length === 2) {
      let _l = Number(value[0]);
      let _r = Number(value[1]);

      if (_l > _r) {
        [_l, _r] = [_r, _l];
      }

      if (Number.isNaN(_l) || _l < left) {
        _l = left;
      }
      if (Number.isNaN(_r) || _r > right) {
        _r = right;
      }

      setCurrentValue([_l, _r]);
    }
  }, [value]);

  const handleChangeCurrentIndex = (event) => {
    handleSetCurrentIndex(Number(event.target.value));
  };

  const handleChangeValue = ({ target: { name, value } }) => {
    let [_l, _r] = currentValue;
    if (name === "left") {
      _l = value;
    } else {
      _r = value;
    }

    if (Number.isNaN(Number(_l)) && Number.isNaN(Number(_r))) {
      _l = Number(_l);
      _r = Number(_r);
      if (_l > _r) {
        [_l, _r] = [_r, _l];
      }
    }
    setCurrentValue([_l, _r]);
  };

  const handleFocusLoss = () => {
    let [_l, _r] = currentValue;
    _l = Number(_l);
    _r = Number(_r);
    if (!Number.isNaN(_l) && !Number.isNaN(_r)) {
      if (_l < _r) {
        handleChangeInterval([_l, _r]);
      } else {
        handleChangeInterval([_r, _l]);
      }
    }
  };

  return (
    <div style={{ display: "flex", gap: 5 }}>
      <Radio
        checked={checked}
        onChange={handleChangeCurrentIndex}
        value={index}
        size="small"
        color="primary"
      />
      <TextField
        value={currentValue[0]}
        size="small"
        label="Начало"
        variant="outlined"
        type="number"
        name={"left"}
        onChange={handleChangeValue}
        onBlur={handleFocusLoss}
        style={{ width: "100%" }}
        fullWidth
        disabled={!checked}
        error={Number.isNaN(Number(currentValue[0]))}
        helperText={
          Number.isNaN(Number(currentValue[0])) ? "Укажите число" : ""
        }
        inputProps={{ min: left, max: right }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        value={currentValue[1]}
        size="small"
        label="Конец"
        variant="outlined"
        type="number"
        fullWidth
        name={"right"}
        onChange={handleChangeValue}
        onBlur={handleFocusLoss}
        style={{ width: "100%" }}
        disabled={!checked}
        error={Number.isNaN(Number(currentValue[1]))}
        helperText={
          Number.isNaN(Number(currentValue[1])) ? "Укажите число" : ""
        }
        inputProps={{ min: left, max: right }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
};
