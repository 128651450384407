import React, { useContext } from 'react'

import {
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core/'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import IconExpandMore from '@material-ui/icons/ExpandMore'

/*
 **-------------------- My Components ---------------------------
 */

import FFTextField from '../../../libft/FFTextField'
import FFSwitch from '../../../libft/FFSwitch'

import Context from '../../../context'

const useStyles = makeStyles(theme => ({
  fullGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  }
}))

export default function FFCardScenario ({
  scenario,
  setScenario,
  activePage,
  ...props
}) {
  const { toGoSave } = useContext(Context)

  const classes = useStyles()

  // const [scenario, setScenario] = useState(props.scenario)
  // Функция для изменения значений активной сущности
  const changeValue = (value, id, name, element) => {
    if (id.indexOf('scenario') > -1) {
      let tempScenario = scenario
      if (name === 'date_start_calc' || name === 'date_validation_calc')
        value = new Date(value).toGMTString()
      tempScenario[name] = value
      setScenario(tempScenario)
      toGoSave(tempScenario)
    }
  }

  return (
    <Context.Provider value={{ changeValue }}>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<IconExpandMore />}>
          <Grid
            item
            xs={12}
            container
            style={{
              pointerEvents: activePage.indexOf('edit.') > -1 ? 'none' : ''
            }}
          >
            <Grid item xs={4}>
              <FFSwitch
                value={scenario.is_active}
                id={'scenario.is_active'}
                name={'is_active'}
              />
              {scenario.is_active ? 'Активировано' : 'Активировать'}
            </Grid>
            <Grid
              item
              xs={8}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography>{'Общие сведения'}</Typography>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid item xs={12} container>
            <Grid item xs={6} container>
              <Grid item xs={12} className={classes.fullGrid}>
                <FFTextField
                  value={scenario.label}
                  id={'scenario.label'}
                  name={'label'}
                  label={'Наименование'}
                />
              </Grid>
              <Grid item xs={12} className={classes.fullGrid}>
                <FFTextField
                  value={scenario.name}
                  id={'scenario.name'}
                  name={'name'}
                  label={'Системное наименование'}
                  is_disabled={true}
                />
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={6} className={classes.fullGrid}>
                  <FFTextField
                    value={scenario.author}
                    id={'scenario.author'}
                    name={'author'}
                    label={'Автор'}
                    is_disabled={true}
                  />
                </Grid>
                <Grid item xs={6} className={classes.fullGrid}>
                  <FFTextField
                    value={new Date(scenario.date_creation).toLocaleString()}
                    id={'scenario.date_creation'}
                    name={'date_creation'}
                    label={'Дата создания'}
                    is_disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={6} className={classes.fullGrid}>
                  <FFTextField
                    value={scenario.editor}
                    id={'scenario.editor'}
                    name={'editor'}
                    label={'Автор редактирования'}
                    is_disabled={true}
                  />
                </Grid>
                <Grid item xs={6} className={classes.fullGrid}>
                  <FFTextField
                    value={new Date(scenario.date_edition).toLocaleString()}
                    id={'scenario.date_edition'}
                    name={'date_edition'}
                    label={'Дата редактирования'}
                    is_disabled={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} container>
              <Grid item xs={12} className={classes.fullGrid}>
                <FFTextField
                  value={scenario.description}
                  id={'scenario.description'}
                  name={'description'}
                  label={'Описание'}
                  is_multiline={true}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.fullGrid}>
              <FFTextField
                value={
                  new Date(scenario.date_start_calc).toJSON().split('T')[0] +
                  'T' +
                  new Date(scenario.date_start_calc)
                    .toLocaleString()
                    .split(', ')[1]
                }
                id={'scenario.date_start_calc'}
                name={'date_start_calc'}
                isDate={'datetime-local'}
                label={'Дата начала расчета'}
              />
            </Grid>
            <Grid item xs={6} className={classes.fullGrid}>
              <FFTextField
                value={
                  new Date(scenario.date_validation_calc)
                    .toJSON()
                    .split('T')[0] +
                  'T' +
                  new Date(scenario.date_validation_calc)
                    .toLocaleString()
                    .split(', ')[1]
                }
                id={'scenario.date_validation_calc'}
                name={'date_validation_calc'}
                isDate={'datetime-local'}
                label={'Дата валидирования'}
              />
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Context.Provider>
  )
}
