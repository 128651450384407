import React, { useState, useEffect, Fragment } from "react";
import Context from "../../../../context";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Typography,
} from "@material-ui/core";
import { SliderIntevalRange } from "../components/slider-interval-range";
import FFTextFields from "../../../../components/FFTextFields";
import FF_Classifier_step3_formatData_list from "./FF_Classifier_step3_formatData_list";
import FFToogleStyle from "../../../../components/FFToogleStyle";

import FF_Classifier_step3_typeData_bool from "./FF_Classifier_step3_typeData_bool";
import { validation_data } from "../../../../supportFunction";

export default function FF_Essence_parameterRouterList(props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // ---------------------------- ---------------------------------------------------
  const [meta, setMeta] = useState(props.meta);
  const [metaList, setMetaValue] = useState(
    props.meta && props.meta.value ? props.meta.value : []
  );
  const [value, setValue] = useState(
    props.value && Array.isArray(props.value) && props.value !== null
      ? props.value
      : null
  );
  // console.log('!!!!', value, metaList, meta)
  // console.log(props.meta, props)
  const [te, setTe] = useState(0);
  const [currentIdRange, setCurrentIdRange] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const type = props.type;
  const source = props.source;

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    // console.log(props)
    // if (props.value) {
    //   setValue(props.value)
    // } else
    // console.log(value)
    let temp = value;

    if (temp === null) {
      if (meta && Number(meta.length) >= 0) {
        if (source.name === "p-source-data-user") {
          if (meta["p-format-data"] === "p-format-data-interval-range") {
            temp = Array.from({ length: meta.length }, (v, i) => [
              meta.value.left.value ?? 0,
              meta.value.right.value ?? 10,
            ]);
            // console.log(temp, "temp");
          } else if (
            [
              "p-type-data-bool",
              "p-type-data-float",
              "p-type-data-int",
              "p-type-data-timestamp",
              "p-type-data-ushort",
            ].indexOf(type) > -1
          ) {
            temp = Array.from({ length: meta.length }, (v, i) => 0);
          } else {
            temp = Array.from({ length: meta.length }, (v, i) => "");
          }
        } else {
          temp = Array.from({ length: meta.length }, (v, i) => "");
        }
      } else {
        temp = [];
      }
    }
    setValue(temp);
  }, []);

  const doChangeSelect = (e, i) => {
    let temp = value;
    temp[i] = e.target.value;
    setValue(temp);
    props.getBack({ name: "p-format-data-list", value: temp, meta: meta });
    setTe(te + 1);
  };

  const pFormatParent = (nameAttr, data, flagLength) => {
    setMeta(data);
    setMetaValue(data.value);
    if (true) {
      if (source.name === "p-source-data-user") {
        if (flagLength === "length") {
          if (
            [
              "p-type-data-bool",
              "p-type-data-float",
              "p-type-data-int",
              "p-type-data-timestamp",
              "p-type-data-ushort",
            ].indexOf(type) > -1
          ) {
            setValue(Array.from({ length: data.length }, (v, i) => 0));
          } else {
            setValue(Array.from({ length: data.length }, (v, i) => ""));
          }
        }
      } else {
        setValue(Array.from({ length: data.length }, (v, i) => ""));
      }
      // console.log(value, meta)
      props.getBack({ name: "p-format-data-list", value: value, meta: meta });
    }
    setTe(te + 1);
  };

  const handleChangeFFTextFields = (value2, id, name, element) => {
    // console.log(value2, id, name, element)
    if (element) {
      if (source.name === "p-source-data-user") {
        if (
          meta["p-format-data"] === "p-format-data-interval-range" &&
          validation_data(type, value2) === ""
        ) {
          let [idy, idx] = name.split(":");
          idy = idy === "left" ? 0 : 1;
          idx = Number(idx);
          let temp = value;
          temp[idx][idy] = value2;
          setValue(temp);
          props.getBack({
            name: "p-format-data-list",
            value: temp,
            meta: meta,
          });
        } else if (String(name).indexOf("bool_") > -1) {
          let temp = value;
          temp[Number(name.replace("bool_", ""))] = value2;
          setValue(temp);
        } else if (validation_data(type, value2) === "") {
          if (
            [
              "p-type-data-bool",
              "p-type-data-float",
              "p-type-data-int",
              "p-type-data-timestamp",
              "p-type-data-ushort",
            ].indexOf(type) > -1
          ) {
            value2 = Number(value2);
          }
          let temp = value;
          temp[name] = value2;
          setValue(temp);
          props.getBack({
            name: "p-format-data-list",
            value: temp,
            meta: meta,
          });
        } else {
          let temp = value;
          temp[name] = "ValidationError";
          props.getBack({
            name: "p-format-data-list",
            value: temp,
            meta: meta,
          });
          return validation_data(type, value2);
        }
      } else {
        let temp = value;
        temp[name] = value2;
        props.getBack({ name: "p-format-data-list", value: temp, meta: meta });
      }
    }
    return "";
  };

  const pFormatDataValueList = (nameAttr, data, flagLength) => {
    if (nameAttr === "bool-value") {
      Object.keys(data).map((key) => {
        meta[key] = data[key];
      });
      setMeta(meta);
      setTe(te + 1);
      props.getBack({ name: "p-format-data-list", value: value, meta: meta });
    }
    // let temp = meta
    // if (!temp || temp === ){
    //   temp = {}
    // }
    // if(nameAttr === 'bool-value'){
    //   temp[nameAttr] = data
    // }
    // setMeta(temp)
    // console.log('---->', temp)
    else {
      props.getBack({ name: "p-format-data-list", value: value, meta: data });
    }
  };

  const handleChange = (event, newValue) => {
    let temp = value;
    // console.log(currentIndex, newValue)
    temp[currentIndex] = newValue;
    setValue(temp);
    setTe(te + 1);
    props.getBack({ name: "p-format-data-list", value: temp, meta: meta });
    // setValue(newValue)
  };

  const handleChangeRange = (result) => {
    setValue(result)
    props.getBack({ name: "p-format-data-list", value: result, meta });
  };

  // Клик на Текстовое поле Range
  const doClick = (e, idElement, i) => {
    // console.log(currentIdRange, idElement);
    if (currentIdRange !== "" && document.getElementById(currentIdRange)) {
      // console.log(document.getSelection(currentIdRange).anchorNode, currentIdRange)
      // console.log(document.getSelection(idElement).anchorNode, idElement)

      document.getElementById(currentIdRange).parentElement.style.background =
        "";
    }
    if (document.getElementById(idElement)) {
      // console.log(document.getElementById(idElement), idElement)
      document.getElementById(idElement).parentElement.style.background =
        "#e8eaf6";
    }
    setCurrentIdRange(idElement);
    setCurrentIndex(i);
    // console.log(e, idElement)
  };

  useEffect(() => {
    props.getBack({ name: "p-format-data-list", value: value, meta: meta });
  }, [value]);

  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={2}>
        <Typography variant={"body1"}>{props.label}</Typography>
        <Typography
          variant={"body2"}
          color={"primary"}
        >{`(${props.name})`}</Typography>
      </Grid>
      <Grid
        item
        xs={5}
        style={{ textAlign: "center" }}
        key={"GridValues" + props.id}
      >
        <Typography>{"Источник данных - " + source.label}</Typography>
        {/* {console.log(value)} */}
        {value && value.length < 42 ? (
          source.name !== "p-source-data-user" && value ? (
            <Grid container xs={12} spacing={1} key={te + "SorceValueList"}>
              {value.map((valueList, i) => {
                return (
                  <Context.Provider value={{ handleChangeFFTextFields }}>
                    <Grid item xs={6}>
                      <FFTextFields
                        style={{ width: "100%" }}
                        key={"FFTextFields" + i}
                        value={String(valueList)}
                        is_filter={false}
                        name={i}
                        label={"Значение " + (i + 1)}
                        parameter={{ id: i }}
                        dis={false}
                      />
                    </Grid>
                  </Context.Provider>
                );
              })}
            </Grid>
          ) : meta &&
            meta["p-format-data"] === "p-format-data-value-list" &&
            value ? (
            value.map((valueList, i) => {
              return (
                <FormControl
                  variant="filled"
                  style={{ margin: 4, width: "100%" }}
                  key={
                    "FormControl" +
                    props.id +
                    i +
                    "_" +
                    (valueList ? valueList : "")
                  }
                >
                  <InputLabel>Значение</InputLabel>
                  <Select
                    key={"Select" + props.id + i + "_" + valueList}
                    value={valueList ? valueList : ""}
                    onChange={(e) => doChangeSelect(e, i)}
                  >
                    {metaList
                      ? metaList.map((objChild) => {
                          return (
                            <MenuItem
                              value={objChild.id}
                              id={objChild.id}
                              key={"SelectMenuItem_" + objChild.id}
                            >
                              {objChild.value}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                </FormControl>
              );
            })
          ) : meta &&
            (meta["p-format-data"] === "" ||
              meta["p-format-data"] === "p-format-data-value-constant") &&
            value &&
            type !== "p-type-data-bool" ? (
            <Grid container xs={12} spacing={1} key={te + "SorceValueList"}>
              {value.map((valueList, i) => {
                return (
                  <Context.Provider value={{ handleChangeFFTextFields }}>
                    <Grid item xs={6}>
                      <FFTextFields
                        style={{ width: "100%" }}
                        key={"FFTextFields" + i}
                        value={String(valueList)}
                        is_filter={false}
                        name={i}
                        label={"Значение " + (i + 1)}
                        parameter={{ id: i }}
                        dis={false}
                      />
                    </Grid>
                  </Context.Provider>
                );
              })}
            </Grid>
          ) : meta &&
            meta["p-format-data"] === "p-format-data-value-range" &&
            value ? (
            <>
              <Grid container spacing={1}>
                <Grid item>{meta.value.left.value}</Grid>
                <Grid item xs={10} style={{ alignSelf: "center" }} spacing={1}>
                  <Slider
                    aria-labelledby="discrete-slider"
                    value={value[currentIndex]}
                    valueLabelDisplay="auto"
                    step={Number(meta.value.step)}
                    marks
                    min={
                      Number(meta.value.left.value) <=
                      Number(meta.value.right.value)
                        ? Number(meta.value.left.value)
                        : 0
                    }
                    onChange={handleChange}
                    max={Number(meta.value.right.value)}
                  />
                </Grid>
                <Grid item>{meta.value.right.value}</Grid>
              </Grid>
              <Grid container xs={12} spacing={1}>
                {value.map((valueList, i) => {
                  return (
                    <Context.Provider value={{ handleChangeFFTextFields }}>
                      <Grid item xs={6}>
                        <FFTextFields
                          style={{ width: "100%" }}
                          key={"FFTextFields" + i + valueList}
                          value={valueList}
                          is_filter={false}
                          id={"textRange" + i + props.id}
                          name={i}
                          doClick={(e) =>
                            doClick(
                              e,
                              "textRange" + props.id + "_" + i + "_text",
                              i
                            )
                          }
                          label={"Значение " + (i + 1)}
                          parameter={{ id: "textRange" + props.id }}
                          dis={false}
                        />
                      </Grid>
                    </Context.Provider>
                  );
                })}
              </Grid>
            </>
          ) : meta &&
            meta["p-format-data"] === "p-format-data-interval-range" &&
            value ? (
            <>
              qwe43
              <SliderIntevalRange
                options={meta}
                value={value}
                onChangeValue={handleChangeRange}
              />
              {/* <Grid container spacing={1}>
                <Grid item>{meta.value.left.value}</Grid>
                <Grid item xs={10} style={{ alignSelf: "center" }} spacing={1}>
                  <Slider
                    aria-labelledby="discrete-slider"
                    value={[
                      value?.[currentIndex]?.[0] ?? 0,
                      value?.[currentIndex]?.[1] ?? 10,
                    ]}
                    valueLabelDisplay="auto"
                    step={Number(meta.value.step)}
                    marks
                    min={
                      Number(meta.value.left.value) <=
                      Number(meta.value.right.value)
                        ? Number(meta.value.left.value)
                        : 0
                    }
                    onChange={handleChangeRange}
                    max={Number(meta.value.right.value)}
                  />
                </Grid>
                <Grid item>{meta.value.right.value}</Grid>
              </Grid>
              <Grid container xs={12} spacing={1}>
                {value.map((intervalValue, i) => {
                  const [left, right] = Array.isArray(intervalValue)
                    ? intervalValue
                    : [];
                  return (
                    <Context.Provider value={{ handleChangeFFTextFields }}>
                      <Grid item xs={12} container>
                        <Grid item xs={6}>
                          <FFTextFields
                            style={{ width: "100%" }}
                            key={"FFTextFields" + i + left}
                            value={left}
                            is_filter={false}
                            id={"textRange" + i + props.id}
                            name={`left:${i}`}
                            type="number"
                            doClick={(e) =>
                              doClick(
                                e,
                                "textRange" + props.id + "_left:" + i + "_text",
                                i
                              )
                            }
                            label={"Начало интервала"}
                            parameter={{ id: "textRange" + props.id }}
                            dis={false}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FFTextFields
                            style={{ width: "100%" }}
                            key={"FFTextFields" + i + right}
                            value={right}
                            type="number"
                            is_filter={false}
                            id={"textRange" + i + props.id}
                            name={`right:${i}`}
                            doClick={(e) =>
                              doClick(
                                e,
                                "textRange" +
                                  props.id +
                                  "_right:" +
                                  i +
                                  "_text",
                                i
                              )
                            }
                            label={"Конец интервала"}
                            parameter={{ id: "textRange" + props.id }}
                            dis={false}
                          />
                        </Grid>
                      </Grid>
                    </Context.Provider>
                  );
                })}
              </Grid> */}
            </>
          ) : type === "p-type-data-bool" && value ? (
            value.map((valueList, i) => {
              return (
                <Context.Provider value={{ handleChangeFFTextFields }}>
                  <FFToogleStyle
                    value={valueList}
                    off={
                      meta && meta["label-false"]
                        ? meta["label-false"]
                        : "Выключено"
                    }
                    on={
                      meta && meta["label-true"]
                        ? meta["label-true"]
                        : "Включено"
                    }
                    meta={meta && meta["bool-value"]}
                    name={"bool_" + i}
                    id={props.id + i}
                  />
                </Context.Provider>
              );
            })
          ) : null
        ) : (
          <Typography color="error">
            {"Уменьшите количество элементов списка"}
          </Typography>
        )}
      </Grid>
      <Grid item xs={5} style={{ textAlign: "center" }}>
        <Typography>{"Формат данных - Список"}</Typography>
        <Context.Provider value={{ pFormatParent }}>
          <FF_Classifier_step3_formatData_list
            key={"FF_Classifier_step3_formatData_list" + props.id}
            id={props.id}
            value={JSON.parse(JSON.stringify({ value: meta }))}
            props={"meta"}
          />
        </Context.Provider>
        {type === "p-type-data-bool" ? (
          <Context.Provider value={{ pFormatDataValueList }}>
            <FF_Classifier_step3_typeData_bool
              value={{ value: meta ? meta : null }}
            />
          </Context.Provider>
        ) : null}
      </Grid>
    </Grid>
  );
}
