import React, { useState, useContext } from 'react';
import { DateRangePicker } from 'rsuite';
import { makeStyles } from '@material-ui/core/styles';
import "../styles/rsuite-default-fix.css";
import Context from '../context'

const useStyles = makeStyles(theme => ({
    datePicker: {
        zIndex: theme.zIndex.drawer + 2,
    }
}));

const Locale = {
    sunday: 'Вс',
    monday: 'Пн',
    tuesday: 'Вт',
    wednesday: 'Ср',
    thursday: 'Чт',
    friday: 'Пт',
    saturday: 'Сб',
    ok: 'OK',
    today: 'Сегодня',
    yesterday: 'Вчера',
    last7Days: 'Последние 7 Дней',
    hours: 'Часов',
    minutes: 'Минут',
    seconds: 'Секунд'
};

const Ranges = [
    {label: "Неделя"},
    {label: "Месяц"},
    {label: "Квартал"},
    {label: "Год"},
];

const { afterToday } = DateRangePicker;

export default (props) => {
    const classes = useStyles();
    const [DateRange, SetDateRange] = useState(props.value);
    const {handleChangeFFTextFields} = useContext(Context)

	const changeDate = (value) => {
		SetDateRange(value)
		handleChangeFFTextFields(value, props.id, props.name, 'dateRange')
	}
    return (
        <DateRangePicker
            className={classes.datePicker}
            placement="bottomEnd"
            value={DateRange}
            onChange={value => {changeDate(value)}}
            locale={Locale}
            disabledDate={afterToday()}
            format={"DD/MM/YYYY"}
            ranges={Ranges}
        />
    )
}
