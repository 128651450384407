import React, { useContext, useState } from 'react'
import Context from '../context'
import '../styles/FFToggleSimple.css'
import { Grid, Typography } from '@material-ui/core'
import IconCheck from '@material-ui/icons/Check'
import IconClear from '@material-ui/icons/Clear'
import { useTheme } from '@material-ui/core/styles'

export default function FFToggleSimple (props) {
  // console.log(props)
  const theme = useTheme()

  const state =
    isNaN(Number(props.value)) || props.value === null
      ? 0.5
      : Number(Boolean(Number(props.value)))

  const { handleChangeFFTextFields } = useContext(Context)

  const changeToggle = val => {
    handleChangeFFTextFields(val, props.id, props.name, 'toogle')
  }
  // console.log(props)
  return (
    <Grid container item xs={12} style={props?.style ? props.style : {}}>
      {/* <Grid item> */}
      <div
        className='toggle'
        onClick={e => changeToggle(Number(!Boolean(state)))}
      >
        <span
          className='base'
          style={{
            '--active':
              state === 1 ? theme.palette.primary.main : theme.palette.grey[300]
          }}
        ></span>
        <span
          className='status'
          style={{ '--position': `${state * 20 + 3}px` }}
        >
          {state === 1 ? (
            <IconCheck
              fontSize='small'
              style={{
                position: 'relative',
                top: '2px',
                left: '1px',
                color: theme.palette.primary.main
              }}
            />
          ) : state === 0 ? (
            <IconClear
              fontSize='small'
              style={{
                position: 'relative',
                top: '2px',
                left: '0px',
                color: theme.palette.grey[500]
              }}
            />
          ) : null}
        </span>
      </div>
      <Typography style={{ margin: 4, alignSelf: 'center' }}>
        {props.label}
      </Typography>
      {/* </Grid> */}
    </Grid>
  )
}
