import React, { useEffect, useState, useContext } from 'react'
import { Grid } from '@material-ui/core'

import Context from '../../../context'


import FFSelectTree from '../../../components/FFSelectTree'

export default function FFEssenceCardClassifier (props) {

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // ---------------------------- ---------------------------------------------------

  const [currentMeta, setCurrentMeta] = useState({})
  const [currentEssence, setCurrentEssence] = useState(props.essence)
  const [openId, setOpenId] = useState({})

  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  useEffect(() => {
    let tempMeta = {}
    let tempOpen = {}
    props.classifier.sort((a, b) => {
      if (a.label > b.label) {
        return 1
      }
      if (a.label < b.label) {
        return -1
      }
      return 0
    })
    props.classifier.map(obj => {
      tempMeta[obj.id] = JSON.parse(JSON.stringify(obj))
      tempOpen[obj.id] = {}
      tempOpen[obj.id].open = false
      tempOpen[obj.id].active = -1
    })
    setCurrentMeta(tempMeta)
    setOpenId(tempOpen)
  }, [])

  const doChangeSelect = (parentId, id) => {
    let temp = currentEssence
    temp.active_classifier_values[parentId] = id
    setCurrentEssence(temp)
  }
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------
  return (
    <Context.Provider value={{ doChangeSelect }}>
      <Grid container item xs={12} spacing={1}>
        {[true, false].map(valueIsSystem => {
          return (
            <Grid
              item
              xs={6}
              key={
                valueIsSystem
                  ? 'GridSystem_Classifier'
                  : 'GridNoSystem_Classifier'
              }
            >
              {Object.keys(currentMeta).map(idCls => {
                if (currentMeta[idCls].is_system === valueIsSystem) {
                  return (
                    <FFSelectTree
                      key={'FFSelectTree_' + idCls}
                      stateMeta={openId[idCls]}
                      idCls={idCls}
                      selectValue={
                        currentEssence.active_classifier_values[idCls]
                          ? currentEssence.active_classifier_values[idCls]
                          : ''
                      }
                      selectMetaLabel={currentMeta[idCls].label}
                      selectNodes={currentMeta[idCls].children}
                    />
                  )
                }
              })}
            </Grid>
          )
        })}
      </Grid>
    </Context.Provider>
  )
}
