import React, { useEffect, useState, useContext } from 'react'

import Context from '../../../context'

import { Grid, Divider } from '@material-ui/core'

import { Pagination } from '@material-ui/lab'
/*
 **------------------- My Page Components -------------------------
 */

import FFEssenceCardParameterRouter from './FFEssenceCardParameterRouter'
import FFLoader from '../../../libft/FFLoader'
import FFTextField from '../../../libft/FFTextField'

export default function FFEssenceCardParameters (props) {
  // -------------------------------------------------------------------------------
  //
  //                                   Стили
  //
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  //
  //                                 Состояния
  //
  // ---------------------------- ---------------------------------------------------

  const [essence, setEssence] = useState(null)
  const [page, setPage] = useState(1) // Текущая страница
  const [countPage, setCountPage] = useState(10) // Количество страниц
  const [search, setSearch] = useState('') // Строка поиска
  const cd = 10  // Количество объектов на страницы
  // -------------------------------------------------------------------------------
  //
  //                                 Обработчики событий
  //
  // -------------------------------------------------------------------------------

  // ПУстышка
  const handleChangeFFTextFields = props => {
    return ''
  }

  useEffect(() => {
    let count = Math.ceil(
      Object.keys(props.essence.full_parameter_values).length / 10
    )
    setCountPage(count)
    setEssence(props.essence)
  }, [])

  // Обратка для сохранения
  const getBack = (parameter, id) => {
    let temp = essence
    temp.parameter_values[id] = { meta: parameter.meta, value: parameter.value }
    temp.full_parameter_values[id] = parameter
    props.getBack(temp)
    setEssence(temp)
  }
  // -------------------------------------------------------------------------------
  //
  //                                    Рендеринг
  //
  // -------------------------------------------------------------------------------

  const getParameters = () => {
    let i = (page - 1) * cd
    let param = []
    let idParam = Object.keys(essence.full_parameter_values)
    idParam = idParam.filter(
      id =>
        essence.full_parameter_values[id].label
          .toLowerCase()
          .includes(search.toLocaleLowerCase()) ||
          essence.full_parameter_values[id].name
          .toLowerCase()
          .includes(search.toLocaleLowerCase())
    )
    while (i < page * cd && i < idParam.length) {
      param.push(
        <React.Fragment>
          <Divider style={{ width: '100%', height: 2, margin: 10 }} />
          <FFEssenceCardParameterRouter
            parameter={essence.full_parameter_values[idParam[i]]}
            parameterId={idParam[i]}
            getBack={getBack}
          />
        </React.Fragment>
      )
      i += 1
    }
    return param
  }

  // Контекст для обнволения поиска
  const changeValue = (value, id, name, element) => {
    setPage(1)
    setSearch(value)
  }

  return (
    <Context.Provider value={{ handleChangeFFTextFields, changeValue }}>
      <Grid container item xs={12} spacing={1}>
        {essence ? (
          <React.Fragment>
            <Grid item xs={12} container>
              <Grid item xs={6} container direction={'column'} alignItems={'center'} justify={'center'}>
                <Pagination
                  page={page}
                  count={countPage}
                  variant='outlined'
                  shape='rounded'
                  color='primary'
                  onChange={(e, val) => setPage(val)}
                />
              </Grid>
              <Grid item xs={6} container direction={'column'}>
                <FFTextField
                  id={'search'}
                  value={search}
                  name={'search'}
                  label={'Поиск'}
                />
              </Grid>
            </Grid>
            <React.Fragment key={page + search}>
              {getParameters().map(obj => {
                return obj
              })}
            </React.Fragment>
          </React.Fragment>
        ) : (
          <FFLoader />
        )}
      </Grid>
    </Context.Provider>
  )
}
