import React, { useEffect, useState, useContext } from 'react'

import { Grid } from '@material-ui/core/'
import { makeStyles, withStyles } from '@material-ui/core/styles'

/*
 **-------------------- My Components ---------------------------
 */

import FFTextField from '../../../libft/FFTextField'
import FFSelect from '../../../libft/FFSelect'
import FFLoader from '../../../libft/FFLoader'
import FFLoaderComponent from '../../../libft/FFLoaderComponent'

import Context from '../../../context'

const useStyles = makeStyles(theme => ({
  marginChip: {
    margin: theme.spacing(0.5)
  }
}))

export default function FFEditAlgorithmValue ({
  isLogin,
  handleClickVariant,
  deviceScenario,
  ...props
}) {
  const [parameters, setParameters] = useState(props.parameters)
  const [valueParameter, setValueParameter] = useState('')
  const [valueParameterPower, setValueParameterPower] = useState('')
  const [idParameter, setIdParameter] = useState(null)

  const { toGoSaveAlgorithm } = useContext(Context)

  useEffect(() => {
    if (idParameter) {
      let valueTemp = parameters.filter(obj => obj.id === idParameter)
      if (valueTemp.length) {
        setValueParameter(valueTemp[0].values.value)
      }
    }
  }, [idParameter])

  const changeValue = (value, id, name, element) => {
    deviceScenario.actual_value = value
    setIdParameter(value)
    toGoSaveAlgorithm(deviceScenario)
    return ''
  }

  useEffect(() => {
    if (parameters) {
      setIdParameter(deviceScenario?.actual_value)
      let value = parameters.filter(
        obj => obj.label.indexOf('Базовое потребление мощности') > -1
      )
      if (value.length) {
        setValueParameterPower(value[0].values.value)
      }
    }
  }, [parameters])

  return (
    <Context.Provider value={{ changeValue }}>
      <FFLoaderComponent
        key={'FFLoaderComponent'}
        loading={parameters}
        isLoader={<FFLoader />}
        component={
          <Grid item xs={12} container direction={'column'}>
            <FFTextField
              value={deviceScenario.label}
              id={'deviceScenario.device'}
              name={'label'}
              label={'Наименование'}
              is_disabled={true}
            />
            <FFTextField
              value={'Фактическое потребление'}
              id={'deviceScenario.algorithm'}
              name={'algorithm'}
              label={'Алгоритм'}
              is_disabled={true}
            />
            <FFTextField
              key={valueParameterPower}
              value={valueParameterPower}
              id={'valueParameterPower'}
              name={'valueParameterPower'}
              label={'Базовое потребление мощности'}
              is_disabled={true}
            />
            <FFSelect
              value={deviceScenario.actual_value}
              id={'deviceScenario.actual_value'}
              name={'actual_value'}
              label={'Параметр'}
              options={parameters}
            />
            <FFTextField
              key={valueParameter}
              value={valueParameter}
              id={'deviceScenario.actual_value.value'}
              name={'value'}
              label={'Значение'}
              is_disabled={true}
            />
          </Grid>
        }
      />
    </Context.Provider>
  )
}
