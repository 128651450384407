import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { SnackbarProvider, useSnackbar } from 'notistack'

import Context from '../context'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    margin: theme.spacing(2),
    textAlignLast: 'center'
  },
  resetContainer: {
    padding: theme.spacing(3)
  }
}))

export default function VerticalLinearStepper (props) {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = props.labelStep
  const { finishSave, goBack } = useContext(Context)
  console.log(props)
  const handleNext = () => {
    if (props.errorChange !== '') {
		handleClickVariant('error', props.errorChange)
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    goBack()
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    goBack()
    setActiveStep(0)
    finishSave(false)
  }

  const finish = () => {
    finishSave(true)
  }

  const getStepContent = step => {
    return props.component[step]
  }

  const { enqueueSnackbar } = useSnackbar()
  const handleClickVariant = (variant, msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(
      msg,
      { variant },
      {
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      }
    )
  }

  return (
    <React.Fragment>
      <Stepper activeStep={activeStep} orientation='vertical'>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Назад
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1
                      ? 'Закончить редактирование'
                      : 'Далее'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          {finish()}
          <Typography>
            Теперь вы можете сохранить измененные сведения!
            {/* {JSON.stringify(props.essence)} */}
          </Typography>
          <Button
            onClick={handleReset}
            color='secondary'
            className={classes.button}
          >
            Отмена
          </Button>
        </Paper>
      )}
    </React.Fragment>
  )
}
